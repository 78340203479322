// @flow
// @jsx h
// force
// External librairies
import { h, Component } from "preact";
import he from "he";

// Style
import styles from "./style.scss";
import themeKiosk from "./theme-kiosk.scss";
import themeMarketplace from "./theme-marketplace.scss";
import { ThemeProvider, WidgetContextProvider } from "elc-theme";

// Translations
import { IntlProvider } from "preact-i18n";
import ca from "../translations/ca.json";
import de from "../translations/de.json";
import en from "../translations/en.json";
import es from "../translations/es.json";
import fr from "../translations/fr.json";
import it from "../translations/it.json";
import nl from "../translations/nl.json";

//Components
import ClientForm from "./client-form";

// API
import { setToken } from "elc-open-api";

type ClientFormComponentProps = {
  locale?: string,
  config?: WidgetConfig,
  token: string,
  order: Order,
  currency: Currency,
  setOrderUser: Function,
  pageDidMountOrUpdate?: Function,
  redirectToPayment?: object
};

type ClientFormComponentState = {
  locale: Object,
  currency: Currency,
  currentCompany: Company
};

/**
 * Client form Component
 * that display a customer/client input form
 */
export default class App extends Component<
  ClientFormComponentProps,
  ClientFormComponentState
> {
  state = {
    locale: fr,
    currentCompany: undefined,
    currency: undefined
  };

  constructor(props) {
    super(props);
    // set OpenAPI endpoint with props
    if (props.apiEndpoint) {
      window.API_ENDPOINT = props.apiEndpoint;
    }
    // set OpenAPI token
    setToken(props.token);

    if (props.googleMapUrl) {
      window.GOOGLE_MAP_URL = props.googleMapUrl;
    }

    // set current locale from props
    switch (props.locale) {
      case "ca":
        this.changeLocale(ca);
        break;

      case "de":
        this.changeLocale(de);
        break;

      case "en":
        this.changeLocale(en);
        break;

      case "es":
        this.changeLocale(es);
        break;

      case "it":
        this.changeLocale(it);
        break;

      case "nl":
        this.changeLocale(nl);
        break;
    }

    // decode currency symbol
    props.currency.symbol = he.decode(props.currency.symbol);
    this.setState({ currency: props.currency });
    if (props.locale) {
      switch (props.locale) {
        case "ca":
          this.setState({ locale: ca });
          break;

        case "de":
          this.setState({ locale: de });
          break;

        case "en":
          this.setState({ locale: en });
          break;

        case "es":
          this.setState({ locale: es });
          break;

        case "it":
          this.setState({ locale: it });
          break;

        case "nl":
          this.setState({ locale: nl });
          break;
      }
    }
  }

  changeLocale = (locale: Object) => {
    this.setState({ locale });
  };

  getTheme = (configType: string, orderOrigin: string) => {
    return configType === "KIOSK"
      ? themeKiosk
      : configType === "SHOP" ||
        orderOrigin === "SHOP" ||
        configType === "PMS" ||
        orderOrigin === "PMS"
      ? themeMarketplace
      : undefined;
  };

  getWidgetContext = (configType: string, orderOrigin: string) => {
    return configType
      ? configType
      : orderOrigin === "KIOSK"
      ? "WIDGET"
      : orderOrigin;
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.currency !== this.state.currency) {
      nextProps.currency.symbol = he.decode(nextProps.currency.symbol);
      this.setState({ currency: nextProps.currency });
    }
    if (nextProps.locale !== this.props.locale) {
      switch (nextProps.locale) {
        case "fr":
          this.setState({ locale: fr });
          break;

        case "ca":
          this.setState({ locale: ca });
          break;

        case "de":
          this.setState({ locale: de });
          break;

        default:
        case "en":
          this.setState({ locale: en });
          break;

        case "es":
          this.setState({ locale: es });
          break;

        case "it":
          this.setState({ locale: it });
          break;

        case "nl":
          this.setState({ locale: nl });
          break;
      }
    }
  }

  render(
    {
      config,
      order,
      setOrderUser,
      pageDidMountOrUpdate,
      redirectToPayment
    }: ClientFormComponentProps,
    { locale, currency }: ClientFormComponentState
  ) {
    const currentTheme = this.getTheme(config.type, order && order.origin);
    return (
      <ThemeProvider theme={currentTheme}>
        <WidgetContextProvider
          widgetContext={this.getWidgetContext(
            config.type,
            order && order.origin
          )}
        >
          <IntlProvider definition={locale}>
            <div
              class={
                currentTheme && currentTheme.clientForm
                  ? currentTheme.clientForm
                  : styles.clientForm
              }
            >
              <ClientForm
                theme={currentTheme}
                config={config}
                currency={currency}
                order={order}
                setOrderUser={setOrderUser}
                pageDidMountOrUpdate={pageDidMountOrUpdate}
                locale={this.props.locale}
                redirectToPayment={redirectToPayment}
              />
            </div>
          </IntlProvider>
        </WidgetContextProvider>
      </ThemeProvider>
    );
  }
}
