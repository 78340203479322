// @flow
// @jsx h

import { h } from "preact";
import { Text } from "preact-i18n";

import { toLocalePrice } from "elc-commons";

import { ConfirmationIcon } from "elc-icons";

import styles from "./BankTransferConfirmation.scss";

/**
 * Bank transfer payment confirmation Component
 */
const BankTransfer = (
  {
    locale,
    order,
    payment,
    paymentMethod
  }: {
    config: WidgetConfig,
    locale: string,
    order: Order,
    payment: Payment,
    paymentMethod: PaymentMethod
  },
  { themeConfig }
) => (
  <div class={styles.column}>
    <header
      class={styles.headerTitle}
      style={`color:${
        themeConfig && themeConfig.color1 ? themeConfig.color1 : ""
      };`}
    >
      <h2>
        <Text id="payment.confirmation.title">Confirmation</Text>
      </h2>
    </header>
    <div class={styles.content}>
      <div class={styles.iconContainer}>
        <ConfirmationIcon color={styles.iconColor} />
      </div>
      <div class={styles.headerContainer}>
        <Text
          id="payment.confirmation.paymentMethod"
          fields={{ paymentMethodName: paymentMethod.baseModule.name }}
        >
          Vous avez choisi de payer par {paymentMethod.baseModule.name}.
        </Text>
      </div>
      <div class={styles.subHeaderContainer}>
        <div>
          <Text id="payment.confirmation.depositAmount">
            Pour finaliser votre commande, merci de regler la somme de :
          </Text>
        </div>
        <div>
          <span class={styles.priceAmount}>
            {toLocalePrice(
              payment.totalAmount / 100,
              locale,
              payment.currencyCode
            )}
          </span>
        </div>
      </div>
      <div>
        <Text id="payment.confirmation.bankTransfer.order">
          A l&apos;ordre de :
        </Text>
      </div>
      <div class={styles.bankDetails}>
        <div>
          <div class={styles.smallSize}>
            <Text id="payment.confirmation.bankTransfer.bankName">Bank :</Text>
          </div>
          <div class={styles.largeSize}>
            {paymentMethod.configuration.bankName}
          </div>
        </div>
        <div>
          <div class={styles.smallSize}>
            <Text id="payment.confirmation.bankTransfer.bankIBAN">IBAN :</Text>
          </div>
          <div>{paymentMethod.configuration.bankIban}</div>
        </div>
        <div>
          <div class={styles.smallSize}>
            <Text id="payment.confirmation.bankTransfer.bankBIC">BIC :</Text>
          </div>
          <div class={styles.largeSize}>
            {paymentMethod.configuration.bankBic}
          </div>
        </div>
      </div>
      {paymentMethod.configuration.deposit !== 100 && (
        <div class={styles.subHeaderContainer}>
          <div class={styles.smallSize}>
            <Text id="payment.confirmation.leftToPay">
              Solde à payer sur place :
            </Text>
          </div>
          <div>
            <span class={styles.paymentMethodDetail}>
              {toLocalePrice(
                order.balance - payment.totalAmount / 100,
                locale,
                payment.currencyCode
              )}
            </span>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default BankTransfer;
