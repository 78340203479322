// @flow
// @jsx h
import { h } from "preact";

/**
 * Confirmation icon component
 */
const Confirmation = ({ color }: { color?: string }) => (
  <svg
    fill={color ? color : "#000"}
    height="50"
    viewBox="0 0 50 50"
    width="50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M25,4.167c-11.5,0 -20.833,9.333 -20.833,20.833c0,11.5 9.333,20.833 20.833,20.833c11.5,0 20.833,-9.333 20.833,-20.833c0,-11.5 -9.333,-20.833 -20.833,-20.833Zm-3.38,30.791l-8.928,-8.929l2.518,-2.517l6.41,6.392l13.554,-13.553l2.518,2.536l-16.072,16.071Z" />
  </svg>
);
export default Confirmation;
