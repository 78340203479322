//@flow

declare var PHP_API_ENDPOINT: string;

import qs from "qs";
import { handleResponse } from "../handle-response";

let headers = new Headers();

headers.append("Accept", "application/json");
headers.append("Content-Type", "application/json");

export function getTermSalesByCompanyId(companyId: number): Promise<string> {
  return (
    fetch(
      `${PHP_API_ENDPOINT}/companies/${companyId}?${qs.stringify({
        contexts: ["merchant_termSales"]
      })}`
    )
      // return fetch(`${PHP_API_ENDPOINT}/api/v1/marketPlace/termSales/${companyId}`)
      .then(handleResponse)
      .then(response => {
        return response.termSale;
      })
  );
}

export function getTermSalesByOrder(orderId: number): Promise<Array> {
  return fetch(`${PHP_API_ENDPOINT}/order/${orderId}/cgvs`)
    .then(handleResponse)
    .then(response => {
      return response;
    });
}

/**
 * Send the URL params to api to get separate params
 * @param {string} params
 * @param orderId
 */
export function postParamsInURL(params: string, orderId: number) {
  //console.log("ORDER CHECK");
  //console.log(params, orderId);
  return fetch(`${PHP_API_ENDPOINT}/${orderId}/preprocess-url`, {
    method: "POST",
    headers,
    body: JSON.stringify(params)
  }).then(handleResponse);
}
