// @flow
// @jsx h
import { h, Component } from "preact";
import styles from "./style.scss";

export default class ProductImages extends Component {
  render({ image, pageDidMountOrUpdate }) {
    let srcSet = "";
    let originalImage = image._embedded.files.find(
      file => file.name === "ORIG"
    );
    srcSet = originalImage ? `${originalImage.path} 1024w` : "";
    let image400 = image._embedded.files.find(file => file.name === "400x400");
    srcSet += image400 ? `, ${image400.path} 640w` : "";
    let image200 = image._embedded.files.find(file => file.name === "200x200");
    srcSet += image200 ? `, ${image200.path} 320w` : "";
    return (
      <picture class={styles.productImgContainer}>
        <img
          onLoad={() => {
            pageDidMountOrUpdate();
          }}
          src={image._embedded.files[0].path}
          srcSet={srcSet}
          alt={image.title}
          class={styles.productImg}
        />
      </picture>
    );
  }
}
