// @flow
// @jsx h

import { h, Component } from "preact";

import QuantityCategory from "./category";
import Discount from "./discount";
import QuantityFB from "./fb-quantity";

import getFBDiscountInfos from "./getFBDiscountInfos";

import styles from "./style.scss";

type FranceBilletQuantitiesProps = {
  tax: Tax,
  locale: string,
  currency: Currency,
  currentSlot: TimeSlot,
  quantities: Map<string, number>,
  updateCurrentQuantities: Function
};

type FranceBilletQuantitiesState = {
  total: number,
  maxReached: boolean
};

/**
 * FranceBilletQuantities component
 */
export default class FranceBilletQuantities extends Component<
  FranceBilletQuantitiesProps,
  FranceBilletQuantitiesState
> {
  state = {
    total: 0,
    maxReached: false
  };

  /**
   * Increment the quantity of a given unit
   * @param {Object} unit - the unit on which you want to increment to the quantity
   */
  incrementUnitQuantity = (unitToIncrement: Unit) => {
    let quantities = new Map(this.props.quantities);
    quantities.set(
      unitToIncrement.publicMetadata.franceBillet.customerClass.customerClassId,
      (quantities.get(
        unitToIncrement.publicMetadata.franceBillet.customerClass
          .customerClassId
      ) || 0) + 1
    );
    this.setState(prevState => ({
      total: prevState.total + 1,
      maxReached: this.isMaxReached(prevState.total + 1)
    }));
    this.props.updateCurrentQuantities(quantities);
  };

  /**
   * Decrement quantity of given unit
   * @param {Unit} unit - the unit on which you want to decrement to the quantity
   */
  decrementUnitQuantity = (unit: Unit) => {
    let quantities = new Map(this.props.quantities);
    if (
      quantities.has(
        unit.publicMetadata.franceBillet.customerClass.customerClassId
      )
    ) {
      if (
        quantities.get(
          unit.publicMetadata.franceBillet.customerClass.customerClassId
        ) === 1
      ) {
        quantities.delete(
          unit.publicMetadata.franceBillet.customerClass.customerClassId
        );
      } else {
        quantities.set(
          unit.publicMetadata.franceBillet.customerClass.customerClassId,
          quantities.get(
            unit.publicMetadata.franceBillet.customerClass.customerClassId
          ) - 1
        );
      }
      this.props.updateCurrentQuantities(quantities);

      this.setState(prevState => ({
        total: prevState.total - 1,
        maxReached: this.isMaxReached(prevState.total - 1)
      }));
    }
  };

  isMaxReached(total) {
    return total === this.props.maxParticipants;
  }

  render({
    theme,
    tax,
    locale,
    currency,
    currentSlot,
    quantities
  }: {
    tax: Tax,
    locale: string,
    currency: Currency,
    currentSlot: TimeSlot,
    quantities: Map<string, number>
  }) {
    return (
      <div class={theme && theme.content ? theme.content : styles.content}>
        {currentSlot.publicMetadata.franceBillet &&
          currentSlot.publicMetadata.franceBillet.discounts &&
          currentSlot.publicMetadata.franceBillet.discounts.map(discount => (
            <Discount key={discount.discountId} discount={discount} />
          ))}
        {currentSlot._embedded.productUnitTimeSlotAvailabilities
          .reduce((categories, productUnitTimeSlotAvailability) => {
            let categoriesCopy = [...categories];
            const categoryName = productUnitTimeSlotAvailability._embedded.unit.name.split(
              ","
            )[0];
            const categoryReadableName = productUnitTimeSlotAvailability._embedded.unit.publicMetadata.franceBillet.customerClass.customerClassLabel.split(
              ","
            )[0];
            const categoryColor =
              productUnitTimeSlotAvailability._embedded.unit.publicMetadata
                .franceBillet.customerClass.customerPricingColor;

            const categoryIndex = categories.findIndex(
              category => categoryName === category.name
            );
            if (categoryIndex !== undefined && categoryIndex !== -1) {
              categoriesCopy[
                categoryIndex
              ].productUnitTimeSlotAvailabilities.push(
                productUnitTimeSlotAvailability
              );
              return categoriesCopy;
            } else {
              return categoriesCopy.concat([
                {
                  name: categoryName,
                  readableName: categoryReadableName,
                  color: categoryColor,
                  productUnitTimeSlotAvailabilities: [
                    productUnitTimeSlotAvailability
                  ]
                }
              ]);
            }
          }, [])
          .map((category, index) => (
            <QuantityCategory
              key={index}
              name={category.readableName}
              color={category.color}
            >
              {category.productUnitTimeSlotAvailabilities
                .map(
                  productUnitTimeSlotAvailability =>
                    productUnitTimeSlotAvailability._embedded.unit
                )
                .map(unit => (
                  <div
                    class={styles.unitQuantity}
                    key={
                      unit.publicMetadata.franceBillet.customerClass
                        .customerClassLabel
                    }
                  >
                    <QuantityFB
                      theme={theme}
                      color={
                        unit.publicMetadata.franceBillet.customerClass
                          .customerPricingColor
                      }
                      unit={unit}
                      tax={tax}
                      locale={locale}
                      currency={currency}
                      quantity={
                        quantities.has(
                          unit.publicMetadata.franceBillet.customerClass
                            .customerClassId
                        )
                          ? quantities.get(
                              unit.publicMetadata.franceBillet.customerClass
                                .customerClassId
                            )
                          : 0
                      }
                      discount={getFBDiscountInfos(
                        currentSlot.publicMetadata.franceBillet,
                        unit.publicMetadata.franceBillet.customerClass
                          .customerClassId,
                        quantities
                      )}
                      handleAddUnit={this.incrementUnitQuantity}
                      handleSubUnit={this.decrementUnitQuantity}
                    />
                  </div>
                ))}
            </QuantityCategory>
          ))}
      </div>
    );
  }
}
