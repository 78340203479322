const parseProductImpressionForGTM = (products, company, type, hash) => {
  let productsList = [];
  for (let i = 0; i <= products.items.length - 1; i++) {
    let product = products.items[i];
    //let price = String(parseFloat(Math.round((product['displayedStartingPrice']) * 100) / 100).toFixed(2));//On formate le nombre pour que google analytics l'accepte
    let zipCode = product.address
      ? product.address.zipCode
      : company ? company.address.zipCode : '00000';
    let category = product.category ? product.category.name : 'NoCategory';
    let subCategory = product.subCategory
      ? product.subCategory.name
      : 'NoSubCategory';

    productsList.push({
      id: product.id.toString(),
      name: product.name, // Name or ID is required.
      //"price": product.sellingPrices[0].value.toFixed(2),//String(product['displayedStartingPrice']), //Utiliser displayedStartingPrice ou bien sellingPrices[0]['value']
      category: product.type, //La catégorie n'est pas affichée dans les produits retournés, on renvoie donc le type du produit : GIFT OU CLASSIQUE
      position: 'page' + products.page + '-' + String(i + 1), //La position dans la liste de produits
      list: type + '-' + (company ? company.id : 0) + '-' + hash,
      dimension2: zipCode.substring(0, 2), //Departement -- TODO : département du produit ? du marchand ?
      dimension3: zipCode, //Code Postal
      dimension5: category, //Catégorie du produit
      dimension6: subCategory, //Sous catégorie du produit
      dimension11: product.id.toString() //id du produit
    });
  }
  return productsList;
};

export default parseProductImpressionForGTM;
