import parseProductForGTM from './parseProductForGTM';
import { push } from './EasyLoisirsGTMClient';

const sendCheckoutEventProductGTM = (product, step, options) => {
  let actionField = { step: step };
  if (options != false) {
    actionField.option = options;
  }
  push({
    event: 'EEcheckout',
    ecommerce: {
      checkout: {
        actionField: actionField,
        products: parseProductForGTM(product)
      }
    }
  });
};

export default sendCheckoutEventProductGTM;
