/* eslint-disable no-console */
//@flow

const env = process.env.NODE_ENV ? process.env.NODE_ENV : "unknown origin";
const hostname = window.location.host;

const push = function(data) {
  //Valeurs de env : staging, preprod, review, production
  if (window.ELdataLayer) {
    if (env === "production" && hostname.indexOf("localhost") === -1) {
      ELdataLayer.push(data);
    } else {
      // console.info("Push GTM data", data);
    }
  } else {
    console.error("Google Tag Manager is not defined !");
  }
};

export { push };
