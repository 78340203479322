// @flow
// @jsx h
import { h } from "preact";

const PCIDSS = () => (
  <svg
    width="27.653"
    height="39.999"
    viewBox="0 0 27.653 10.583"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(-.02 -.02) scale(.02441)"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="COMPLIANT-+-Rectangle-3-+-Letters-+-DSS"
        transform="translate(0 -8)"
      >
        <text
          id="COMPLIANT"
          fill="#225e63"
          fontFamily="'Arial Black'"
          fontSize="58"
          fontWeight="686"
        >
          <tspan x="742" y="272">
            COMPLIANT
          </tspan>
        </text>
        <g id="Rectangle-3-+-Letters" transform="translate(0 8.5)">
          <path
            d="m619.41 285.35 37.159-11.712-12.031-26.803c-8.9454 11.045-17.758 25.323-25.128 38.515zm-12.656-122.69-72.868-162.34-533.44 30.288 147.79 403.25 284.49-89.665c-18.034-26.01-24.727-56.928-3.8109-72.041 23.411-16.915 58.632 2.648 81.257 30.463 21.714-36.489 82.639-120.75 96.584-139.95z"
            fill="#225e63"
          />
          <g id="Letters" fill="#fefefe">
            <path
              d="m415.33 76.267c22.329 0 40.431-17.073 40.431-38.134s-18.101-38.134-40.431-38.134c-22.329 0-40.431 17.073-40.431 38.134s18.101 38.134 40.431 38.134zm-33.08 16.54h66.159v172.75h-66.159z"
              transform="translate(102 41.35)"
            />
            <path
              d="m349.59 143.22c0.4734 0.27827 0.85716 0.0509 0.85716-0.49448v-43.764c0-0.55136-0.37838-1.2191-0.87361-1.4829 0 0-8.6764-5.6554-35.159-7.231-1.4142-0.78237-24.461-0.89749-30.856 0-96.215 7.7156-99.784 77.325-99.784 80.504v16.911c0 2.0762 0 73.244 99.78 79.554 9.7482 0.74175 28.848 0 30.856 0 22.98-1e-5 38.906-6.9204 38.906-6.9204 0.5065-0.20525 0.91711-0.82517 0.91711-1.3706v-40.975c0-0.55172-0.36614-0.7475-0.84341-0.4569 0 0-7.1416 5.741-38.55 8.8041-8.9036 1.0012-13.128 0.53178-16.338 0-44.531-7.4889-46.651-40.116-46.651-40.116-0.0613-0.53976-0.11102-1.4269-0.11102-1.9733v-12.499c0-0.55008 0.0508-1.4384 0.12743-1.9794 0 0 3.0108-34.954 46.636-38.641h16.338c19.27 2.6024 34.747 12.13 34.747 12.13z"
              transform="translate(102 41.35)"
            />
            <path
              d="m0.26649 264.87c-1.345e-5 0.54964 0.44175 0.99522 0.99061 0.99522h64.45c0.54711 0 0.99063-0.45061 0.99063-0.99469v-50.134c0-0.54935 0.44052-0.96669 0.99472-0.93606 0 0 102.87 7.4426 102.87-61.616-1e-5 -54.492-64.543-60.471-85.793-59.473-0.42741 0.02007-83.5 2.34e-4 -83.5 2.34e-4 -0.55388-1.3e-4 -1.0029 0.45146-1.0029 0.99498zm65.283-89.36v-45.169h15.772s22.934 1.0324 24.72 16.635c0.41694 1.2191 0.31638 8.8567-2e-3 9.2777-2.9546 18.361-22.88 19.259-22.88 19.259z"
              transform="translate(102 41.35)"
            />
          </g>
          <path
            d="m519.15 390.31c5.3763 0 9.4946 0 16.292-3.0158 23.49-12.352 102.56-205.45 185.95-264.85 0.50825-0.38075 1.1898-0.93523 1.584-1.4993 0.54713-0.78292 0.56928-1.582 0.56928-1.582s-1e-5 -3.9833-12.365-3.9833c-74.243-2.0237-151.45 153.62-192.04 215.19-0.50693 0.67831-3.2987 0-3.2987 0s-27.197-32.083-50.825-44.397c-0.49263-0.25675-3.1805-1.076-5.9698-0.86914-1.8683 0-12.859 2.2116-17.979 7.6149-6.0522 6.386-5.927 9.97-5.8738 17.741 4e-3 0.63301 0.41067 3.1922 1.1602 4.5029 5.7762 10.101 32.163 46.381 53.784 66.397 3.3456 2.3807 8.4358 8.7412 29.002 8.7412z"
            fill="#2bbc5d"
          />
        </g>
        <text
          id="DSS"
          fill="#225e63"
          fontFamily="'Arial Black'"
          fontSize="188"
          fontWeight="686"
          letterSpacing="-3.2"
        >
          <tspan x="729" y="207">
            DS
          </tspan>
          <tspan x="1004.6" y="207">
            S
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);
export default PCIDSS;
