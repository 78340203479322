// @flow

let token;

/**
 * Store OpenAPI token in Session sessionStorage
 * @param {string} newToken - the Open API token to store
 */
export function setToken(newToken: string) {
  let today = new Date();
  newToken.expireDate = new Date(today.getTime() + newToken.expires_in * 1000);
  token = newToken;
  // sessionStorage.setItem('token', newToken);
}

/**
 * Get OpenAPI token from sessionStorage
 * @return {string} the Open API token stored
 */
export function getToken(): string {
  return token;
  // return sessionStorage.getItem('token');
}

let generateToken = () => {};

/**
 * Store the function that generate the OpenAPI token
 * @param {Function} generateTokenMethod - the Open API token generation method
 */
export function setGenerateTokenMethod(generateTokenMethod: Function) {
  generateToken = generateTokenMethod;
}

export const refreshToken = () => {
  return token && token.expireDate > new Date()
    ? Promise.resolve(token)
    : generateToken();
};

export const withToken = f => (...ys) => {
  return refreshToken().then(token => {
    setToken(token);
    return f(token.access_token, ...ys);
  });
};
