// @flow
// @jsx h

import { h } from 'preact';

import styles from './BackButton.scss';
import themeMarketplace from './theme-marketplace.scss';
import themeKiosk from './theme-kiosk.scss';

import Button from './Button';

/**
 * Common Button stateless functional Component
 */
const BackButton = (
  {
    type,
    handleClick,
    disabled,
    children,
    className,
    styleName
  }: {
    type?: string,
    handleClick?: Function,
    disabled?: boolean,
    children?: any,
    className?: string,
    styleName?: string | Object
  },
  context
) => {
  //   const colors = context.themeConfig;
  const theme =
    context.widgetContext === 'KIOSK'
      ? themeKiosk
      : context.widgetContext === 'SHOP' || context.widgetContext === 'PMS'
        ? themeMarketplace
        : undefined;

  let computedClassName = '';
  if (className) {
    computedClassName += className;
  }
  computedClassName += ` ${
    theme && theme.backButton ? theme.backButton : styles.backButton
  }`;
  return (
    <Button
      type={type || 'button'}
      handleClick={handleClick}
      disabled={disabled}
      className={computedClassName}
      styleName={styleName}
    >
      <span
        class={
          theme && theme.buttonContentWrapper
            ? theme.buttonContentWrapper
            : styles.buttonContentWrapper
        }
      >
        {children}
      </span>
    </Button>
  );
};
export default BackButton;
