// @flow
// @jsx h

// External librairies
import { h, Component } from "preact";
import scriptLoader from "react-async-script-loader";
import { toLocalePrice } from "elc-commons";

import { Text } from "preact-i18n";
import Link from "../link";
import {
  ArrowLeftIcon,
  MasterCardIcon,
  VisaIcon,
  MaestroIcon,
  VirementBancaireIcon,
  PCIDSSIcon
} from "elc-icons";

import StripePayment from "./StripePayment";

// Styles
import styles from "./style.scss";

/* FIXME when Preact implement getDerivedStateFromProps */
/* eslint-disable-next-line react/no-deprecated */
class LoadStripeScript extends Component {
  state = {
    loading: true
  };

  getWidgetContext = (configType: string, orderOrigin: string) => {
    return configType
      ? configType
      : orderOrigin === "KIOSK"
      ? "WIDGET"
      : orderOrigin;
  };

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        this.setState({ loading: false });
      } else this.props.onError();
    }
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate() {
    if (this.props.pageDidMountOrUpdate) {
      this.props.pageDidMountOrUpdate();
    }
  }

  render(
    {
      hash,
      config,
      currency,
      locale,
      order,
      paymentMethod,
      paymentMethods,
      setPaymentMethod,
      removeBeforeUnloadListener,
      callbackStripe,
      urlToShop,
      checkSession
    },
    { loading }
  ) {
    let bookings = order._embedded.bookings;
    let isClassicPresent = false;
    let isGiftPresent = false;
    let bookingsWithGiftPrice = 0;

    for (let bookingIndex = 0; bookingIndex < bookings.length; bookingIndex++) {
      if (bookings[bookingIndex]._embedded.product.type == "GIFT") {
        isGiftPresent = true;
        bookingsWithGiftPrice += bookings[bookingIndex].balance;
      } else if (bookings[bookingIndex]._embedded.product.type == "CLASSIC") {
        isClassicPresent = true;
      }
    }
    return (
      <div className={styles.column}>
        <header
          style={`color: ${
            this.context.themeConfig && this.context.themeConfig.color1
              ? this.context.themeConfig.color1
              : ""
          }`}
          className={styles.headerTitle}
        >
          <h2>
            <Text id="payment.creditCard.title">Pay with Stripe</Text>
            {order}
          </h2>
        </header>
        <div className={styles.content}>
          {order.totalPrice && (
            <div className={styles.priceInfos}>
              {paymentMethod && paymentMethod.configuration.deposit && (
                <div>
                  {paymentMethod.configuration.deposit != 100 && (
                    <div>
                      {isClassicPresent && (
                        <h3 className={styles.mainPrice}>
                          <span>
                            {this.props.orderRef &&
                            this.props.orderRef.payments.length ? (
                              <Text id="payment.left">Reste à payer :</Text>
                            ) : (
                              <Text id="payment.deposit">
                                Acompte à régler :
                              </Text>
                            )}
                          </span>
                          {this.props.orderRef &&
                          this.props.orderRef.payments.length ? (
                            <div>
                              <strong className={styles.price}>
                                {toLocalePrice(
                                  order.balance,
                                  locale,
                                  currency.isoCode
                                )}
                              </strong>
                            </div>
                          ) : (
                            <div>
                              <strong className={styles.price}>
                                {toLocalePrice(
                                  (paymentMethod.configuration.deposit *
                                    (order.balance - bookingsWithGiftPrice)) /
                                    100,
                                  locale,
                                  currency.isoCode
                                )}
                              </strong>
                              <span>
                                &nbsp;
                                <Text id="payment.deposit.of">sur</Text>
                                &nbsp;
                              </span>
                              <strong className={styles.price}>
                                {toLocalePrice(
                                  order.balance - bookingsWithGiftPrice,
                                  locale,
                                  currency.isoCode
                                )}
                              </strong>
                            </div>
                          )}
                        </h3>
                      )}
                      {isGiftPresent && (
                        <h3 className={styles.mainPrice}>
                          <span>
                            <Text id="payment.gifts">
                              Montant des bons cadeaux :
                            </Text>
                          </span>
                          <div>
                            <strong className={styles.price}>
                              {toLocalePrice(
                                bookingsWithGiftPrice,
                                locale,
                                currency.isoCode
                              )}
                            </strong>
                          </div>
                        </h3>
                      )}
                      <div className={styles.explanation}>
                        <Text id="payment.deposit.explanation">
                          Vous devez régler cet acompte pour terminer votre
                          réservation.
                        </Text>
                        {isGiftPresent && (
                          <div>
                            <Text id="payment.deposit.explanation">
                              Le(s) bon(s) cadeau(x) de votre commande ne sont
                              pas pris en charge par le versement de
                              l&apos;acompte. Ils doivent être réglés en
                              totalité.
                            </Text>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {paymentMethod.configuration.deposit == 100 && (
                    <h3 className={styles.mainPrice}>
                      <span>
                        <Text id="payment.balance">
                          Montant total à payer :
                        </Text>
                      </span>
                      <div>
                        <strong className={styles.price}>
                          {toLocalePrice(
                            order.balance,
                            locale,
                            currency.isoCode
                          )}
                        </strong>
                      </div>
                    </h3>
                  )}
                </div>
              )}
              {paymentMethod && !this.props.orderRef && !isGiftPresent && (
                <span className={styles.priceSubInfo}>
                  {" "}
                  <Text
                    id="payment.remainingAmount"
                    fields={{
                      amount: toLocalePrice(
                        ((100 - paymentMethod.configuration.deposit) *
                          order.balance) /
                          100,
                        locale,
                        currency.isoCode
                      )
                    }}
                  >
                    Il vous restera{" "}
                    {toLocalePrice(
                      ((100 - paymentMethod.configuration.deposit) *
                        order.balance) /
                        100,
                      locale,
                      currency.isoCode
                    )}{" "}
                    à régler sur place.
                  </Text>
                </span>
              )}
            </div>
          )}
          {loading ? (
            <div>Loading script</div>
          ) : (
            <StripePayment
              hash={hash}
              config={config}
              currency={currency}
              locale={locale}
              order={order}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              removeBeforeUnloadListener={removeBeforeUnloadListener}
              callbackStripe={callbackStripe}
              urlToShop={urlToShop}
              checkSession={checkSession}
            />
          )}
          {paymentMethods && paymentMethods.length > 1 && order.origin !== 'SHOP' && (
            <div className={styles.row}>
              <Link
                href={`/order/${order.id}/otherPaymentMethods`}
                className={styles.otherPayments}
              >
                <Text id="payment.creditCard.otherPaymentMethods">
                  Choose another payment method
                </Text>
              </Link>
            </div>
          )}
          <div className={styles.row}>
            <Link href={`/order/${order.id}`} className={styles.previousButton}>
              <ArrowLeftIcon
                color={
                  this.context.themeConfig && this.context.themeConfig.color1
                    ? this.context.themeConfig.color1
                    : styles.backIconColor
                }
              />
              <Text id="payment.backToBasket">Retour au panier</Text>
            </Link>
          </div>
          <div className={styles.row}>
            <div className={styles.ccImgContainer}>
              <MasterCardIcon />
              <MaestroIcon />
              <VisaIcon />
              <VirementBancaireIcon />
              <PCIDSSIcon />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default scriptLoader("https://js.stripe.com/v3/")(LoadStripeScript);
