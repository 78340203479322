// @flow
// @jsx h

import { h } from "preact";
import { Text, IntlProvider } from "preact-i18n";

import { en, fr, de, nl, it, es, ca } from "./translations";

import styles from "./InputError.scss";

/**
 * Common Input error stateless functional Component
 */
const InputError = ({
  error,
  locale
}: {
  error: {
    id: number,
    type: "mandatory" | "invalid" | string
  },
  locale?: string
}) => {
  let localeDefinition = "";

  switch (locale) {
    case "fr":
      localeDefinition = fr;
      break;
    case "en":
      localeDefinition = en;
      break;
    case "de":
      localeDefinition = de;
      break;
    case "nl":
      localeDefinition = nl;
      break;
    case "it":
      localeDefinition = it;
      break;
    case "es":
      localeDefinition = es;
      break;
    case "ca":
      localeDefinition = ca;
      break;
    default:
      localeDefinition = fr;
  }

  // let localeDefinition = locale ? (locale === "fr" ? fr : en) : fr;
  return (
    <IntlProvider definition={localeDefinition}>
      <div class={styles.error}>
        {error.type === "mandatory" ? (
          <Text id="clientForm.error.mandatory">This field is mandatory</Text>
        ) : error.type === "invalid" ? (
          <Text id="clientForm.error.invalid">This field is invalid</Text>
        ) : error.type === "invalidconfirm" ? (
          <Text id="clientForm.error.invalidconfirm">
            This field is invalid
          </Text>
        ) : error.type === "confirmationrequired" ? (
          <Text id="clientForm.error.confirmationrequired">
            This field is required
          </Text>
        ) : (
          <div>{error.type}</div>
        )}
      </div>
    </IntlProvider>
  );
};

export default InputError;
