// @flow
// @jsx h

import { h } from "preact";

import { withText } from "preact-i18n";

// Tab component
import Tab from "./Tab";
import TabIcon from "./TabIcon";
import TabLabel from "./TabLabel";

// Icons
import { SlotIcon } from "elc-icons";

/**
 * Slot tab component
 */
const SlotTab = withText({ label: "header.slot" })(
  ({
    theme,
    active,
    currentSlot,
    handleClick,
    label
  }: {
    theme?: Object,
    active: boolean,
    currentSlot?: Slot,
    handleClick?: Function,
    label: string
  }) => (
    <Tab handleClick={handleClick}>
      <TabIcon theme={theme} active={active}>
        <SlotIcon />
      </TabIcon>
      <TabLabel
        theme={theme}
        active={active}
        label={
          currentSlot
            ? `${new Date(currentSlot.startDateTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
                timeZone: "Europe/Paris"
              })}`
            : label
        }
      />
    </Tab>
  )
);

export default SlotTab;
