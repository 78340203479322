// @flow
// @jsx h
import { h, Component } from "preact";
import styles from "./FormInput.scss";
import InputError from "./InputError";
import PhoneInput from "./PhoneInput";
import SwitchInput from "./SwitchInput";
import AutocompleteAddress from "./AutocompleteAddress";
import { Text } from "preact-i18n";

declare var GOOGLE_MAP_URL: string;

type FormInputProps = {
  clientFormField: ClientFormField,
  handleInputChange: Function,
  error: boolean,
  locale: string,
  isClientInformationMandatory?: boolean
};

export default class FormInput extends Component<FormInputProps> {
  constructor(props: FormInputProps) {
    super(props);

    if (
      props.clientFormField._embedded.clientFormInputType.inputType === "TEL" &&
      props.value !== undefined
    ) {
      props.handleInputChange({
        value: props.value,
        clientFormInputType: {
          id: props.clientFormField._embedded.clientFormInputType.id,
          code: props.clientFormField._embedded.clientFormInputType.code,
          type: props.clientFormField._embedded.clientFormInputType.type,
          inputType:
            props.clientFormField._embedded.clientFormInputType.inputType
        },
        isValid: true
      });
    }

    if (
      props.clientFormField._embedded.clientFormInputType.inputType ===
        "SELECT" &&
      props.value === undefined
    ) {
      // Always set a default value for select inputs
      let defaultValue =
        props.clientFormField._embedded.clientFormInputType._embedded.options[
          Object.keys(
            props.clientFormField._embedded.clientFormInputType._embedded
              .options
          )[0]
        ];
      props.handleInputChange({
        value: defaultValue,
        clientFormInputType: {
          id: props.clientFormField._embedded.clientFormInputType.id,
          code: props.clientFormField._embedded.clientFormInputType.code,
          type: props.clientFormField._embedded.clientFormInputType.type,
          inputType:
            props.clientFormField._embedded.clientFormInputType.inputType
        },
        isValid: true
      });
    }
  }

  onInputChange = ({ target }: SyntheticInputEvent<HTMLInputElement>) => {
    const value: boolean | string =
      target.type === "checkbox" ? (target.checked ? "1" : "0") : target.value;

    this.props.handleInputChange({
      value,
      clientFormInputType: {
        id: this.props.clientFormField._embedded.clientFormInputType.id,
        code: this.props.clientFormField._embedded.clientFormInputType.code,
        type: this.props.clientFormField._embedded.clientFormInputType.type,
        inputType: this.props.clientFormField._embedded.clientFormInputType
          .inputType
      },
      isValid: target.isValid
    });
  };

  onInputConfirmChange = ({
    target
  }: SyntheticInputEvent<HTMLInputElement>) => {
    const value: string = target.value;

    this.props.handleInputChange({
      value,
      clientFormInputType: {
        inputType: "EMAILCONFIRM"
      }
    });
  };

  onAddressChange = (address: GoogleAddress) => {
    const value = JSON.stringify(address);
    this.props.handleInputChange({
      value,
      clientFormInputType: {
        id: this.props.clientFormField._embedded.clientFormInputType.id,
        code: this.props.clientFormField._embedded.clientFormInputType.code,
        type: this.props.clientFormField._embedded.clientFormInputType.type,
        inputType: this.props.clientFormField._embedded.clientFormInputType
          .inputType
      }
    });
  };

  render({
    theme,
    clientFormField,
    value,
    error,
    locale,
    isClientInformationMandatory,
    emailconfirmvalue
  }: {
    clientFormField: ClientFormField,
    value?: string | boolean,
    error?: {
      id: number,
      type: "mandatory" | "invalid"
    },
    locale?: string,
    isClientInformationMandatory?: boolean
  }) {
    if (clientFormField._embedded.clientFormInputType.inputType === "TEL") {
      return (
        <div
          key={clientFormField.id}
          class={
            theme && theme.inputField ? theme.inputField : styles.inputField
          }
        >
          <label
            htmlFor={clientFormField.id}
            class={theme && theme.label ? theme.label : styles.label}
          >
            {clientFormField._embedded.clientFormInputType.label}
            {clientFormField.mandatory && (
              <span
                class={
                  theme && theme.requiredSign
                    ? theme.requiredSign
                    : styles.requiredSign
                }
              >
                *
              </span>
            )}
            {!clientFormField.mandatory && (
              <span>
                {" "}
                (<Text id="clientForm.facultatif">facultatif</Text>)
              </span>
            )}
          </label>
          <PhoneInput
            name={clientFormField._embedded.clientFormInputType.label}
            id={clientFormField.id}
            value={value}
            placeholder={clientFormField._embedded.clientFormInputType.label}
            onChange={this.onInputChange}
            locale={locale}
          />
          {error && <InputError error={error} locale={locale} />}
        </div>
      );
    } else if (
      clientFormField._embedded.clientFormInputType.inputType === "EMAIL"
    ) {
      return (
        <div
          key={clientFormField.id}
          class={
            theme && theme.inputField ? theme.inputField : styles.inputField
          }
        >
          <label
            htmlFor={clientFormField.id}
            class={theme && theme.label ? theme.label : styles.label}
          >
            {clientFormField._embedded.clientFormInputType.label}
            {clientFormField.mandatory && (
              <span
                class={
                  theme && theme.requiredSign
                    ? theme.requiredSign
                    : styles.requiredSign
                }
              >
                *
              </span>
            )}
            {!clientFormField.mandatory && (
              <span>
                {" "}
                (<Text id="clientForm.facultatif">facultatif</Text>)
              </span>
            )}
          </label>
          <input
            name={clientFormField._embedded.clientFormInputType.label}
            id={clientFormField.id}
            type="email"
            value={value}
            placeholder={clientFormField._embedded.clientFormInputType.label}
            onChange={this.onInputChange}
            class={styles.input}
          />
          <div
            class={
              theme && theme.inputField ? theme.inputField : styles.inputField
            }
          >
            <label
              htmlFor={clientFormField.id + "confirm"}
              class={theme && theme.label ? theme.label : styles.label}
              style="margin-top:8px;"
            >
              <Text id="clientForm.emailconfirm">Confirmation email</Text>
              {clientFormField.mandatory && (
                <span class="requiredSign__2J5yT">*</span>
              )}
              {!clientFormField.mandatory && (
                <span>
                  {" "}
                  (<Text id="clientForm.facultatif">facultatif</Text>)
                </span>
              )}
            </label>
            <input
              name={
                clientFormField._embedded.clientFormInputType.label + "confirm"
              }
              id={clientFormField.id + "confirm"}
              type="email"
              onChange={this.onInputConfirmChange}
              autocomplete="off"
              defaultValue=""
              value={emailconfirmvalue}
              onPaste={e => {
                e.preventDefault();
              }}
              class={styles.input}
            />
          </div>
          {error && <InputError error={error} locale={locale} />}
        </div>
      );
    } else if (
      clientFormField._embedded.clientFormInputType.inputType === "TEXT"
    ) {
      return (
        <div
          key={clientFormField.id}
          class={
            theme && theme.inputField ? theme.inputField : styles.inputField
          }
        >
          <label
            htmlFor={clientFormField.id}
            class={theme && theme.label ? theme.label : styles.label}
          >
            {clientFormField._embedded.clientFormInputType.label}
            {clientFormField.mandatory && (
              <span
                class={
                  theme && theme.requiredSign
                    ? theme.requiredSign
                    : styles.requiredSign
                }
              >
                *
              </span>
            )}
          </label>
          <input
            name={clientFormField._embedded.clientFormInputType.label}
            id={clientFormField.id}
            type="text"
            value={value}
            placeholder={clientFormField._embedded.clientFormInputType.label}
            onChange={this.onInputChange}
            class={styles.input}
          />
          {error && <InputError error={error} locale={locale} />}
        </div>
      );
    } else if (
      clientFormField._embedded.clientFormInputType.inputType === "SELECT"
    ) {
      return (
        <div
          key={clientFormField.id}
          class={
            theme && theme.inputField ? theme.inputField : styles.inputField
          }
        >
          <label class={theme && theme.label ? theme.label : styles.label}>
            {clientFormField._embedded.clientFormInputType.label}
            {clientFormField.mandatory && (
              <span
                class={
                  theme && theme.requiredSign
                    ? theme.requiredSign
                    : styles.requiredSign
                }
              >
                *
              </span>
            )}
          </label>
          <div
            class={
              theme && theme.selectWrapper
                ? theme.selectWrapper
                : styles.selectWrapper
            }
          >
            <select
              name={clientFormField._embedded.clientFormInputType.label}
              value={value}
              onChange={this.onInputChange}
              class={styles.select}
            >
              {Object.entries(
                clientFormField._embedded.clientFormInputType._embedded.options
              ).map(entry => (
                <option key={entry[1]} value={entry[1]}>
                  {entry[0]}
                </option>
              ))}
            </select>
          </div>
          {error && <InputError error={error} locale={locale} />}
        </div>
      );
    } else if (
      clientFormField._embedded.clientFormInputType.inputType === "CHECKBOX"
    ) {
      return (
        <div
          key={clientFormField.id}
          class={
            theme && theme.inputField ? theme.inputField : styles.inputField
          }
        >
          <div
            class={
              theme && theme.switchInput
                ? theme.switchInput
                : styles.switchInput
            }
          >
            <SwitchInput
              name={clientFormField._embedded.clientFormInputType.label}
              id={clientFormField.id}
              value={value}
              onChange={this.onInputChange}
            />
            <label
              class={theme && theme.label ? theme.label : styles.label}
              htmlFor={clientFormField.id}
            >
              {clientFormField._embedded.clientFormInputType.label}
              {clientFormField.mandatory && (
                <span
                  class={
                    theme && theme.requiredSign
                      ? theme.requiredSign
                      : styles.requiredSign
                  }
                >
                  *
                </span>
              )}
            </label>
          </div>
          {error && <InputError error={error} locale={locale} />}
        </div>
      );
    } else if (
      clientFormField._embedded.clientFormInputType.inputType === "DATE"
    ) {
      return (
        <div
          key={clientFormField.id}
          class={
            theme && theme.inputField ? theme.inputField : styles.inputField
          }
        >
          <label
            htmlFor={clientFormField.id}
            class={theme && theme.label ? theme.label : styles.label}
          >
            {clientFormField._embedded.clientFormInputType.label}
          </label>
          <input
            name={clientFormField._embedded.clientFormInputType.label}
            id={clientFormField.id}
            type="date"
            placeholder={clientFormField._embedded.clientFormInputType.label}
            onChange={this.onInputChange}
            class={styles.input}
          />
          {clientFormField.mandatory && (
            <div
              class={
                theme && theme.requiredSign
                  ? theme.requiredSign
                  : styles.requiredSign
              }
            >
              *
            </div>
          )}
          {error && <InputError error={error} locale={locale} />}
        </div>
      );
    } else if (
      clientFormField._embedded.clientFormInputType.inputType === "ADDRESS"
    ) {
      return (
        <AutocompleteAddress
          key={clientFormField.id}
          googleMapURL={GOOGLE_MAP_URL}
          loadingElement={<div style={{ height: `100%` }} />}
          value={value ? JSON.parse(value) : undefined}
          clientFormField={clientFormField}
          onAddressChange={this.onAddressChange}
          isClientInformationMandatory={isClientInformationMandatory}
          error={error}
        />
      );
    }
  }
}
