export default class LoadCSS {
  load(style) {
    let styles = document.createElement("link");
    styles.rel = "stylesheet";
    styles.type = "text/css";
    styles.media = "screen";

    if (style.indexOf("http://") == -1 && style.indexOf("https://") == -1) {
      styles.href = `${window.location.origin}/widget/${style}`;
    } else {
      styles.href = style;
    }

    document.getElementsByTagName("head")[0].appendChild(styles);
  }
}
