// @flow
// @jsx h

import { h, Component } from "preact";
import { Text } from "preact-i18n";

import $ from "jquery";

import styles from "./ParticipantClientForms.scss";
import BookingSummary from "../order-summary/BookingSummary.js";
import BookingParticipantClientForms from "./booking-participant-client-forms/BookingParticipantClientForms.js";
import Link from "../link";
import { ArrowLeftIcon } from "elc-icons";

import { ConfirmationIcon } from "elc-icons";
import { dateFormater } from "elc-commons";

import {
  getOrderById,
  getParticipantClientForm,
  setGenerateTokenMethod,
  getParticipantClientData
} from "elc-open-api";
import { SecondaryButton } from "elc-button";
import { generateTokenByOrderReference } from "elc-internal-api";
import ParticipipantClientFormsHeader from "./ParticipipantClientFormsHeader";

export default class ParticipantClientForms extends Component {
  state = {
    mobileDisplay: true,
    currentOpenedBooking: 0,
    changeLoadingState: null,
    isParticipantEmpty: true
  };

  componentDidMount() {
    setGenerateTokenMethod(() =>
      generateTokenByOrderReference(this.props.orderRef)
    );
    getOrderById(this.props.orderId)
      .then(order => {
        this.setState({ order });
        return Promise.all(
          order._embedded.bookings.map(booking =>
            Promise.all([
              getParticipantClientForm(booking.id).catch(error => {
                if (error.status !== 404) {
                  this.setState({ error });
                  // return Promise.reject();
                  throw new Error();
                } else {
                  return;
                }
              }),
              booking
            ])
          )
        );
      })
      .then(responseArray => {
        let bookingsParticipantClientForm = responseArray;

        let currentOpenedBooking = bookingsParticipantClientForm.findIndex(
          clientForm => {
            return clientForm[0] !== undefined;
          }
        );
        this.setState({ bookingsParticipantClientForm, currentOpenedBooking });
        this.props.changeLoadingState();
        this.checkValidParticipantForm();
        return responseArray;
      })
      .catch(error => {
        console.error("Error getParticipantClientForm", error);
      });

    if (!window.matchMedia) return;
    this.mql = window.matchMedia(
      "(max-width: " +
        (styles.mobileMode ? styles.mobileMode : "53.750em") +
        ")"
    );
    this.mql.addListener(this.onMatch);
    this.onMatch(this.mql);
  }

  checkValidParticipantForm() {
    const { bookingsParticipantClientForm } = this.state;
    let bookingId = 0;
    let participantClientForm = [];

    for (let i = 0; i < bookingsParticipantClientForm[0].length; i++) {
      const item = bookingsParticipantClientForm[0][i];
      if (typeof item.balance === "number") {
        bookingId = item.id;
      }
      if (item && item.infoPerParticipant) {
        participantClientForm = item._embedded.clientFormFields;
      }
    }
    getParticipantClientData(bookingId)
      .then(participantClientData => {
        const clientData = participantClientData._embedded.clientData;
        if (!clientData.length) {
          return;
        }
        for (
          let indexForm = 0;
          indexForm < participantClientForm.length;
          indexForm++
        ) {
          const item = participantClientForm[indexForm];
          if (item.mandatory) {
            if (
              item._embedded.clientFormInputType.id ===
              clientData[indexForm]._embedded.clientFormInputTypeSaved.id
            ) {
              if (clientData[indexForm].value !== "") {
                this.setState({
                  isParticipantEmpty: false
                });
              }
            }
          }
        }
        return participantClientData;
      })
      .catch(error => {
        console.error("Error getParticipantClientData", error);
      });
  }

  getClientData(clientData) {
    return clientData;
  }

  componentWillUnmount() {
    this.mql && this.mql.removeListener(this.onMatch);
  }

  onMatch = mql => {
    const mobileDisplay = !!mql.matches;
    this.setState({ mobileDisplay });
  };

  render(
    props,
    { bookingsParticipantClientForm, order, themeConfig, participantClientData }
  ) {
    return (
      <div>
        <div class={styles.row}>
          <div class={styles.cellSummary}>
            <header class={styles.orderSummaryHeader}>
              <h2 class={styles.orderSummaryHeaderTitle}>
                <Text
                  id="participantClientForm.nbOrder"
                  fields={{
                    nbBookings: order
                      ? order._embedded.bookings.filter(booking => {
                          return booking._embedded.product.type !== "EXTRA";
                        }).length
                      : 0
                  }}
                >
                  You have ordered{" "}
                  {order
                    ? order._embedded.bookings.filter(booking => {
                        return booking._embedded.product.type !== "EXTRA";
                      }).length
                    : 0}{" "}
                  product(s)
                </Text>
              </h2>
            </header>
            <ul class={styles.bookingsList}>
              {order &&
                order._embedded.bookings
                  .filter(booking => {
                    return booking._embedded.product.type !== "EXTRA";
                  })
                  .map((booking, index) => (
                    <li key={booking.id}>
                      <BookingSummary booking={booking} />
                      {!this.mobileDisplay &&
                        order._embedded.bookings.length > 1 &&
                        bookingsParticipantClientForm &&
                        bookingsParticipantClientForm[index][0] && (
                          <SecondaryButton
                            className={styles.setBookingButton}
                            handleClick={() => {
                              this.setState({ currentOpenedBooking: index });
                            }}
                          >
                            <Text id="participantClientForm.completeInfos">
                              Complete your informations
                            </Text>
                          </SecondaryButton>
                        )}
                    </li>
                  ))}
            </ul>
          </div>
          <div class={styles.cellBasket}>
            <div class={styles.participantFormContainer}>
              {this.state.isParticipantEmpty ? (
                <div>
                  <ParticipipantClientFormsHeader
                    textId={"participantClientForm.title"}
                    context={this.context}
                  />
                  <div class={styles.participantFormContent}>
                    <div class={styles.participantFormHeader}>
                      <Text id="participantClientForm.label">
                        Merci de prendre un instant pour compléter le(s)
                        formulaire(s) suivant(s)
                      </Text>
                    </div>
                    {bookingsParticipantClientForm &&
                      bookingsParticipantClientForm.map(
                        ([participantClientForm, booking], index) => (
                          <div
                            key={booking.id}
                            class={
                              this.state.currentOpenedBooking === index ||
                              this.state.mobileDisplay
                                ? styles.visibleBooking
                                : styles.hiddenBooking
                            }
                          >
                            <BookingParticipantClientForms
                              booking={booking}
                              participantClientForm={participantClientForm}
                              localeString={this.props.localeString}
                              getClientData={this.getClientData}
                            />
                            {this.props.hash && (
                              <Link
                                href={`/${this.props.hash}/products`}
                                className={styles.newOrderButton}
                              >
                                <ArrowLeftIcon
                                  color={
                                    themeConfig && themeConfig.color1
                                      ? themeConfig.color1
                                      : styles.arrowColor
                                  }
                                />
                                <Text id="payment.confirmation.newOrder">
                                  Pass a new order
                                </Text>
                              </Link>
                            )}
                          </div>
                        )
                      )}
                  </div>
                </div>
              ) : (
                order && (
                  <div>
                    <ParticipipantClientFormsHeader
                      textId={"order.finalized.title"}
                      context={this.context}
                    />
                    <div class={styles.content}>
                      <div class={styles.headerContainer}>
                        <Text id="order.finalized.elementValidate">
                          Tous les éléments requis pour votre commande ont été
                          validés.
                        </Text>
                      </div>
                      <div class={styles.iconContainer}>
                        <ConfirmationIcon color={styles.iconColor} />
                      </div>
                      <div class={styles.headerContainer}>
                        <Text id="order.finalized.rendezVous">
                          On se donne rendez-vous:
                        </Text>
                      </div>
                      <div class={styles.dateContainer}>
                        <Text id="order.finalized.in">le </Text>
                        <strong>
                          <Text
                            id="order.finalized.date"
                            fields={{
                              date: dateFormater(
                                order._embedded.bookings[0].startTime,
                                order.locale
                              )
                            }}
                          >
                            {dateFormater(
                              order._embedded.bookings[0].startTime,
                              order.locale
                            )}
                          </Text>
                        </strong>
                        <Text id="order.finalized.at"> , à </Text>
                        <strong>
                          <Text
                            id="order.finalized.date"
                            fields={{
                              date: dateFormater(
                                order._embedded.bookings[0].startTime,
                                order.locale,
                                "time"
                              )
                            }}
                          >
                            {dateFormater(
                              order._embedded.bookings[0].startTime,
                              order.locale,
                              "time"
                            )}
                          </Text>
                        </strong>
                      </div>
                      <div class={styles.seeYou}>
                        <Text id="order.finalized.seeYou"> A bientôt! </Text>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
