// @flow
// @jsx h
import { h } from "preact";

import styles from "./style.scss";

import ProductMap from "./productmap";

/**
 * Product address and map component
 */
const MapTab = props =>
  props.address && props.displayMap ? (
    <div class={styles.mapContent}>
      <ProductMap
        marker={{
          position: {
            lat: props.address.latitude,
            lng: props.address.longitude
          },
          key: props.product.name,
          defaultAnimation: 2
        }}
        class={styles.mapElement}
      />
      <div class={styles.address}>
        <div class={styles.row}>
          {props.address.address1} {props.address.address2}
          {", "}
          {props.address.zipCode} {props.address.city}
        </div>
        {props.address.state && (
          <div class={styles.row}>{props.address.state}</div>
        )}
      </div>
    </div>
  ) : null;

export default MapTab;
