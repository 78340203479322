const parseProductsForGTM = order => {
  let products = [];
  let booking;
  let promocode = false;

  for (let i = 0; i < order._embedded.bookings.length; i++) {
    booking = order._embedded.bookings[i];
    let price = booking.totalTax; // ou totalPriceHt + tax
    //Comme on ne peut pas compter les extras comme des produits, on va parcourir les extras associés à chaque produit et ajouter le prix au produit initial
    //(On a également l'option à l'étape précédente qui indique "Avec Extras" ou "Sans Extras")
    if (booking._embedded.associatedBookings) {
      for (let j = 0; j < booking._embedded.associatedBookings.length; j++) {
        price += booking._embedded.associatedBookings[j].totalTax; // ou totalPriceHt + tax
      }
    }
    if (booking._embedded.promoCodes) {
      if (booking._embedded.promoCodes.length > 0) {
        //On ne peut envoyer qu'un seul promocode à analytics, j'envoie donc le premier dans la liste
        promocode = booking._embedded.promoCodes[0].reference; //reference = MINREQUIS50, voucherCode = MINREQUIS50, name = PROMO MIN REQUIS
      }
    }

    /*
      ne sais pas si on préfère un code comme celui-ci, ou celui d'après en ternaire #JimmyILearnJavaScriptI'mSorryGuys :
      var zipCode;
      if(booking._embedded.product._embedded.address){
        zipCode = booking._embedded.product._embedded.address.zipCode;
      }else if(booking._embedded.product._embedded.merchant._embedded.address){
        zipCode = booking._embedded.product._embedded.merchant._embedded.address.zipCode;
      }else{
        zipCode = '00000';
      }
      */
    let zipCode = booking._embedded.product._embedded.address
      ? booking._embedded.product._embedded.address.zipCode
      : booking._embedded.product._embedded.merchant._embedded.address
        ? booking._embedded.product._embedded.merchant._embedded.address.zipCode
        : '00000';
    let category = booking._embedded.product._embedded.category
      ? booking._embedded.product._embedded.category.name
      : 'NoCategory';
    let subCategory = booking._embedded.product._embedded.subCategory
      ? booking._embedded.product._embedded.subCategory.name
      : 'NoSubCategory';

    products.push({
      id: booking._embedded.product.id.toString(),
      name: booking._embedded.product.name,
      price: parseFloat((price / booking.quantity).toFixed(2)),
      //Obligatoire de diviser le prix du booking par nombre d'unités
      //En effet, si price : 300, et quantity : 4, il va comprendre que 4 produits à 300 ont été vendus, et non 4 produits pour un total de 300,
      //et donc la vente de 300 sera interprétée comme une vente de 1200
      category: booking._embedded.product.type,
      quantity: booking.quantity,
      dimension2: zipCode.substring(0, 2), //Departement -- TODO : département du produit ? du marchand ?
      dimension3: zipCode, //Code Postal
      dimension5: category, //Catégorie du produit
      dimension6: subCategory, //Sous catégorie du produit
      dimension11: booking._embedded.product.id.toString() //id du produit
    });
  }
  return { products: products, promocode: promocode };
};

export default parseProductsForGTM;
