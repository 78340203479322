// @flow
// @jsx h
import { h } from "preact";
import styles from "./Tabs.scss";
import { withStyles } from "elc-theme";

/**
 * Tabs stateless functional component
 * used to display the booker tabs in common styled container
 */
const Tabs = ({ children, getClassName }) => (
  <nav class={getClassName("nav")}>{children}</nav>
);

export default withStyles(Tabs, styles);
