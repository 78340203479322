// @flow
// @jsx h
import { h } from 'preact';

const Options = ({ color }: { color: string }) => (
  <svg
    fill={color ? color : '#000000'}
    width="24"
    height="13.846"
    viewBox="0 0 24 13.846"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m18.462 13.846h-18.462v-8.3077h5.5385v-5.5385h12.923v5.5385h5.5385v8.3077zm-16.615-6.4615v4.6154h3.6923v-4.6154zm14.769-5.5385h-9.2308v10.154h9.2308v-6.4615zm5.5385 5.5385h-3.6923v4.6154h3.6923z"
      fill="#72828a"
      fillRule="evenodd"
      strokeWidth=".923"
    />
  </svg>
);
export default Options;
