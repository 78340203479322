// @flow
// @jsx h

import { Component } from "preact";
// import { route } from 'preact-router';

// API
import { getWidgetConfiguration, generateTokenByHash } from "elc-internal-api";

import { getCompanyById, setGenerateTokenMethod } from "elc-open-api";

type RedirectProps = {
  hash: string,
  preview?: string,
  initConfig: Function
};

/**
 * Redirect Component
 * that will redirect to the product or catalog route
 * depending on the configuration
 */
/* FIXME when Preact implement getDerivedStateFromProps */
/* eslint-disable-next-line react/no-deprecated */
export default class Redirect extends Component<RedirectProps> {
  componentWillMount() {
    const { hash, preview } = this.props;
    if (hash !== "checkout") {
      // if (preview === 'preview') {
      // getWidgetToken(hash)
      //   .then(() => getWidgetConfiguration(hash, preview === 'preview'))
      getWidgetConfiguration(hash, preview === "preview")
        .then(config => {
          setGenerateTokenMethod(() => generateTokenByHash(hash));
          return Promise.all([config, getCompanyById(config.company.id)]);
        })
        .then(([config, company]) => {
          this.props.initConfig(hash, config, preview === "preview", company);
          return config;
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  render() {
    return null;
  }
}
