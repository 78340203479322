// @flow
// @jsx h

// External librairies
import { h } from "preact";

// Style
import styles from "./style.scss";

/**
 * Header title stateless functional component
 * displaying header title depending on route
 */
const QuantityCategory = ({
  name,
  children
}: {
  name: string,
  children: any
}) => (
  <div class={styles.quantityCategory}>
    <h2 class={styles.categoryName}>{name}</h2>
    {children}
  </div>
);

export default QuantityCategory;
