// @flow
import { h, Component } from "preact";
import { Text } from "preact-i18n";

// Style
import styles from "./style.scss";
import { withStyles } from "elc-theme";

// Components
import Extra from "./extra";
import Container from "../container";
import BookerContainer from "../bookercontainer";
import StartingPrice from "../starting-price";
import { PrimaryButton } from "elc-button";
import FooterButton from "../footerbutton";

import ReactHintFactory from "react-hint";

import { sendCheckoutEventProductGTM } from "elc-commons";

type ExtrasProps = {
  config: WidgetConfig,
  locale: string,
  currentProduct: Product,
  currency: Currency,
  extras: Extras
};

type ExtrasState = {
  quantities: Map<number, number>
};

const ReactHint = ReactHintFactory({ createElement: h, Component });

/**
 * Extras Component
 * displaying the available extras for the current products
 */
class Extras extends Component<ExtrasProps, ExtrasState> {
  state = {
    quantities: new Map()
  };

  constructor(props) {
    super(props);
    if (props.currentExtrasQuantities) {
      this.setState({
        quantities: props.currentExtrasQuantities
      });
    }
  }

  /**
   * Increment quantity for a given unit
   * @param {Object} unit - the unit to increment
   */
  addUnit = unit => {
    if (this.state.quantities.get(unit.id)) {
      this.state.quantities.set(
        unit.id,
        this.state.quantities.get(unit.id) + 1
      );
    } else {
      this.state.quantities.set(unit.id, 1);
    }
    this.setState({
      quantities: this.state.quantities
    });
  };

  /**
   * Decrement quantity for a given unit
   * @param {Object} unit - the unit to decrement
   */
  subUnit = unit => {
    if (this.state.quantities.get(unit.id) === 1) {
      this.state.quantities.delete(unit.id);
    } else if (this.state.quantities.get(unit.id)) {
      this.state.quantities.set(
        unit.id,
        this.state.quantities.get(unit.id) - 1
      );
    }
    this.setState({
      quantities: this.state.quantities
    });
  };

  /**
   * Click next button handler
   * passing the extras quantities choosen
   */
  onClickNext = () => {
    this.props.setCurrentQuantities(this.state);

    //Step Gtm : Tracking des extras
    let extras = this.state.quantities.size > 0 ? "Avec extras" : "Sans Extras";
    sendCheckoutEventProductGTM(this.props.currentProduct, 4, extras);
  };

  onRenderContent = target => {
    const { extraImageUrl, summary } = target.dataset;
    // const width = 240;
    // TODO: replace by extra image
    // const url = `https://images.pexels.com/photos/${catId}/pexels-photo-${
    //   catId
    // }.jpeg?w=${width}`;

    return (
      <div class={styles.tooltipContent}>
        {this.context.widgetContext !== "KIOSK" && (
          <img src={extraImageUrl} class={styles.tooltipImage} alt="" />
        )}
        <div class={styles.tooltipSummary}>{summary}</div>
      </div>
    );
  };

  /**
   * render
   * @param {ExtrasProps} - the Extras component props
   * @param {ExtrasState} - the Extras component state
   * @return {ReactElement} markup
   */
  render(
    {
      config,
      locale,
      currentProduct,
      currency,
      extras,
      theme,
      getClassName
    }: ExtrasProps,
    { quantities }: ExtrasState
  ) {
    return (
      <Container>
        <BookerContainer>
          <div class={styles.tooltipPositionHint}>
            <ReactHint
              attribute="data-custom"
              className={styles.tooltip}
              events={{ click: true }}
              onRenderContent={this.onRenderContent}
              position="bottom"
              ref={ref => (this.instance = ref)}
            />
          </div>
          {extras.map(extra => (
            <div key={extra.id} class={getClassName("extrasContainer")}>
              <Extra
                theme={theme}
                locale={locale}
                extra={extra}
                quantities={quantities}
                handleAddUnit={this.addUnit}
                handleSubUnit={this.subUnit}
                currency={currency}
              />
            </div>
          ))}
        </BookerContainer>
        {(config.type === "KIOSK" || config.type === "PMS") && (
          <StartingPrice
            theme={theme}
            productType={currentProduct.type}
            price={currentProduct.displayedStartingPrice}
            locale={locale}
            currency={currency}
          />
        )}
        <FooterButton config={config} theme={theme}>
          <PrimaryButton
            disabled={quantities ? false : true}
            handleClick={this.onClickNext}
          >
            <Text id="extras.button.next">Next</Text>
          </PrimaryButton>
        </FooterButton>
      </Container>
    );
  }
}

export default withStyles(Extras, styles);
