import { h, Component } from "preact";
import { Text } from "preact-i18n";

import styles from "./style.scss";

export default class Privatize extends Component {
  // state = {
  //   isPrivatized: false
  // };

  constructor(props) {
    super(props);
    // this.state = {
    //   isPrivatized: props.isPrivatized
    // };
  }

  togglePrivatized = e => {
    // this.setState({
    //   isPrivatized: e.target.checked
    // });
    this.props.onChangePrivatized(e.target.checked);
  };

  isMinimunParticipantsReached(
    isPrivatized,
    total,
    minParticipantsToPrivatize
  ) {
    return (
      isPrivatized &&
      total < minParticipantsToPrivatize &&
      minParticipantsToPrivatize > 1
    );
  }

  render({ isPrivatized, total, minParticipantsToPrivatize, theme }) {
    let showMinimunParticipantsWarning = this.isMinimunParticipantsReached(
      isPrivatized,
      total,
      minParticipantsToPrivatize
    );
    return (
      <div
        class={
          theme && theme.privatizeContent
            ? theme.privatizeContent
            : styles.privatizeContent
        }
      >
        <div
          class={theme && theme.privatize ? theme.privatize : styles.privatize}
        >
          <div
            class={
              theme && theme.switchInput
                ? theme.switchInput
                : styles.switchInput
            }
          >
            <div>
              <div class={theme && theme.switch ? theme.switch : styles.switch}>
                <label htmlFor="isPrivatized">
                  <input
                    type="checkbox"
                    id="isPrivatized"
                    checked={isPrivatized}
                    onChange={this.togglePrivatized}
                  />
                  {/* <span class={theme.slider + " " + theme.round ? theme.slider + " " + theme.round : ''} /> */}
                  <span
                    class={`${
                      theme && theme.slider ? theme.slider : styles.slider
                    } ${theme && theme.round ? theme.round : styles.round}`}
                    style={`${
                      this.context.themeConfig &&
                      this.context.themeConfig.color1 &&
                      isPrivatized
                        ? "background-color:" + this.context.themeConfig.color1
                        : ""
                    }`}
                  />
                </label>
              </div>
            </div>
            <label htmlFor="isPrivatized">
              <Text id="quantities.privatize">Privatize this activity</Text>
            </label>
          </div>
          {showMinimunParticipantsWarning && (
            <div
              class={
                theme && theme.privatizeWarning
                  ? theme.privatizeWarning
                  : styles.privatizeWarning
              }
            >
              <Text
                id="quantities.minParticipantsToPrivatize"
                fields={{ minParticipantsToPrivatize }}
              >
                A minimun of {minParticipantsToPrivatize} is required to
                privatize this activity.
              </Text>
            </div>
          )}
        </div>
      </div>
    );
  }
}
