// @flow
// @jsx h

import { h, Component } from "preact";

import TermSales from "./TermSales";
import Loading from "elc-loading";

// API methods
import { getTermSalesByOrder } from "elc-internal-api";

type FetchTermSalesProps = {
  isTermSalesAccepted: boolean,
  toggleTermSalesAccepted: Function
};

type FetchTermSalesState = {
  loading: boolean,
  termSales: string
};

/**
 * Fetch term sales Stateful Component
 * fetch term sales for a comapny id
 * and render the TermSales Component
 * displaying term sales with a show/hide toggle
 */
export default class FetchTermSales extends Component<
  FetchTermSalesProps,
  FetchTermSalesState
> {
  state = {
    loading: true
  };

  componentDidMount() {
    const { order } = this.props;
    getTermSalesByOrder(order.id)
      .then(termSales => {
        this.setState({ loading: false, termSales });
        return termSales;
      })
      .catch(error => {
        console.error(error);
      });
  }

  render(
    {
      isTermSalesAccepted,
      toggleTermSalesAccepted
    }: { isTermSalesAccepted: boolean, toggleTermSalesAccepted: Function },
    { loading, termSales }: { loading: boolean, termSales: string }
  ) {
    return (
      <div>
        {loading && <Loading loading={loading} />}
        {!loading && (
          <TermSales
            termSales={termSales}
            isTermSalesAccepted={isTermSalesAccepted}
            toggleTermSalesAccepted={toggleTermSalesAccepted}
          />
        )}
      </div>
    );
  }
}
