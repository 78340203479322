// @flow

declare var API_ENDPOINT: string;

import { handleResponse } from '../errors';
import { withToken } from '../auth';

/**
 * Put user client data
 * @param {number} companyId - the company ID
 * @param {number} userId - the user ID
 * @param {Object} userClientData - the user client data
 * @return {Promise} the promise of user client data update
 */
export const putUserClientData = withToken(
  (
    accessToken: string,
    companyId: number,
    userId: number,
    userClientData: UserClientData
  ): Promise<any> => {
    // tranform input type checkbox value from boolean to '0' or '1'
    // FIXME the inputType CHECKBOX (actual RADIO) should accept a boolean instead of an integer string
    const formattedUserClientData = userClientData.map(
      data =>
        typeof data.value === 'boolean'
          ? data.value ? { ...data, value: '1' } : { ...data, value: '0' }
          : data
    );
    return fetch(
      `${API_ENDPOINT}/companies/${companyId}/users/${userId}/user_client_data?access_token=${accessToken}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify(formattedUserClientData)
        // body: JSON.stringify(userClientData)
      }
    ).then(handleResponse);
  }
);

/**
 * List of user client data for a specific company
 * and a specific user
 * @param {number} companyId - the company ID
 * @param {number} userId - the user ID
 * @return {Promise} the promise of the user client data
 */
export const getUserClientData = withToken(
  (
    accessToken: string,
    companyId: number,
    userId: number
  ): Promise<UserClientData> =>
    fetch(
      `${API_ENDPOINT}/companies/${companyId}/user_client_data?user_id=${userId}&access_token=${accessToken}`
    )
      .then(handleResponse)
      .then(userClientData =>
        userClientData._embedded.userClientData.map(data => {
          if (data._embedded.clientFormInputTypeSaved.inputType === 'RADIO') {
            data.value = data.value === '1' ? true : false;
            return data;
          } else {
            return data;
          }
        })
      )
);
