// @flow
// @jsx h
import { h, Component } from "preact";
import { withText, Text } from "preact-i18n";

import { Button } from "elc-button";
import { RemoveIcon, GlassIcon, ArrowRightIcon } from "elc-icons";

import Downshift from "downshift/preact";

import styles from "./style.scss";

import { sendUXEventGTM } from "elc-commons";

type RadiusFilterProps = {
  currentSearch: string,
  setRadius: Function
};

@withText({
  searchPlaceholder: "products.filters.search.placeholder"
})
export default class RadiusFilter extends Component<RadiusFilterProps> {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      hasValue: false,
      showRadiusSelection: false,
      geolocValue: this.props.config.module.conf.radius
        ? this.props.config.module.conf.radius + " km"
        : "5 km"
    };
  }

  setRadiusValue = ({ target: { innerText } }) => {
    this.setState({ geolocValue: innerText, showRadiusSelection: false });
    this.props.setRadius(parseInt(innerText));
  };

  toggleRadiusSelection = () => {
    this.setState({ showRadiusSelection: !this.state.showRadiusSelection });
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  componentDidMount() {
    window.document.addEventListener(
      "mousedown",
      this.handleClickOutsideRadiusSelection
    );
  }

  componentWillUnmount() {
    window.document.removeEventListener(
      "mousedown",
      this.handleClickOutsideRadiusSelection
    );
  }

  handleClickOutsideRadiusSelection = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showRadiusSelection: false });
    }
  };

  render() {
    const { geolocValue, showRadiusSelection } = this.state;
    return (
      <div class={styles.mainRadiusContainer} ref={this.setWrapperRef}>
        <label>
          <div class={styles.radiusLabel}>
            <Text id="products.filters.radius">Périmètre</Text>
          </div>
          <div
            class={styles.displayedRadius}
            onClick={this.toggleRadiusSelection}
          >
            <p class={styles.radiusValue}>
              {geolocValue}
              <ArrowRightIcon
                color={
                  this.context.themeConfig && this.context.themeConfig.color1
                    ? this.context.themeConfig.color1
                    : styles.color
                }
              />
            </p>
          </div>
          {showRadiusSelection ? (
            <div class={styles.radiusSelection}>
              <div
                class={styles.radiusSelectionItem}
                onClick={this.setRadiusValue}
              >
                5 km
              </div>
              <div
                class={styles.radiusSelectionItem}
                onClick={this.setRadiusValue}
              >
                10 km
              </div>
              <div
                class={styles.radiusSelectionItem}
                onClick={this.setRadiusValue}
              >
                15 km
              </div>
              <div
                class={styles.radiusSelectionItem}
                onClick={this.setRadiusValue}
              >
                20 km
              </div>
              <div
                class={styles.radiusSelectionItem}
                onClick={this.setRadiusValue}
              >
                30 km
              </div>
              <div
                class={styles.radiusSelectionItem}
                onClick={this.setRadiusValue}
              >
                40 km
              </div>
              <div
                class={styles.radiusSelectionItem}
                onClick={this.setRadiusValue}
              >
                50 km
              </div>
              <div
                class={styles.radiusSelectionItem}
                onClick={this.setRadiusValue}
              >
                75 km
              </div>
              <div
                class={styles.radiusSelectionItem}
                onClick={this.setRadiusValue}
              >
                100 km
              </div>
            </div>
          ) : null}
        </label>
      </div>
    );
  }
}
