// @flow
// @jsx h

import { h, Component } from "preact";
import { Text } from "preact-i18n";

import styles from "./ParticipantsFormsLink.scss";

import Link from "../link";

import { getParticipantClientForm } from "elc-open-api";

type ParticipantsFormsLinkProps = {
  order: Order
};

type ParticipantsFormsLinkState = {
  loading: boolean
};

export default class ParticipantsFormsLink extends Component<
  ParticipantsFormsLinkProps,
  ParticipantsFormsLinkState
> {
  state = {
    loading: true
  };

  componentDidMount() {
    const { order } = this.props;
    return Promise.all(
      order._embedded.bookings.map(booking =>
        Promise.all([
          getParticipantClientForm(booking.id).catch(error => {
            if (error.status !== 404) {
              this.setState({ error });
              return Promise.reject();
            } else {
              return Promise.resolve();
            }
          }),
          booking
        ])
      )
    )
      .then(bookingsParticipantClientForm =>
        bookingsParticipantClientForm.filter(
          bookingsParticipantClientForm =>
            bookingsParticipantClientForm[0] !== undefined
        )
      )
      .then(bookingsParticipantClientForm => {
        if (bookingsParticipantClientForm.length > 0) {
          this.setState({ loading: false, bookingsParticipantClientForm });
        }
        return bookingsParticipantClientForm;
      })
      .catch(error => {
        console.error(error);
      });
  }

  render(
    { order }: ParticipantsFormsLinkProps,
    { loading }: ParticipantsFormsLinkState,
    { themeConfig }
  ) {
    return (
      <div class={styles.participantsButtonContainer}>
        {!loading ? (
          <div>
            <div class={styles.contentParticipants}>
              <div class={styles.participantsdescription}>
                <strong>
                  <Text id="order.confirmation.participants.descriptionLibelle">
                    Attention, vous n'avez pas terminé votre commande !
                  </Text>
                </strong>
                <br />
                <Text id="order.confirmation.participants.description">
                  Vous devez obligatoirement renseigner maintenant les
                  participants aux activités. Les informations qui vont vous
                  être demandées sont obligatoires pour le bon déroulement de
                  l'activité.
                </Text>
              </div>
            </div>
            <Link
              href={`/order/${order.id}/participantclientforms/${order.reference}`}
              className={styles.participantsButton}
              style={`background-color:${
                themeConfig && themeConfig.color2 ? themeConfig.color2 : ""
              };`}
            >
              <Text id="order.confirmation.participants.button">
                Participants
              </Text>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
