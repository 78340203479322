// @flow
// @jsx h

declare var STRIPE_API_KEY: string;

// External librairies
import { h, Component } from "preact";
import { StripeProvider, Elements } from "react-stripe-elements";

// Components
import StripePaymentForm from "./stripe-payment-form";

class StripePayment extends Component {
  render({
    hash,
    config,
    currency,
    locale,
    order,
    paymentMethod,
    setPaymentMethod,
    removeBeforeUnloadListener,
    callbackStripe,
    urlToShop,
    checkSession
  }) {
    return (
      <StripeProvider apiKey={`${STRIPE_API_KEY}`}>
        <Elements locale={locale}>
          <StripePaymentForm
            hash={hash}
            config={config}
            currency={currency}
            locale={locale}
            order={order}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            removeBeforeUnloadListener={removeBeforeUnloadListener}
            callbackStripe={callbackStripe}
            urlToShop={urlToShop}
            checkSession={checkSession}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

export default StripePayment;
