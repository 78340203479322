// @flow
// @jsx h

// External librairies
import { h } from "preact";

// Style
import { withStyles } from "elc-theme";
import styles from "./style.scss";

/**
 * Booker container Component
 * used to display the booker views in common styled container
 */
function Container({ children, getClassName }) {
  // return <div class={styles.container}>{children}</div>;
  return <div class={getClassName("container")}>{children}</div>;
}

export default withStyles(Container, styles);
