// @flow
// @jsx h
import { h } from "preact";

import { withText } from "preact-i18n";

// Tab component
import Tab from "./Tab";
import TabIcon from "./TabIcon";
import TabLabel from "./TabLabel";

// Icons
import { CalendarIcon } from "elc-icons";

/**
 * Day tab component
 */
const DayTab = withText({ label: "header.day" })(
  ({ theme, active, currentDay, handleClick, label }) => (
    <Tab handleClick={handleClick}>
      <TabIcon theme={theme} active={active}>
        <CalendarIcon />
      </TabIcon>
      <TabLabel
        theme={theme}
        active={active}
        label={
          currentDay
            ? `${currentDay
                .getDate()
                .toString()
                .padStart(2, "0")}/${(currentDay.getMonth() + 1)
                .toString()
                .padStart(2, "0")}`
            : label
        }
      />
    </Tab>
  )
);

export default DayTab;
