// @flow
// @jsx h
import { h } from "preact";

const ViewModule = ({ color }: { color?: string }) => (
  <svg
    fill={color ? color : "#FFFFFF"}
    height="24"
    viewBox="0 0 50 50"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.292,23.958l10.416,0l0,-12.5l-10.416,0l0,12.5Zm0,14.584l10.416,0l0,-12.5l-10.416,0l0,12.5Zm12.5,0l10.416,0l0,-12.5l-10.416,0l0,12.5Zm12.5,0l10.416,0l0,-12.5l-10.416,0l0,12.5Zm-12.5,-14.584l10.416,0l0,-12.5l-10.416,0l0,12.5Zm12.5,-12.5l0,12.5l10.416,0l0,-12.5l-10.416,0Z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
export default ViewModule;
