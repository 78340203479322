// @flow
// @jsx h
import { h, Component } from "preact";
import { Text } from "preact-i18n";

import styles from "./style.scss";
import { sendUXEventGTM } from "elc-commons";

type QuantityFilterProps = {
  quantity: number,
  setQuantity: Function
};

export default class QuantityFilter extends Component<QuantityFilterProps> {
  handleSubQuantity = () => {
    this.props.setQuantity(this.props.quantity - 1);
    sendUXEventGTM({
      event: "removeQuantity",
      "chosen-quantity": this.props.quantity - 1
    });
  };

  handleAddQuantity = () => {
    let quantityForGTM;
    if (this.props.quantity === undefined) {
      this.props.setQuantity(1);
      quantityForGTM = 1;
    } else {
      this.props.setQuantity(this.props.quantity + 1);
      quantityForGTM = this.props.quantity + 1;
    }
    sendUXEventGTM({
      event: "addQuantity",
      "chosen-quantity": quantityForGTM
    });
  };

  render({ quantity }: { quantity: ?number }) {
    return (
      <div class={styles.howManyFilterrContainer}>
        <label class={styles.filterLabel}>
          <Text id="products.filters.quantity.label">Participants</Text>
        </label>
        <div class={styles.howManyFilter}>
          <button
            type="button"
            disabled={!quantity || quantity === 0}
            class={styles["quantity-button"] + " " + styles.minus}
            onClick={this.handleSubQuantity}
            style={`color:${
              this.context.themeConfig &&
              this.context.themeConfig.color1 &&
              quantity &&
              quantity > 0
                ? this.context.themeConfig.color1
                : ""
            };`}
          >
            -
          </button>
          <div class={styles.quantity}>
            {quantity !== undefined ? quantity : "?"}
          </div>
          <button
            type="button"
            class={styles["quantity-button"] + " " + styles.plus}
            onClick={this.handleAddQuantity}
            style={`color:${
              this.context.themeConfig && this.context.themeConfig.color1
                ? this.context.themeConfig.color1
                : ""
            };`}
          >
            +
          </button>
        </div>
      </div>
    );
  }
}
