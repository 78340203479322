// @flow
// @jsx h

import { h } from 'preact';

import styles from './Button.scss';

/**
 * Common Button stateless functional Component
 */
const Button = ({
  type,
  handleClick,
  color,
  background,
  borderColor,
  disabled,
  children,
  className,
  styleName
}: {
  type?: string,
  handleClick?: Function,
  color?: string,
  background?: string,
  borderColor?: string,
  disabled?: boolean,
  children?: any,
  className?: string,
  styleName?: string | Object
}) => {
  let computedClassName = styles.button;
  if (className) {
    computedClassName += ` ${className}`;
  }
  if (!className && !background && !color) {
    computedClassName += ` ${styles.defaultColors}`;
  }
  let style = '';
  style = styleName ? styleName : '';
  style += color ? `color: ${color};` : '';
  style += background ? `background: ${background};` : '';
  style += borderColor ? `border-color: ${borderColor};` : '';
  return (
    <button
      type={type || 'button'}
      onClick={handleClick}
      disabled={disabled}
      class={computedClassName}
      style={style}
    >
      {children}
    </button>
  );
};
export default Button;
