// @flow
// @jsx h

// External librairies
import { h } from "preact";

// style
import styles from "./style.scss";

// Components
import HeaderTitle from "./title";
import MainProductTabs from "./tabs/MainProductTabs";
import SessionProductTabs from "./tabs/SessionProductTabs";
import GiftProductTabs from "./tabs/GiftProductTabs";
import StartingPrice from "../starting-price";
import { getPriceWithTax } from "elc-commons";

type HeaderProps = {
  route: string,
  conf: WidgetConfig,
  locale: string,
  singlePanelMode: boolean,
  currency: Currency,
  currentProduct: Product,
  currentQuantities: Quantities,
  currentDay: Date,
  currentSlot: Slot,
  currentExtrasQuantities: Quantities,
  goToQuantities: Function,
  goToDay: Function,
  goToSlot: Function,
  goToGifts: Function
};

/**
 * Header stateless functional component
 * displaying the booker tabs according to :
 * - the product type
 * - the product unit type
 * - the current route
 */
const Header = ({
  theme,
  config,
  singlePanelMode,
  route,
  locale,
  currency,
  currentProduct,
  currentQuantities,
  currentDay,
  currentSlot,
  currentExtrasQuantities,
  goToQuantities,
  goToDay,
  goToSlot,
  goToGifts
}: HeaderProps) => {
  return (
    <header class={styles.header}>
      {!singlePanelMode &&
        config.type !== "KIOSK" &&
        config.type !== "SHOP" &&
        config.type !== "PMS" &&
        (route === "productGifts" ? (
          <StartingPrice
            theme={theme}
            productType={currentProduct.type}
            price={Math.min(
              ...currentProduct._embedded.giftProducts.map(product =>
                getPriceWithTax(
                  product._embedded.prices[0].value,
                  product._embedded.tax
                )
              )
            )}
            locale={locale}
            currency={currency}
          />
        ) : (
          <StartingPrice
            theme={theme}
            productType={currentProduct.type}
            price={
              currentProduct.type === "GIFT"
                ? getPriceWithTax(
                    currentProduct._embedded.prices[0].value,
                    currentProduct._embedded.tax
                  )
                : currentProduct.displayedStartingPrice
            }
            locale={locale}
            currency={currency}
          />
        ))}
      {currentProduct &&
        currentProduct.type === "CLASSIC" &&
        (currentProduct._embedded.unit.type === "MAIN" ||
          currentProduct._embedded.unit.type === "FORFAIT") &&
        route !== "productGifts" && (
          <MainProductTabs
            theme={theme}
            route={route}
            currentProduct={currentProduct}
            currentQuantities={currentQuantities}
            currentExtrasQuantities={currentExtrasQuantities}
            currentDay={currentDay}
            currentSlot={currentSlot}
            goToQuantities={goToQuantities}
            goToDay={goToDay}
            goToSlot={goToSlot}
          />
        )}
      {currentProduct &&
        ((currentProduct.type === "CLASSIC" &&
          currentProduct._embedded.unit.type === "SESSION") ||
          currentProduct.type === "FRANCE_BILLET") &&
        route !== "productGifts" && (
          <SessionProductTabs
            theme={theme}
            route={route}
            currentProduct={currentProduct}
            currentQuantities={currentQuantities}
            currentExtrasQuantities={currentExtrasQuantities}
            currentDay={currentDay}
            currentSlot={currentSlot}
            goToDay={goToDay}
            goToSlot={goToSlot}
            goToQuantities={goToQuantities}
          />
        )}
      {((route && route === "productGifts") ||
        (currentProduct && currentProduct.type === "GIFT")) && (
        <GiftProductTabs
          theme={theme}
          route={route}
          currentProduct={currentProduct}
          currentQuantities={currentQuantities}
          goToGifts={goToGifts}
        />
      )}
      {/* {config.type !== 'SHOP' &&
        config.type !== 'PMS' && ( */}
      <HeaderTitle config={config} route={route} theme={theme} />
    </header>
  );
};

export default Header;
