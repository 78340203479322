// @flow
// @jsx h

// External librairies
import { h } from "preact";

// Style
import styles from "./TabIcon.scss";

/**
 * Tab icon container that accept as a children a JSX SVG
 */
const TabIcon = ({ theme, active, children }, context) => (
  <div
    class={`${styles.iconContainer} ${
      active
        ? theme && theme.iconActive
          ? theme.iconActive
          : styles.iconActive
        : theme && theme.iconInactive
          ? theme.iconInactive
          : styles.iconInactive
    }`}
    style={`background-color:${
      context.themeConfig && context.themeConfig.color1 && active
        ? context.themeConfig.color1
        : ""
    };`}
  >
    {children}
  </div>
);

export default TabIcon;
