// @flow
// @jsx h

import { h } from "preact";

import { withText } from "preact-i18n";

// Tab component
import Tab from "./Tab";
import TabIcon from "./TabIcon";
import TabLabel from "./TabLabel";

// Icon
import { TicketIcon } from "elc-icons";

/**
 * Extras tab component
 */
const TicketHoldersTab = withText({ label: "header.ticketHolders" })(
  ({
    theme,
    active,
    label
  }: {
    theme?: Object,
    active: boolean,
    label: string
  }) => (
    <Tab>
      <TabIcon theme={theme} active={active}>
        <TicketIcon />
      </TabIcon>
      <TabLabel theme={theme} active={active} label={label} />
    </Tab>
  )
);

export default TicketHoldersTab;
