// @flow

declare var PHP_API_ENDPOINT: string;
declare var MEDIAS_ENDPOINT: string;

import { handleResponse } from "../handle-response";
import { pickBy, identity } from "lodash";
import qs from "qs";

/**
 * Return the Widget OpenAPI token
 * @param hash {string} - the widget hash
 * @return {Promise} the widget OpenAPI token
 */
export const generateTokenByHash = (hash: string): Promise<string> =>
  fetch(`${PHP_API_ENDPOINT}/module/${hash}/generateToken`).then(
    handleResponse
  );

/**
 * Return the widget configuration
 * @param hash {string} - the widget hash
 * @param preview {boolean} - optional preview flag
 * @return {Promise} the widget configuration
 */
export function getWidgetConfiguration(
  hash: string,
  preview?: boolean
): Promise<WidgetConfig> {
  let url = `${PHP_API_ENDPOINT}/module/${hash}/conf`;
  if (preview) {
    url += "/preview";
  }
  return fetch(url)
    .then(handleResponse)
    .then(config => {
      // setToken(config.access_token);

      //Emulation mode SHOP
      //config.type = "SHOP";

      if (
        preview &&
        config.module.previewConf &&
        !(config.module.previewConf instanceof Array)
      ) {
        config.module.conf = config.module.previewConf;
      }
      config.company.type = convertCompanyTypeFromNumberToString(
        config.company.type
      );
      return config;
    });
}

/**
 * Convert the company type number to the corresponding string
 * to match OpenAPI company type
 */
function convertCompanyTypeFromNumberToString(type: number): string {
  if (type === 1) {
    return "MERCHANT";
  } else if (type === 2) {
    return "RESELLER";
  } else if (type === 3) {
    return "MERCHANT_RESELLER";
  } else {
    throw new Error("Unknowned company type !");
  }
}

/**
 * Return the categories associated with the widget products
 * @param hash {string} - the widget hash
 * @param preview {boolean} - optional preview flag
 * @return {Promise} the list of categories associated with the widget products
 */
export function getWidgetCategories(
  hash: string,
  { preview, locale }: { preview?: boolean, locale?: string } = {
    preview: false,
    locale: "fr"
  }
): Promise<WidgetCategories> {
  let url = `${PHP_API_ENDPOINT}`;
  if (locale) {
    url += `/${locale}`;
  }
  url += `/module/${hash}/ds/categories`;
  if (preview) {
    url += "/preview";
  }
  return fetch(url).then(handleResponse);
}

/**
 * Return the categories associated with the widget products
 * @param hash {string} - the widget hash
 * @param preview {boolean} - optional preview flag
 * @return {Promise} the list of categories associated with the widget products
 */
export function getWidgetProductsCategories(
  hash: string,
  { preview, locale }: { preview?: boolean, locale?: string } = {
    preview: false,
    locale: "fr"
  }
): Promise<ProductsCategories> {
  let url = `${PHP_API_ENDPOINT}`;
  if (locale) {
    url += `/${locale}`;
  }
  url += `/module/${hash}/categories`;
  if (preview) {
    url += "/preview";
  }
  return fetch(url).then(handleResponse);
}

type ProductsFilters = {
  name: ?string,
  categories: ?Array<number>,
  subCategories: ?Array<number>,
  distance: ?{
    latitude: number,
    longitude: number,
    radius: number
  },
  available_on_date: ?Date,
  number_participants: ?number
};
/**
 * Return the widget products
 * @param {string} hash           the widget hash
 * @param {number} defaultRadius  the radius around the geolocation
 * @param {object} filters        the filters to apply on the widget products
 * @param {number} page           the products page wanted
 *
 * @return {Array} the list of products associated with the widget
 */
export function getWidgetProducts(
  hash: string,
  defaultRadius: number,
  {
    preview,
    locale,
    filters,
    page
  }: {
    preview?: boolean,
    locale?: string,
    filters: ?ProductsFilters,
    page?: number
  } = {
    preview: false,
    locale: "fr",
    page: 1
  }
) {
  let url = `${PHP_API_ENDPOINT}`;
  if (locale) {
    url += `/${locale}`;
  }
  url += `/module/${hash}/products`;
  if (preview) {
    url += "/preview";
  }
  if (filters) {
    let {
      categories,
      city,
      distance: { radius = defaultRadius ? defaultRadius : 5 },
      dsc,
      latitude,
      longitude,
      number_participants,
      available_on_date,
      subCategories
    } = filters;

    if (!latitude && !longitude) {
      radius = null;
    }

    let filtersQueryParameters = {
      // city,
      distance: { radius, latitude, longitude },
      available_on_date,
      category_id:
        categories && categories.length > 0
          ? "in" + categories.join(",")
          : null,
      dsc_id: dsc && dsc.length > 0 ? "in" + dsc.join(",") : null,
      subCategory:
        subCategories && subCategories.length > 0
          ? "in" + subCategories.join(",")
          : null,
      min_participants: number_participants ? "<=" + number_participants : null,
      max_participants: number_participants ? ">=" + number_participants : null
    };
    // delete null or undefined entries from object
    filtersQueryParameters.distance = pickBy(
      filtersQueryParameters.distance,
      identity
    );
    const filteredQueryObj = pickBy(filtersQueryParameters, identity);
    url = `${url}?${qs.stringify({ find: filteredQueryObj })}`;
  }
  // if ($scope.filters.sort) {
  //   queries.sort = {};
  //   queries.sort[$scope.filters.sort] = '-';
  // }
  // console.log(
  //   'filter',
  //   queryString.stringify(filters, { arrayFormat: 'bracket' })
  // );
  if (page) {
    if (!filters) {
      url = `${url}?page=${page}`;
    } else {
      url = `${url}&page=${page}`;
    }
  }
  return fetch(url)
    .then(handleResponse)
    .then(response => {
      response.items = response.items.map(product =>
        _initProductPrimaryImage(product)
      );
      return response;
    });
}

export function _initProductPrimaryImage(product) {
  if (product.images && product.images.length > 0) {
    let imgResolution;
    let primaryImage = product.images.find(image => image.isPrimary);
    if (!primaryImage) {
      primaryImage = product.images[0];
    }
    if (primaryImage.file["400x400"]) {
      imgResolution = "400x400";
    } else if (primaryImage.file["ORIG"]) {
      imgResolution = "ORIG";
    } else if (primaryImage.file["HD300_0"]) {
      imgResolution = "HD300_0";
    } else if (primaryImage.file["HD600_0"]) {
      imgResolution = "HD600_0";
    } else if (primaryImage.file["HD900_0"]) {
      imgResolution = "HD900_0";
    } else if (primaryImage.file["LARGE_0"]) {
      imgResolution = "LARGE_0";
    } else if (primaryImage.file["MEDIUM_0"]) {
      imgResolution = "MEDIUM_0";
    } else if (primaryImage.file["SMALL_0"]) {
      imgResolution = "SMALL_0";
    }
    product.primaryImage = {
      path: MEDIAS_ENDPOINT + "/" + primaryImage.file[imgResolution],
      legend: primaryImage.title
    };
  }
  return product;
}
