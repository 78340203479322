// @flow
// @jsx h

// External librairies
import { h } from "preact";

import FormInput from "./FormInput";

/**
 * FormInputs container component
 */
const FormInputs = ({
  theme,
  locale,
  clientFormFields,
  clientDataList,
  inputErrors,
  setClientDataList
}) => {
  /**
   * generic handle input change on the client details form
   * @param {SyntheticInputEvent} e
   */
  const handleInputChange = (inputValue: InputValue) => {
    const newClientDataList = [...clientDataList];
    setClientDataList(
      newClientDataList
        .filter(
          data =>
            data.clientFormInputType.id !== inputValue.clientFormInputType.id
        )
        .concat(inputValue)
    );
  };
  return (
    <div>
      {clientFormFields &&
        clientFormFields.map(clientFormField => (
          <FormInput
            theme={theme}
            id={clientFormField.id}
            key={clientFormField.id}
            clientFormField={clientFormField}
            handleInputChange={handleInputChange}
            locale={locale}
            value={
              clientDataList.find(
                data =>
                  data.clientFormInputType.id ===
                  clientFormField._embedded.clientFormInputType.id
              )
                ? clientDataList.find(
                    data =>
                      data.clientFormInputType.id ===
                      clientFormField._embedded.clientFormInputType.id
                  ).value
                : undefined
            }
            emailconfirmvalue={
              clientDataList.find(
                data => data.clientFormInputType.inputType === "EMAILCONFIRM"
              )
                ? clientDataList.find(
                    data =>
                      data.clientFormInputType.inputType === "EMAILCONFIRM"
                  ).value
                : undefined
            }
            error={inputErrors.find(
              inputError =>
                inputError.id ===
                clientFormField._embedded.clientFormInputType.id
            )}
          />
        ))}
    </div>
  );
};

export default FormInputs;
