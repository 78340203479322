//@flow

declare var PHP_API_ENDPOINT: string;

import { handleResponse } from "../handle-response";

let headers = new Headers();

headers.append("Accept", "application/json");
headers.append("Content-Type", "application/json");

/**
 * Confirm an order and send a confirmation email/sms
 * @param {number} idOrder - the order id to confirmation
 * @param {Object} payload - the request payload containing email and/or mobile phone number
 */
export function sendOrderConfirmation(
  orderId: number,
  payload,
  locale,
  orderLocale
) {
  return fetch(
    `${PHP_API_ENDPOINT}/marketPlace/orders/confirmation/${orderId}?locale=${
      locale ? locale : "fr"
    }&orderLocale=${orderLocale}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(payload)
    }
  ).then(handleResponse);
}

/**
 * Return an order for a given order reference
 * @param {string} orderReference - the order reference
 * @return the promise of the returned order
 */
export function getOrderByReference(orderReference: string): Promise<any> {
  return fetch(
    `${PHP_API_ENDPOINT}/marketPlace/order/byReference/${orderReference}`
  ).then(handleResponse);
}

/**
 * Return the payment methods available for a company and an eventual moduleType
 * @param {number} companyId - the company ID
 * @param {string} moduleType - the module type (widget/kiosk/mypage)
 * @return the promise of the returned payment methods
 */
export function getPaymentMethods(
  companyId: number,
  orderId: number,
  moduleType: string,
  isFromMail: boolean,
  locale
): Promise<any> {
  let url = `${PHP_API_ENDPOINT}/marketPlace/company/${companyId}/order/${orderId}/payments`;
  if (moduleType) {
    url += `?context=${moduleType}`;
  }
  if (isFromMail) {
    url += "&isPayBalance=1";
  }
  return fetch(url, {
    headers: {
      "Accept-Language": locale
    }
  }).then(handleResponse);
}

export function postOrderPaymentEmail(orderId, params) {
  return fetch(`${PHP_API_ENDPOINT}/orders/${orderId}/payments_msg`, {
    method: "POST",
    headers,
    body: JSON.stringify(params)
  }).then(handleResponse);
}

/**
 * Associate a payment method to an order
 */
export function postOrderPayment(orderId: number, locale, payment) {
  return fetch(
    `${PHP_API_ENDPOINT}/${locale ? locale : "fr"}/orders/${orderId}/payments`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(payment)
    }
  ).then(handleResponse);
}
