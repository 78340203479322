// @flow
// @jsx h
import { h } from "preact";
import { Text } from "preact-i18n";
import styles from "./style.scss";

/**
 * Add promo code error Stateless Component
 * displaying the server side 400 error returned by the OpenAPI endpoint
 * @param {object} component props
 */
const PromoCodeError = ({ error }: { error: Error }) => (
  <div class={styles.errorMessage}>
    {error.stringCode === "ORDER_SERVICE.PROMO_CODE_NO_ID_NO_VOUCHER_CODE" && (
      <div>
        <Text id="basket.promoCode.error.notFound">{error.message}</Text>
      </div>
    )}
    {error.stringCode === "ORDER_SERVICE.PROMO_CODE_NOT_FOUND" && (
      <div>
        <Text id="basket.promoCode.error.notFound">{error.message}</Text>
      </div>
    )}
    {error.stringCode === "ORDER_SERVICE.ORDER_MINIMUM_AMOUNT_NOT_REACHED" && (
      <div>
        <Text id="basket.promoCode.error.orderMinimunAmountNotReached">
          {error.message}
        </Text>
      </div>
    )}
    {error.stringCode === "ORDER_SERVICE.PROMO_CODE_NOT_ACTIVE" && (
      <div>
        <Text id="basket.promoCode.error.notActive">{error.message}</Text>
      </div>
    )}
    {error.stringCode === "ORDER_SERVICE.PROMO_CODE_NOT_VALID" && (
      <div>
        <Text id="basket.promoCode.error.notValid">{error.message}</Text>
      </div>
    )}
    {error.stringCode ===
      "ORDER_SERVICE.PROMO_CODE_NOT_ATTACHED_SAME_PRODUCT" && (
      <div>
        <Text id="basket.promoCode.error.notApplicable">{error.message}</Text>
      </div>
    )}
    {error.stringCode === "ORDER_SERVICE.PROMO_CODE_NOT_CUMULABLE" && (
      <div>
        <Text id="basket.promoCode.error.notCumulable">{error.message}</Text>
      </div>
    )}
    {error.stringCode === "ORDER_SERVICE.PROMO_CODE_OUT_OF_STOCK" && (
      <div>
        <Text id="basket.promoCode.error.outOfStock">{error.message}</Text>
      </div>
    )}
    {error.stringCode === "ORDER_SERVICE.PARTIAL_USE_NOT_SUPPORTED" && (
      <div>
        <Text id="basket.promoCode.error.partialUseNotSupported">
          {error.message}
        </Text>
      </div>
    )}
  </div>
);
export default PromoCodeError;
