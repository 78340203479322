// @flow
// @jsx h

import { h, Component } from "preact";
import { Text } from "preact-i18n";

import { route } from "preact-router";

import styles from "./style.scss";
import { withStyles } from "elc-theme";

import { PrimaryButton } from "elc-button";
import Loading from "elc-loading";

import FooterButton from "../footerbutton";
// import FormInput from './form-input';
import { FormInput } from "elc-client-forms";

// import inputsValidation from './inputsValidation';
import { formValidation } from "elc-client-forms";

import { getDefaultClientForm, patchOrderUser } from "elc-open-api";

import { sendCheckoutEventOrderGTM } from "elc-commons";

type ClientFormProps = {
  config?: WidgetConfig,
  theme?: Object,
  order: Order,
  setOrderUser: Function,
  locale?: string,
  redirectToPayment?: object
};

type ClientFormState = {
  loading: boolean,
  clientFormFields: Array<ClientFormField>,
  userClientData: Array<UserClientData>,
  inputErrors: Array<>
};

/**
 * Client/Customer details input form Component
 */
class ClientForm extends Component<ClientFormProps, ClientFormState> {
  /**
   * @type {object}
   * @property {Array} userClientData the user client data
   * @property {Array} inputErrors the input errors
   */
  state = {
    loading: true,
    userClientData: [],
    inputErrors: []
  };

  /**
   * generic handle input change on the client details form
   * @param {SyntheticInputEvent} e
   */
  handleInputChange = (inputValue: InputValue) => {
    this.setState(prevState => ({
      userClientData: prevState.userClientData
        .filter(
          data =>
            data.clientFormInputType.id !== inputValue.clientFormInputType.id
        )
        .concat(inputValue)
    }));
  };

  /**
   * handle the client form submit
   * and inputs validations
   * @param {SyntheticEvent} event
   */
  handleSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.setState({ loading: true });
    let userInfo = this.props.config.module
      ? this.props.config.module.userInfo
      : undefined;
    let isRedirectToPayment = this.props.redirectToPayment;
    if (userInfo) {
      let buildField = [];
      for (let key in userInfo) {
        this.state.clientFormFields.map(item => {
          if (item._embedded.clientFormInputType.code === key) {
            buildField.push({
              value: userInfo[key].toString(),
              clientFormInputType: {
                id: item._embedded.clientFormInputType.id,
                type: item._embedded.clientFormInputType.type,
                inputType: item._embedded.clientFormInputType.inputType,
                code: item._embedded.clientFormInputType.code
              },
              isValid: true
            });
          }
        });
      }
      this.setState({ userClientData: buildField });
    }
    const inputErrors = formValidation(
      this.state.clientFormFields,
      this.state.userClientData,
      this.isClientInformationMandatory()
    );
    if (inputErrors.length === 0) {
      const { order, setOrderUser } = this.props;
      const { userClientData } = this.state;
      //on envoi tous les champs sauf emailconfirm
      const user = {
        clientDataList: userClientData
          .filter(
            user =>
              user.clientFormInputType.inputType != "EMAILCONFIRM" &&
              user.value != null
          )
          .map(data => ({
            value: data.value.toString(),
            clientFormInputType: {
              id: data.clientFormInputType.id,
              type: data.clientFormInputType.type
            }
          }))
      };

      patchOrderUser(order.id, user)
        .then(newOrder => {
          const userSaved = newOrder._embedded.userSaved;
          newOrder._embedded = order._embedded;
          newOrder._embedded.userSaved = userSaved;
          return newOrder;
        })
        .then(order => {
          if (isRedirectToPayment) {
            route(`/order/${order.id}/payment`);
          } else {
            setOrderUser(order);
            sendCheckoutEventOrderGTM(order, 6, false);
          }
          return order;
        })
        .catch(backEndError => {
          console.error("backEndError", backEndError);
          if (
            backEndError.status === 400 &&
            backEndError.stringCode === "ORDER_SERVICE.USER_EMAIL_NOT_VALID"
          ) {
            let inputErrors = [{ id: 4, type: backEndError.detail }];
            this.setState({ inputErrors, loading: false });
          } else {
            this.setState({ backEndError, loading: false });
          }
        });
    } else {
      this.setState({ inputErrors, loading: false });
    }
  };

  componentDidMount() {
    const { order } = this.props;
    getDefaultClientForm(this.props.locale)
      .then(defaultClientForm => {
        this.setState({
          loading: false,
          clientFormFields: defaultClientForm._embedded.clientFormFields,
          userClientData:
            order._embedded.userSaved && order._embedded.userSaved._embedded
              ? order._embedded.userSaved._embedded.clientDataList.map(
                  data => ({
                    value: data.value,
                    clientFormInputType: {
                      id: data._embedded.clientFormInputTypeSaved.id,
                      type: data._embedded.clientFormInputTypeSaved.type,
                      inputType:
                        data._embedded.clientFormInputTypeSaved.inputType
                    },
                    isValid: true
                  })
                )
              : order._embedded.user
              ? defaultClientForm._embedded.clientFormFields.reduce(
                  (acc, clientFormField) =>
                    acc.concat({
                      value:
                        order._embedded.user[
                          clientFormField._embedded.clientFormInputType.code
                        ],
                      clientFormInputType: {
                        id: clientFormField._embedded.clientFormInputType.id,
                        type:
                          clientFormField._embedded.clientFormInputType.type,
                        inputType:
                          clientFormField._embedded.clientFormInputType
                            .inputType
                      }
                    }),
                  []
                )
              : []
        });
        return defaultClientForm;
      })
      .catch(error => {
        console.error(error);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageDidMountOrUpdate) {
      this.props.pageDidMountOrUpdate();
    }

    if (prevProps.locale !== this.props.locale) {
      const { order } = this.props;
      getDefaultClientForm(this.props.locale)
        .then(defaultClientForm => {
          this.setState({
            loading: false,
            clientFormFields: defaultClientForm._embedded.clientFormFields
          });
          return defaultClientForm;
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  isClientInformationMandatory = () =>
    this.props.order._embedded.bookings
      .filter(booking => booking._embedded.product.type === "FRANCE_BILLET")
      .find(
        booking =>
          booking._embedded.product.publicMetadata.franceBillet
            .isClientInformationMandatory === true
      );

  /**
   * render
   * @param {ClientDetailsProps} the ClientDetails component props
   * @param {ClientDetailsState} the ClientDetails component state
   * @return {ReactElement} markup
   */
  render(
    {
      config,
      order,
      currency,
      theme,
      locale,
      getClassName
    }: {
      config?: WidgetConfig,
      order: Order,
      currency: Currency,
      backEndError: Error,
      theme?: object,
      locale?: string
    },
    {
      clientFormFields,
      userClientData,
      loading,
      inputErrors
    }: {
      clientFormFields: ClientFormFields,
      userClientData: UserClientData,
      loading: boolean,
      inputErrors?: Array<>
    }
  ) {
    return (
      <div class={styles.container}>
        <header
          class={getClassName("headerTitle")}
          style={`color:${
            this.context.themeConfig && this.context.themeConfig.color1
              ? this.context.themeConfig.color1
              : ""
          };`}
        >
          <h2>
            <Text id="client.header">Client details</Text>
          </h2>
        </header>
        <form
          autoComplete="off"
          onSubmit={this.handleSubmit}
          class={getClassName("formElement")}
        >
          <div class={getClassName("formInputsContainer")}>
            <div class={getClassName("formInputsContent")}>
              {loading && <Loading loading={loading} />}
              {!loading &&
                clientFormFields &&
                clientFormFields.map(clientFormField => (
                  <div>
                    <FormInput
                      theme={theme}
                      key={clientFormField.id}
                      clientFormField={clientFormField}
                      handleInputChange={this.handleInputChange}
                      locale={locale}
                      isClientInformationMandatory={this.isClientInformationMandatory()}
                      value={
                        userClientData.find(
                          data =>
                            data.clientFormInputType.id ===
                            clientFormField._embedded.clientFormInputType.id
                        )
                          ? userClientData.find(
                              data =>
                                data.clientFormInputType.id ===
                                clientFormField._embedded.clientFormInputType.id
                            ).value
                          : config.module && config.module.userInfo
                          ? config.module.userInfo[
                              clientFormField._embedded.clientFormInputType.code
                            ]
                          : undefined
                      }
                      emailconfirmvalue={
                        userClientData.find(
                          data =>
                            data.clientFormInputType.inputType ===
                            "EMAILCONFIRM"
                        )
                          ? userClientData.find(
                              data =>
                                data.clientFormInputType.inputType ===
                                "EMAILCONFIRM"
                            ).value
                          : undefined
                      }
                      error={inputErrors.find(
                        inputError =>
                          inputError.id ===
                          clientFormField._embedded.clientFormInputType.id
                      )}
                    />
                  </div>
                ))}
            </div>
          </div>

          {(config.type === "KIOSK" || config.type === "PMS") &&
            order.totalPrice !== null && (
              <div class={getClassName("totalPrice")}>
                <h3 class={getClassName("totalTitle")}>
                  <Text id="client.total">Total :</Text>
                </h3>
                <div>
                  <span class={getClassName("price")}>
                    {order.totalPrice.toFixed(2)}
                    {currency.symbol}
                  </span>
                  <span>
                    <Text id="client.priceTTC">TTC</Text>
                  </span>
                </div>
              </div>
            )}
          <FooterButton config={config} theme={theme}>
            <PrimaryButton className={getClassName("nextButton")} type="submit">
              {loading ? (
                <Loading color="#fff" />
              ) : (
                <Text id="client.save">Save</Text>
              )}
            </PrimaryButton>
          </FooterButton>
        </form>
      </div>
    );
  }
}

export default withStyles(ClientForm, styles);
