// @flow
// @jsx h

// External librairies
import { Component } from "preact";

type CheckoutProps = {
  orderReference: string,
  setOrderByReference: Function
};

/* FIXME when Preact implement getDerivedStateFromProps */
/* eslint-disable-next-line react/no-deprecated */
export default class Checkout extends Component<CheckoutProps> {
  componentWillMount() {
    const { orderReference, setOrderByReference } = this.props;
    if (orderReference) {
      setOrderByReference(orderReference);
    }
  }

  render() {
    return null;
  }
}
