// @flow

/**
 * Client side form inputs validation
 */
export default function inputsFormValidation(
  formFields: Array<ClientFormField>,
  formData
) {
  // mandatory inputs validation
  let formErrors = formFields
    .filter(formField => formField.mandatory === true)
    .reduce((acc, curr) => {
      let formItem = formData.find(
        inputData =>
          inputData.clientFormInputType.id ===
          curr._embedded.clientFormInputType.id
      );
      if (!formItem || formItem.value === "") {
        return acc.concat([
          { id: curr._embedded.clientFormInputType.id, type: "mandatory" }
        ]);
      } else {
        return acc;
      }
    }, []);

  // email validation
  let emailData = formData.find(
    inputData => inputData.clientFormInputType.code === "email"
  );

  let emailDataConfirm = formData.find(
    inputData => inputData.clientFormInputType.inputType === "EMAILCONFIRM"
  );

  if (emailData && !isValidEmail(emailData.value)) {
    formErrors = [
      ...formErrors,
      { id: emailData.clientFormInputType.id, type: "invalid" }
    ];
    //console.log(emailData);
  }

  //On test la validation du mail ATTENTION : existe aussi pour le elc-client-forms
  if (emailDataConfirm) {
    if (!isValidEmail(emailDataConfirm.value)) {
      formErrors = [
        ...formErrors,
        { id: emailData.clientFormInputType.id, type: "invalid" }
      ];
    }

    if (emailData.value != emailDataConfirm.value) {
      formErrors = [
        ...formErrors,
        { id: emailData.clientFormInputType.id, type: "invalidconfirm" }
      ];
    }
  } else {
    if (emailData) {
      formErrors = [
        ...formErrors,
        { id: emailData.clientFormInputType.id, type: "confirmationrequired" }
      ];
    }
  }

  // phone number validation
  formData
    .filter(formField => formField.clientFormInputType.code === "mobilephone")
    .map(phoneField => {
      if (!phoneField.isValid) {
        formErrors = [
          ...formErrors,
          { id: phoneField.clientFormInputType.id, type: "invalid" }
        ];
      }
    });

  return formErrors;
}

const isValidEmail = email =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
// function isValidEmail(email) {
//   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(email);
// }
