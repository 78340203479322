// @flow
// @jsx h

// External librairies
import { h, Component } from "preact";
import { Text } from "preact-i18n";

// Styles
import styles from "./TicketHoldersForm.scss";

// Utils
import { formValidation } from "elc-client-forms";
// import { FormInputs, formValidation } from 'elc-client-forms';

// API
import { createBooking, addParticipantClientData } from "elc-open-api";

// Components
import BookerContainer from "../bookercontainer";
import FooterButton from "../footerbutton";
import { PrimaryButton } from "elc-button";
import { LoaderIcon } from "elc-icons";
import TicketHolder from "./TicketHolder";

type TicketHoldersFormProps = {
  config: WidgetConfig,
  currentCompany: Company,
  currentProduct: Product,
  currentOrder: Order,
  currentDay: Day,
  currentTimeSlots: TimeSlots,
  currentSlot: Slot,
  currentQuantities: Quantities,
  clientFormFields: Array<ClientFormField>,
  setOrder: Function
};

type TicketHoldersFormState = {
  loading: boolean,
  clientDataList: Array<ClientData>,
  formErrors: Array<>,
  currentParticipantIndex: number,
  totalQuantity: number
};

/**
 * Ticket holders form Component
 */
export default class TicketHoldersForm extends Component<
  TicketHoldersFormProps,
  TicketHoldersFormState
> {
  constructor(props: TicketHoldersFormProps) {
    super(props);
    const totalQuantity = Array.from(props.currentQuantities.quantities).reduce(
      (acc, curr) => acc + curr[1],
      0
    );
    this.state = {
      loading: false,
      clientDataList: Array(totalQuantity).fill([]),
      formErrors: Array(totalQuantity).fill([]),
      currentParticipantIndex: 1,
      totalQuantity
    };
  }

  setClientDataList = (
    participantIndex: number,
    clientDataList: Array<ClientData>
  ) => {
    this.setState(prevState => {
      prevState.clientDataList[participantIndex - 1] = clientDataList;
      return { clientDataList: prevState.clientDataList };
    });
  };

  validateTicketHolderDataList = (participantIndex: number) => {
    const { clientFormFields } = this.props;
    const { clientDataList } = this.state;
    const ticketHolderErrors = formValidation(
      clientFormFields,
      clientDataList[participantIndex - 1]
    );
    if (ticketHolderErrors.length > 0) {
      this.setState(prevState => {
        prevState.formErrors[participantIndex - 1] = ticketHolderErrors;
        return { formErrors: prevState.formErrors };
      });
    } else {
      this.setState(prevState => {
        prevState.formErrors[participantIndex - 1] = ticketHolderErrors;
        return {
          formErrors: prevState.formErrors,
          currentParticipantIndex: prevState.currentParticipantIndex + 1
        };
      });
    }
  };

  isSaveVisible = () => {
    const {
      // formErrors,
      clientDataList,
      totalQuantity,
      currentParticipantIndex
    } = this.state;
    // const hasFormError = formErrors
    //   .slice(0, totalQuantity)
    //   .find(formError => formError.length > 0);
    const hasClientData = clientDataList.reduce(
      (acc, curr) => (curr.length === 0 ? false : acc),
      true
    );
    // return !hasFormError && hasClientData;
    return (
      totalQuantity === 1 ||
      currentParticipantIndex === totalQuantity ||
      hasClientData
    );
  };

  handleSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const {
      config,
      currentProduct,
      currentOrder,
      currentQuantities,
      currentDay,
      currentSlot,
      currentCompany,
      clientFormFields,
      setOrder
    } = this.props;
    const { clientDataList } = this.state;
    const formErrors = clientDataList.map(participantClientData =>
      formValidation(clientFormFields, participantClientData)
    );
    if (formErrors.reduce((acc, curr) => acc + curr.length, 0) > 0) {
      this.setState({ formErrors });
    } else {
      this.setState({ loading: true });
      createBooking(
        config.hash,
        config.type,
        currentProduct,
        currentQuantities,
        currentDay,
        currentSlot,
        undefined,
        currentOrder,
        currentCompany
      )
        .then(order => {
          return Promise.all(
            [order].concat(
              clientDataList.map((participantClientData, index) =>
                addParticipantClientData(
                  order._embedded.bookings[order._embedded.bookings.length - 1]
                    .id,
                  index + 1,
                  participantClientData.map(data => ({
                    value: data.value,
                    clientFormInputType: {
                      id: data.clientFormInputType.id,
                      type: data.clientFormInputType.type
                    }
                  }))
                )
              )
            )
          );
          // TODO : si souci ajouter filter comme dans ParticipantsClientForm.js
        })
        .then(([order]) => {
          setOrder(order);
          return order;
        })
        .catch(backEndError => {
          console.error("error creating booking", backEndError);
          if (
            backEndError.status === 400 &&
            backEndError.stringCode ===
              "BOOKING_SERVICE.USER_EMAIL_ADDRESS_NOT_VALID"
          ) {
            let formErrors = [["email", "invalid"]];
            this.setState({ formErrors, loading: false });
          } else {
            this.setState({ backEndError, loading: false });
          }
        });
    }
  };

  getCustomerClassLabel(customerClassId: string, currentSlot: Slot) {
    const productUnitTimeSlotAvailability = currentSlot._embedded.productUnitTimeSlotAvailabilities.find(
      productUnitTimeSlotAvailability =>
        productUnitTimeSlotAvailability._embedded.unit.name === customerClassId
    );
    return productUnitTimeSlotAvailability._embedded.unit.publicMetadata
      .franceBillet.customerClass.customerClassLabel;
  }

  toggleTicketHolder = (participantIndex: number) => {
    this.setState({ currentParticipantIndex: participantIndex });
  };

  render(
    {
      theme,
      config,
      locale,
      error,
      currentSlot,
      currentQuantities,
      clientFormFields
    },
    {
      loading,
      formErrors,
      clientDataList,
      currentParticipantIndex,
      totalQuantity
    }
  ) {
    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        class={styles.ticketHoldersFormContainer}
      >
        <BookerContainer>
          {Array.from(currentQuantities.quantities).map((quantity, index) => {
            let participants = [];
            for (let i = 1; i <= quantity[1]; i++) {
              const participantIndex =
                index > 0
                  ? Array.from(currentQuantities.quantities)
                      .slice(0, index)
                      .reduce((acc, curr) => curr[1] + acc, 0) + i
                  : index + i;
              participants.push(
                <div
                  key={i}
                  class={`${styles.ticketHolderContainer} ${
                    currentParticipantIndex === participantIndex ||
                    formErrors[participantIndex - 1].length > 0
                      ? styles.visibleTicketHolder
                      : styles.hiddenTicketHolder
                  }`}
                >
                  <div class={`${styles.ticketHolderTitle}`}>
                    <span>Participant n°{i}</span>
                    {participantIndex < currentParticipantIndex && (
                      <button
                        class={styles.toggleButton}
                        onClick={() => {
                          this.toggleTicketHolder(index + i);
                        }}
                      />
                    )}
                  </div>
                  {participantIndex !== currentParticipantIndex && (
                    <div>
                      <div>
                        {clientDataList[participantIndex - 1].find(
                          data => data.clientFormInputType.code === "prename"
                        ) && (
                          <span>
                            {
                              clientDataList[participantIndex - 1].find(
                                data =>
                                  data.clientFormInputType.code === "prename"
                              ).value
                            }
                          </span>
                        )}{" "}
                        {clientDataList[participantIndex - 1].find(
                          data => data.clientFormInputType.code === "name"
                        ) && (
                          <span>
                            {
                              clientDataList[participantIndex - 1].find(
                                data => data.clientFormInputType.code === "name"
                              ).value
                            }
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  <div class={styles.ticketHolderFormContainer}>
                    <TicketHolder
                      theme={theme}
                      locale={locale}
                      participantIndex={participantIndex}
                      clientFormFields={clientFormFields}
                      clientDataList={clientDataList[participantIndex - 1]}
                      inputErrors={formErrors[participantIndex - 1]}
                      setClientDataList={this.setClientDataList}
                      validateTicketHolderDataList={
                        participantIndex !== totalQuantity &&
                        totalQuantity !== 1
                          ? this.validateTicketHolderDataList
                          : undefined
                      }
                    />
                  </div>
                </div>
              );
            }
            return (
              <div key={quantity[0]}>
                <div>
                  {this.getCustomerClassLabel(quantity[0], currentSlot)}
                </div>
                {participants}
              </div>
            );
          })}
          {error && error.message && <div>{error.message}</div>}
        </BookerContainer>
        {this.isSaveVisible() && (
          <FooterButton theme={theme} config={config}>
            <PrimaryButton type="submit">
              {loading ? (
                <div class={styles.loaderIcon}>
                  <LoaderIcon />
                </div>
              ) : (
                <Text id="button.save">Save</Text>
              )}
            </PrimaryButton>
          </FooterButton>
        )}
      </form>
    );
  }
}
