// @flow
import { Component } from 'preact';

/** `<ThemeConfigProvider>` is a nestable theme configuration provider.
 *	It exposes a theme configuration into the tree,
 *	making it available to descendant components.
 *
 *	@name ThemeConfigProvider
 *	@param props
 *	@param {Object} [props.theme]			Nest `definition` under a root key, and set the active scope for the tree (essentially prefixing all `<Text />` keys).
 *
 *	@example
 *	// themeConfig is generally retrived the Widget conf parsed JSON.
 *
 *	<ThemeConfigProvider conf={conf}>
 *		<div style={`color:${themeConfig.color1}`}>The Foo</div>
 *	</ThemeConfigProvider>
 */
export default class ThemeConfigProvider extends Component {
  getChildContext() {
    let { conf } = this.props;
    if (conf) {
      let fontFamily = [
        {
          id: 1,
          value: "'Open Sans', sans-serif"
        },
        {
          id: 2,
          value: "'Indie Flower', cursive"
        },
        {
          id: 3,
          value: "'Poiret One', cursive"
        },
        {
          id: 4,
          value: "'Lobster', cursive"
        },
        {
          id: 5,
          value: "'Rochester', cursive"
        },
        {
          id: 6,
          value: "'Poppins', sans-serif"
        },
        {
          id: 7,
          value: "'Balthazar', serif"
        },
        {
          id: 8,
          value: "'Concert One', cursive"
        },
        {
          id: 9,
          value: "'Roboto Slab', serif"
        },
        {
          id: 10,
          value: "'Rokkitt', serif"
        },
        {
          id: 11,
          value: "'Sanchez', serif"
        }
      ].find(font => font.id === parseInt(conf.font));
      if (fontFamily) {
        fontFamily = fontFamily.value;
      }

      return {
        themeConfig: {
          color1: conf.color1,
          color2: conf.color2,
          color3: conf.color3,
          color4: conf.color4,
          color5: conf.color5,
          fontFamily: fontFamily
        }
      };
    }
  }

  render({ children }) {
    return (children && children[0]) || null;
  }
}
