// @flow
// @jsx h
import { h, Component } from "preact";

import { getWidgetProductsCategories } from "elc-internal-api";

import ProductsFilter from "elc-products-filter";

export default class FetchCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      categories: undefined
    };
  }

  fetchWidgetProductsCategories() {
    getWidgetProductsCategories(this.props.hash, {
      preview: this.props.preview,
      locale: this.props.locale
    })
      .then(categories => {
        this.setState({ categories, loading: false });
        return categories;
      })
      .catch(error => {
        console.error("Error getWidgetCategories", error);
      });
  }

  componentDidMount() {
    if (typeof this.state.categories === "undefined") {
      this.fetchWidgetProductsCategories();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.fetchWidgetProductsCategories();
    }
  }

  render({ config, locale, filters, filterProducts }, { loading, categories }) {
    return config.module.conf.display_filter &&
      config.module.conf.display_filter === 1 ? (
      loading ? null : (
        <ProductsFilter
          config={config}
          locale={locale}
          filters={filters}
          categories={categories}
          filterProducts={filterProducts}
        />
      )
    ) : null;
  }
}
