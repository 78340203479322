// @flow
// @jsx h

// External librairies
import { h, Component } from "preact";

import scriptLoader from "react-async-script-loader";

/**
 * Load Stripe script and render children component once it is loaded
 */
/* FIXME when Preact implement getDerivedStateFromProps */
/* eslint-disable-next-line react/no-deprecated */
class LoadStripeScript extends Component {
  state = {
    loading: true
  };

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        this.setState({ loading: false });
      } else this.props.onError();
    }
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ loading: false });
    }
  }

  render({ children }, { loading }) {
    return <div>{loading ? <div>Loading script</div> : { children }}</div>;
  }
}

export default scriptLoader("https://js.stripe.com/v3/")(LoadStripeScript);
