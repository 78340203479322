const toLocalePrice = (amount, language, currency) => {
  const options = {
    style: 'currency',
    currency: currency
  };

  // Number.isInteger polyfill (for IE)
  // Number.isInteger =
  //   Number.isInteger ||
  //   function(value) {
  //     return (
  //       typeof value === 'number' &&
  //       isFinite(value) &&
  //       Math.floor(value) === value
  //     );
  //   };
  if (!Number.isInteger(amount)) {
    amount = Math.round(amount * Math.pow(10, 2)) / Math.pow(10, 2);
  }
  if (Number.isInteger(amount)) {
    options.minimumFractionDigits = 0;
  }

  return amount.toLocaleString(language, options);
};

export default toLocalePrice;
