// @flow
// @jsx h

// External librairies
import { h } from "preact";
import { Text } from "preact-i18n";

import { FormInputs } from "elc-client-forms";
import { PrimaryButton } from "elc-button";

type InputErrors = {
  id: number,
  type: string,
  fields?: Array<string>
};

type TicketHolderProps = {
  theme?: Object,
  locale: string,
  participantIndex: number,
  clientFormFields: Array<ClientFormField>,
  clientDataList: Array<ClientData>,
  inputErrors: InputErrors,
  setClientDataList: Function,
  validateTicketHolderDataList: Function
};

/**
 * TicketHolder presentational functional component
 */
const TicketHolder = ({
  theme,
  locale,
  participantIndex,
  clientFormFields,
  clientDataList,
  inputErrors,
  setClientDataList,
  validateTicketHolderDataList
}: TicketHolderProps) => {
  const setTicketHolderClientDataList = clientDataList => {
    setClientDataList(participantIndex, clientDataList);
  };

  const handleValidateTicketHolderDataList = () => {
    validateTicketHolderDataList(participantIndex);
  };

  return (
    <div>
      <FormInputs
        theme={theme}
        locale={locale}
        clientFormFields={clientFormFields}
        clientDataList={clientDataList}
        inputErrors={inputErrors}
        setClientDataList={setTicketHolderClientDataList}
      />
      {validateTicketHolderDataList && (
        <PrimaryButton handleClick={handleValidateTicketHolderDataList}>
          <Text id="button.next">Next</Text>
        </PrimaryButton>
      )}
    </div>
  );
};

export default TicketHolder;
