// @flow
// @jsx h
import { h, Component } from "preact";
import { Text } from "preact-i18n";

import styles from "./BookingParticipantClientForms.scss";
import ParticipantClientForm from "./participant-client-form/ParticipantClientForm.js";
import { getParticipantClientData } from "elc-open-api";

export default class BookingParticipantClientForms extends Component {
  state = {
    currentOpenedParticipants: 0,
    savedParticipants: {}
  };

  handleInputChange = event => {
    const value = event.value;
    const name = event.clientFormInputType.name;

    this.setState({
      [name]: value
    });
  };

  componentDidMount() {
    getParticipantClientData(this.props.booking.id)
      .then(participantClientData => {
        this.setState({
          clientData: participantClientData._embedded.clientData
        });
        return participantClientData;
      })
      .catch(error => {
        console.error("Error getParticipantClientData", error);
      });
  }

  toggleParticipant(index) {
    if (this.state.currentOpenedParticipants === index) {
      return;
    }

    this.setState({
      currentOpenedParticipants: index
    });
  }

  onParticipantSaved = (index, newClientData) => {
    this.setState(prevState => {
      prevState.savedParticipants[index] = true;

      prevState.clientData = prevState.clientData.filter(field => {
        return (
          field.participantIndex !==
          newClientData._embedded.participantClientData[0].participantIndex
        );
      });
      prevState.clientData = [
        ...prevState.clientData,
        ...newClientData._embedded.participantClientData
      ];
      return prevState;
    });

    this.toggleParticipant(index + 1);
  };

  render({ booking, participantClientForm }, { clientData }) {
    let forms = [];
    for (
      let participantIndex = 0;
      participantIndex < booking.quantity;
      participantIndex++
    ) {
      forms.push(
        <div
          class={`${styles.participantContainer} ${
            this.state.currentOpenedParticipants === participantIndex
              ? styles.visibleParticipant
              : styles.hiddenParticipant
          }`}
        >
          <div
            class={`${styles.participantNb} ${
              this.state.savedParticipants[participantIndex] ? styles.saved : ""
            }`}
          >
            <span>
              {" "}
              <Text
                id="participantClientForm.participantNb"
                fields={{
                  number: participantIndex + 1
                }}
              >
                Participant n°{participantIndex + 1}
              </Text>
            </span>
            <button
              class={styles.toggleButton}
              onClick={() => {
                this.toggleParticipant(participantIndex);
              }}
            />
          </div>
          <div class={styles.participantFormContainer}>
            <ParticipantClientForm
              key={participantIndex}
              participantIndex={participantIndex}
              participantClientForm={participantClientForm}
              clientData={
                clientData &&
                clientData
                  .filter(
                    data => data.participantIndex === participantIndex + 1
                  )
                  .map(data => ({
                    participantIndex: data.participantIndex,
                    value: data.value,
                    clientFormInputType: {
                      id: data._embedded.clientFormInputTypeSaved.id,
                      type: data._embedded.clientFormInputTypeSaved.type
                    }
                  }))
              }
              bookingId={booking.id}
              localeString={this.props.localeString}
              onParticipantSaved={this.onParticipantSaved}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        <div class={styles.bookingId}>{booking._embedded.product.name}</div>
        {forms}
      </div>
    );
  }
}
