// @flow
// @jsx h

import { h } from "preact";

import { ListIcon, CardsIcon } from "elc-icons";

import styles from "./style.scss";

/**
 * Bank check payment confirmation Component
 */
const ProductsViewToggle = (
  {
    setProductsViewModeList,
    setProductsViewModeGrid,
    productsListViewMode
  }: {
    setProductsViewModeList: Function,
    setProductsViewModeGrid: Function,
    productsListViewMode: boolean
  },
  context
) => (
  <div class={styles.viewModes}>
    <button
      onClick={setProductsViewModeList}
      class={`${styles.changeViewButton} ${
        productsListViewMode ? styles.viewActive : ""
      }`}
      style={
        context.themeConfig &&
        context.themeConfig.color1 &&
        productsListViewMode
          ? "background-color: " + context.themeConfig.color1
          : ""
      }
    >
      <ListIcon />
    </button>
    <button
      onClick={setProductsViewModeGrid}
      class={`${styles.changeViewButton} ${
        !productsListViewMode ? styles.viewActive : ""
      }`}
      style={
        context.themeConfig &&
        context.themeConfig.color1 &&
        !productsListViewMode
          ? "background-color: " + context.themeConfig.color1
          : ""
      }
    >
      <CardsIcon />
    </button>
  </div>
);

export default ProductsViewToggle;
