// @flow
// @jsx h
import { h, Component } from "preact";

import Phone, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/rrui.css";
import "react-phone-number-input/style.css";

import styles from "./PhoneInput.scss";

type PhoneInputProps = {
  name: string,
  id: string,
  value: string,
  onChange: Function,
  placeholder: string,
  locale?: string
};

type PhoneInputState = {
  internationalValue: string,
  country: string
};

export default class PhoneInput extends Component<
  PhoneInputProps,
  PhoneInputState
> {
  constructor(props: PhoneInputProps) {
    super(props);
    this.state = {
      internationalValue: this.props.value || "",
      country: "FR"
    };
  }

  setInternationalValue(phone: string) {
    this.setState({ internationalValue: phone });
    this.props.onChange({
      target: {
        type: "phone",
        name: this.props.name,
        value: phone,
        isValid: isValidPhoneNumber(phone)
      }
    });
  }

  componentWillReceiveProps(nextProps: PhoneInputProps) {
    if (nextProps.locale !== this.props.locale) {
      if (nextProps.locale === "fr") {
        this.setState({ country: "FR" });
      } else {
        this.setState({ country: "GB" });
      }
    }
  }

  render() {
    return (
      <Phone
        id={this.props.id}
        name={this.props.name}
        placeholder={this.props.placeholder}
        value={this.state.internationalValue}
        country={this.state.country}
        flagsPath="https://flagicons.lipis.dev/flags/4x3/"
        onChange={value => this.setInternationalValue(value)}
        convertToNational
        className={styles.phoneElement}
        inputClassName={styles.phoneInput}
        // nativeExpanded
      />
    );
  }
}
