// @flow
// @jsx h
import { h, Component } from "preact";
import styles from "./style.scss";
import Swiper from "react-id-swiper";

export default class ProductImagesSwipe extends Component {
  render({ images, pageDidMountOrUpdate }) {
    const params = {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        bulletActiveClass: `${styles.swiperPaginationBulletActive}`
      },
      paginationClickable: true,
      slidesPerView: 1,
      autoplay: {
        delay: 5000
      },
      containerClass: `swiper-container ${styles.swiperContainer}`
    };

    return (
      <Swiper {...params}>
        {images.map((image, index) => {
          let srcSet = "";
          let originalImage = image._embedded.files.find(
            file => file.name === "ORIG"
          );
          srcSet = originalImage ? `${originalImage.path} 1024w` : "";
          let image400 = image._embedded.files.find(
            file => file.name === "400x400"
          );
          srcSet += image400 ? `, ${image400.path} 640w` : "";
          let image200 = image._embedded.files.find(
            file => file.name === "200x200"
          );
          srcSet += image200 ? `, ${image200.path} 320w` : "";
          return (
            <div key={index} class={`swiper-slide ${styles.swiperSlide}`}>
              <div class="swiper-zoom-container">
                <img
                  onLoad={() => {
                    pageDidMountOrUpdate();
                  }}
                  src={image._embedded.files[0].path}
                  srcSet={srcSet}
                  alt={image.title}
                  class={styles.productSlideImg}
                />
                {/*<img
                  src={image._embedded.files[0].path}
                  alt={image.title}
                  class={styles.productSlideImg}
                />*/}
              </div>
            </div>
          );
        })}
      </Swiper>
    );
  }
}
