// @flow
// @ jsx h

import { h, Component } from "preact";
import { Text, IntlProvider } from "preact-i18n";

import styles from "./OrderSummary.scss";
import { ArrowLeftIcon } from "elc-icons";

import BookingSummary from "./BookingSummary.js";

import { getOrderById } from "elc-open-api";

import ca from "../../translations/ca.json";
import de from "../../translations/de.json";
import en from "../../translations/en.json";
import es from "../../translations/es.json";
import fr from "../../translations/fr.json";
import it from "../../translations/it.json";
import nl from "../../translations/nl.json";
import he from "he";

type OrderSummaryProps = {
  config: WidgetConfig,
  order: Order,
  urlToShop: string,
  locale: string,
  checkSession:Function
};

export default class OrderSummary extends Component<OrderSummaryProps> {
  constructor(props) {
    super(props);
    switch (props.locale) {
      case "fr":
        this.setState({ localeDefinition: fr, localeString: "fr" });
        break;

      case "ca":
        this.setState({ localeDefinition: ca, localeString: "ca" });
        break;

      case "de":
        this.setState({ localeDefinition: de, localeString: "de" });
        break;

      default:
      case "en":
        this.setState({ localeDefinition: en, localeString: "en" });
        break;

      case "es":
        this.setState({ localeDefinition: es, localeString: "es" });
        break;

      case "it":
        this.setState({ localeDefinition: it, localeString: "it" });
        break;

      case "nl":
        this.setState({ localeDefinition: nl, localeString: "nl" });
        break;
    }

  }

  componentDidMount() {
    if (!window.matchMedia) return;
    this.mql = window.matchMedia("(max-width: 53.75em)");
    this.mql.addListener(this.onMatch);
    this.onMatch(this.mql);
  }

  componentWillUnmount() {
    this.mql && this.mql.removeListener(this.onMatch);
  }

  onMatch = mql => {
    const mobileDisplay = !!mql.matches;
    this.setState({ mobileDisplay });
  };

  componentDidUpdate(prevProps) {
    if (this.props.pageDidMountOrUpdate) {
      this.props.pageDidMountOrUpdate();
    }
    if (prevProps.locale !== this.props.locale) {
      getOrderById(this.props.order.id, this.props.locale).then(order => {
        this.setState({ order: order });
        this.props.order = order;
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.order && this.props.checkSession && nextProps.path  !== '/order/:orderId/payment/confirmation') {
      this.props.checkSession(this.props.order);
    }
    if (nextProps.locale !== this.props.locale) {
      switch (nextProps.locale) {
        case "de":
          this.setState({ locale: "de", localeDefinition: de });
          break;

        case "ca":
          this.setState({ locale: "ca", localeDefinition: ca });
          break;

        case "en":
          this.setState({ locale: "en", localeDefinition: en });
          break;

        case "es":
          this.setState({ locale: "es", localeDefinition: es });
          break;

        case "fr":
          this.setState({ locale: "fr", localeDefinition: fr });
          break;

        case "it":
          this.setState({ locale: "it", localeDefinition: it });
          break;

        case "nl":
          this.setState({ locale: "nl", localeDefinition: nl });
          break;
      }
    }
  }

  render({
    config,
    order,
    children,
    urlToShop,
    localeDefinition
  }: {
    hash: string,
    config: WidgetConfig,
    currency: Currency,
    order: Order,
    urlToShop: string,
    localeDefinition: string
  }) {
    let localeDef = this.state.localeDefinition;
    return (
      <div>
        <IntlProvider definition={localeDef}>
          <div class={styles.columnsContainer}>
            <div class={styles.cellSummary}>
              <header class={styles.orderSummaryHeader}>
                {urlToShop && (
                  <a href={urlToShop} class={styles.backToShop}>
                    <ArrowLeftIcon
                      color={
                        this.context.themeConfig &&
                        this.context.themeConfig.color1
                          ? this.context.themeConfig.color1
                          : styles.green
                      }
                    />
                    <Text id="basket.summary.backToShop">
                      Retour à la boutique
                    </Text>
                  </a>
                )}
                <h2 class={styles.orderSummaryHeaderTitle}>
                  <Text
                    id="basket.summary.nbBookings"
                    fields={{
                      nbBookings: order ? order._embedded.bookings.length : 0
                    }}
                  >
                    You have {order ? order._embedded.bookings.length : 0}{" "}
                    product(s) in you basket
                  </Text>
                </h2>
                {order &&
                  (order.status === "DRAFT" ||
                    order.status === "USER_FILLED") && (
                    <p class={styles.orderSummaryHeaderSubtitle}>
                      <Text id="basket.summary.duration">
                        We book your order during 15 minutes
                      </Text>
                    </p>
                  )}
              </header>
              <ul class={styles.bookingsList}>
                {order &&
                  order._embedded.bookings.map(booking => (
                    <li key={booking.id}>
                      <BookingSummary config={config} booking={booking} />
                    </li>
                  ))}
              </ul>
            </div>
            <div class={styles.cellBasket}>{children}</div>
          </div>
        </IntlProvider>
      </div>
    );
  }
}
