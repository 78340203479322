// @flow
// @jsx h

// External librairies
import { h, Component } from "preact";
import { Text } from "preact-i18n";

// Style
import styles from "./TermSales.scss";

type TermSalesProps = {
  isTermSalesAccepted: boolean,
  termSales: string,
  toggleTermSalesAccepted: Function
};
type TermSalesState = { showTermSales: boolean };

/**
 * Term sales component
 * used to display
 * - a checkbox to accept the term of sales
 * - a button to show/hide the term of sales
 */
export default class TermSales extends Component<
  TermSalesProps,
  TermSalesState
> {
  state = {
    showTermSales: false
  };

  handleAcceptTermSales = ({ target }) => {
    this.props.toggleTermSalesAccepted(target.checked);
  };

  onClickShowTermSales = () => {
    this.setState({ showTermSales: !this.state.showTermSales });
  };

  onClickCloseTermSales = () => {
    this.setState({ showTermSales: false });
  };

  render(
    {
      isTermSalesAccepted,
      termSales
    }: { isTermSalesAccepted: boolean, termSales: string },
    { showTermSales }: { showTermSales: boolean },
    context
  ) {
    return (
      <div class={styles.termSalesContainer}>
        <div class={styles.termSalesMovableElement}>
          <div class={styles.termSalesHeader}>
            <div class={styles.switchInput}>
              <label htmlFor="isTermSalesAccepted" class={styles.switch}>
                <input
                  type="checkbox"
                  id="isTermSalesAccepted"
                  name="isTermSalesAccepted"
                  checked={isTermSalesAccepted}
                  onChange={this.handleAcceptTermSales}
                />
                <span
                  class={`${styles.slider} ${styles.round}`}
                  style={`${
                    context.themeConfig &&
                    context.themeConfig.color1 &&
                    isTermSalesAccepted
                      ? "background-color:" + context.themeConfig.color1
                      : ""
                  }`}
                />
              </label>
              <label htmlFor="isTermSalesAccepted">
                <Text id="basket.termSales.accept">J&apos;accepte les CGV</Text>
              </label>
            </div>
            <button
              class={styles.showTermSalesButton}
              onClick={this.onClickShowTermSales}
            >
              <Text id="basket.termSales.show">Show</Text>
            </button>
          </div>
          {showTermSales && (
            <div class={`${styles.modal} ${showTermSales ? styles.show : ""}`}>
              <div
                class={styles.overlay}
                onClick={this.onClickCloseTermSales}
              ></div>
              <div class={`${styles.termSales}`}>
                <header class={styles.row}>
                  <h2>
                    <Text id="basket.termSales.title">Hide</Text>
                  </h2>
                  <button onClick={this.onClickCloseTermSales}>
                    <Text id="basket.termSales.hide">CGV</Text>
                  </button>
                </header>
                <div class={styles.termWrapper}>
                  {termSales &&
                    termSales.length &&
                    termSales.map(item =>
                      item.termSale != null ? (
                        <div>
                          <h3>{item.name}</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.termSale.replace(/\n/g, "<br>")
                            }}
                          ></div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
