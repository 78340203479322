// @flow
// @jsx h

// External librairies
import { h, Component } from "preact";
import { Text, withText } from "preact-i18n";

// Styles
import styles from "./BeneficiaryForm.scss";
import { withStyles } from "elc-theme";

// Utils
import { FormInputs, formValidation } from "elc-client-forms";

// API
import { createGiftBooking } from "elc-open-api";

// Components
import BookerContainer from "../bookercontainer";
import FooterButton from "../footerbutton";
import { PrimaryButton } from "elc-button";
import { LoaderIcon } from "elc-icons";

type BeneficiaryFormProps = {
  config: WidgetConfig,
  currentGift: GiftProduct,
  setOrder: Function,
  getClassName: Function,
  sendBeneficiaryEmail: boolean,
  toggleSendEmailAccepted: Function
};

type BeneficiaryFormState = {
  loading: boolean,
  clientDataList: Array<>,
  beneficiaryMessage: string,
  sendBeneficiaryEmail: boolean
};

/**
 * Gift beneficiary Component form
 */
@withText({
  beneficiaryMessagePlaceholder:
    "gift.beneficiary.placeholder.beneficiaryMessage"
})
class BeneficiaryForm extends Component<
  BeneficiaryFormProps,
  BeneficiaryFormState
> {
  state = {
    loading: false,
    clientDataList: [],
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    formErrors: []
  };

  setClientDataList = clientDataList => {
    this.setState({ clientDataList });
  };

  handleAcceptSendBeneficiaryEmail = ({ target }) => {
    this.state.sendBeneficiaryEmail = target.checked;
  };

  handleInputChange = ({
    target
  }: SyntheticInputEvent<HTMLInputElement> | Object) => {
    const value: boolean | string =
      target.type === "checkbox" ? target.checked : target.value;
    const name: string = target.name;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const {
      config,
      currentProduct,
      currentOrder,
      currentCompany,
      clientFormFields,
      setOrder,
      currentGift
    } = this.props;
    const {
      clientDataList,
      beneficiaryMessage,
      sendBeneficiaryEmail
    } = this.state;
    const formErrors = formValidation(clientFormFields, clientDataList);
    if (formErrors.length > 0) {
      this.setState({ formErrors });
    } else {
      const beneficiary = {
        clientDataList: clientDataList
          .filter(user => user.clientFormInputType.inputType != "EMAILCONFIRM")
          .filter(data => data.value != "")
          .map(data => ({
            value: data.value,
            clientFormInputType: {
              id: data.clientFormInputType.id,
              type: data.clientFormInputType.type
            }
          }))
      };
      createGiftBooking(
        config.hash,
        config.type,
        currentProduct,
        1,
        // this.state.giftQuantity,
        beneficiary,
        currentOrder,
        currentCompany,
        config,
        beneficiaryMessage,
        sendBeneficiaryEmail,
        currentGift,
        this.props.locale
      )
        .then(order => {
          setOrder(order);
          return order;
        })
        .catch(backEndError => {
          console.error("error creating booking", backEndError);
          if (
            backEndError.status === 400 &&
            backEndError.stringCode ===
              "BOOKING_SERVICE.USER_EMAIL_ADDRESS_NOT_VALID"
          ) {
            let formErrors = [["email", "invalid"]];
            this.setState({ formErrors, loading: false });
          } else {
            this.setState({ backEndError, loading: false });
          }
        });
    }
  };

  render(
    {
      theme,
      config,
      locale,
      error,
      clientFormFields,
      beneficiaryMessagePlaceholder,
      sendBeneficiaryEmail,
      getClassName
    }: { sendBeneficiaryEmail: boolean },
    { loading, formErrors, clientDataList }
  ) {
    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        class={getClassName("giftformContainer")}
      >
        <BookerContainer>
          <div class={getClassName("giftformContent")}>
            <FormInputs
              theme={theme}
              locale={locale}
              clientFormFields={clientFormFields}
              clientDataList={clientDataList}
              inputErrors={formErrors}
              setClientDataList={this.setClientDataList}
            />
            <div class={getClassName("inputField")}>
              <label htmlFor="beneficiaryMessage" class={getClassName("label")}>
                <Text id="gift.beneficiary.label.beneficiaryMessage">
                  Votre message sur le bon cadeau
                </Text>
              </label>
              <textarea
                id="beneficiaryMessage"
                name="beneficiaryMessage"
                placeholder={beneficiaryMessagePlaceholder}
                onChange={this.handleInputChange}
                class={getClassName("beneficiaryMessage")}
              />
            </div>
            <div
              class={`${getClassName("inputField")} ${
                theme && theme.inputFieldCheckbox
                  ? theme.inputFieldCheckbox
                  : styles.inputFieldCheckbox
              }`}
            >
              <div class={getClassName("switchInput")}>
                <div>
                  <label
                    class={getClassName("switch")}
                    htmlFor="sendBeneficiaryEmail"
                  >
                    <input
                      name="sendBeneficiaryEmail"
                      id="sendBeneficiaryEmail"
                      type="checkbox"
                      checked={sendBeneficiaryEmail}
                      onChange={this.handleAcceptSendBeneficiaryEmail}
                    />
                    <span
                      class={`${getClassName("slider")} ${
                        theme && theme.round ? theme.round : styles.round
                      }`}
                      style={`${
                        this.context.themeConfig &&
                        this.context.themeConfig.color1 &&
                        sendBeneficiaryEmail
                          ? "background-color:" +
                            this.context.themeConfig.color1
                          : ""
                      }`}
                    />
                  </label>
                </div>

                <label
                  class={getClassName("label")}
                  htmlFor="sendBeneficiaryEmail"
                >
                  <Text id="gift.beneficiary.label.sendBeneficiaryEmail">
                    Envoyer le bon cadeau par mail au bénéficiaire
                  </Text>
                </label>
              </div>

              {error && error.message && <div>{error.message}</div>}
            </div>
          </div>
        </BookerContainer>
        <FooterButton theme={theme} config={config}>
          <PrimaryButton type="submit">
            {loading ? (
              <div class={getClassName("loaderIcon")}>
                <LoaderIcon />
              </div>
            ) : (
              <Text id="gift.beneficiary.button.next">Next</Text>
            )}
          </PrimaryButton>
        </FooterButton>
      </form>
    );
  }
}

export default withStyles(BeneficiaryForm, styles);
