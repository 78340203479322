// @flow
// @jsx h

import FreeOrderConfirmation from "./payment-confirmation/FreeOrderConfirmation";

declare var PHP_API_ENDPOINT: string;

// External librairies
import { h, Component } from "preact";
import Router from "preact-router";
import { route } from "preact-router";
import Match from "preact-router/match";
// import he from 'he';
import WebFont from "webfontloader";

// Translations
import { IntlProvider } from "preact-i18n";
import ca from "../translations/ca.json";
import de from "../translations/de.json";
import en from "../translations/en.json";
import es from "../translations/es.json";
import fr from "../translations/fr.json";
import it from "../translations/it.json";
import nl from "../translations/nl.json";

// Styles and theming
import styles from "./style.scss";
import { ThemeConfigProvider } from "elc-theme";

// Components
import Error from "./error";
import Header from "./header/Header";
import Redirect from "./redirect";
import Products from "./products";
import Product from "./product";

import ClientForm from "elc-client-form";
import OrderSummary from "./order-summary/OrderSummary.js";
import OrderExpired from "./order-expired/OrderExpired.js";
import Basket from "elc-basket";
import Loading from "elc-loading";
import Checkout from "./checkout";
import StripePayment from "./stripe-payment";
import Stripe3DSecureConfirmation from "./stripe-3D-secure-confirmation";
import OtherPaymentMethods from "./other-payment-methods/OtherPaymentMethods.js";
import PaymentConfirmation from "./payment-confirmation/PaymentConfirmation.js";
import ParticipantClientForms from "./participant-client-forms/ParticipantClientForms.js";
import ParticipantFormCreated from "./participant-form-created/ParticipantFormCreated.js";
import LoadCSS from "../service/load-css";
import {
  sendPurchaseEventGTM,
  sendUXEventGTM,
  sendCheckoutEventOrderGTM,
  sendDataLayerVariableGTM
} from "elc-commons";

// API methods
import { getToken, setGenerateTokenMethod, getOrderById } from "elc-open-api";
import {
  generateTokenByOrderReference,
  getOrderByReference,
  getPaymentMethods,
  postOrderPayment,
  postOrderPaymentEmail,
  postParamsInURL
} from "elc-internal-api";

type WidgetProps = {};

type WidgetState = {
  loading: boolean,
  locale: Object,
  localeString: string,
  currency: Currency,
  productId?: number,
  productsListViewMode: boolean,
  currentOrder?: Order
};

class App extends Component<WidgetProps, WidgetState> {
  state = {
    loading: true,
    locale: fr,
    localeString: "fr",
    callbackStripe: undefined,
    currency: {
      // code: "EUR",
      // codeNumber: "978",
      // id: 2,
      // name: "Euro",
      // sign: "€",
      // symbol: "&euro;"
      active: true,
      id: 2,
      isoCode: "EUR",
      name: "Euro",
      numCode: 978,
      symbol: "&euro;"
    },
    isRedirectToPayment: false,
    orderRef: null,
    preview: false,
    productId: undefined,
    productPageUpdate: false,
    productsListViewMode: true,
    showBackToProduct: false,
    urlToShop: undefined,
    noPayment: false,
    sessionValid: true
  };

  // constructor(props) {
  //   super(props);
  //   // get Widget hash from url
  //   const urlParts = window.location.href.split('/');
  //   this.setState({ hash: urlParts[3] });
  // }

  /**
   * Initiliaze the config for the widget
   * @param {string} hash
   * @param {object} config
   * @param {boolean} preview
   * @param {object} company
   */
  initConfig = (hash, config, preview, company) => {
    const searchParam = window.location.search;
    if (searchParam) {
      const getParams = searchParam.slice(
        searchParam.indexOf("=") + 1,
        searchParam.length
      );
      const parseParams = JSON.parse(decodeURIComponent(getParams));
      this.disabledReservation = parseParams.disabledReservation;
      this.options = parseParams;
    }
    parent.postMessage(`action=loaded&id_iframe=${hash}`, "*");
    // config.company.defaultCurrency.symbol = he.decode(
    //   config.company.defaultCurrency.symbol
    // );
    const font = this.getFont(parseInt(config.module.conf.font));
    if (font) {
      WebFont.load({
        google: {
          families: [font.value]
        }
      });
    }
    this.setState(
      {
        hash,
        config,
        preview,
        loading: false,
        company,
        currency: company._embedded.defaultCurrency,
        sessionValid: true,
        currentOrder: undefined
      },
      () => {
        // Check if display_style should be as grid
        if (
          config.module.conf.display_style &&
          config.module.conf.display_style === "GRID"
        ) {
          this.setState({ productsListViewMode: false });
        }
        if (
          config.module.conf.mode === "product" &&
          config.module.conf.id_product
        ) {
          route(`/${hash}/products/${config.module.conf.id_product}`, true);
        } else if (config.module.conf.mode === "catalog") {
          route(`/${hash}/products`, true);
        } else {
          throw new Error(
            "Your Widget should be configured in mode catalog or product !"
          );
        }
        let convertToJson = function(str) {
          let hash;
          let json = {};
          let hashes = str.split(";");
          for (let i = 0; i < hashes.length; i++) {
            hash = hashes[i].split("=");
            json[hash[0]] = hash[1];
          }
          return json;
        };
        if (this.options && this.options.userInfo) {
          config.module.userInfo = convertToJson(this.options.userInfo);
        }
      }
    );

    //console.log("this.state", this.state)
  };

  getFont(fontId) {
    return [
      {
        id: 1,
        value: "Open Sans"
      },
      {
        id: 2,
        value: "Indie Flower"
      },
      {
        id: 3,
        value: "Poiret One"
      },
      {
        id: 4,
        value: "Lobster"
      },
      {
        id: 5,
        value: "Rochester"
      },
      {
        id: 6,
        value: "Poppins"
      },
      {
        id: 7,
        value: "Balthazar"
      },
      {
        id: 8,
        value: "Concert One"
      },
      {
        id: 9,
        value: "Roboto Slab"
      },
      {
        id: 10,
        value: "Rokkitt"
      },
      {
        id: 11,
        value: "Sanchez"
      }
    ].find(font => font.id === fontId);
  }

  changeTranslation = country => {
    if (country === "GB" && this.state.localeString !== "en") {
      this.setState({ locale: en, localeString: "en" });
    } else if (country === "FR" && this.state.localeString !== "fr") {
      this.setState({ locale: fr, localeString: "fr" });
    } else if (country === "DE" && this.state.localeString !== "de") {
      this.setState({ locale: de, localeString: "de" });
    } else if (country === "ES" && this.state.localeString !== "es") {
      this.setState({ locale: es, localeString: "es" });
    } else if (country === "IT" && this.state.localeString !== "it") {
      this.setState({ locale: it, localeString: "it" });
    } else if (country === "NL" && this.state.localeString !== "nl") {
      this.setState({ locale: nl, localeString: "nl" });
    } else if (country === "PL" && this.state.localeString !== "ca") {
      this.setState({ locale: ca, localeString: "ca" });
    }
    sendUXEventGTM({
      event: "changeLanguage",
      "chosen-language": this.state.localeString
    });
  };

  setOrder = order => {
    //;
    getPaymentMethods(
      this.state.config.company.id,
      order.id,
      this.state.config.type.toLowerCase()
    ).then(paymentMethods => {

      this.setState({paymentMethod: paymentMethods.find(
        paymentMethod => paymentMethod.baseModule.code === "creditcard"
      )});
      route(`/order/${order.id}`);
      this.setState({ currentOrder: order });
    });
    // getOrderById(order.id)
    //   .then(order => {


    //   return order;
    // })
    // .catch(error => this.setState({ error }));
  };

  setCurrentProduct = product => {
    this.setState({ currentProduct: product });
  };

  setConfirmedOrder = () => {
    this.setState({ currentOrder: undefined });
  };

  setOrderUser = (order, userClientData) => {
    route(`/order/${order.id}`);
    this.setState({ currentOrder: order, userClientData });
  };

  updateOrder = order => {
    this.setState({ currentOrder: order });
  };

  setPaymentMethods = paymentMethods => {
    this.setState({
      paymentMethods
    });
  };

  goToConfirm = (paymentMethod, payment, hasParticipantClientForms) => {
    route(`/order/${this.state.currentOrder.id}/payment/confirmation`);
    this.setState(prevState => {
      return {
        loading: false,
        paymentMethod,
        payment,
        hasParticipantClientForms,
        currentOrder: undefined,
        previousOrder: prevState.currentOrder
      };
    });
    const order = this.state.currentOrder
      ? this.state.currentOrder
      : this.state.previousOrder;
    sendDataLayerVariableGTM({
      "user-id": order._embedded.userSaved.id
    });
    sendCheckoutEventOrderGTM(order, 8, paymentMethod);
    sendPurchaseEventGTM(order);
  };

  setPaymentMethod = paymentMethod => {
    this.setState(
      {
        loading: true
      },
      () => {
        console.log("this.state.currentOrder", this.state.currentOrder)
        this.checkSession(this.state.currentOrder);

        if(this.state.sessionValid){
          postOrderPayment(this.state.currentOrder.id, this.state.localeString, {
            currencyCode: this.state.currency.isoCode,
            paymentMethod: paymentMethod.baseModule.id,
            downPayment:
              this.state.currentOrder.status === "USER_FILLED" &&
              paymentMethod.configuration.deposit !== 100
                ? true
                : false
          })
            .then(payment => {
              //console.log(payment.payments[0].clientEmail);
              postOrderPaymentEmail(payment.id, {
                value: payment.payments[0].clientEmail,
                paymentCode: payment.payments[0].paymentMethod.code,
                downPayment:
                  paymentMethod.configuration.deposit === 100
                    ? payment.totalPrice
                    : (payment.totalPrice * paymentMethod.configuration.deposit) /
                      100,
                sendType: "mail"
              })
                .then(function(response) {
                  return response;
                })
                .catch(error => this.setState({ error }));
              this.goToConfirm(
                paymentMethod,
                payment.payments.reduce((acc, curr) =>
                  !acc || new Date(curr.creationDate) > new Date(acc.creationDate)
                    ? curr
                    : acc
                )
              );
              return payment;
            })
            .catch(error => this.setState({ error }));
        }

      }
    );
  };

  setStripePaymentMethod = (paymentMethod, payment) => {
    // Redirect if callbackStripe is set for open-api users
    if (this.state.callbackStripe) {
      //console.log(this.state.urlToShop);
      window.location.href =
        PHP_API_ENDPOINT +
        "/payment-confirmation/" +
        this.state.currentOrder.id +
        "/" +
        this.state.urlToShop;
      return;
    } else {
      this.goToConfirm(paymentMethod, payment);
    }
  };

  toggleProductsViewMode = () => {
    this.setState(prevState => ({
      productsListViewMode: !prevState.productsListViewMode
    }));
  };

  onSelectProduct = (productId: number) => {
    route(`/${this.state.hash}/products/${productId}`, true);
    this.setState({ showBackToProduct: false });
    setTimeout(() => {
      parent.postMessage(`scroll_top`, "*");
    }, 500);
  };


  checkSession = order => {

    // Utilisation de reduce pour trouver l'objet avec la plus petite creationDate
    var smallestBookingCreationDate = order._embedded.bookings.reduce((minBooking, currentBooking) => {
      var currentBookingCreationDate = new Date(currentBooking.creationDate);
      var minBookingCreationDate = new Date(minBooking.creationDate);
        // Comparaison basée sur creationDate
        return currentBookingCreationDate.getTime() < minBookingCreationDate.getTime() ? currentBooking : minBooking;
      }, order._embedded.bookings[0]); // Utilisation de bookings[0] comme valeur initiale

      let creationDate = new Date(smallestBookingCreationDate.creationDate);

      let now = new Date();
      let timeDiff = Math.abs(
        creationDate.getTime() - now.getTime()
      );
      //console.log('timeDiff', timeDiff )
      // console.log('orderRef', this.state )
      // console.log("smallestBookingCreationDate", smallestBookingCreationDate ); // Affiche 1

    if (timeDiff > 900000) {
      this.setState(prevState => {
        return {
          sessionValid : false,
          currentOrder: undefined
        };
      });
      route(`/order/expired`);
      return false
    } else{
      return true
    }
  }

  setOrderByReference = (orderReference: string) => {
    setGenerateTokenMethod(() => generateTokenByOrderReference(orderReference));
    getOrderByReference(orderReference)
      .then(orderInfos => {
        this.setState({
          orderRef: orderInfos
        });
        return Promise.all([
          orderInfos,
          getOrderById(orderInfos.id, orderInfos.locale)
        ]);
      })
      .then(([orderInfos, order]) => {
        let config = {
          company: {
            id: orderInfos.companyId
          }
        };

        this.setState({ locale: order.locale, localeString: order.locale });
        this.setState({ config, currentOrder: order, loading: false }, () => {
          if (order.balance === 0) {
            route(
              `/order/${order.id}/participantclientforms/${order.reference}`
            );
          } else {
            //console.log("a ton lordier ?", order);
            postParamsInURL({ params: window.location.search }, order.id)
              .then(
                function(response) {
                  const getContext = response.context
                    ? response.context
                    : undefined;
                  const getUrlCallbackStripe = response.urlcallback
                    ? response.urlcallback
                    : undefined;
                  const getUrlToShop = response.urlredirect
                    ? response.urlredirect
                    : undefined;

                  this.setState({
                    callbackStripe: getUrlCallbackStripe
                      ? getUrlCallbackStripe
                      : undefined,
                    urlToShop: getUrlToShop ? getUrlToShop : undefined
                  });

                  let creationDate = new Date(order.creationDate);
                  let now = new Date();
                  let timeDiff = Math.abs(
                    creationDate.getTime() - now.getTime()
                  );

                  if (timeDiff > 900000) {
                    route(`/order/expired`);
                  } else {
                    if (order.balance > 0) {
                      const userSaved = order._embedded.userSaved;

                      if (userSaved) {
                        if (order.origin === "KIOSK") {
                          this.getPayments(
                            { type: getContext ? getContext : "WIDGET" },
                            order,
                            false
                          );
                          //this.setState({ isRedirectToPayment: true });
                          route(`/order/${order.id}/client`);
                        } else {
                          if (userSaved.firstName !== "") {
                            route(`/order/${order.id}`);
                            // this.getPayments(
                            //   { type: getContext ? getContext : "WIDGET" },
                            //   order,
                            //   true
                            // );
                          }
                        }
                      } else {
                        this.getPayments(
                          { type: getContext ? getContext : "WIDGET" },
                          order,
                          false
                        );
                        //this.setState({ isRedirectToPayment: true });
                        route(`/order/${order.id}/client`);
                      }
                    } else if (order.balance == 0 || order.balance == null) {
                      this.setState({ isTermSalesAccepted: true });
                      // this.setState({ loading: true });
                      // var loading = true;
                      // return <FreeOrderConfirmation />;
                      /*  route(
                        `/order/${order.id}/participantclientforms/${order.reference}`
                      );*/
                      this.setState({ locale: "fr", localeDefinition: fr });
                      route(`/order/${order.id}/payment/confirmation`);

                      var hasParticipantClientForms = true;
                      var paymentMethod = {
                        baseModule: {
                          code: "freeorder"
                        }
                      };

                      this.setState(prevState => {
                        return {
                          loading: false,
                          paymentMethod: paymentMethod,

                          order: order,
                          currentOrder: order,
                          hasParticipantClientForms,
                          previousOrder: order,
                          locale: order.locale,
                          localeString: order.locale,
                          localeDefinition: order.locale
                        };
                      });
                    }
                  }
                  return response;
                }.bind(this)
              )
              .catch(error => this.setState({ error }));
          }
        });
        return order;
      })
      .catch(error => console.error(error));
  };

  goToClientForm = order => {
    route(`/order/${order.id}/client`);
  };

  goToCatalog = () => {
    const { hash, config } = this.state;

    if (config.module.conf.mode == "catalog") {
      route(`/${hash}/products`);
    } else {
      route(`/${hash}/products/${config.module.conf.id_product}`);
    }
    setTimeout(() => {
      parent.postMessage(`scroll_top`, "*");
    }, 500);
  };

  backToProduct = () => {
    this.setState({ productPageUpdate: true, showBackToProduct: false });
  };

  onEnterBooker = () => {
    this.setState({ productPageUpdate: false, showBackToProduct: true });
  };

  goToPayment = () => {
    const { config, currentOrder } = this.state;
    //stepGTM 7 : Finaliser ma commande APRES informations clients;
    sendCheckoutEventOrderGTM(this.state.currentOrder, 7, false);

    if (currentOrder.totalPrice === 0 || currentOrder.totalPrice === null) {
      let payment = {
        paymentMethod: 16,
        description: "Paiement par bon cadeau",
        status: "CONFIRMED",
        note: ""
      };
      return postOrderPayment(currentOrder.id, this.state.localeString, payment)
        .then(payment => {
          this.setState({
            paymentMethod: {
              baseModule: {
                code: "freeorder"
              }
            }
          });
          route(`/order/${currentOrder.id}/payment/confirmation`);
          this.setState(prevState => {
            return {
              loading: false,
              currentOrder: undefined,
              previousOrder: prevState.currentOrder
            };
          });
          return payment;
        })
        .catch(error => this.setState({ error }));
    } else {
      this.getPayments(config, currentOrder, true);
    }
  };

  getPayments(config, order, isRedirect) {
    getPaymentMethods(
      order._embedded.company.id,
      order.id,
      config.type ? config.type : "WIDGET",
      config && config.module ? false : true,
      order.locale
    )
      .then(paymentMethods => {
        const closestBookingStartTime = order._embedded.bookings.reduce(
          (acc, currentBooking) =>
            !acc || new Date(acc) > new Date(currentBooking.startTime)
              ? currentBooking.startTime
              : acc,
          undefined
        );
        if (!paymentMethods) {
          return [];
        }
        let filterPaymentMethod = paymentMethods.filter(paymentMethod => {
          if (
            paymentMethod.configuration &&
            !paymentMethod.configuration.daysBefore
          ) {
            return true;
          } else {
            let todayPlusDelay = new Date();
            let daysBefore = paymentMethod.configuration
              ? paymentMethod.configuration.daysBefore
              : 0;
            todayPlusDelay.setDate(todayPlusDelay.getDate() + daysBefore);
            return (
              paymentMethod.configuration &&
              paymentMethod.configuration.alwaysActived !== 1 &&
              paymentMethod.configuration.daysBefore &&
              todayPlusDelay < new Date(closestBookingStartTime)
            );
          }
        });
        return filterPaymentMethod.length
          ? filterPaymentMethod
          : paymentMethods;
      })
      .then(paymentMethods => {
        if (paymentMethods.length > 0) {
          if (
            !paymentMethods.find(
              paymentMethod => paymentMethod.baseModule.code === "creditcard"
            )
          ) {
            /*
              10/08/2022 vu JMM/OTO
              si pas de paiement CB alors on empêche le passage à l'étape des autres moyens de paiement
              raison : l'utilisation d'un autre moyen de paiement interroge si il y a strype
              ce n'est pas cohérent mais pour le moment on fait comme ca
            */
            this.setState({ noPayment: true });
            return "";

            //on ne revoi plus les autres methodes de paiement
            /*this.setState({
              paymentMethods
            });
            route(`/order/${order.id}/otherPaymentMethods`);*/
          } else {
            this.setState({
              paymentMethods,
              paymentMethod: paymentMethods.find(
                paymentMethod => paymentMethod.baseModule.code === "creditcard"
              )
            });
            if (isRedirect) {
              route(`/order/${order.id}/payment`);
            }
          }
          return paymentMethods;
        } else {
          this.setState({ noPayment: true });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  pageDidMountOrUpdate = () => {
    const { hash } = this.state;
    let widgetEl = document.getElementById("widget");
    if (widgetEl) {
      let height = widgetEl.clientHeight;
      const width = widgetEl.clientWidth;

      setTimeout(() => {
        // widgetEl.height n'est pas un attribut existant = undefined donc if toujours valide
        // du coup il ne sert a rien, A revoir
        if (widgetEl.height !== height) {
          if (height < 357) {
            height = 357;
          }
          parent.postMessage(
            `action=resize&height=${height}&width=${width}&id_iframe=${hash}`,
            "*"
          );
        }
      }, 500);
    }
  };

  onUnload = event => {
    // Enable redirection if callbackStripe is set for open-api users
    if (this.state.callbackStripe) {
      return;
    }

    // the method that will be used for both add and remove event
    if (this.state.currentOrder) {
      const confirmationMessage =
        "Etes-vous sur de quitter cette page ? Toutes les produits ajoutés à votre panier seront perdues...";
      event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    }
  };

  componentDidUpdate() {
    this.pageDidMountOrUpdate();
    if (this.options && this.options.style) {
      const loadCSS = new LoadCSS();
      loadCSS.load(this.options.style);
      const getWidget = document.getElementById("widget");
      getWidget.classList.add(`widget__${this.options.style.split(".")[0]}`);
    }
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
    window.addEventListener("resize", this.pageDidMountOrUpdate);
    // window.addEventListener('message', this.handleFrameTasks);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
    window.removeEventListener("resize", this.pageDidMountOrUpdate);
    // window.removeEventListener('message', this.handleFrameTasks);
  }

  removeBeforeUnloadListener = () => {
    window.removeEventListener("beforeunload", this.onUnload);
    window.removeEventListener("resize", this.pageDidMountOrUpdate);
  };

  changeLoadingState = () => {
    this.setState({ loading: false });
  };
  // handleFrameTasks = e => {
  //   console.log('handleFrameTasks', e);
  // };

  render(
    props,
    {
      hash,
      locale,
      localeString,
      currency,
      company,
      currentOrder,
      previousOrder,
      payment,
      paymentMethod,
      paymentMethods,
      loading,
      config,
      preview,
      error,
      productsListViewMode,
      hasParticipantClientForms,
      currentProduct,
      isRedirectToPayment,
      callbackStripe,
      urlToShop,
      noPayment,
      sessionValid
    }
  ) {
    return (
      <ThemeConfigProvider conf={config && config.module && config.module.conf}>
        <IntlProvider definition={locale}>
          <div id="widget" class={styles.app}>
            {this.state.customStyle}
            {this.disabledReservation}
            {error && <Error error={error} />}
            <Loading loading={loading}>Loading...</Loading>
            <div class={styles.container}>
              {config && config.module && config.module.conf && sessionValid && (
                <Match
                  path={`/${hash}/products/${currentProduct &&
                    currentProduct.id}`}
                >
                  {({ matches }) => (
                    <Header
                      hash={hash}
                      config={config}
                      order={currentOrder}
                      matchesProduct={matches}
                      changeTranslation={this.changeTranslation}
                      showBackToProduct={this.state.showBackToProduct}
                      backToProduct={this.backToProduct}
                      goToCatalog={this.goToCatalog}
                    />
                  )}
                </Match>
              )}
              <Router>
                <Redirect
                  path="/:hash/:preview?"
                  initConfig={this.initConfig}
                />
                <Products
                  path="/:hash/products"
                  config={config}
                  company={company}
                  preview={preview}
                  currency={currency}
                  locale={localeString}
                  productsListViewMode={productsListViewMode}
                  toggleProductsViewMode={this.toggleProductsViewMode}
                  onSelectProduct={this.onSelectProduct}
                  pageDidMountOrUpdate={this.pageDidMountOrUpdate}
                  disabledReservation={this.disabledReservation}
                  options={this.options}
                />
                <Product
                  path="/:hash/products/:productId"
                  config={config}
                  preview={preview}
                  currency={currency}
                  locale={localeString}
                  currentOrder={currentOrder}
                  setCurrentProduct={this.setCurrentProduct}
                  setOrder={this.setOrder}
                  goToCatalog={
                    config &&
                    config.module &&
                    config.module.conf &&
                    this.goToCatalog
                  }
                  pageDidMountOrUpdate={this.pageDidMountOrUpdate}
                  productPageUpdate={this.state.productPageUpdate}
                  onEnterBooker={this.onEnterBooker}
                />

                <OrderSummary
                  path="/order/:orderId"
                  config={config}
                  order={currentOrder}
                  urlToShop={urlToShop}
                  locale={localeString}
                  pageDidMountOrUpdate={this.pageDidMountOrUpdate}
                  checkSession={this.checkSession}
                >
                  <Basket
                    config={config}
                    token={getToken()}
                    currency={currency}
                    locale={localeString}
                    order={currentOrder}
                    setOrder={this.updateOrder}
                    goToClientForm={this.goToClientForm}
                    goToPayment={this.goToPayment}
                    goToCatalog={
                      config &&
                      config.module &&
                      config.module.conf &&
                      this.goToCatalog
                    }
                    noPayment={noPayment}
                    pageDidMountOrUpdate={this.pageDidMountOrUpdate}

                    paymentMethod={paymentMethod}
                    checkSession={this.checkSession}
                  />
                </OrderSummary>
                <OrderSummary
                  path="/order/:orderId/client"
                  config={config}
                  order={currentOrder}
                  urlToShop={urlToShop}
                  locale={localeString}
                  checkSession={this.checkSession}
                >
                  <ClientForm
                    config={config}
                    token={getToken()}
                    currency={currency}
                    locale={localeString}
                    order={currentOrder}
                    setOrderUser={this.setOrderUser}
                    pageDidMountOrUpdate={this.pageDidMountOrUpdate}
                    redirectToPayment={isRedirectToPayment}
                  />
                </OrderSummary>
                <OrderExpired
                  path="/order/expired"
                  changeLoadingState={this.changeLoadingState}
                  config={config}
                />
                <Checkout
                  path="/checkout/:orderReference?"
                  setOrderByReference={this.setOrderByReference}
                />
                <OrderSummary
                  path="/order/:orderId/payment"
                  config={config}
                  order={currentOrder}
                  urlToShop={urlToShop}
                  locale={localeString}
                  checkSession={this.checkSession}
                >
                  <StripePayment
                    hash={hash}
                    config={config}
                    currency={currency}
                    locale={localeString}
                    order={currentOrder}
                    paymentMethod={paymentMethod}
                    paymentMethods={paymentMethods}
                    setPaymentMethods={this.setPaymentMethods}
                    setOrder={this.updateOrder}
                    setPaymentMethod={this.setStripePaymentMethod}
                    removeBeforeUnloadListener={this.removeBeforeUnloadListener}
                    pageDidMountOrUpdate={this.pageDidMountOrUpdate}
                    orderRef={this.state.orderRef}
                    callbackStripe={callbackStripe}
                    urlToShop={urlToShop}
                    checkSession={this.checkSession}
                  />
                </OrderSummary>
                <Stripe3DSecureConfirmation path="/order/:orderReference/payment/3D-secure-confirmation" />
                <OrderSummary
                  path="/order/:orderId/otherPaymentMethods"
                  config={config}
                  order={currentOrder}
                  urlToShop={urlToShop}
                  locale={localeString}
                >
                  <OtherPaymentMethods
                    config={config}
                    currency={currency}
                    locale={localeString}
                    paymentMethods={paymentMethods}
                    currentOrder={currentOrder}
                    setPaymentMethod={this.setPaymentMethod}
                  />
                </OrderSummary>
                <OrderSummary
                  path="/order/:orderId/payment/confirmation"
                  config={config}
                  order={currentOrder || previousOrder}
                  urlToShop={urlToShop}
                  locale={localeString}
                >
                  <PaymentConfirmation
                    hash={hash}
                    config={config}
                    locale={localeString}
                    currency={currency}
                    order={previousOrder}
                    payment={payment}
                    paymentMethod={paymentMethod}
                    hasParticipantClientForms={hasParticipantClientForms}
                  />
                </OrderSummary>
                <ParticipantClientForms
                  hash={hash}
                  config={config}
                  path="/order/:orderId/participantclientforms/:orderRef"
                  localeString={localeString}
                  changeLoadingState={this.changeLoadingState}
                />
                <ParticipantFormCreated
                  path="/participantclientforms/created"
                  changeLoadingState={this.changeLoadingState}
                  hash={hash}
                />
              </Router>
            </div>
          </div>
        </IntlProvider>
      </ThemeConfigProvider>
    );
  }
}

export default App;
