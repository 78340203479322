// @flow
// @jsx h

import { h, Component } from "preact";

import Loading from "elc-loading";
import Container from "../container";
import BeneficiaryForm from "./BeneficiaryForm";

import { getDefaultBeneficiaryForm } from "elc-open-api";

type FetchBeneficiaryFormProps = {
  config?: WidgetConfig,
  theme?: Object,
  order: Order,
  setOrderUser: Function,
  locale?: string
};

type FetchBeneficiaryFormState = {
  loading: boolean,
  clientFormFields?: Array<ClientFormField>
};

/**
 * Fetch beneficiary form component
 */
export default class FetchBeneficiaryForm extends Component<
  FetchBeneficiaryFormProps,
  FetchBeneficiaryFormState
> {
  /**
   * @type {object}
   * @property {boolean} loading fetch the beneficiary form fields
   * @property {Object} clientFormFields the beneficiary form fields
   */
  state = {
    loading: true,
    clientFormFields: undefined
  };

  componentDidMount() {
    getDefaultBeneficiaryForm(this.props.locale)
      .then(defaultBeneficiaryForm => {
        this.setState({
          loading: false,
          clientFormFields: defaultBeneficiaryForm._embedded.clientFormFields
        });
        return defaultBeneficiaryForm;
      })
      .catch(error => {
        console.error(error);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageDidMountOrUpdate) {
      this.props.pageDidMountOrUpdate();
    }

    if (prevProps.locale !== this.props.locale) {
      getDefaultBeneficiaryForm(this.props.locale)
        .then(defaultBeneficiaryForm => {
          this.setState({
            loading: false,
            clientFormFields: defaultBeneficiaryForm._embedded.clientFormFields
          });
          return defaultBeneficiaryForm;
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  /**
   * render
   * @param {ClientDetailsProps} the ClientDetails component props
   * @param {ClientDetailsState} the ClientDetails component state
   * @return {ReactElement} markup
   */
  render(
    {
      config,
      theme,
      locale,
      currentOrder,
      currentCompany,
      currentProduct,
      setOrder,
      currentGift
    }: {
      config?: WidgetConfig,
      order: Order,
      currency: Currency,
      backEndError: Error,
      theme?: object,
      locale?: string,
      setOrder: Function,
      currentGift: Object
    },
    {
      clientFormFields,
      loading
    }: {
      clientFormFields: ClientFormFields,
      loading: boolean
    }
  ) {
    return (
      <Container>
        {loading ? (
          <Loading />
        ) : (
          <BeneficiaryForm
            theme={theme}
            config={config}
            locale={locale}
            currentOrder={currentOrder}
            currentCompany={currentCompany}
            currentProduct={currentProduct}
            clientFormFields={clientFormFields}
            setOrder={setOrder}
            currentGift={currentGift}
          />
        )}
      </Container>
    );
  }
}
