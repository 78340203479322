// @flow
// @jsx h

// External librairies
import { h } from "preact";

// Style
import styles from "./style.scss";
import { withStyles } from "elc-theme";

/**
 * Booker container Component
 * used to display the booker views in common styled container
 */
function BookerContainer({ children, getClassName }) {
  return <div class={getClassName("bookerContainer")}>{children}</div>;
}

export default withStyles(BookerContainer, styles);
