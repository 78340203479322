// @jsx h
import { h, Component } from 'preact';
import classNames from 'classnames/bind';

export default function withStyles(WrappedComponent, styles) {
  // eslint-disable-next-line react/display-name
  return class extends Component {
    constructor(props, context) {
      super(props);
      const mergedStyles = mergeTheming(styles, context.theme);
      this.state = {
        styles: mergedStyles,
        getClassName: classNames.bind(mergedStyles)
      };
    }
    render() {
      // Wraps the input component in a container, without mutating it. Good!
      return (
        <WrappedComponent
          styles={this.state.styles}
          getClassName={this.state.getClassName}
          {...this.props}
        />
      );
    }
  };
}

function mergeTheming(styles, theme = {}) {
  for (let nextKey in theme) {
    // Avoid bugs when hasOwnProperty is shadowed
    if (
      Object.prototype.hasOwnProperty.call(theme, nextKey) &&
      styles[nextKey]
    ) {
      styles[nextKey] = theme[nextKey];
    }
  }
  return styles;
}
