// @flow
// @jsx h

import { h, Component } from "preact";
import { Text } from "preact-i18n";

import styles from "./style.scss";

import { BackButton, PrimaryButton } from "elc-button";
import { PlaceIcon, ArrowLeftIcon } from "elc-icons";
import ProductImages from "./productimages";
import Tabs from "./tabs";
import { getProductTitleStyle } from "elc-theme";
import { toLocalePrice, getPriceWithTax } from "elc-commons";

type ProductDescriptionProps = {
  config: WidgetConfig,
  product: Product,
  currency: Currency,
  goToCatalog: Function,
  singlePanelMode: boolean
};

type ProductDescriptionState = {
  address: Address
};

/**
 * Product description component
 * displaying the product :
 *  - name
 *  - location
 *  - image(s)
 *  - number of particpants / duration / starting price
 *  - description
 *  - map and address
 *  - contact link
 */
class ProductDescription extends Component<
  ProductDescriptionProps,
  ProductDescriptionState
> {
  constructor(props: ProductDescriptionProps) {
    super(props);
    const address = props.product._embedded.address
      ? props.product._embedded.address
      : props.config.company.address;
    this.setState({ address });
  }

  render(
    {
      config,
      product,
      goToCatalog,
      pageDidMountOrUpdate,
      showBooker,
      showGiftVoucherCode,
      showBookerClick,
      currency
    }: ProductComponentProps,
    { address }: ProductComponentState
  ) {
    let isBook = "book";

    if (product.type === "GIFT") {
      isBook = "gift";
    }

    return (
      <div class={styles.description}>
        <header class={styles.productHeader}>
          <h3
            class={styles.productTitle}
            style={getProductTitleStyle(this.context.themeConfig)}
          >
            {product.name}
          </h3>
          {config.company.type === "RESELLER" && (
            <h4>{product._embedded.merchant.name}</h4>
          )}
          {config.module.conf.display_map === 1 && (
            <a
              class={`${styles.location}`}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${
                address.latitude
              },${address.longitude}`}
            >
              <PlaceIcon color={styles.darkGrey} />
              <span>{address.city}</span>
            </a>
          )}
        </header>
        <ProductImages
          images={product._embedded.images}
          pageDidMountOrUpdate={pageDidMountOrUpdate}
        />
        {!showBooker &&
          !showGiftVoucherCode && (
            <div class={styles.openBookerStickyContainer}>
              <div class={styles.openBookerButtonContainer}>
                <PrimaryButton
                  className={styles.bookMeButton}
                  handleClick={() => showBookerClick(isBook)}
                >
                  {product.type === "GIFT" ? (
                    <Text id="product.description.offer">Offrir</Text>
                  ) : (
                    <Text id="product.description.bookMe">Réserver</Text>
                  )}
                  <span />
                  <span class={styles.price}>
                    <Text id="bookerIntro.startingPrice">A partir de </Text>
                    {toLocalePrice(
                      product.type === "GIFT"
                        ? getPriceWithTax(
                            product._embedded.prices[0].value,
                            product._embedded.tax
                          )
                        : product.displayedStartingPrice,
                      this.props.locale,
                      currency.isoCode
                    )}
                  </span>
                </PrimaryButton>
              </div>
            </div>
          )}
        <div class={styles.column}>
          <Tabs config={config} product={product} address={address} />
          {!this.props.singlePanelMode &&
            config.module.conf.mode === "catalog" &&
            goToCatalog && (
              <BackButton
                handleClick={goToCatalog}
                className={styles.previousButton}
              >
                <ArrowLeftIcon
                  color={
                    this.context.themeConfig && this.context.themeConfig.color1
                      ? this.context.themeConfig.color1
                      : styles.green
                  }
                />
                <Text id="product.backToCatalog">Back to catalog</Text>
              </BackButton>
            )}
        </div>
      </div>
    );
  }
}

export default ProductDescription;
