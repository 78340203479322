// @flow
// @jsx h
import { h } from "preact";
import styles from "./style.scss";

/**
 * Time slot button stateless functional Component
 */
export default function TimeSlot({ theme, slot, selected, onSlotClick }) {
  /**
   * Handle click on slot
   */

  const onClick = () => {
    onSlotClick(slot);
  };
  return (
    <button
      disabled={!slot.bookable}
      class={`${theme && theme.slot ? theme.slot : styles.slot} ${
        selected
          ? theme && theme.selected
            ? theme.selected
            : styles.selected
          : ""
      } ${slot.inProgress ? styles.inProgress : ""}`}
      onClick={onClick}
    >
      {new Date(slot.startDateTime).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "Europe/Paris"
      })}
    </button>
  );
}
