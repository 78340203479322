// @flow
// @jsx h
import { h } from "preact";
import { Text } from "preact-i18n";
import { toLocalePrice } from "elc-commons";
import styles from "./style.scss";

type Price = {
  type: string,
  value: number
};

export default function PriceRange({
  theme,
  tax,
  prices,
  locale,
  currency
}: {
  theme: Object,
  tax: Tax,
  prices: Array<Price>,
  locale: string,
  currency: Currency
}) {
  const getMinPrice = prices => {
    return prices
      .filter(price => price.type !== "DEGRESSIVE")
      .map(price => price.value)
      .reduce((prev, curr) => {
        return curr < prev ? curr : prev;
      });
  };

  const getMaxPrice = prices => {
    return prices
      .filter(price => price.type !== "DEGRESSIVE")
      .map(price => price.value)
      .reduce((prev, curr) => {
        return curr > prev ? curr : prev;
      });
  };

  const getPrices = prices => {
    return prices.filter(price => price.type !== "DEGRESSIVE");
  };

  const getPriceWithTax = price => {
    return tax && tax.active
      ? Math.round((price + price * (tax.rate / 100)) * Math.pow(10, 2)) /
          Math.pow(10, 2)
      : price;
  };

  return (
    <div
      class={
        theme && theme.priceCaption ? theme.priceCaption : styles.priceCaption
      }
    >
      {getPrices(prices) &&
        getPrices(prices).length > 1 &&
        getMinPrice(prices) !== getMaxPrice(prices) && (
          <Text
            id="quantities.priceFrom"
            fields={{
              minPrice: toLocalePrice(
                getPriceWithTax(getMinPrice(prices)),
                locale,
                currency.isoCode
              )
            }}
          >
            From{" "}
            {toLocalePrice(
              getPriceWithTax(getMinPrice(prices)),
              locale,
              currency.isoCode
            )}
          </Text>
        )}
      {getPrices(prices) &&
        (getPrices(prices).length === 1 ||
          (getPrices(prices).length > 1 &&
            getMinPrice(prices) === getMaxPrice(prices))) && (
          <span>
            {toLocalePrice(
              getPriceWithTax(prices[0].value),
              locale,
              currency.isoCode
            )}
          </span>
        )}
    </div>
  );
}
