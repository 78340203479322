// @flow
// @jsx h

// External librairies
import { h } from "preact";
import { Text } from "preact-i18n";

import styles from "./BookerHowTo.scss";

import { TicketIcon, CreditcardsIcon, HandIcon } from "elc-icons";

type BookerHowToProps = {
  showBooker: boolean
};
/**
 * BookerHowTo presentational functional component
 */
const BookerHowTo = ({ showBooker }: BookerHowToProps) => (
  <div class={`${styles.howTo} ${showBooker ? styles.bookerShown : ""}`}>
    <h4 class={styles.howToTitle}>
      <Text id="product.howto.title">How does it work ?</Text>
    </h4>
    <div class={styles.howToStep}>
      <div>
        <HandIcon color="#fff" />
      </div>
      <div>
        <span class={styles.howToNumber}>01</span>
        <span class={styles.howToText}>
          <Text id="product.howto.step1">Select and book your experience.</Text>
        </span>
      </div>
    </div>
    <div class={styles.howToStep}>
      <div>
        <CreditcardsIcon color="#fff" />
      </div>
      <div>
        <span class={styles.howToNumber}>02</span>
        <span class={styles.howToText}>
          <Text id="product.howto.step2">Pay securely on aur website.</Text>
        </span>
      </div>
    </div>
    <div class={styles.howToStep}>
      <div>
        <TicketIcon color="#fff" />
      </div>
      <div>
        <span class={styles.howToNumber}>03</span>
        <span class={styles.howToText}>
          <Text id="product.howto.step3">
            Immediatly receive your e-tocket by email and enjoy your activity.
          </Text>
        </span>
      </div>
    </div>
  </div>
);

export default BookerHowTo;
