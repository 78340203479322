// @flow
// @jsx h
import { h } from 'preact';

const FirstPage = ({ color }: { color?: string }) => (
  <svg
    fill={color ? color : '#000000'}
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
    <path d="M24 24H0V0h24v24z" fill="none" />
  </svg>
);
export default FirstPage;
