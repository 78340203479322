/**
 * Generic function that throw an error if the API endpoint return an error
 * otherwise send back the API response
 * @param {Response} response - the API fetch Response object
 * @return the response if it's valid
 * @throws {Error} an error containing the response status returned by the API
 */
export function handleResponse(response) {
  let contentType = response.headers.get("content-type");

  if (
    contentType.includes("application/json") ||
    contentType.includes("application/problem+json")
  ) {
    return handleJSONResponse(response);
  } else if (contentType.includes("text/html")) {
    return handleTextResponse(response);
  } else {
    // Other response types as necessary. I haven't found a need for them yet though.
    throw new Error(`Sorry, content-type ${contentType} not supported`);
  }
}

function handleJSONResponse(response) {
  return response.json().then(json => {
    if (response.ok) {
      return json;
    } else {
      return Promise.reject(
        Object.assign({}, json, {
          status: response.status,
          statusText: response.statusText
        })
      );
    }
  });
}

function handleTextResponse(response) {
  return response.text().then(text => {
    if (response.ok) {
      return text;
    } else {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        err: text
      });
    }
  });
}
