// @flow
// @jsx h

import { h, Component } from "preact";

import { IntlProvider } from "preact-i18n";
import ca from "../translations/ca.json";
import de from "../translations/de.json";
import en from "../translations/en.json";
import es from "../translations/es.json";
import fr from "../translations/fr.json";
import it from "../translations/it.json";
import nl from "../translations/nl.json";

import Product from "./product";

type ProductComponentProps = {
  hash: string,
  config: WidgetConfig,
  locale?: string,
  currency: Currency,
  product: Product,
  setOrder: Function,
  goToCatalog?: Function,
  order?: Order
};

type ProductComponentState = {
  localeDefinition: Object
};

/**
 * Product Component displaying :
 *   - the products details:
 *     - image(s)
 *     - name
 *     - location link
 *     - number of participants / duration / starting price
 *     - description / summary
 *     - map displaying location
 *     - contact link
 *   - the product booker
 */
/* FIXME when Preact implement getDerivedStateFromProps */
/* eslint-disable-next-line react/no-deprecated */
export default class App extends Component<
  ProductComponentProps,
  ProductComponentState
> {
  state = { localeDefinition: fr };

  constructor(props: ProductComponentProps) {
    super(props);
    if (props.locale) {
      switch (props.locale) {
        case "ca":
          this.setState({ localeDefinition: ca });
          break;

        case "de":
          this.setState({ localeDefinition: de });
          break;

        case "en":
          this.setState({ localeDefinition: en });
          break;

        case "es":
          this.setState({ localeDefinition: es });
          break;

        case "it":
          this.setState({ localeDefinition: it });
          break;

        case "nl":
          this.setState({ localeDefinition: nl });
          break;
      }
    }
  }

  componentWillReceiveProps(nextProps: ProductComponentProps) {
    if (nextProps.locale !== this.props.locale) {
      switch (nextProps.locale) {
        case "ca":
          this.setState({ localeDefinition: ca });
          break;

        case "de":
          this.setState({ localeDefinition: de });
          break;

        case "en":
          this.setState({ localeDefinition: en });
          break;

        case "es":
          this.setState({ localeDefinition: es });
          break;

        case "fr":
          this.setState({ localeDefinition: fr });
          break;

        case "it":
          this.setState({ localeDefinition: it });
          break;

        case "nl":
          this.setState({ localeDefinition: nl });
          break;
      }
    }
  }

  componentDidMount() {
    this.props.pageDidMountOrUpdate();
  }

  componentDidUpdate() {
    this.props.pageDidMountOrUpdate();
  }

  getColorConfig = (conf: Object) =>
    conf && conf.color1
      ? {
          color1: conf.color1,
          color2: conf.color2,
          color3: conf.color3,
          color4: conf.color4,
          color5: conf.color5
        }
      : undefined;

  render(
    {
      hash,
      locale,
      config,
      preview,
      product,
      order,
      currency,
      setOrder,
      goToCatalog,
      pageDidMountOrUpdate
    }: ProductComponentProps,
    { localeDefinition }: ProductComponentState
  ) {
    return (
      <IntlProvider definition={localeDefinition}>
        <Product
          hash={hash}
          config={config}
          preview={preview}
          locale={locale}
          currency={currency}
          currentOrder={order}
          product={product}
          setOrder={setOrder}
          goToCatalog={goToCatalog}
          pageDidMountOrUpdate={pageDidMountOrUpdate}
          productPageUpdate={this.props.productPageUpdate}
          onEnterBooker={this.props.onEnterBooker}
        />
      </IntlProvider>
    );
  }
}
