// @flow
// @jsx h
import { h } from "preact";

const Pdf = ({ color }: { color?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M30,4l11,11c0,0 0,20.256 0,28.02c0,1.646 -1.334,2.98 -2.98,2.98c-6.076,0 -19.84,0 -25.973,0c-1.683,0 -3.047,-1.364 -3.047,-3.047c0,-7.765 0,-28.225 0,-35.946c0,-1.661 1.346,-3.007 3.007,-3.007l17.993,0Zm8,13l-10,-10l0,10l10,0Z" />
    <path
      d="M24.527,20.921c0.367,0.03 0.837,0.306 0.837,0.306c0.275,0.136 0.286,0.164 0.39,0.27c0.567,0.579 0.295,1.563 0.182,2.502c-0.206,1.602 -0.582,3.18 -1.061,4.722l-0.008,0.023c0.845,1.683 2.021,3.181 3.27,4.601c0,0 3.978,-0.038 5.935,1.048c1.163,0.645 2.147,2.073 1.482,3.373c-0.806,1.579 -3.146,1.203 -5.02,-0.186c-1.002,-0.743 -1.887,-1.628 -2.741,-2.534l-0.108,-0.116c-2.253,0.25 -4.479,0.802 -6.628,1.558c-1.586,2.389 -3.628,5.369 -5.836,5.076c-0.101,-0.013 -0.199,-0.038 -0.292,-0.077c-1.322,-0.558 -0.508,-2.849 1.197,-4.111c1.158,-0.859 2.487,-1.46 3.843,-1.981l0.17,-0.063c1.28,-2.004 2.393,-4.124 3.183,-6.375c0,0 -0.386,-1.208 -0.605,-2.039c-0.099,-0.374 -0.187,-0.752 -0.256,-1.133c-0.096,-0.527 -0.153,-1.062 -0.155,-1.598c-0.005,-1.408 0.333,-3.036 1.841,-3.234c0.085,-0.011 0.085,-0.043 0.38,-0.032Zm-5.989,16.617c-1.296,0.665 -2.748,1.567 -2.893,2.596c0,0 1.663,-1.004 2.82,-2.442c0,0 0.289,-0.262 0.073,-0.154Zm11.077,-2.623c1.318,1.303 2.907,2.995 4.32,2.48c0.377,-0.137 0.523,-0.747 0.171,-1.195c-0.953,-1.21 -2.844,-1.316 -4.564,-1.358c0.025,0.025 0.049,0.049 0.073,0.073Zm-5.406,-4.335c-0.53,1.295 -1.152,2.548 -1.838,3.765l-0.171,0.298c0.217,-0.065 0.435,-0.127 0.654,-0.188c1.132,-0.306 2.281,-0.543 3.436,-0.74l0.277,-0.044c-0.851,-0.996 -1.639,-2.052 -2.325,-3.173c-0.011,0.028 -0.022,0.055 -0.033,0.082Zm0.176,-8.246c-1.118,0.159 -0.81,2.256 -0.349,4.095l0.012,0.045c0.318,-1.308 0.581,-2.647 0.638,-3.993c0,0 -0.102,-0.182 -0.301,-0.147Z"
      style="fill:#fff;fill-rule:nonzero;"
    />
  </svg>
);
export default Pdf;
