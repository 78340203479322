// @flow
// @jsx h

// External librairies
import { h } from "preact";
import { Text } from "preact-i18n";
import { toLocalePrice } from "elc-commons";

// Style
import styles from "./style.scss";

/**
 * StartingPrice component
 * displaying starting price
 */
const StartingPrice = ({
  price,
  locale,
  currency,
  theme,
  productType
}: {
  price: number,
  locale: string,
  currency: Currency,
  theme: Object,
  productType: string
}) => (
  <div
    class={
      theme && theme.startingPrice ? theme.startingPrice : styles.startingPrice
    }
  >
    {productType !== "GIFT" && (
      <h3 class={theme && theme.priceFrom ? theme.priceFrom : styles.priceFrom}>
        <span>
          <Text id="footer.priceFrom">A partir de</Text>
        </span>
      </h3>
    )}
    <div>
      <span class={styles.price}>
        {toLocalePrice(price, locale, currency.isoCode)}
      </span>
      <span>
        <Text id="footer.priceTTC">TTC</Text>
      </span>
    </div>
  </div>
);
export default StartingPrice;
