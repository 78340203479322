// @flow
// @jsx h

//External librairies
import { h, Component } from "preact";
import { toLocalePrice } from "elc-commons";

import styles from "./style.scss";
import { withStyles } from "elc-theme";

type ExtraProps = {
  locale: string,
  extra: Extra,
  quantities: Quantities,
  handleAddUnit: Function,
  handleSubUnit: Function
};

/**
 * Extra Component
 * allowong to add or substract quantity
 */

class Extra extends Component<ExtrasProps> {
  getExtraImage(extra) {
    if (
      extra._embedded &&
      extra._embedded.images &&
      extra._embedded.images.length > 0
    ) {
      const image = extra._embedded.images[0];
      return image._embedded.files.find(file => file.name === "200x200")
        ? image._embedded.files.find(file => file.name === "200x200").path
        : image._embedded.files.find(file => file.name === "400x400")
          ? image._embedded.files.find(file => file.name === "400x400").path
          : image._embedded.files.find(file => file.name === "ORIG")
            ? image._embedded.files.find(file => file.name === "ORIG").path
            : undefined;
    }
  }

  render({
    theme,
    locale,
    extra,
    quantities,
    handleAddUnit,
    handleSubUnit,
    currency,
    getClassName
  }: ExtraProps) {
    const getPriceWithTax = price => {
      return extra._embedded.tax && extra._embedded.tax.active
        ? Math.round(
            (price + price * (extra._embedded.tax.rate / 100)) * Math.pow(10, 2)
          ) / Math.pow(10, 2)
        : price;
    };

    return (
      <div>
        {this.context.widgetContext === "KIOSK" && (
          <img
            class={getClassName("extraImage")}
            src={this.getExtraImage(extra)}
            alt={extra.name}
          />
        )}
        {/* {this.context.widgetContext === 'KIOSK' && <ExtraImage extra={extra} />} */}
        {/* <div class={styles.container}> */}
        <div
          class={
            theme && theme.extraContainer
              ? theme.extraContainer
              : styles.extraContainer
          }
        >
          <div class={theme && theme.caption ? theme.caption : styles.caption}>
            <div>
              <div
                data-custom
                data-extra-image-url={this.getExtraImage(extra)}
                data-title={extra.name}
                data-summary={extra.summary}
                class={styles.title}
              >
                <span class={styles.questionButton}>
                  <span>?</span>
                </span>
                {extra.name}
              </div>
              {/* <div class={styles.subtext}>
                <button
                  data-custom
                  data-cat-id="10913"
                  data-title={extra.name}
                  data-summary={extra.summary}
                  class={getClassName('tooltipButton')}
                  style={`${
                    this.context.themeConfig && this.context.themeConfig.color1
                      ? 'color:' + this.context.themeConfig.color1
                      : ''
                  }`}
                >
                  see more
                </button>
              </div> */}
            </div>
            <div>
              {toLocalePrice(
                getPriceWithTax(extra._embedded.prices[0].value),
                locale,
                currency.isoCode
              )}
            </div>
          </div>
          <div class={styles.quantities}>
            <div
              class={`${
                theme && theme.quantity ? theme.quantity : styles.quantity
              } ${
                quantities.get(extra.id) && quantities.get(extra.id) > 0
                  ? theme && theme.notZero
                    ? theme.notZero
                    : styles.notZero
                  : ""
              }`}
              style={`${
                this.context.themeConfig && this.context.themeConfig.color1
                  ? quantities.get(extra.id) && quantities.get(extra.id) > 0
                    ? "background-color:" + this.context.themeConfig.color1
                    : "color:" + this.context.themeConfig.color1
                  : ""
              }`}
            >
              {quantities.get(extra.id) || 0}
            </div>
            <div class={styles.quantityControls}>
              <button
                // disabled={maxReached || (max && quantity === max)}
                onClick={() => handleAddUnit(extra)}
                class={`${
                  theme && theme.quantityButton
                    ? theme.quantityButton
                    : styles.quantityButton
                } ${theme && theme.plus ? theme.plus : styles.plus}`}
              >
                <span
                  style={`${
                    this.context.themeConfig && this.context.themeConfig.color1
                      ? "color:" + this.context.themeConfig.color1
                      : ""
                  }`}
                >
                  +
                </span>
              </button>
              <button
                disabled={
                  !quantities.get(extra.id) || quantities.get(extra.id) === 0
                }
                onClick={() => handleSubUnit(extra)}
                class={`${
                  theme && theme.quantityButton
                    ? theme.quantityButton
                    : styles.quantityButton
                } ${theme && theme.minus ? theme.minus : styles.minus}`}
              >
                <span
                  style={`${
                    this.context.themeConfig && this.context.themeConfig.color1
                      ? !quantities.get(extra.id) ||
                        quantities.get(extra.id) === 0
                        ? ""
                        : "color:" + this.context.themeConfig.color1
                      : ""
                  }`}
                >
                  -
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(Extra, styles);
