// @flow

declare var API_ENDPOINT: string;
declare var PHP_API_ENDPOINT: string;

import { handleResponse } from "../errors";
import { withToken } from "../auth";
import { createOrder } from "../orders";
import { getProductById } from "../products";

/**
 * Get booking for a given booking id
 * @param {number} bookingId - the booking ID
 * @return {Promise} the promise of booking
 */
export const getBookingById = withToken(
  (accessToken: string, bookingId: number, locale: ?string): Promise<Booking> =>
    fetch(
      `${API_ENDPOINT}/bookings/${bookingId}?access_token=${accessToken}&embed=product,promo_codes,time_ranges,associated_bookings`,
      {
        headers: {
          "Accept-Language": locale
        }
      }
    )
      .then(handleResponse)
      .then(booking => {
        return Promise.all([
          booking,
          getProductById(booking._embedded.product.id, locale)
        ]);
      })
      .then(([booking, product]) => {
        booking._embedded.product = product;
        return booking;
      })
);

/**
 * Create a booking
 * @param {Object} hash - the hash associated to the module
 * @param {Object} product - the product on which you want to create a booking
 * @param {Object} quantities - the quantities you want to book
 * @param {Date} [day] - the date on which you want to book
 * @param {Object} [slot] - the time on which you want to book
 * @param {Object} [extras] - the extras products you want to book
 * @param {Object} [order] - the order on which to add the booking
 * @param {Object} [company] - the company on which to create the order
 * @param {string} [locale] - the locale (language) the order was booked with
 */
export function createBooking(
  hash,
  origin,
  product,
  quantities,
  day,
  slot,
  extras,
  order,
  company,
  locale
): Promise<Order> {
  // return getOrderFB();
  // create booking from product, quantities, day and slot
  let booking = {
    product: {
      id: product.id,
      type: product.type === "FRANCE_BILLET" ? "CLASSIC" : product.type
    },
    startTime: slot
      ? slot.startDateTime
      : day
      ? day.toISOString()
      : new Date().toISOString()
  };
  if (
    product._embedded.unit &&
    (product._embedded.unit.type === "MAIN" ||
      product._embedded.unit.type === "FORFAIT")
  ) {
    booking.unitQuantityDeclarations = Array.from(quantities.quantities).map(
      ([key, value]) => ({
        unit: { id: key, type: "TARGET" },
        quantity: value
      })
    );
  } else if (
    product._embedded.unit &&
    product._embedded.unit.type === "SESSION"
  ) {
    booking.unitQuantityDeclarations = quantities.quantities.map(quantity => ({
      unit: { id: quantity.id, type: "SESSION" },
      quantity: quantity.quantity,
      numberOfSessions: quantity.numberOfSessions
    }));
  } else if (product.type === "FRANCE_BILLET") {
    booking.unitQuantityDeclarations = Array.from(quantities.quantities).map(
      ([key, value]) => ({
        unit: {
          publicMetadata: {
            customerClassId: key
          },
          type: "VIRTUAL"
        },
        quantity: value
      })
    );
  }
  if (quantities.isPrivatized) {
    booking.privatized = quantities.isPrivatized;
  }
  if (extras) {
    extras = Array.from(extras.quantities).map(([key, value]) => ({
      product: {
        id: key,
        type: "EXTRA"
      },
      startTime: slot ? slot.startDateTime : day,
      quantity: value
    }));
  }
  // if (!orderId && companyId && companyType) {
  if (!order && company) {
    return createOrder(company.id, company.type, hash, origin, locale)
      .then(order =>
        Promise.all([
          order,
          ...postBookings(
            order.id,
            booking,
            extras,
            company.type,
            locale,
            origin
          )
        ])
      )
      .then(([order, bookings]) => {
        let previousOrder = { ...order };
        order = bookings[bookings.length - 1]._embedded.order;
        order._embedded = previousOrder._embedded;
        order._embedded.bookings = bookings;
        return order;
      });
  } else if (order) {
    return postBookings(
      order.id,
      booking,
      extras,
      company.type,
      locale,
      origin
    ).then(bookings => {
      let previousBookings = order._embedded.bookings;
      if (!previousBookings) previousBookings = [];
      let previousOrder = { ...order };
      order = bookings[bookings.length - 1]._embedded.order;
      order._embedded = previousOrder._embedded;
      order._embedded.bookings = previousBookings.concat(bookings);
      return order;
    });
  } else {
    throw new Error("Provide a company (id and type) or an order (id).");
  }
}

/**
 * Create a gift booking
 * @param {hash} hash - the hash of the associated module
 * @param {Object} product - the product on which you want to create a booking
 * @param {Object} quantities - the quantities you want to book
 * @param {Date} day - the date on which you want to book
 * @param {Object} [slot] - the time on which you want to book
 * @param {Object} [extras] - the extras products you want to book
 * @param {Object} [order] - the order on which to add the booking
 * @param {Object} [company] - the company on which to create the order
 */
export function createGiftBooking(
  hash,
  origin,
  product,
  quantity,
  beneficiary,
  order,
  company,
  conf,
  beneficiaryMessage,
  sendBeneficiaryEmail,
  currentGift,
  locale
) {
  // ici ok
  let booking = {
    startTime: new Date().toISOString().split(".")[0] + "Z",
    product: {
      id: product._embedded.giftProducts ? currentGift.id : product.id,
      type: product._embedded.giftProducts
        ? product._embedded.giftProducts[0].type
        : product.type
    },
    quantity,
    beneficiary,
    beneficiaryMessage,
    sendBeneficiaryEmail
  };
  if (!order && company) {
    //console.log("locale : " + locale);
    return createOrder(company.id, company.type, hash, origin, locale)
      .then(order =>
        Promise.all([
          order,
          ...postBookings(order.id, booking, null, null, locale)
        ])
      )
      .then(([order, bookings]) => {
        let previousOrder = { ...order };
        order = bookings[bookings.length - 1]._embedded.order;
        order._embedded = previousOrder._embedded;
        order._embedded.bookings = bookings;
        return order;
      });
  } else if (order) {
    return postBookings(order.id, booking, null, null, locale).then(
      bookings => {
        let previousBookings = order._embedded.bookings;
        if (!previousBookings) previousBookings = [];
        let previousOrder = { ...order };
        order = bookings[bookings.length - 1]._embedded.order;
        order._embedded = previousOrder._embedded;
        order._embedded.bookings = previousBookings.concat(bookings);
        return order;
      }
    );
  } else {
    throw new Error("Provide a company (id and type) or an order (id).");
  }
}

type Booking = {
  order: ?{
    id: ?number
  },
  product: {
    id: number,
    type: string
  },
  startTime: string,
  unitQuantityDeclarations: Array<{
    unit: {
      id: number,
      type: string
    },
    quantity: number,
    session_quantity: ?number
  }>,
  privatized: ?boolean
};

type Extras = ?Array<{
  order: ?{
    id: ?number
  },
  product: {
    id: number,
    type: string
  },
  startTime: string,
  quantity: number
}>;

/**
 * Create bookings
 * @param {number} orderId - the order ID on which the booking will be added
 * @param {Object} booking - the main booking to create
 * @param {Array} [extras] - the extras bookings to create
 */
function postBookings(
  orderId: number,
  booking: Booking,
  extras: Extras,
  companyType: ?string,
  locale: ?string,
  origin: ?string
): Promise<any> {
  booking.order = {
    id: orderId
  };

  if (!extras || extras.length === 0) {
    return postBooking(booking, companyType, locale, origin).then(booking => [
      booking
    ]);
  } else {
    extras = extras.map(extra => {
      extra.order = {
        id: orderId
      };
      return extra;
    });
    let bookings = [];
    return postBooking(booking, companyType, locale, origin)
      .then(booking => {
        bookings.push(booking);
        return Promise.all(
          extras.map(extra => {
            extra.bookingLinked = {
              id: booking.id
            };
            return postBooking(extra, companyType, locale, origin);
          })
        );
      })
      .then(extrasBookings => {
        bookings[
          bookings.length - 1
        ]._embedded.associatedBookings = extrasBookings;
        bookings[bookings.length - 1]._embedded.order = extrasBookings
          .map(extrasBooking => extrasBooking._embedded.order)
          .reduce((acc, curr) =>
            !acc || curr.totalPrice > acc.totalPrice ? curr : acc
          );
        return bookings;
      });
  }
}

let typeOfCompany;
const postBooking = withToken(
  (accessToken: string, bookingParam, companyType, locale, origin) =>
    fetch(`${API_ENDPOINT}/bookings?access_token=${accessToken}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-language": locale
      },
      method: "POST",
      body: JSON.stringify(bookingParam)
    })
      .then(handleResponse)
      .then(booking => {
        if (!typeOfCompany) {
          typeOfCompany = companyType;
        }
        return Promise.all([
          booking,
          getProductById(
            booking._embedded.product.id,
            locale,
            undefined,
            typeOfCompany
          )
        ]);
      })
      .then(([booking, product]) => {
        booking._embedded.product = product;
        return booking;
      })
);

/**
 * Update a booking
 */
export function updateBooking(
  currentBooking,
  quantities,
  day,
  slot,
  extras
): Promise<Order> {
  let booking = {
    startTime: slot ? slot.startDateTime : day.toISOString()
  };
  if (quantities.quantities instanceof Map) {
    booking.unitQuantityDeclarations = Array.from(quantities.quantities).map(
      ([key, value]) => ({
        unit: { id: key, type: "TARGET" },
        quantity: value
      })
    );
  } else {
    booking.unitQuantityDeclarations = quantities.quantities.map(quantity => ({
      unit: { id: quantity.id, type: "SESSION" },
      quantity: quantity.quantity,
      numberOfSessions: quantity.numberOfSessions
    }));
  }
  if (quantities.isPrivatized) {
    booking.privatized = quantities.isPrivatized;
  }
  return patchBooking(currentBooking.id, booking)
    .then(booking =>
      currentBooking._embedded && currentBooking._embedded.associatedBookings
        ? Promise.all([
            booking,
            ...currentBooking._embedded.associatedBookings.map(
              associatedBooking => deleteBooking(associatedBooking)
            )
          ]).then(([booking]) => booking)
        : booking
    )
    .then(booking => {
      if (extras && extras.quantities) {
        const extrasQuantities = Array.from(extras.quantities).map(
          ([key, value]) => ({
            product: {
              id: key,
              type: "EXTRA"
            },
            startTime: slot ? slot.startDateTime : day,
            quantity: value,
            order: {
              id: booking._embedded.order.id
            }
          })
        );

        return Promise.all(
          extrasQuantities.map(extra => {
            extra.bookingLinked = {
              id: booking.id
            };
            return postBooking(extra);
          })
        ).then(() => {
          return booking;
        });
      } else {
        return booking;
      }
    });
}

/**
 * Patch a single booking
 */
const patchBooking = withToken(
  (accessToken: string, bookingId: number, booking: Booking) =>
    fetch(`${API_ENDPOINT}/bookings/${bookingId}?access_token=${accessToken}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PATCH",
      body: JSON.stringify(booking)
    }).then(handleResponse)
);

/**
 * Delete a booking by ID
 * @param {Booking} booking - the booking to delete
 * @return {Promise} the promise of deleted booking
 */
export function deleteBooking(booking: Booking): Promise<any> {
  if (
    booking._embedded.associatedBookings &&
    booking._embedded.associatedBookings.length > 0
  ) {
    return Promise.all([
      deleteBookingById(booking.id),
      booking._embedded.associatedBookings.map(associatedBooking =>
        deleteBookingById(associatedBooking.id)
      )
    ]);
  } else {
    return deleteBookingById(booking.id);
  }
}

/**
 * Delete a booking by ID
 * @param {number} bookingId - the booking ID
 * @return {Promise} the promise of deleted booking
 */
export const deleteBookingById = withToken(
  (accessToken: string, bookingId: number): Promise<any> =>
    fetch(`${API_ENDPOINT}/bookings/${bookingId}?access_token=${accessToken}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "DELETE"
    }).then(handleResponse)
);

export const getParticipantClientForm = withToken(
  (accessToken: string, bookingId: number): Promise<ClientForm> =>
    fetch(
      `${API_ENDPOINT}/bookings/${bookingId}/participant_client_form?access_token=${accessToken}`
    ).then(handleResponse)
);

export const getParticipantClientData = withToken(
  (accessToken: string, bookingId: number) =>
    fetch(
      `${API_ENDPOINT}/bookings/${bookingId}/participant_client_data?access_token=${accessToken}`
    ).then(handleResponse)
);

export function addTimeRange(
  currentBooking,
  quantities,
  day,
  slot
): Promise<Order> {
  let booking = {
    startTime: slot ? slot.startDateTime : day.toISOString()
  };
  if (quantities.quantities instanceof Map) {
    booking.unitQuantityDeclarations = Array.from(quantities.quantities).map(
      ([key, value]) => ({
        unit: { id: key, type: "TARGET" },
        quantity: value
      })
    );
  } else {
    booking.unitQuantityDeclarations = quantities.quantities.map(quantity => ({
      unit: { id: quantity.id, type: "SESSION" },
      quantity: quantity.quantity,
      numberOfSessions: quantity.numberOfSessions
    }));
  }
  if (quantities.isPrivatized) {
    booking.privatized = quantities.isPrivatized;
  }
  return postTimeRange(currentBooking.id, booking);
}

const postTimeRange = withToken(
  (accessToken: string, bookingId: number, booking: Booking) =>
    fetch(
      `${API_ENDPOINT}/bookings/${bookingId}/time_range?access_token=${accessToken}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(booking)
      }
    ).then(handleResponse)
);

export const addParticipantClientData = withToken(
  (
    accessToken: string,
    bookingId: number,
    participantIndex: number,
    participantData: Array<{
      value: any,
      clientFormInputType: { id: number, type: string }
    }>
  ) =>
    fetch(
      `${API_ENDPOINT}/bookings/${bookingId}/participant/${participantIndex}/participant_client_data?access_token=${accessToken}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify(participantData)
      }
    ).then(handleResponse)
);

export const decrementeBookingVoucherUsed = (booking: Booking) => {
  return fetch(
    `${PHP_API_ENDPOINT}/booking/${booking.id}/usepromocode/decremente`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PATCH"
    }
  ).then(handleResponse);
};

export const createParticipants = (
  bookingId: number,
  participantData: Array<{
    value: any,
    clientFormInputType: { id: number, type: string }
  }>
) => {
  return fetch(`${PHP_API_ENDPOINT}/participant/${bookingId}/user/create`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify(participantData)
  }).then(handleResponse);
};
