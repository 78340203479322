// @flow

/**
 * Return the main unit quantity to add to validate the choosen quantities
 */
const getMandatoryMainUnitQuantityToAdd = (
  quantities: Map<number, number>,
  units: Array<Unit>
) =>
  // find if there is at least a target unit with a specific price
  // and a mandatory main unit quantity superior to zero
  units.find(
    unit =>
      quantities.has(unit.id) &&
      unit.type === "TARGET" &&
      unit.prices[0].type === "SPECIFIC" &&
      unit.prices[0].mandatoryMainUnitQuantity &&
      unit.prices[0].mandatoryMainUnitQuantity > 0
  ) && units.find(unit => unit.type === "MAIN")
    ? Math.ceil(
        units.reduce(
          (acc, unit) =>
            quantities.has(unit.id) &&
            unit.type === "TARGET" &&
            unit.prices[0].type === "SPECIFIC" &&
            unit.prices[0].mandatoryMainUnitQuantity &&
            unit.prices[0].mandatoryMainUnitQuantity > 0
              ? acc +
                (quantities.get(unit.id) || 0) /
                  unit.prices[0].mandatoryMainUnitQuantity
              : acc,
          0
        ) - (quantities.get(units.find(unit => unit.type === "MAIN").id) || 0)
      )
    : 0;

export default getMandatoryMainUnitQuantityToAdd;
