// @flow
// @jsx h
import { h } from "preact";

// style
import styles from "./TabLabel.scss";

/**
 * Quantities tab component
 */
const TabLabel = (
  {
    theme,
    active,
    label
  }: {
    theme?: Object,
    active: boolean,
    label: string
  },
  context
) => (
  <div
    class={`${
      active
        ? theme && theme.labelActive
          ? theme.labelActive
          : styles.labelActive
        : theme && theme.labelInactive
          ? theme.labelInactive
          : styles.labelInactive
    } ${styles.label}`}
    style={`color:${
      context.themeConfig && context.themeConfig.color1 && active
        ? context.themeConfig.color1
        : ""
    };`}
  >
    {label}
  </div>
);
export default TabLabel;
