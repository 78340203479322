// @flow
import { h } from 'preact';

const Language = ({ color }: { color: string }) => (
  <svg
    fill={color ? color : '#000000'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 20.5"
    width="24"
    height="20.5"
  >
    <path d="M22.6,5.9h-8.4L12.9,0H1.4C0.6,0,0,0.6,0,1.4v11.8c0,0.8,0.6,1.4,1.4,1.4h8.4l1.4,5.9	h11.5c0.8,0,1.4-0.6,1.4-1.4V7.3C24,6.5,23.4,5.9,22.6,5.9L22.6,5.9z M1.6,13V1.6h10.1L14.3,13H1.6L1.6,13z M11.4,14.6h3l-2.2,3.2	L11.4,14.6z M22.4,18.9h-9.2l1.7-2.5l0.3,0.4c0.9-0.6,1.8-1.3,2.5-2.1l2,2l0.8-0.8l-2.1-2.2c0.7-1,1.2-2.1,1.5-3.2h1.4V9.5h-2.9V8.8	h-1.2v0.7h-2.1l-0.5-2h7.8L22.4,18.9L22.4,18.9L22.4,18.9z M16.2,14.6L16.2,14.6l-0.4-1.7l1,1C16.7,14.2,16.4,14.4,16.2,14.6	L16.2,14.6L16.2,14.6L16.2,14.6z M16.2,11.6l-0.5,0.5l-0.3-1.5h3.3c-0.3,0.8-0.6,1.6-1.1,2.3L16.2,11.6z M7.1,6.5H11v0.8	c0,3.5-4.2,5.2-6.7,2.8S3.7,3.4,7.1,3.4c0.9,0,1.7,0.3,2.4,0.8l-1,1.2C6.8,4.1,4.3,5.8,4.9,7.9s3.6,2.3,4.4,0.1H7.1V6.5z" />
  </svg>
);
export default Language;
