// @flow
// @jsx h

import { h } from 'preact';
import { LoaderIcon } from 'elc-icons';

import styles from './style.scss';
import themeMarketplace from './theme-marketplace.scss';
import themeKiosk from './theme-kiosk.scss';

/**
 * Common Loading icon stateless functional Component
 */
const Loading = (
  {
    loading,
    color,
    children
  }: {
    loading?: boolean,
    color?: string,
    children?: any
  },
  context
) => {
  const theme =
    context.widgetContext === 'KIOSK'
      ? themeKiosk
      : context.widgetContext === 'SHOP' || context.widgetContext === 'PMS'
        ? themeMarketplace
        : undefined;
  if (loading == undefined) {
    loading = true;
  }
  const customColors = context.themeConfig;
  return (
    loading && (
      <div class={theme && theme.loading ? theme.loading : styles.loading}>
        <span
          class={
            theme && theme.loadingIcon ? theme.loadingIcon : styles.loadingIcon
          }
        >
          <LoaderIcon
            color={
              color
                ? color
                : theme && theme.loadingColor
                  ? theme.loadingColor
                  : customColors && customColors.color1
                    ? customColors.color1
                    : styles.loadingColor
            }
          />
        </span>
        {children &&
          children.length > 0 && (
            <span
              class={
                theme && theme.loadingText
                  ? theme.loadingText
                  : styles.loadingText
              }
            >
              {children}
            </span>
          )}
      </div>
    )
  );
};
export default Loading;
