// @flow
// @jsx h

import { h } from "preact";

import { withText } from "preact-i18n";

// Tab component
import Tab from "./Tab";
import TabIcon from "./TabIcon";
import TabLabel from "./TabLabel";

// Icon
import { GiftIcon } from "elc-icons";

/**
 * Beneficiary tab component
 */
const GiftsTab = withText({ label: "header.giftBeneficiary" })(
  ({
    theme,
    active,
    label,
    handleClick
  }: {
    theme?: Object,
    active: boolean,
    label: string,
    handleClick: Function
  }) => (
    <Tab handleClick={handleClick}>
      <TabIcon theme={theme} active={active}>
        <GiftIcon />
      </TabIcon>
      {label && <TabLabel theme={theme} active={active} label={label} />}
    </Tab>
  )
);

export default GiftsTab;
