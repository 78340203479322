export const getMaxQuantityBySessions = (units, timeSlots, quantities) => {
  return units.map(unit => {
    let maxRemainingBookingCapacity = 99999;

    if (timeSlots !== null) {
      maxRemainingBookingCapacity = timeSlots
        .slice(0, unit.numberOfSessions)
        .reduce(
          (acc, curr) =>
            acc === -1 ||
            curr._embedded.productUnitTimeSlotAvailabilities[0]
              .remainingBookingCapacity < acc
              ? curr._embedded.productUnitTimeSlotAvailabilities[0]
                  .remainingBookingCapacity
              : acc,
          -1
        );
    }

    const currentUnitQuantity =
      quantities.findIndex(
        quantity =>
          quantity.id === unit.id &&
          quantity.numberOfSessions === unit.numberOfSessions
      ) !== -1
        ? quantities.find(
            quantity =>
              quantity.id === unit.id &&
              quantity.numberOfSessions === unit.numberOfSessions
          ).quantity
        : 0;
    return maxRemainingBookingCapacity + currentUnitQuantity;
  });
};
