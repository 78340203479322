import { h, Component } from "preact";
import styles from "./ParticipantClientForms.scss";
import { Text } from "preact-i18n";

export default class ParticipipantClientFormsHeader extends Component {
  render ({context, textId}) {
    return <header
    class={styles.headerTitle}
    style={`color:${
      context.themeConfig && context.themeConfig.color1
        ? context.themeConfig.color1
        : ""
    };`}
  >
    <h2>
      {
        <Text id={textId}></Text>
      }
    </h2>
  </header>
  }
}