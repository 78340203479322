/**
 * Return the product title style object from the theme config
 */
export default function getProductTitleStyle(themeConfig) {
  let productTitleStyle = {};
  if (themeConfig) {
    if (themeConfig.color1) {
      productTitleStyle.color = themeConfig.color1;
    }
    if (themeConfig.fontFamily) {
      productTitleStyle.fontFamily = themeConfig.fontFamily;
    }
  }
  return productTitleStyle;
}
