//@flow

import { Component } from 'preact';

/** `<WidgetContextProvider>` is a nestable Widget context provider.
 *	It exposes a Widget context into the tree,
 *	making it available to descendant components.
 *	Its main (only) usage should be theming.
 *
 *	@name WidgetContextProvider
 *	@param props
 *	@param {Object} [props.widgetContext]
 *
 *	@example
 *	// generally imported from a JSON file:
 *	import theme from './theme.scss';
 *
 *	<WidgetContextProvider widgetContext="KIOSK">
 *		...
 *	</WidgetContextProvider>
 */
export default class WidgetContextProvider extends Component {
  getChildContext() {
    let { widgetContext } = this.props;
    return { widgetContext };
  }

  render({ children }) {
    return (children && children[0]) || null;
  }
}
