import { h, Component } from "preact";

export default class Error extends Component {
  render({ error }) {
    console.error("error", error);
    return (
      <div>
        <div style="text-align: center; color: #ff4156;">
          Erreur : {error.props ? error.props : error.statusText}
        </div>
        {/*<div>{JSON.parse(error)}</div>*/}
      </div>
    );
  }
}
