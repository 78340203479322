// @flow
// @jsx h

import { h } from "preact";
import { Text } from "preact-i18n";

import { ConfirmationIcon, PdfIcon } from "elc-icons";

import styles from "./style.scss";
import { withStyles } from "elc-theme";
import { PrimaryButton } from "elc-button";

function getOrderRecap() {
  // console.log(this);
}

/**
 * Confirmation that a email or smshas been sent to the customer Component
 */
const PreBookingConfirmationPayOnArrival = ({
  getClassName,
  urlToRecap,
  urlToTicket,
  totalBookings,
  totalBookingsBooked,
  listBookingsConfirmed,
  listBookingsNotConfirmed,
  cash,
  cashAmount
}) => (
  <div>
    <header class={getClassName("headerTitle")}>
      <h2>
        <Text id="basket.confirmationPayOnArrival">
          Informations sur la commande
        </Text>
      </h2>
    </header>
    <div class={styles.column}>
      <div class={styles.contentContainer}>
        <div class={styles.headerconfirmation}>
          <ConfirmationIcon color={styles.vaildColor} />
          <div>
            <Text id="basket.booking.title">Commande enregistrée</Text>
          </div>
        </div>

        <div>
          {totalBookings > 1 ? (
            <span>
              <p>
                <strong>
                  Vous avez réservé {totalBookings} produits et un mail vient
                  d'être envoyé au client.
                </strong>
              </p>
              {totalBookingsBooked > 0 && (
                <p>
                  Parmi les {totalBookings} produits réservés, un e-billet est
                  disponible pour les activités suivantes :
                </p>
              )}
            </span>
          ) : (
            <span>
              <p>
                <strong>
                  Vous avez réservé {totalBookings} produit et un mail vient
                  d'être envoyé au client.
                </strong>
              </p>
              {totalBookingsBooked > 0 && (
                <p>Pour le produit réservé un e-billet est disponible :</p>
              )}
            </span>
          )}
          <ul>
            {listBookingsConfirmed.map(item => (
              <li>
                <strong>{item._embedded.product.name}</strong>
              </li>
            ))}
          </ul>
          {listBookingsNotConfirmed.length > 0 && (
            <span>
              <Text id="basket.booking.ebilletApresValidationMarchand">
                Le client recevra les e-billets manquants pour les activités
                suivantes après validation par le marchand :
              </Text>
              <ul>
                {listBookingsNotConfirmed.map(item => (
                  <li>
                    <strong>{item._embedded.product.name}</strong>
                  </li>
                ))}
              </ul>
            </span>
          )}
        </div>

        <hr class={styles.hr} />
        <div>
          <a href={urlToRecap} class={styles.action}>
            <i class="mdi mdi-file-pdf-box mdi-18px"></i>&nbsp;{" "}
            <Text id="basket.recapCommande">Récapitulatif de la commande</Text>
          </a>
          {totalBookingsBooked > 0 && (
            <a href={urlToTicket} class={styles.action}>
              <i class="mdi mdi-file-pdf-box mdi-18px"></i>&nbsp;{" "}
              <Text id="basket.ebillet">E-billet</Text>
            </a>
          )}
        </div>

        {cash === true && cashAmount !== null && (
          <div class="w100">
            <hr class={styles.hr} />
            <div class="bg-blue md-padding margin-bottom-8">
              <div
                layout="row"
                layout-align="start-center"
                class="layout-align-start-center layout-row"
              >
                <i class="mdi-24px mdi mdi-check"></i>&nbsp;
                <span class=""> Vous avez encaissé</span>
              </div>
              <div
                class="md-display-1 layout-align-space-between-center layout-row"
                layout="row"
                layout-align="space-between-center"
              >
                <span> {cashAmount}&nbsp;€</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default withStyles(PreBookingConfirmationPayOnArrival, styles);
