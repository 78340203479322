// @flow
// @jsx h

// External librairies
import { h } from "preact";
import { Text } from "preact-i18n";
import { DiscountIcon } from "elc-icons";

// Style
import styles from "./style.scss";

/**
 * France Billet product discount presentational component
 * displaying the discount information
 */
const Discount = ({ discount }: { discount: FBDiscount }) => (
  <div class={styles.discount}>
    <div class={styles.discountIcon}>
      <DiscountIcon />
    </div>
    <div class={styles.discountDescription}>
      <div class={styles.discountTitle}>
        <Text id="quantities.discount.title">Promotion</Text>
      </div>
      <div class={styles.discountLabel}>
        <Text
          id="quantities.discount.primary"
          plural={discount.primaryCustomerClassTicketQuantity}
          fields={{
            primaryCustomerClassTicketQuantity:
              discount.primaryCustomerClassTicketQuantity,
            primaryCustomerClassLabel: discount.primaryCustomerClassLabel.split(
              ","
            )[2],
            secondaryCustomerClassTicketQuantity:
              discount.secondaryCustomerClassTicketQuantity,
            secondaryCustomerClassLabel: discount.secondaryCustomerClassLabel.split(
              ","
            )[2]
          }}
        >
          {discount.primaryCustomerClassTicketQuantity} billet(s){" "}
          {discount.primaryCustomerClassLabel.split(",")[2]} acheté(s),
        </Text>
        <Text
          id="quantities.discount.secondary"
          plural={discount.secondaryCustomerClassTicketQuantity}
          fields={{
            secondaryCustomerClassTicketQuantity:
              discount.secondaryCustomerClassTicketQuantity,
            secondaryCustomerClassLabel: discount.secondaryCustomerClassLabel.split(
              ","
            )[2]
          }}
        >
          {discount.secondaryCustomerClassTicketQuantity} billet(s){" "}
          {discount.secondaryCustomerClassLabel.split(",")[2]} offert(s)
        </Text>
      </div>
    </div>
  </div>
);

export default Discount;
