// @flow
// @jsx h
import { h, Component } from "preact";

import styles from "./SwitchInput.scss";
import { withStyles } from "elc-theme";

type SwitchInputProps = {
  name: string,
  id: string,
  value: string,
  onChange: Function,
  getClassName: Function
};

class SwitchInput extends Component<SwitchInputProps> {
  render({ getClassName }) {
    return (
      <label class={getClassName("switch")} htmlFor={this.props.id}>
        <input
          name={this.props.name}
          id={this.props.id}
          type="checkbox"
          checked={this.props.value === "1" ? true : false}
          onChange={this.props.onChange}
        />
        <span
          class={getClassName("slider", "round")}
          style={`${
            this.context.themeConfig &&
            this.context.themeConfig.color1 &&
            this.props.value &&
            this.props.value === "1"
              ? "background-color:" + this.context.themeConfig.color1
              : ""
          }`}
        />
      </label>
    );
  }
}

export default withStyles(SwitchInput, styles);
