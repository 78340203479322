// @flow

import { Component } from 'preact';

/** `<ThemeProvider>` is a nestable CSS Modules theme provider.
 *	It exposes a theme into the tree,
 *	making it available to descendant components.
 *
 *	@name ThemeProvider
 *	@param props
 *	@param {Object} [props.theme]			Nest `definition` under a root key, and set the active scope for the tree (essentially prefixing all `<Text />` keys).
 *
 *	@example
 *	// generally imported from a JSON file:
 *	import theme from './theme.scss';
 *
 *	<ThemeProvider theme={theme}>
 *		<div class={theme.myClassName}>The Foo</div>
 *	</ThemeProvider>
 */
export default class ThemeProvider extends Component {
  getChildContext() {
    let { theme } = this.props;
    return { theme };
  }

  render({ children }) {
    return (children && children[0]) || null;
  }
}
