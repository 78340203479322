// @flow
// @jsx h

// External librairies
import { h } from "preact";

import { Button } from "elc-button";
import { RemoveIcon } from "elc-icons";

// Style
import styles from "./SelectedCategories.scss";

const SelectedCategories = (
  {
    categories,
    selectedCategories,
    removeCategory
  }: {
    categories: Array<Object>,
    selectedCategories: Array<Number>,
    removeCategory: Function
  },
  context
) => {
  let selectedCategoriesInfos;
  if (selectedCategories && categories) {
    selectedCategoriesInfos = selectedCategories.map(catId => {
      return categories.find(cat => cat.id === catId);
    });
  }

  return (
    <div class={styles.selectedCategoriesContainer}>
      {selectedCategoriesInfos &&
        selectedCategoriesInfos.length > 0 && (
          <ul class={styles.selectedCategoriesList}>
            {selectedCategoriesInfos.map(cat => {
              return (
                <li
                  key={cat.id}
                  class={styles.selectedCategoriesElement}
                  style={`background-color:${
                    context.themeConfig && context.themeConfig.color1
                      ? context.themeConfig.color1
                      : ""
                  };`}
                >
                  <span>{cat.name}</span>
                  <Button
                    className={styles.removeCatButton}
                    handleClick={() => {
                      removeCategory(cat.id, cat.code);
                    }}
                  >
                    <RemoveIcon color="#fff" />
                  </Button>
                </li>
              );
            })}
          </ul>
        )}
    </div>
  );
};
export default SelectedCategories;
