// @flow
// @jsx h
import { h } from "preact";

// Tabs components
import Tabs from "./Tabs";
import QuantitiesTab from "./tab/QuantitiesTab";
import DayTab from "./tab/DayTab";
import SlotTab from "./tab/SlotTab";
import ExtrasTab from "./tab/ExtrasTab";

/**
 * Main Unit header component
 */
const MainHeader = ({
  theme,
  route,
  currentProduct,
  currentQuantities,
  currentDay,
  currentSlot,
  goToQuantities,
  goToDay,
  goToSlot
}) => (
  <Tabs>
    <QuantitiesTab
      theme={theme}
      active={route === "quantities"}
      currentQuantities={currentQuantities}
      handleClick={goToQuantities}
    />
    {currentProduct.scheduleType !== "OPEN_BILLET_WITHOUT_DATE" && (
      <DayTab
        theme={theme}
        active={route === "day"}
        currentDay={currentDay}
        handleClick={route !== "quantities" && goToDay}
      />
    )}
    {currentProduct.scheduleType !== "OPEN_BILLET" &&
      currentProduct.scheduleType !== "OPEN_BILLET_WITHOUT_DATE" && (
        <SlotTab
          theme={theme}
          active={route === "slot"}
          currentSlot={currentSlot}
          handleClick={route !== "quantities" && route !== "day" && goToSlot}
        />
      )}
    {currentProduct &&
      currentProduct._embedded.extraProducts &&
      currentProduct._embedded.extraProducts.length > 0 && (
        <ExtrasTab theme={theme} active={route === "extras"} />
      )}
  </Tabs>
);
export default MainHeader;
