// @flow
// @jsx h
import { h, Component } from "preact";
import styles from "./style.scss";

import { IntlProvider, Text } from "preact-i18n";
// import he from 'he';
import ca from "../translations/ca.json";
import de from "../translations/de.json";
import en from "../translations/en.json";
import es from "../translations/es.json";
import fr from "../translations/fr.json";
import it from "../translations/it.json";
import nl from "../translations/nl.json";

import Products from "./products";
import ProductsPagination from "./pagination";

type ProductsComponentProps = {
  hash: string,
  config: WidgetConfig,
  locale: string,
  currency: Currency,
  products: Products,
  onSelectProduct: Function,
  goToPage: Function,
  productsListViewMode: boolean,
  setProductsViewModeList: Function,
  setProductsViewModeGrid: Function,
  pageDidMountOrUpdate: Function
};

type ProductsComponentState = {
  localeDefinition: Object,
  currency: Currency
};

/* FIXME when Preact implement getDerivedStateFromProps */
/* eslint-disable-next-line react/no-deprecated */
export default class App extends Component<
  ProductsComponentProps,
  ProductsComponentState
> {
  state = {
    localeDefinition: fr,
    currency: {
      // code: 'EUR',
      // codeNumber: '978',
      // id: 2,
      // name: 'Euro',
      // sign: '€',
      // symbol: '&euro;'
      active: true,
      id: 2,
      isoCode: "EUR",
      name: "Euro",
      numCode: 978,
      symbol: "&euro;"
    }
  };

  constructor(props: ProductsComponentProps) {
    super(props);
    if (props.locale) {
      switch (props.locale) {
        case "ca":
          this.setState({ locale: ca });
          break;

        case "de":
          this.setState({ locale: de });
          break;

        case "en":
          this.setState({ locale: en });
          break;

        case "es":
          this.setState({ locale: es });
          break;

        case "it":
          this.setState({ locale: it });
          break;

        case "nl":
          this.setState({ locale: nl });
          break;
      }
    }
  }

  // componentDidUpdate() {
  //   console.log('Products componentDidUpdate');
  //   this.props.pageDidMountOrUpdate();
  // }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.currency !== this.state.currency) {
    //   nextProps.currency.symbol = he.decode(nextProps.currency.symbol);
    // this.setState({ currency: nextProps.currency });
    // }
    if (nextProps.locale !== this.props.locale) {
      switch (nextProps.locale) {
        case "ca":
          this.setState({ localeDefinition: ca });
          break;

        case "de":
          this.setState({ localeDefinition: de });
          break;

        case "en":
          this.setState({ localeDefinition: en });
          break;

        case "es":
          this.setState({ localeDefinition: es });
          break;

        case "fr":
        default:
          this.setState({ localeDefinition: fr });
          break;

        case "it":
          this.setState({ localeDefinition: it });
          break;

        case "nl":
          this.setState({ localeDefinition: nl });
          break;
      }
    }
  }

  render(
    {
      hash,
      config,
      locale,
      currency,
      products,
      onSelectProduct,
      goToPage,
      productsListViewMode
    }: { onSelectProduct: Function, goToPage: Function },
    { localeDefinition }: ProductsComponentState
  ) {
    return (
      <IntlProvider definition={localeDefinition}>
        <div class={styles.catalog}>
          <Products
            hash={hash}
            config={config}
            locale={locale}
            currency={currency}
            products={products.items}
            productsListViewMode={productsListViewMode}
            onSelectProduct={onSelectProduct}
            disabledReservation={this.props.disabledReservation}
            options={this.props.options}
          />
          {products.pageCount > 1 && (
            <footer class={styles.catalogFooter}>
              <ProductsPagination
                config={config}
                currentPage={products.page}
                pageCount={products.pageCount}
                goToPage={goToPage}
              />
            </footer>
          )}
          {products.totalItems > 0 ? (
            <p class={styles.addockLink}>
              <Text id="header.poweredby">Propulsé par</Text>{" "}
              <a href="https://addock.co" target="_blank">
                <span class={styles.addockLinkPurple}>AD</span>
                <span class={styles.addockLinkRed}>DOCK</span>
              </a>
            </p>
          ) : (
            <p class={styles.noProducts}>
              <Text id="products.noResult">
                Aucune activité ne correspond à votre recherche.
              </Text>
            </p>
          )}
        </div>
      </IntlProvider>
    );
  }
}
