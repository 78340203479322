// @flow
// @jsx h
import { h, Component } from "preact";
import { Text } from "preact-i18n";

import styles from "./GiftVoucher.scss";

import { ArrowLeftIcon } from "elc-icons";
import { PrimaryButton } from "elc-button";
import { ValidCircleIcon, CancelCircleIcon } from "elc-icons";
import Loading from "elc-loading";
import ReactHintFactory from "react-hint";

import { getPromoCodeByVoucherCode } from "elc-open-api";

type GiftVoucherProps = {
  companyId: number,
  productId: number,
  bookFromGift: Function,
  backFromGiftForm: Function
};

type GiftVoucherState = {
  loading: boolean,
  voucherCode?: string,
  isVoucherCodeValid?: boolean
};

const ReactHint = ReactHintFactory({ createElement: h, Component });

/**
 * Client/Customer details input form Component
 */
export default class GiftVoucher extends Component<
  GiftVoucherProps,
  GiftVoucherState
> {
  /**
   * @type {object}
   * @property {boolean} loading - waiting for API response
   */
  state = {
    loading: false,
    voucherCode: undefined
  };

  /**
   * Voucher code input change handler
   * @param {SyntheticInputEvent} e
   */
  onInputChange = ({ target }: SyntheticInputEvent<HTMLInputElement>) => {
    const voucherCode: string = target.value;
    this.setState({ voucherCode });
  };

  handleBookWithVoucherCode = () => {
    this.props.bookFromGift(this.state.voucherCode);
  };

  handleBackFromGiftForm = () => {
    this.props.backFromGiftForm();
  };

  /**
   * handle the client form submit
   * and inputs validations
   * @param {SyntheticEvent} event
   */
  handleSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { companyId, productId } = this.props;
    const { voucherCode } = this.state;

    this.setState({ loading: true });

    getPromoCodeByVoucherCode(companyId, voucherCode)
      .then(response => {
        this.setState({
          loading: false,
          isVoucherCodeValid:
            response._embedded.promoCodes.findIndex(
              promoCode =>
                new Date(promoCode.validityFromDate) <= new Date() &&
                new Date(promoCode.validityToDate) >= new Date() &&
                promoCode.availableQuantity >= 1 &&
                promoCode._embedded.products.findIndex(
                  product => product.id === productId
                ) > -1
            ) > -1
        });
        return response;
      })
      .catch(error => {
        console.error(error);
      });
  };

  onRenderContent = () => {
    return (
      <div class={styles.tooltipContent}>
        <Text id="giftVoucher.information">
          Entrez le code cadeau que vous avez reçu
        </Text>
      </div>
    );
  };

  /**
   * render
   * @param {GiftVoucherProps} props - the GiftVoucher component props
   * @param {ClientDetailsState} state - the GiftVoucher component state
   * @return {ReactElement} markup
   */
  render(props, { loading, voucherCode, isVoucherCodeValid }, { themeConfig }) {
    return (
      <div class={styles.voucherContainer}>
        <header
          class={styles.headerTitle}
          style={`color:${
            this.context.themeConfig && this.context.themeConfig.color1
              ? this.context.themeConfig.color1
              : ""
          };`}
        >
          <h2>
            <Text id="giftVoucher.header">Réserver cette activité</Text>
          </h2>
        </header>
        <div class={styles.column}>
          <form
            autoComplete="off"
            onSubmit={this.handleSubmit}
            class={styles.giftForm}
          >
            <div class={styles.tooltipPositionHint}>
              <ReactHint
                attribute="data-custom"
                className={styles.tooltip}
                events={{ click: true }}
                onRenderContent={this.onRenderContent}
                position="bottom"
              />
            </div>
            <label htmlFor="voucherCode">
              <button class={styles.questionButton} type="button" data-custom>
                <span>?</span>
              </button>

              <span>
                <Text id="giftVoucher.label">Numéro du bon cadeau</Text>
              </span>
            </label>
            <input
              id="voucherCode"
              value={voucherCode}
              type="text"
              onChange={this.onInputChange}
            />
            <PrimaryButton type="submit" className={styles.submitButton}>
              {loading ? (
                <Loading color="#fff" />
              ) : (
                <Text id="giftVoucher.save">Valider</Text>
              )}
            </PrimaryButton>
            {isVoucherCodeValid === true && (
              <div class={styles.responseBlock}>
                <div class={styles.icon}>
                  <ValidCircleIcon color={styles.validColor} />
                </div>
                <div>
                  <Text id="giftVoucher.valid">Voucher code valid !</Text>
                </div>
                <PrimaryButton
                  handleClick={this.handleBookWithVoucherCode}
                  className={styles.bookButton}
                >
                  <Text id="giftVoucher.book">Book</Text>
                </PrimaryButton>
              </div>
            )}
            {isVoucherCodeValid === false && (
              <div class={styles.responseBlock}>
                <div class={styles.icon}>
                  <CancelCircleIcon color={styles.invalidColor} />
                </div>
                <div>
                  <Text id="giftVoucher.invalid">Voucher code invalid !</Text>
                </div>
              </div>
            )}
          </form>
          <div class={styles.row}>
            <button
              onClick={this.handleBackFromGiftForm}
              className={styles.previousButton}
            >
              <ArrowLeftIcon
                color={
                  themeConfig && themeConfig.color1
                    ? themeConfig.color1
                    : styles.backIconColor
                }
              />
              <Text id="giftVoucher.back">Retour</Text>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
