// @flow
// @jsx h
import { h, Component } from "preact";
import ProductItem from "./productitem";
import styles from "./style.scss";

type ProductListProps = {
  hash: string,
  locale: string,
  config: WidgetConfig,
  currency: Currency,
  products: Array<Product>,
  onSelectProduct: Function
};

export default class ProductList extends Component<ProductListProps> {
  render({
    config,
    locale,
    currency,
    products,
    onSelectProduct
  }: {
    config: WidgetConfig,
    locale: string,
    currency: Currency,
    products: Array<Product>,
    onSelectProduct: Function
  }) {
    return (
      <div class={styles.productsList}>
        {products && (
          <div id="cards" class={styles.centered}>
            <section class={styles.cards}>
              {products.map(product => (
                <ProductItem
                  key={product.id}
                  config={config}
                  locale={locale}
                  currency={currency}
                  product={product}
                  onSelectProduct={onSelectProduct}
                  disabledReservation={this.props.disabledReservation}
                />
              ))}
            </section>
          </div>
        )}
      </div>
    );
  }
}
