//@flow

declare var PHP_API_ENDPOINT: string;

import { handleResponse } from "../handle-response";

let headers = new Headers();
headers.append("Accept", "application/json");
headers.append("Content-Type", "application/json");

export class StripeService {
  static charge({ order, paymentMethod, stripePaymentMethod }, callback) {
    fetch(`${PHP_API_ENDPOINT}/stripe/charge`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        orderId: order.id,
        confId: paymentMethod.configuration.id,
        method: stripePaymentMethod.id,
        downPayment:
          order.status === "USER_FILLED" &&
          paymentMethod.configuration.deposit !== 100
            ? true
            : false
      })
    })
      .then(res => res.json())
      .then(res => callback(res, null))
      .catch(err => callback(err, true));
  }

  static chargeConfirm(
    { id, order, has3DSecure, paymentMethod, locale, callbackStripe },
    callback
  ) {
    fetch(`${PHP_API_ENDPOINT}/stripe/charge_confirm`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        downPayment:
          order.status === "USER_FILLED" &&
          paymentMethod.configuration.deposit !== 100
            ? true
            : false,
        has3DSecure,
        locale: locale,
        orderId: order.id,
        paiement_id: id,
        callbackStripe: callbackStripe
      })
    })
      .then(res => res.json())
      .then(res => callback(res, null))
      .catch(err => callback(err, true));
  }

  static createCharge(charge) {
    fetch(`${PHP_API_ENDPOINT}/stripe/charge`, {
      method: "PUT",
      headers,
      body: JSON.stringify(charge)
    }).then(handleResponse);
  }
}
