/* eslint-disable promise/always-return */
// @flow
// @jsx h

declare var WIDGET_URL: string;
declare var ENV: string;

// External librairies
import { h, Component } from "preact";
import { Text, withText } from "preact-i18n";
import { injectStripe } from "react-stripe-elements";

// Style
import styles from "./style.scss";

// Components
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from "react-stripe-elements";
import StripeError from "./StripeError";
import { PrimaryButton } from "elc-button";
import Loading from "elc-loading";

// API
import { StripeService } from "elc-internal-api";

/**
 * Stripe checkout form component
 */

const stripeErrors = ["STRIPE_CONNECT_CONTROLLER.PAYMENT_CHARGE_PROBLEM"];
@withText({
  cardHolderNamePlaceholder: "payment.creditCard.cardHolderName.placeholder"
})
class StripePaymentForm extends Component {
  state = {
    loading: false,
    disablePaymentBtn: false,
    stripeError: false
  };

  handleInputChange = ({ target }) => {
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };
  // stop loading and show error component
  handleStripeError = () => {
    this.setState({
      loading: false,
      stripeError: true,
      disablePaymentBtn: false
    });
  };

  // Step 1
  handleSubmit = ev => {

    if(this.props.checkSession(this.props.order)){
      ev.preventDefault();
      this.setState({
        loading: true,
        disablePaymentBtn: true,
        stripeError: null
      });

      this.props.stripe
        .createPaymentMethod("card", {
          billing_details: { name: this.state.cardHolderName }
        })
        .then(({ paymentMethod: stripePaymentMethod }) => {
          StripeService.charge(
            {
              order: this.props.order,
              paymentMethod: this.props.paymentMethod,
              stripePaymentMethod
            },
            (res, err) => {
              if (err) {
                this.handleStripeError();
              } else {
                this.handleServerResponse(res, err);
              }
            }
          );
        })
        .catch(err => {
          // Stripe Error
          this.handleStripeError();
          console.error(err);
        });
    }


  };

  // Step 2
  handleServerResponse = (res, err) => {
    if (err || res.data.charge.error) {
      this.handleStripeError();
      console.error(response.error);
      return;
    }

    const response = res.data.charge;
    const parsedBodyResponse = JSON.parse(response._last_response.body);

    // if credit card require 3DS
    if (res.data.requires_action) {
      this.props.stripe
        .handleCardAction(parsedBodyResponse.client_secret)
        .then(res => {
          if (res.error) {
            // 3DS failure
            this.handleStripeError();
          } else {
            // 3DS success
            StripeService.chargeConfirm(
              {
                id: res.paymentIntent.id,
                order: this.props.order,
                paymentMethod: this.props.paymentMethod,
                has3DSecure: true,
                locale: this.props.locale,
                callbackStripe: this.props.callbackStripe
              },
              (res, err) => {
                if (err || !res.paymentSuccess) {
                  this.handleStripeError();
                  return;
                }
                // Show success message
                this.props.setPaymentMethod(this.props.paymentMethod, {
                  charge: parsedBodyResponse
                });
              }
            );
          }
        })
        .catch(err => {
          // Stripe Error
          this.handleStripeError();
          console.error(err);
        });
      // credit card doesnt require 3DS
    } else {
      StripeService.chargeConfirm(
        {
          id: parsedBodyResponse.id,
          order: this.props.order,
          paymentMethod: this.props.paymentMethod,
          has3DSecure: false,
          locale: this.props.locale,
          callbackStripe: this.props.callbackStripe
        },
        (res, err) => {
          if (err || !res.paymentSuccess) {
            this.handleStripeError();
            console.error(res);
          } else {
            // Show success message
            this.props.setPaymentMethod(this.props.paymentMethod, {
              charge: parsedBodyResponse
            });
          }
        }
      );
    }
  };

  render({ config, cardHolderNamePlaceholder }, { loading, stripeError }) {
    let buttonProps = {};
    if (
      config &&
      config.module &&
      config.module.conf &&
      config.module.conf.color1
    )
      buttonProps.color = config.module.conf.color1;
    if (
      config &&
      config.module &&
      config.module.conf &&
      config.module.conf.color5
    )
      buttonProps.background = config.module.conf.color5;

    const elementStyle = {
      // base style—all other variants inherit from this style
      base: {
        iconColor: "#666EE8",
        color: styles.textColor,
        // lineHeight: '40px',
        fontWeight: 600,
        // fontFamily: 'Helvetica Neue',
        fontSize: "15px",
        lineHeight: "18px",

        "::placeholder": {
          color: styles.placeholderColor
        }
        //     color
        //     fontFamily
        //     fontSize
        //     fontSmoothing
        //     fontStyle
        //     fontVariant
        //     iconColor
        //     lineHeight, to avoid cursors being rendered inconsistently across browsers, consider using a padding on the Element's container instead.
        //     letterSpacing
        //     textAlign, available for the cardNumber, cardExpiry, and cardCvc Elements.
        //     textDecoration
        //     textShadow
        //     textTransform
        //
        // The following pseudo-classes and pseudo-elements can also be styled with the above properties, as a nested object inside the variant.
        //
        //     :hover
        //     :focus
        //     ::placeholder
        //     ::selection
        //     :-webkit-autofill
        //     ::-ms-clear, available for the cardNumber, cardExpiry, and cardCvc Elements. Inside the ::-ms-clear selector, the display property can be customized
      },
      invalid: {
        color: styles.errorColor
      }

      // complete, applied when the Element has valid input
      // empty, applied when the Element has no customer input
      // invalid, applied when the Element has invalid input
    };
    const elementClasses = {
      base: styles.stripeElement,
      focus: styles.stripeElementFocus,
      complete: styles.stripeElementComplete,
      invalid: styles.stripeElementInvalid
    };

    return (
      <form onSubmit={this.handleSubmit} class={styles.stripeFormElement}>
        {/*<AddressSection />*/}
        {/* <CardSection /> */}

        {ENV != "production" && (
          <div class={styles.cardNumberInfos}>
            <Text id="payment.creditCard.cardNumberTest">Card number :</Text>{" "}
            <strong>4000 0025 0000 0003</strong> <br />
            <Text id="payment.creditCard.cardDateValidTest">
              Utilisez une date d’expiration valide telle que
            </Text>{" "}
            <strong>12/34</strong>
            <br />
            <Text id="payment.creditCard.cardCvcTest">
              Utilisez n’importe quel code CVC à trois chiffres
            </Text>
          </div>
        )}
        <div class={styles.stripeFormScrollableContent}>
          <div class={styles.row}>
            <div class={styles.inputField}>
              <label>
                <span class={styles.inputLabel}>
                  <Text id="payment.creditCard.cardNumber">Card number</Text>
                </span>
                <CardNumberElement
                  style={elementStyle}
                  classes={elementClasses}
                />
              </label>
            </div>
          </div>
          <div class={styles.row}>
            <div class={styles.inputField}>
              <label>
                <span class={styles.inputLabel}>
                  <Text id="payment.creditCard.expirationDate">
                    Expiration date
                  </Text>
                </span>
                <CardExpiryElement
                  style={elementStyle}
                  classes={elementClasses}
                />
              </label>
            </div>
            <div class={styles.inputField}>
              <label>
                <span class={styles.inputLabel}>
                  <Text id="payment.creditCard.CVC">CVC</Text>
                </span>
                <CardCVCElement style={elementStyle} classes={elementClasses} />
              </label>
            </div>
          </div>
          <div class={styles.row}>
            <div class={styles.inputField}>
              <label>
                <span class={styles.inputLabel}>
                  <Text id="payment.creditCard.cardHolderName.label">
                    Card holders name
                  </Text>
                </span>
                <div>
                  <input
                    class={`${styles.stripeElement} ${styles.inputElement}`}
                    name="cardHolderName"
                    placeholder={cardHolderNamePlaceholder}
                    onChange={this.handleInputChange}
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class={styles.footerButton}>
          <PrimaryButton
            className={styles.payButton}
            // {...buttonProps}
            type="submit"
            disabled={this.state.disablePaymentBtn}
          >
            {loading ? (
              <Loading color="#fff" />
            ) : (
              <Text id="payment.creditCard.pay">Pay</Text>
            )}
          </PrimaryButton>
        </div>
        {!stripeError && (
          <div class={styles.footerExplanation}>
            <p>
              <Text id="payment.creditCard.pleaseWait">Please wait...</Text>
            </p>
            <p>
              <Text id="payment.creditCard.doNotRefresh">Do not refresh</Text>
            </p>
          </div>
        )}
        {stripeError && (
          <StripeError code={stripeError.code} message={stripeError.message} />
        )}
      </form>
    );
  }
}

export default injectStripe(StripePaymentForm);
