import parseProductsForGTM from './parseProductsForGTM';
import { push } from './EasyLoisirsGTMClient';

const sendCheckoutEventOrderGTM = (order, step, options) => {
  let products = parseProductsForGTM(order).products;
  let actionField = { step: step };
  if (options != false) {
    actionField.option = options;
  }
  push({
    event: 'EEcheckout',
    ecommerce: {
      checkout: {
        actionField: actionField,
        products: products
      }
    }
  });
};

export default sendCheckoutEventOrderGTM;
