// @flow
// @jsx h
import { h } from "preact";

const MasterCard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="40"
    width="40"
    viewBox="0 0 66.850815 40"
    id="svg3759"
  >
    <g
      id="Page-1"
      transform="matrix(.6685 0 0 .6685 -126.1 -191.983)"
      fill="none"
    >
      <g id="g3809" transform="matrix(.16667 0 0 .16667 176.128 277.848)">
        <path
          id="Fill-1"
          d="m 434.00833,235.5 c 0,99.14167 -80.37083,179.50417 -179.50833,179.50417 -99.1375,0 -179.500003,-80.3625 -179.500003,-179.50417 0,-99.13333 80.362503,-179.504168 179.500003,-179.504168 99.1375,0 179.50833,80.370838 179.50833,179.504168"
          fill="#d9222a"
        />
        <path
          id="Fill-2"
          d="m 495.49167,55.995832 c -46.37917,0 -88.64167,17.595834 -120.5,46.466668 -6.4875,5.88333 -12.54584,12.2375 -18.125,18.99583 h 36.26666 c 4.95834,6.02917 9.525,12.37084 13.68334,19.0125 h -63.63334 c -3.8125,6.10417 -7.275,12.44584 -10.34166,19.00834 h 84.3125 c 2.87916,6.15833 5.42083,12.49583 7.6,19.00416 h -99.5125 c -2.075,6.19167 -3.82084,12.52917 -5.21667,19.00834 h 109.94167 c 2.6375,12.25 4.04166,24.96666 4.04166,38.00833 0,19.93333 -3.25416,39.1125 -9.25416,57.02083 h -99.5125 c 2.175,6.5125 4.71666,12.85417 7.59583,19.00834 h 84.31667 c -3.075,6.5625 -6.52917,12.90416 -10.34584,19.0125 h -63.625 c 4.15417,6.62916 8.72917,12.97916 13.68334,18.99583 H 393.125 c -5.57083,6.77083 -11.63333,13.13333 -18.12917,19.0125 31.85834,28.86667 74.11667,46.45417 120.49584,46.45417 C 594.62917,415.00417 675,334.64167 675,235.5 675,136.37083 594.62917,55.995832 495.49167,55.995832"
          fill="#ee9f2d"
        />
        <path
          id="Fill-4"
          d="m 212.5875,255.15417 c -2.04584,-0.2375 -2.94584,-0.3 -4.35,-0.3 -11.04584,0 -16.6375,3.7875 -16.6375,11.26666 0,4.6125 2.72916,7.54584 6.9875,7.54584 7.9375,0 13.65833,-7.55834 14,-18.5125 z M 226.75833,288.15 H 210.6125 l 0.37083,-7.675 c -4.925,6.06667 -11.49583,8.95 -20.425,8.95 -10.5625,0 -17.80417,-8.25 -17.80417,-20.22917 0,-18.025 12.59584,-28.54166 34.21667,-28.54166 2.20833,0 5.04167,0.2 7.94167,0.57083 0.60416,-2.44167 0.7625,-3.4875 0.7625,-4.8 0,-4.90833 -3.39584,-6.7375 -12.5,-6.7375 -9.53334,-0.10833 -17.39584,2.27083 -20.625,3.33333 0.20416,-1.22916 2.7,-16.65833 2.7,-16.65833 9.7125,-2.84583 16.11666,-3.91667 23.325,-3.91667 16.73333,0 25.59583,7.5125 25.57916,21.7125 0.0333,3.80417 -0.59583,8.5 -1.57916,14.67084 -1.69167,10.72916 -5.32084,33.71666 -5.81667,39.32083 z"
          fill="#000"
        />
        <path
          id="Fill-5"
          d="M 164.6,288.15 H 145.1125 L 156.275,218.15417 131.35,288.15 H 118.07083 L 116.42916,218.55417 104.69583,288.15 H 86.454164 l 15.237496,-91.05417 h 28.02084 l 1.7,50.96667 17.09166,-50.96667 h 31.16667 L 164.6,288.15"
          fill="#000"
        />
        <path
          id="Fill-6"
          d="m 519.575,255.15417 c -2.0375,-0.2375 -2.94167,-0.3 -4.34167,-0.3 -11.04166,0 -16.63333,3.7875 -16.63333,11.26666 0,4.6125 2.725,7.54584 6.98333,7.54584 7.9375,0 13.6625,-7.55834 13.99167,-18.5125 z M 533.75833,288.15 H 517.6125 l 0.36667,-7.675 c -4.925,6.06667 -11.5,8.95 -20.42084,8.95 -10.56666,0 -17.8,-8.25 -17.8,-20.22917 0,-18.025 12.5875,-28.54166 34.2125,-28.54166 2.20834,0 5.0375,0.2 7.93334,0.57083 0.60416,-2.44167 0.7625,-3.4875 0.7625,-4.8 0,-4.90833 -3.39167,-6.7375 -12.49584,-6.7375 -9.53333,-0.10833 -17.3875,2.27083 -20.62916,3.33333 0.20416,-1.22916 2.70833,-16.65833 2.70833,-16.65833 9.7125,-2.84583 16.1125,-3.91667 23.3125,-3.91667 16.74167,0 25.60417,7.5125 25.5875,21.7125 0.0333,3.80417 -0.59583,8.5 -1.57917,14.67084 -1.68333,10.72916 -5.32083,33.71666 -5.8125,39.32083 z"
          fill="#000"
        />
        <path
          id="Fill-7"
          d="m 313.36667,287.025 c -5.33334,1.67917 -9.49167,2.4 -14,2.4 -9.9625,0 -15.4,-5.725 -15.4,-16.26667 -0.14167,-3.27083 1.43333,-11.87916 2.67083,-19.7375 1.125,-6.91666 8.45,-50.52916 8.45,-50.52916 h 19.37083 L 312.19583,214.1 h 11.7 l -2.64166,17.79583 H 309.5125 c -2.25,14.08334 -5.45417,31.625 -5.49167,33.95 0,3.81667 2.0375,5.48334 6.67084,5.48334 2.22083,0 3.94166,-0.225 5.25416,-0.7 l -2.57916,16.39583"
          fill="#000"
        />
        <path
          id="Fill-8"
          d="m 372.75833,286.425 c -6.65416,2.03333 -13.075,3.01667 -19.87916,3 -21.68334,-0.0208 -32.9875,-11.34583 -32.9875,-33.03333 0,-25.3125 14.37916,-43.94584 33.9,-43.94584 15.97083,0 26.17083,10.43334 26.17083,26.79584 0,5.42916 -0.7,10.72916 -2.3875,18.2125 H 339 c -1.30417,10.74166 5.57083,15.21666 16.8375,15.21666 6.93333,0 13.1875,-1.42916 20.14167,-4.6625 z m -10.8875,-43.9 c 0.10834,-1.54167 2.05417,-13.21667 -9.0125,-13.21667 -6.17083,0 -10.58333,4.70417 -12.37916,13.21667 z"
          fill="#000"
        />
        <path
          id="Fill-9"
          d="m 238.44583,237.50833 c 0,9.36667 4.54167,15.825 14.84167,20.675 7.89167,3.70834 9.1125,4.80834 9.1125,8.17084 0,4.61666 -3.47917,6.7 -11.19167,6.7 -5.8125,0 -11.22083,-0.90834 -17.45833,-2.92084 0,0 -2.5625,16.32084 -2.67917,17.1 4.42917,0.96667 8.37917,1.8625 20.27917,2.19167 20.5625,0 30.05833,-7.82917 30.05833,-24.75 0,-10.175 -3.975,-16.14583 -13.7375,-20.63333 -8.17083,-3.75 -9.10833,-4.5875 -9.10833,-8.04584 0,-4.00416 3.2375,-6.04583 9.5375,-6.04583 3.825,0 9.05,0.40833 14,1.1125 l 2.775,-17.175 c -5.04583,-0.8 -12.69583,-1.44167 -17.15,-1.44167 -21.8,0 -29.34584,11.3875 -29.27917,25.0625"
          fill="#000"
        />
        <path
          id="Fill-10"
          d="m 467.53333,214.39167 c 5.4125,0 10.45834,1.42083 17.4125,4.92083 l 3.1875,-19.7625 c -2.85416,-1.12083 -12.90416,-7.7 -21.41666,-7.7 -13.04167,0 -24.06667,6.47083 -31.82084,17.15 -11.30833,-3.74583 -15.95833,3.825 -21.65833,11.36667 l -5.0625,1.17916 c 0.38333,-2.48333 0.72917,-4.95 0.6125,-7.44583 h -17.89583 c -2.44584,22.91667 -6.77917,46.12917 -10.17084,69.075 l -0.88333,4.975 h 19.49583 c 3.25417,-21.14167 5.0375,-34.67917 6.12084,-43.84167 l 7.34166,-4.08333 c 1.09584,-4.07917 4.52917,-5.45833 11.41667,-5.29167 -0.9,4.83334 -1.38333,9.91667 -1.38333,15.18334 0,24.225 13.07083,39.30833 34.05,39.30833 5.40416,0 10.04166,-0.7125 17.22083,-2.65833 l 3.42917,-20.75834 c -6.45834,3.17917 -11.75834,4.675 -16.55834,4.675 -11.32916,0 -18.18333,-8.3625 -18.18333,-22.18333 0,-20.05 10.19583,-34.10833 24.74583,-34.10833"
          fill="#000"
        />
        <path
          id="Fill-12"
          d="m 170.20833,282.74167 h -19.49167 l 11.17084,-69.9875 -24.925,69.9875 h -13.28334 l -1.64166,-69.5875 -11.73334,69.5875 H 92.062498 L 107.3,191.7 h 28.02083 l 0.7875,56.3625 L 155.0125,191.7 h 30.26666 l -15.07083,91.04167"
          fill="#fff"
        />
        <path
          id="Fill-11"
          d="M 632.52084,197.09583 628.2,223.40417 c -5.32916,-7.0125 -11.05416,-12.0875 -18.6125,-12.0875 -9.83333,0 -18.78333,7.45416 -24.64167,18.425 C 576.7875,228.05 568.35,225.17917 568.35,225.17917 l -0.004,0.0667 c 0.65834,-6.13333 0.92084,-9.875 0.86251,-11.14583 H 551.3085 c -2.4375,22.91667 -6.77083,46.12917 -10.15833,69.075 l -0.89167,4.975 H 559.75 c 2.63333,-17.09583 4.65,-31.29167 6.13333,-42.55 6.65834,-6.01667 9.99167,-11.26667 16.72084,-10.91667 -2.97917,7.20417 -4.725,15.50417 -4.725,24.01667 0,18.5125 9.36667,30.725 23.53333,30.725 7.14167,0 12.62083,-2.4625 17.96667,-8.17083 l -0.9125,6.88333 H 636.9 l 14.84167,-91.04167 H 632.52084 Z M 608.15,271.0375 c -6.63333,0 -9.98333,-4.90833 -9.98333,-14.59583 0,-14.55417 6.27083,-24.875 15.1125,-24.875 6.69583,0 10.32083,5.10416 10.32083,14.50833 0,14.67917 -6.37083,24.9625 -15.45,24.9625 z"
          fill="#000"
        />
        <path
          id="Fill-13"
          d="m 218.19166,249.75833 c -2.04166,-0.2375 -2.94583,-0.3 -4.34583,-0.3 -11.04583,0 -16.63333,3.7875 -16.63333,11.26667 0,4.60417 2.72916,7.54583 6.97916,7.54583 7.94584,0 13.66667,-7.55833 14,-18.5125 z m 14.17917,32.98334 H 216.225 l 0.36666,-7.6625 c -4.92083,6.05416 -11.5,8.95 -20.42083,8.95 -10.56667,0 -17.80417,-8.25 -17.80417,-20.22917 0,-18.03333 12.59167,-28.54167 34.21667,-28.54167 2.20833,0 5.04167,0.2 7.9375,0.57084 0.60417,-2.44167 0.7625,-3.4875 0.7625,-4.80834 0,-4.90833 -3.39167,-6.72916 -12.49583,-6.72916 -9.5375,-0.10834 -17.39584,2.27083 -20.62917,3.32083 0.20417,-1.225 2.7,-16.6375 2.7,-16.6375 9.70833,-2.85833 16.12083,-3.92917 23.32083,-3.92917 16.7375,0 25.60417,7.51667 25.5875,21.70417 0.0292,3.82083 -0.60416,8.5125 -1.58333,14.675 -1.6875,10.725 -5.32083,33.725 -5.8125,39.31667 z"
          fill="#fff"
        />
        <path
          id="Fill-14"
          d="m 493.74583,194.15 -3.19166,19.76667 c -6.95,-3.49584 -12,-4.92084 -17.40834,-4.92084 -14.55,0 -24.75,14.05834 -24.75,34.10834 0,13.82083 6.85834,22.17916 18.18334,22.17916 4.8,0 10.09583,-1.49166 16.55416,-4.675 l -3.42083,20.75 c -7.18333,1.95834 -11.81667,2.67084 -17.225,2.67084 -20.975,0 -34.05,-15.08334 -34.05,-39.30834 0,-32.55 18.05833,-55.3 43.8875,-55.3 8.50833,0 18.5625,3.60834 21.42083,4.72917"
          fill="#fff"
        />
        <path
          id="Fill-15"
          d="m 525.1875,249.75833 c -2.04167,-0.2375 -2.94167,-0.3 -4.34583,-0.3 -11.04167,0 -16.63334,3.7875 -16.63334,11.26667 0,4.60417 2.72917,7.54583 6.98334,7.54583 7.9375,0 13.6625,-7.55833 13.99583,-18.5125 z m 14.17917,32.98334 h -16.15 l 0.37083,-7.6625 c -4.925,6.05416 -11.5,8.95 -20.42083,8.95 -10.5625,0 -17.80417,-8.25 -17.80417,-20.22917 0,-18.03333 12.59583,-28.54167 34.2125,-28.54167 2.2125,0 5.04167,0.2 7.94167,0.57084 0.6,-2.44167 0.7625,-3.4875 0.7625,-4.80834 0,-4.90833 -3.39167,-6.72916 -12.49584,-6.72916 -9.53333,-0.10834 -17.39583,2.27083 -20.62916,3.32083 0.20416,-1.225 2.70416,-16.6375 2.70416,-16.6375 9.70834,-2.85833 16.11667,-3.92917 23.31667,-3.92917 16.74167,0 25.60417,7.51667 25.58333,21.70417 0.0333,3.82083 -0.59583,8.5125 -1.57916,14.675 -1.68334,10.725 -5.325,33.725 -5.8125,39.31667 z"
          fill="#fff"
        />
        <path
          id="Fill-16"
          d="m 318.975,281.62083 c -5.3375,1.67917 -9.49583,2.40834 -14,2.40834 -9.9625,0 -15.4,-5.725 -15.4,-16.26667 -0.1375,-3.27917 1.4375,-11.87917 2.675,-19.7375 1.12083,-6.925 8.44583,-50.53333 8.44583,-50.53333 h 19.36667 l -2.25833,11.2125 h 9.94166 L 325.1,226.49167 h -9.975 c -2.25,14.09166 -5.4625,31.62083 -5.49583,33.95 0,3.82916 2.04166,5.48333 6.67083,5.48333 2.22083,0 3.9375,-0.21667 5.25417,-0.69167 l -2.57917,16.3875"
          fill="#fff"
        />
        <path
          id="Fill-17"
          d="m 378.36667,281.02917 c -6.65,2.03333 -13.07917,3.0125 -19.87917,3 -21.68333,-0.0208 -32.9875,-11.34584 -32.9875,-33.03334 0,-25.32083 14.37917,-43.95 33.9,-43.95 15.97083,0 26.17083,10.42917 26.17083,26.8 0,5.43334 -0.7,10.73334 -2.38333,18.2125 h -38.575 c -1.30417,10.74167 5.57083,15.22084 16.8375,15.22084 6.92917,0 13.1875,-1.43334 20.1375,-4.675 z M 367.475,237.11667 c 0.11667,-1.5375 2.05833,-13.21667 -9.0125,-13.21667 -6.16667,0 -10.57917,4.71667 -12.375,13.21667 z"
          fill="#fff"
        />
        <path
          id="Fill-18"
          d="m 244.05416,232.1125 c 0,9.36667 4.54167,15.81667 14.84167,20.675 7.89167,3.70833 9.1125,4.8125 9.1125,8.17083 0,4.61667 -3.48333,6.7 -11.1875,6.7 -5.81667,0 -11.225,-0.90833 -17.46667,-2.92083 0,0 -2.55416,16.32083 -2.67083,17.1 4.42083,0.96667 8.375,1.85 20.275,2.19167 20.56667,0 30.05834,-7.82917 30.05834,-24.74584 0,-10.17916 -3.97084,-16.15 -13.7375,-20.6375 -8.16667,-3.75833 -9.1125,-4.58333 -9.1125,-8.04583 0,-4 3.24583,-6.05833 9.54166,-6.05833 3.82084,0 9.04584,0.42083 14.00417,1.125 l 2.77083,-17.17917 c -5.04166,-0.8 -12.69166,-1.44167 -17.14583,-1.44167 -21.80417,0 -29.34584,11.37917 -29.28334,25.06667"
          fill="#fff"
        />
        <path
          id="Fill-19"
          d="m 642.50833,282.74167 h -18.4375 L 624.9875,275.85 c -5.34583,5.71667 -10.825,8.17917 -17.96666,8.17917 -14.16667,0 -23.52917,-12.2125 -23.52917,-30.725 0,-24.62917 14.52083,-45.39167 31.70833,-45.39167 7.55833,0 13.27917,3.0875 18.60417,10.09583 L 638.12917,191.7 H 657.35 Z M 613.7625,265.63333 c 9.075,0 15.45,-10.28333 15.45,-24.95416 0,-9.40417 -3.62916,-14.50834 -10.325,-14.50834 -8.8375,0 -15.11666,10.31667 -15.11666,24.875 0,9.6875 3.35833,14.5875 9.99166,14.5875 z"
          fill="#fff"
        />
        <path
          id="Fill-20"
          d="m 556.92083,208.70417 c -2.44166,22.91666 -6.775,46.12916 -10.1625,69.0625 l -0.89166,4.975 h 19.49166 c 6.97084,-45.275 8.65834,-54.11667 19.5875,-53.00834 1.74167,-9.26666 4.98334,-17.38333 7.4,-21.47916 -8.16249,-1.7 -12.72083,2.9125 -18.6875,11.675 0.47084,-3.7875 1.33334,-7.46667 1.1625,-11.225 h -17.9"
          fill="#fff"
        />
        <path
          id="Fill-21"
          d="m 396.5,208.70417 c -2.44583,22.91666 -6.77917,46.12916 -10.16667,69.0625 l -0.8875,4.975 h 19.5 c 6.9625,-45.275 8.64584,-54.11667 19.57084,-53.00834 1.75,-9.26666 4.99166,-17.38333 7.4,-21.47916 -8.15417,-1.7 -12.71667,2.9125 -18.67917,11.675 0.47083,-3.7875 1.325,-7.46667 1.1625,-11.225 h -17.9"
          fill="#fff"
        />
        <path
          id="Fill-22"
          d="m 651.06667,276.94583 c 0,-3.2125 2.59583,-5.8 5.79583,-5.8 3.20417,0 5.79583,2.5875 5.79583,5.8 0,3.19584 -2.59166,5.79584 -5.79583,5.79584 -3.2,0 -5.79583,-2.6 -5.79583,-5.79584 z m 5.79583,4.40417 c 2.4375,0 4.40417,-1.975 4.40417,-4.40417 0,-2.43333 -1.96667,-4.40833 -4.40417,-4.40833 -2.43333,0 -4.40833,1.975 -4.40833,4.40833 0,2.42917 1.975,4.40417 4.40833,4.40417 z m -0.78333,-1.87083 h -1.1875 v -5.08334 h 2.15416 c 0.44584,0 0.90834,0.008 1.29584,0.25417 0.41666,0.28333 0.65417,0.76667 0.65417,1.275 0,0.575 -0.33751,1.1125 -0.88751,1.31667 l 0.94167,2.2375 h -1.32083 L 656.95,277.47083 h -0.87083 z m 0,-2.87917 h 0.65416 c 0.24584,0 0.51251,0.0167 0.72917,-0.1 0.19583,-0.125 0.29583,-0.3625 0.29583,-0.5875 0,-0.1875 -0.11666,-0.4125 -0.2875,-0.525 -0.20416,-0.11667 -0.54166,-0.0833 -0.76249,-0.0833 h -0.62917 z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
export default MasterCard;
