// @flow
// @jsx h
import { h } from "preact";
import { Link } from "preact-router/match";
import styles from "./style.scss";

const CustomLink = ({ href, style, children, className }) => (
  <Link href={href} class={`${styles.button} ${className}`} style={style}>
    {children}
  </Link>
);
export default CustomLink;
