// @flow
// @jsx h
import { h, Component } from "preact";
import { withText, Text } from "preact-i18n";

import { Button } from "elc-button";
import { RemoveIcon, GeolocationIcon } from "elc-icons";

import styles from "./style.scss";

import { sendUXEventGTM, isSafari, isEdge } from "elc-commons";

type SearchFilterProps = {
  currentSearch: string,
  setSearch: Function
};
@withText({
  addressPlaceholder: "products.filters.address.placeholder"
})
export default class SearchFilter extends Component<SearchFilterProps> {
  constructor(props) {
    super(props);
    this.state = {
      hasValue: false,
      geolocation: {
        latitude: null,
        longitude: null,
        city: null
      }
    };
  }

  componentDidMount() {
    if (!isSafari && !isEdge) {
      window.navigator.permissions
        .query({ name: "geolocation" })
        .then(() => this.handleGeolocation())
        .catch(err => console.error(err));
    }
  }

  handleGeolocation = () => {
    const { setSearch } = this.props;
    // get current city name from coordinates / getCurrentPosition(success, error)
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyC6l_r6h831FL9dA7LCeE_t27W2XxA3_qc`
        )
          .then(res => res.json())
          .then(({ results }) => {
            const city = results[0].address_components[2].short_name;
            this.setState({
              geolocation: {
                latitude,
                longitude,
                city
              },
              hasValue: true
            });
            setSearch(this.state.geolocation);
            return;
          })
          .catch(err => console.error(err));
      },
      err => {
        // User refused geolocation
        console.info(err);
      }
    );
  };

  handleChange = event => {
    event.preventDefault();

    const { value } = event.target;
    const geolocation = { ...this.state.geolocation };

    geolocation.city = value;

    this.setState({ hasValue: !!value.length });

    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${value}&key=AIzaSyC6l_r6h831FL9dA7LCeE_t27W2XxA3_qc`
    )
      .then(res => res.json())
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        geolocation.latitude = lat;
        geolocation.longitude = lng;
        this.props.setSearch(geolocation);
        this.setState({ geolocation });
        return;
      })
      .catch(err => console.error(err));
  };

  handleSubmit = event => {
    event.preventDefault();
    const { value } = event.target[0];
    const geolocation = { ...this.state.geolocation };

    geolocation.city = value;

    this.setState({ hasValue: !!value.length });

    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${value}&key=AIzaSyC6l_r6h831FL9dA7LCeE_t27W2XxA3_qc`
    )
      .then(res => res.json())
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        geolocation.latitude = lat;
        geolocation.longitude = lng;
        this.props.setSearch(geolocation);
        this.setState({ geolocation });
        return;
      })
      .catch(err => console.error(err));

    sendUXEventGTM({
      event: "siteSearch",
      "search-term": this.state.geolocation
    });
  };

  clearInput = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const geolocation = {
      latitude: null,
      longitude: null,
      city: null
    };
    this.setState({ geolocation, hasValue: false });
    this.props.setSearch(geolocation);
  };

  render({ addressPlaceholder }) {
    return (
      <div class={styles.searchFilterContainer}>
        <label class={styles.filterLabel}>
          <Text id="products.filters.address.label">Adresse</Text>
        </label>
        <div class={styles.searchFilter}>
          <form onSubmit={this.handleSubmit}>
            <input
              class={styles.searchInput}
              value={this.state.geolocation.city}
              onChange={this.handleChange}
              type="text"
              placeholder={addressPlaceholder}
            />
            {this.state.hasValue && (
              <Button
                handleClick={this.clearInput}
                className={`${styles.actionButton} ${styles.clearButton}`}
              >
                <RemoveIcon color={styles.grey} />
              </Button>
            )}
            <Button
              id="geolocation"
              handleClick={this.handleGeolocation}
              className={`${styles.actionButton}`}
            >
              <GeolocationIcon
                color={
                  this.context.themeConfig && this.context.themeConfig.color1
                    ? this.context.themeConfig.color1
                    : styles.color
                }
              />
            </Button>
          </form>
        </div>
      </div>
    );
  }
}
