// @flow
// @jsx h
import { h } from "preact";

import Tabs from "./Tabs";
import QuantitiesTab from "./tab/QuantitiesTab";
import DayTab from "./tab/DayTab";
import SlotTab from "./tab/SlotTab";
import ExtrasTab from "./tab/ExtrasTab";
import TicketHoldersTab from "./tab/TicketHoldersTab";

const SessionHeader = ({
  theme,
  route,
  currentProduct,
  currentQuantities,
  currentDay,
  currentSlot,
  goToDay,
  goToSlot,
  goToQuantities
}) => (
  <Tabs>
    {currentProduct.scheduleType !== "OPEN_BILLET_WITHOUT_DATE" && (
      <DayTab
        theme={theme}
        active={route === "day"}
        currentDay={currentDay}
        handleClick={goToDay}
      />
    )}
    {currentProduct.scheduleType !== "OPEN_BILLET" &&
      currentProduct.scheduleType !== "OPEN_BILLET_WITHOUT_DATE" && (
        <SlotTab
          theme={theme}
          active={route === "slot"}
          currentSlot={currentSlot}
          handleClick={route !== "day" && goToSlot}
        />
      )}
    <QuantitiesTab
      theme={theme}
      active={route === "quantities"}
      currentQuantities={currentQuantities}
      goToQuantities={route !== "day" && route !== "slot" && goToQuantities}
    />
    {currentProduct &&
      currentProduct._embedded.extraProducts &&
      currentProduct._embedded.extraProducts.length > 0 && (
        <ExtrasTab theme={theme} active={route === "extras"} />
      )}
    {currentProduct.type === "FRANCE_BILLET" &&
      currentProduct.publicMetadata.franceBillet.isTicketHolderMandatory ===
        true && (
        <TicketHoldersTab theme={theme} active={route === "ticketHolders"} />
      )}
  </Tabs>
);
export default SessionHeader;
