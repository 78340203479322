// @flow
import { h } from "preact";

const VirementBancaire = () => (
  <svg
    width="33.674"
    height="39.999"
    viewBox="0 0 33.674 10.583"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#ff961e" transform="translate(9.991 -2.479) scale(.37005)">
      <path
        id="XMLID_310_"
        className="st0"
        d="m-13.9 7.3-4.7 12.9h-3.7l-4.7-12.9h3.5l3.1 9.1 3.1-9.1h3.4z"
      />
      <path
        id="XMLID_307_"
        className="st0"
        d="m-9 9.1h-3.3v-2.4h3.3zm0 11.1h-3.1v-9.7h3.1z"
      />
      <path
        id="XMLID_305_"
        className="st0"
        d="m1 13.4h-0.3c-0.1 0-0.3-0.1-0.6-0.1h-0.7c-0.4 0-0.8 0-1.2 0.1-0.3 0.1-0.7 0.2-1.1 0.3v6.5h-3.1v-9.7h3v1.4c0.7-0.6 1.3-1 1.8-1.2s1-0.3 1.4-0.3h0.4 0.4v3z"
      />
      <path
        id="XMLID_302_"
        className="st0"
        d="m12.3 15.9h-7.1c0 0.8 0.3 1.3 0.9 1.8 0.5 0.4 1.3 0.6 2.4 0.6 0.7 0 1.3-0.1 1.9-0.4s1.1-0.5 1.5-0.8h0.3v2.5c-0.7 0.3-1.4 0.5-2 0.6s-1.3 0.2-2.1 0.2c-2 0-3.5-0.4-4.5-1.3s-1.6-2.1-1.6-3.8c0-1.6 0.5-2.9 1.5-3.8s2.3-1.4 4.1-1.4c1.6 0 2.8 0.4 3.6 1.2s1.2 2 1.2 3.5v1.1h-0.1zm-3.1-1.8c0-0.7-0.2-1.1-0.5-1.5-0.3-0.3-0.8-0.5-1.4-0.5s-1.1 0.2-1.5 0.5-0.6 0.8-0.7 1.5z"
      />
      <path
        id="XMLID_300_"
        className="st0"
        d="m24 13.3v6.9h-3.1v-4.9-1.2c0-0.3-0.1-0.6-0.2-0.8s-0.3-0.4-0.5-0.5-0.5-0.1-0.9-0.1c-0.3 0-0.6 0.1-0.9 0.2s-0.5 0.3-0.8 0.4v6.9h-3.1v-9.7h3.1v1.1c0.5-0.4 1.1-0.8 1.5-1 0.5-0.2 1-0.4 1.6-0.4s1.2 0.1 1.7 0.4 0.9 0.7 1.2 1.3c0.6-0.5 1.2-0.9 1.8-1.2s1.2-0.5 1.7-0.5c1.1 0 1.8 0.3 2.4 0.9 0.5 0.6 0.8 1.5 0.8 2.7v6.3h-3.1v-4.9-1.2c0-0.3-0.1-0.6-0.2-0.8s-0.3-0.4-0.5-0.5-0.5-0.1-0.9-0.1c-0.3 0-0.5 0-0.8 0.1-0.1 0.3-0.4 0.4-0.8 0.6z"
      />
      <path
        id="XMLID_233_"
        className="st0"
        d="m42.9 15.9h-7.1c0 0.8 0.3 1.3 0.9 1.8 0.5 0.4 1.3 0.6 2.4 0.6 0.7 0 1.3-0.1 1.9-0.4 0.6-0.2 1.1-0.5 1.5-0.8h0.3v2.5c-0.7 0.3-1.4 0.5-2 0.6s-1.3 0.2-2.1 0.2c-2 0-3.5-0.4-4.5-1.3s-1.6-2.1-1.6-3.8c0-1.6 0.5-2.9 1.5-3.8s2.3-1.4 4.1-1.4c1.6 0 2.8 0.4 3.6 1.2s1.2 2 1.2 3.5v1.1zm-3.1-1.8c0-0.7-0.2-1.1-0.5-1.5-0.3-0.3-0.8-0.5-1.4-0.5s-1.1 0.2-1.5 0.5-0.6 0.8-0.7 1.5z"
      />
      <path
        id="XMLID_231_"
        className="st0"
        d="m54.9 20.2h-3.1v-4.8c0-0.4 0-0.8-0.1-1.2 0-0.4-0.1-0.7-0.2-0.9s-0.3-0.4-0.5-0.5-0.5-0.1-0.9-0.1c-0.3 0-0.6 0-0.9 0.1s-0.6 0.2-0.9 0.4v6.9h-3.1v-9.7h3.1v1.1c0.6-0.4 1.1-0.8 1.6-1s1.1-0.3 1.7-0.3c1.1 0 1.9 0.3 2.5 0.9s0.9 1.5 0.9 2.7z"
      />
      <path
        id="XMLID_229_"
        className="st0"
        d="m64 20.1c-0.3 0.1-0.7 0.2-1.1 0.2s-0.8 0.1-1.4 0.1c-1.2 0-2.1-0.2-2.7-0.7s-0.9-1.3-0.9-2.5v-4.6h-1.3v-2.1h1.4v-2.8h3.1v2.8h2.9v2.1h-2.9v3.4 0.9c0 0.3 0.1 0.5 0.1 0.7 0.1 0.2 0.2 0.4 0.4 0.5s0.5 0.2 0.9 0.2c0.2 0 0.4 0 0.6-0.1 0.3-0.1 0.4-0.1 0.6-0.2h0.3z"
      />
    </g>
    <g fill="#003c64" transform="translate(9.991 -2.479) scale(.37005)">
      <path
        id="XMLID_225_"
        className="st1"
        d="m-11.9 25c0 0.8-0.2 1.4-0.6 1.9s-1.1 0.9-2 1.2v0.1c0.7 0.2 1.2 0.5 1.6 0.9s0.5 0.9 0.5 1.6c0 1.4-0.6 2.4-1.7 3.2s-2.7 1.1-4.7 1.1h-5.6l3-12.9h4.7c1.6 0 2.7 0.3 3.6 0.8 0.8 0.5 1.2 1.2 1.2 2.1zm-3.9 5.8c0-0.5-0.2-0.8-0.5-1-0.4-0.2-0.9-0.3-1.7-0.3h-1.8l-0.7 3.2h1.7c1 0 1.7-0.2 2.2-0.5s0.8-0.8 0.8-1.4zm0.3-5.2c0-0.4-0.2-0.7-0.5-0.8-0.3-0.2-0.7-0.3-1.2-0.3h-1.5l-0.6 2.7h1.5c0.7 0 1.3-0.1 1.7-0.4s0.6-0.7 0.6-1.2z"
      />
      <path
        id="XMLID_222_"
        className="st1"
        d="m-4.9 34c-0.2 0.1-0.4 0.3-0.7 0.5s-0.5 0.3-0.8 0.4-0.6 0.2-0.9 0.3-0.7 0.1-1.2 0.1c-0.8 0-1.5-0.2-2-0.7s-0.8-1-0.8-1.8 0.2-1.4 0.5-2c0.4-0.5 0.9-1 1.6-1.3s1.5-0.5 2.4-0.7 1.9-0.2 3-0.3v-0.2-0.3c0-0.4-0.2-0.7-0.6-0.9s-1-0.2-1.7-0.2c-0.5 0-1.1 0.1-1.7 0.3s-1.1 0.3-1.4 0.4h-0.3l0.5-2.3c0.4-0.1 0.9-0.2 1.7-0.3s1.5-0.2 2.3-0.2c1.5 0 2.6 0.2 3.4 0.6 0.7 0.4 1.1 1 1.1 1.9v0.5c0 0.2 0 0.3-0.1 0.5l-1.5 6.7h-3.1zm0.8-3.6c-0.6 0-1.1 0.1-1.6 0.2s-0.9 0.2-1.2 0.3c-0.4 0.1-0.6 0.3-0.8 0.5s-0.3 0.5-0.3 0.9c0 0.3 0.1 0.6 0.4 0.7 0.2 0.1 0.6 0.2 1.1 0.2 0.3 0 0.6-0.1 1-0.2s0.7-0.3 1-0.5z"
      />
      <path
        id="XMLID_220_"
        className="st1"
        d="m12.2 27.5v0.6c0 0.2-0.1 0.4-0.1 0.6l-1.5 6.3h-3.2l1.1-4.8c0.1-0.4 0.1-0.7 0.2-0.9s0.1-0.5 0.1-0.7c0-0.4-0.1-0.7-0.3-0.8-0.2-0.2-0.5-0.3-1.1-0.3-0.2 0-0.5 0.1-0.9 0.2-0.3 0.1-0.7 0.3-1.1 0.5l-1.5 6.8h-3.1l2.3-9.7h3.1l-0.3 1.1c0.6-0.4 1.2-0.8 1.8-1 0.5-0.2 1.1-0.4 1.8-0.4 0.9 0 1.6 0.2 2 0.6s0.7 1.1 0.7 1.9z"
      />
      <path
        id="XMLID_218_"
        className="st1"
        d="m18.5 35.3c-0.7 0-1.3-0.1-1.9-0.2-0.6-0.2-1.1-0.4-1.5-0.8-0.4-0.3-0.8-0.8-1-1.3s-0.4-1.1-0.4-1.8c0-0.9 0.2-1.7 0.5-2.4s0.8-1.4 1.4-2c0.6-0.5 1.3-1 2.2-1.3 0.8-0.3 1.8-0.5 2.8-0.5 0.6 0 1.2 0.1 1.8 0.2s1.1 0.3 1.6 0.6l-0.7 2.7h-0.4c-0.3-0.4-0.7-0.7-1.2-0.9-0.4-0.2-0.9-0.3-1.5-0.3-1 0-1.8 0.3-2.4 1-0.5 0.7-0.8 1.5-0.8 2.6 0 0.7 0.2 1.3 0.6 1.6s0.9 0.5 1.7 0.5c0.6 0 1.1-0.1 1.6-0.4 0.5-0.2 0.9-0.5 1.3-0.8h0.4l-0.6 2.7c-0.6 0.3-1.2 0.5-1.6 0.6-0.6 0.1-1.2 0.2-1.9 0.2z"
      />
      <path
        id="XMLID_215_"
        className="st1"
        d="m30.1 34c-0.2 0.1-0.4 0.3-0.7 0.5s-0.5 0.3-0.8 0.4-0.6 0.2-0.9 0.3-0.7 0.1-1.2 0.1c-0.8 0-1.5-0.2-2-0.7s-0.8-1-0.8-1.8 0.2-1.4 0.5-2c0.4-0.5 0.9-1 1.6-1.3s1.5-0.5 2.4-0.7c0.9-0.1 1.9-0.2 3-0.3v-0.2-0.3c0-0.4-0.2-0.7-0.6-0.9s-1-0.2-1.7-0.2c-0.5 0-1.1 0.1-1.7 0.3s-1.1 0.3-1.4 0.4h-0.3l0.5-2.3c0.4-0.1 0.9-0.2 1.7-0.3s1.5-0.2 2.3-0.2c1.5 0 2.6 0.2 3.4 0.6 0.7 0.4 1.1 1 1.1 1.9v0.5c0 0.2 0 0.3-0.1 0.5l-1.5 6.7h-3.1zm0.8-3.6c-0.6 0-1.1 0.1-1.6 0.2s-0.9 0.2-1.2 0.3c-0.4 0.1-0.6 0.3-0.8 0.5s-0.3 0.5-0.3 0.9c0 0.3 0.1 0.6 0.4 0.7 0.2 0.1 0.6 0.2 1.1 0.2 0.3 0 0.6-0.1 1-0.2s0.7-0.3 1-0.5z"
      />
      <path
        id="XMLID_212_"
        className="st1"
        d="m41.2 25.3-2.3 9.7h-3.1l2.3-9.7zm0.9-3.8-0.5 2.4h-3.3l0.5-2.4z"
      />
      <path
        id="XMLID_210_"
        className="st1"
        d="m50.5 28.2h-0.3c-0.1 0-0.3-0.1-0.6-0.1h-0.8c-0.4 0-0.8 0-1.2 0.1l-1.2 0.3-1.4 6.5h-3.1l2.3-9.7h3.1l-0.3 1.4c0.6-0.4 1.2-0.8 1.8-1.1s1.2-0.4 1.8-0.4h0.4 0.4z"
      />
      <path
        id="XMLID_207_"
        className="st1"
        d="m56.2 35.3c-1.7 0-2.9-0.4-3.8-1.1s-1.4-1.8-1.4-3c0-0.9 0.1-1.7 0.4-2.4s0.7-1.4 1.3-2c0.5-0.5 1.2-1 2-1.3s1.7-0.5 2.6-0.5c1.5 0 2.5 0.3 3.3 0.9 0.7 0.6 1.1 1.5 1.1 2.6 0 0.4 0 0.8-0.1 1.2s-0.2 0.7-0.3 1.1h-7.3v0.2c0 0.7 0.2 1.2 0.7 1.6s1.2 0.6 2.2 0.6c0.7 0 1.3-0.1 1.9-0.3s1.1-0.5 1.6-0.8h0.3l-0.6 2.5c-0.6 0.3-1.2 0.5-1.9 0.6-0.6 0-1.3 0.1-2 0.1zm2.4-6.4v-0.3-0.3c0-0.5-0.1-0.8-0.4-1.1-0.3-0.2-0.7-0.4-1.2-0.4-0.6 0-1.2 0.2-1.6 0.5s-0.8 0.8-1 1.5z"
      />
    </g>
  </svg>
);
export default VirementBancaire;
