// @flow
// @jsx h

import { h, Component } from "preact";

import styles from "./style.scss";

import { Text } from "preact-i18n";
import Quantity from "../quantity";
// import DegressivePrices from '../degressive-prices';
import Privatize from "../privatize";

import getUnitsRemainingCapacity from "./getUnitsRemainingCapacity";

type UnitsQuantitiesProps = {
  tax: Tax,
  locale: locale,
  currency: Currency,
  quantities: Map<number, number>,
  units: Array<Unit>,
  minParticipants: number,
  maxParticipants: number,
  canBePrivatized: boolean,
  minParticipantsToPrivatize?: number,
  updateCurrentQuantities: Function
};

type UnitsQuantitiesState = {
  total: number,
  maxReached: boolean,
  isPrivatized: boolean
};

/**
 * Unitary (Unitaire) or Fixed (Forfait) prices (tarifs) product units quantities
 */
export default class UnitsQuantities extends Component<
  UnitsQuantitiesProps,
  UnitsQuantitiesState
> {
  // state = {
  //   total: 0,
  //   maxReached: false,
  //   isPrivatized: false
  // };

  constructor(props: UnitsQuantitiesProps) {
    super(props);
    const total = Array.from(props.quantities).reduce(
      (acc, quantity) => acc + quantity[1],
      0
    );
    const maxReached = this.isMaxReached(total);
    const isPrivatized = props.isPrivatized ? props.isPrivatized : false;
    this.state = {
      total,
      maxReached,
      isPrivatized,
      unitsRemainingCapacity: getUnitsRemainingCapacity(
        props.units,
        props.product._embedded.productUnitResources,
        props.quantities,
        props.product.materialCombined
      )
    };
  }

  /**
   * Increment selected quantity of a given unit
   * @param {Unit} unit - the unit on which you want to increment to the quantity
   */
  incrementUnitQuantity = (unitToIncrement: Unit) => {
    let quantities = new Map(this.props.quantities);
    let quantitytoaddtotal =
      !unitToIncrement.prices[0].targetUnitQuantityMode ||
      unitToIncrement.prices[0].targetUnitQuantityMode !== 1
        ? 1
        : unitToIncrement.prices[0].minTargetUnitQuantity ==
          unitToIncrement.prices[0].maxTargetUnitQuantity
        ? unitToIncrement.prices[0].minTargetUnitQuantity
        : (quantities.get(unitToIncrement.id) || 0) >=
          unitToIncrement.prices[0].minTargetUnitQuantity
        ? 1
        : unitToIncrement.prices[0].minTargetUnitQuantity;

    quantities.set(
      unitToIncrement.id,
      (quantities.get(unitToIncrement.id) || 0) + quantitytoaddtotal
    );

    this.setState(
      prevState => ({
        total: prevState.total + quantitytoaddtotal,
        maxReached: this.isMaxReached(prevState.total + quantitytoaddtotal),
        unitsRemainingCapacity: getUnitsRemainingCapacity(
          this.props.units,
          this.props.product._embedded.productUnitResources,
          quantities,
          this.props.product.materialCombined
        )
      }),
      () => {
        this.props.updateCurrentQuantities(quantities);
      }
    );
  };

  /**
   * Decrement quantity of given unit
   * @param {Object} unit - the unit on which you want to decrement to the quantity
   */
  decrementUnitQuantity = (unit: Unit) => {
    let quantities = new Map(this.props.quantities);
    let quantitytodeletetotal =
      !unit.prices[0].targetUnitQuantityMode ||
      unit.prices[0].targetUnitQuantityMode !== 1
        ? 1
        : unit.prices[0].minTargetUnitQuantity ==
          unit.prices[0].maxTargetUnitQuantity
        ? unit.prices[0].minTargetUnitQuantity
        : (quantities.get(unit.id) || 0) <= unit.prices[0].minTargetUnitQuantity
        ? unit.prices[0].minTargetUnitQuantity
        : 1;

    if (quantities.get(unit.id) === 1) {
      quantities.delete(unit.id);
    } else {
      quantities.set(
        unit.id,
        (quantities.get(unit.id) || 0) - quantitytodeletetotal
      );
    }

    this.setState(
      prevState => ({
        total: prevState.total - quantitytodeletetotal,
        maxReached: this.isMaxReached(prevState.total - quantitytodeletetotal),
        unitsRemainingCapacity: getUnitsRemainingCapacity(
          this.props.units,
          this.props.product._embedded.productUnitResources,
          quantities,
          this.props.product.materialCombined
        )
      }),
      () => {
        this.props.updateCurrentQuantities(quantities);
      }
    );
  };

  isMaxReached(total: number): boolean {
    return (
      this.props.maxParticipants !== 0 && total >= this.props.maxParticipants
    );
  }

  render(
    {
      theme,
      tax,
      units,
      locale,
      minParticipants,
      canBePrivatized,
      minParticipantsToPrivatize,
      currency,
      quantities,
      isPrivatized,
      onChangePrivatized,
      maxParticipants
    },
    { total, maxReached, unitsRemainingCapacity }
  ) {
    return (
      <div class={theme && theme.content ? theme.content : styles.content}>
        {canBePrivatized && (
          <Privatize
            theme={theme}
            isPrivatized={isPrivatized}
            onChangePrivatized={onChangePrivatized}
            minParticipantsToPrivatize={minParticipantsToPrivatize}
            total={total}
          />
        )}
        {units.map((unit, index) => {
          return (
            <div
              key={index}
              class={
                theme && theme.unitQuantity
                  ? theme.unitQuantity
                  : styles.unitQuantity
              }
            >
              <Quantity
                theme={theme}
                unit={unit}
                tax={tax}
                locale={locale}
                currency={currency}
                quantity={quantities.get(unit.id) || 0}
                max={
                  unit.prices[0].targetUnitQuantityMode === 1
                    ? (quantities.get(unit.id) || 0) == 0
                      ? maxParticipants -
                        (this.state.total +
                          unit.prices[0].minTargetUnitQuantity)
                      : unit.prices[0].minTargetUnitQuantity ==
                        unit.prices[0].maxTargetUnitQuantity
                      ? unit.prices[0].maxTargetUnitQuantity -
                        (quantities.get(unit.id) +
                          unit.prices[0].minTargetUnitQuantity)
                      : (quantities.get(unit.id) || 0) >=
                        unit.prices[0].minTargetUnitQuantity
                      ? unit.prices[0].maxTargetUnitQuantity -
                        (quantities.get(unit.id) + 1)
                      : unit.prices[0].maxTargetUnitQuantity -
                        (quantities.get(unit.id) +
                          unit.prices[0].minTargetUnitQuantity)
                    : undefined
                }
                maxReached={
                  maxReached ||
                  (unitsRemainingCapacity &&
                  unitsRemainingCapacity.findIndex(
                    unitCapacity => unitCapacity.id === unit.id
                  ) !== -1
                    ? unitsRemainingCapacity.find(
                        unitCapacity => unitCapacity.id === unit.id
                      ).remainingCapacity === 0
                    : false)
                }
                handleAddUnit={this.incrementUnitQuantity}
                handleSubUnit={this.decrementUnitQuantity}
              />
              {/*unit.prices && (
                <DegressivePrices
                  theme={theme}
                  tax={tax}
                  currency={currency}
                  prices={unit.prices}
                />
              )*/}
            </div>
          );
        })}
        {minParticipants > 1 && minParticipants > total && (
          <span
            class={
              theme && theme.quantityMessage
                ? theme.quantityMessage
                : styles.quantityMessage
            }
          >
            <Text
              class="test"
              id="quantities.minParticipants"
              fields={{ minParticipants }}
            >
              A minimun of {minParticipants} is required.
            </Text>
          </span>
        )}
        {maxReached && (
          <span
            class={
              theme && theme.quantityMessage
                ? theme.quantityMessage
                : styles.quantityMessage
            }
          >
            <Text id="quantities.maxReached">
              Maximum participants reached.
            </Text>
          </span>
        )}
      </div>
    );
  }
}
