// @flow
// @jsx h
import { h } from "preact";
import { Text } from "preact-i18n";

// Style
import styles from "./ClientInfos.scss";
import { withStyles } from "elc-theme";

type ClientInfosProps = {
  user: User,
  orderStatus: string,
  userClientData: UserClientData,
  goToClientForm: Function,
  getClassName: Function
};

const ClientInfos = ({
  orderStatus,
  user,
  userClientData,
  goToClientForm,
  getClassName
}: ClientInfosProps) => (
  <div class={getClassName("basketInfos")}>
    <h5 class={getClassName("basketInfosTitle")}>
      <Text id="basket.user">Vos informations</Text>
    </h5>
    <div class={getClassName("basketInfosValue")}>
      {user && user.email && !userClientData && <div>{user.email}</div>}
      {user && user.phone && !userClientData && <div>{user.phone}</div>}
      {userClientData && (
        <div>
          {userClientData.find(
            data => data._embedded.clientFormInputTypeSaved.code === "email"
          ) && (
            <div>
              {
                userClientData.find(
                  data =>
                    data._embedded.clientFormInputTypeSaved.code === "email"
                ).value
              }
            </div>
          )}
          <div>
            {userClientData.find(
              data => data._embedded.clientFormInputTypeSaved.code === "prename"
            ) && (
              <span>
                {
                  userClientData.find(
                    data =>
                      data._embedded.clientFormInputTypeSaved.code === "prename"
                  ).value
                }
              </span>
            )}{" "}
            {userClientData.find(
              data => data._embedded.clientFormInputTypeSaved.code === "name"
            ) && (
              <span>
                {
                  userClientData.find(
                    data =>
                      data._embedded.clientFormInputTypeSaved.code === "name"
                  ).value
                }
              </span>
            )}
          </div>
          {userClientData.find(
            data =>
              data._embedded.clientFormInputTypeSaved.code === "mobilephone"
          ) && (
            <div>
              {
                userClientData.find(
                  data =>
                    data._embedded.clientFormInputTypeSaved.code ===
                    "mobilephone"
                ).value
              }
            </div>
          )}
        </div>
      )}
    </div>
    {orderStatus === "USER_FILLED" && (
      <button class={getClassName("basketInfosEdit")} onClick={goToClientForm}>
        <Text id="basket.edit">Edit</Text>
      </button>
    )}
  </div>
);

export default withStyles(ClientInfos, styles);
