// @flow
import { h } from 'preact';

const Filter = ({ color }: { color: string }) => (
  // <svg
  //   fill={color ? color : '#FFFFFF'}
  //   height="24"
  //   viewBox="0 0 24 24"
  //   width="24"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
  //   <path d="M0 0h24v24H0z" fill="none" />
  // </svg>

  <svg
    fill={color ? color : '#FFFFFF'}
    height="24"
    width="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65z"
      transform="matrix(.09699 0 0 .09699 0 -.939)"
    />
  </svg>
);
export default Filter;
