// @flow
// @jsx h

import { h } from "preact";
// import LinesEllipsis from 'react-lines-ellipsis';
import sanitizeHtml from "sanitize-html";
import { convertBreakLineToBr } from 'elc-commons';

// Style
import styles from "./BookingSummary.scss";

/**
 * BookingSummary stateless functional component
 * displaying summary of a booking
 */
const BookingSummary = ({ booking }: { booking: Booking }) => {
  let main_image;
  if (booking._embedded.product.type === "FRANCE_BILLET") {
    if (
      booking._embedded.product._embedded.images &&
      booking._embedded.product._embedded.images.length
    ) {
      main_image = booking._embedded.product._embedded.images[0];
    }
  } else {
    main_image =
      booking._embedded.product._embedded.images &&
      booking._embedded.product._embedded.images.find(img => {
        return img.primary;
      });
  }
  return (
    <div class={styles.bookingSummary}>
      {main_image && (
        <div class={styles.bookingImg}>
          <img
            src={
              main_image._embedded.files.find(file => {
                return file.name == "200x200" || file.name == "LARGE_0";
              }).path
            }
            alt={main_image.title}
          />
        </div>
      )}
      <div>
        <h3 class={styles.bookingTitle}>{booking._embedded.product.name}</h3>
        <div
          class={styles.textContent}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(convertBreakLineToBr(booking._embedded.product.summary))
          }}
        />
        {/* <LinesEllipsis
          text={booking._embedded.product.summary}
          maxLine="4"
          ellipsis="..."
          trimRight
          basedOn="letters"
          className={styles.textContent}
        /> */}
      </div>
    </div>
  );
};
export default BookingSummary;
