// @flow
// @jsx h

import { h } from "preact";

import Tabs from "./Tabs";
// import QuantitiesTab from './tab/QuantitiesTab';
import GiftsTab from "./tab/GiftsTab";
import BeneficiaryTab from "./tab/BeneficiaryTab";

const GiftHeader = ({ theme, route, goToGifts }) => (
  <Tabs>
    {/* <QuantitiesTab
      theme={theme}
      active={route === 'giftQuantity'}
      currentQuantities={currentQuantities}
      handleClick={goToQuantities}
    /> */}
    <GiftsTab
      theme={theme}
      active={route === "productGifts"}
      handleClick={goToGifts}
    />
    <BeneficiaryTab theme={theme} active={route === "giftBeneficiary"} />
  </Tabs>
);
export default GiftHeader;
