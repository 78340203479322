import parseProductImpressionForGTM from './parseProductImpressionForGTM';
import { push } from './EasyLoisirsGTMClient';

const sendProductImpressionGTM = (
  products,
  company,
  type,
  hash,
  currencyCode
) => {
  let productList = parseProductImpressionForGTM(products, company, type, hash); //Google tag manager
  //On envoie une seule fois les customs dimensions qui ont un scope = Session
  //cf https://support.google.com/analytics/answer/2709828?hl=fr --> Rubrique : Champ d'application défini au niveau de la session
  //TODO : Voir si ça fonctionne également pour le kiosks
  push({
    event: 'EEProductImpression',
    ecommerce: {
      currencyCode: currencyCode,
      impressions: productList
    }
  });
};

export default sendProductImpressionGTM;
