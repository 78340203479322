// @flow
// @jsx h
/* eslint-disable */
import { h } from "preact";
import styles from "./style.scss";
import { Text } from "preact-i18n";
import sanitizeHtml from "sanitize-html";
import { LanguageIcon, SlotIcon, OptionsIcon, PdfIcon } from "elc-icons";
import { convertBreakLineToBr } from "elc-commons";

function convertImgToIframe(description) {
  if (description == null) {
    return;
  }

  description = description.replace(
    /<img\s[^>]*?ta-insert-video\s*=\s*['\"]([^'\"]*?)['\"][^>]*?>/,
    `<div style="position: relative; padding-bottom: 56.25%; padding-top: 25px; height: 0;">
    <iframe src=$1 contenteditable='false' style='position: absolute; height: 100%; width: 100%; top: 0; left: 0;' allowfullscreen='true' frameborder='0'></iframe>
  </div>`
  );

  return description;
}

/**
 * Product description and summary component
 */
const DescriptionTab = ({ product }) => (
  <div class={styles.descriptionContent}>
    <div class={styles.summary}>
      <h5 class={styles.title}>
        <Text id="product.description.summary">Summary</Text>
      </h5>
      {product.publicMetadata &&
        product.publicMetadata.franceBillet &&
        product.publicMetadata.franceBillet.organization && (
          <div class={styles.summaryRow}>
            <OptionsIcon color={styles.darkGrey} />
            <span class={styles.chip}>
              {product.publicMetadata.franceBillet.organization}
            </span>
          </div>
        )}
      {product.availableFromDate && (
        <div class={styles.summaryRow}>
          <SlotIcon color={styles.darkGrey} />
          <strong>
            <div>
              {new Date(product.availableFromDate).toLocaleDateString()}-
              {new Date(product.availableToDate).toLocaleDateString()}
            </div>
          </strong>
        </div>
      )}
      {product._embedded.merchant &&
        product._embedded.merchant._embedded.languages.length > 0 && (
          <div class={styles.summaryRow}>
            <LanguageIcon color={styles.darkGrey} />
            <span>
              <Text id="product.description.languages">Languages</Text>
            </span>
            <span>:</span>
            {product._embedded.merchant._embedded.languages.map(language => (
              <strong key={language.id}>{language.name}</strong>
            ))}
          </div>
        )}
      {product._embedded.tags &&
        product._embedded.tags.length > 0 && (
          <div class={styles.summaryRow}>
            <OptionsIcon color={styles.darkGrey} />
            {product._embedded.tags.map(tag => (
              <span key={tag.id} class={styles.chip}>
                {tag.text}
              </span>
            ))}
          </div>
        )}

      {product.activityDuration !== 0 && (
        <div class={styles.summaryRow}>
          <SlotIcon color={styles.darkGrey} />
          <span>
            <Text id="product.description.duration">Duration</Text>
          </span>
          <span>:</span>
          <strong>
            <div>
            {(product.activityDuration / 3600) / 24 > 0 && Number.isInteger((product.activityDuration / 3600) / 24) ? (
                <span>
                  {Math.floor((product.activityDuration / 3600) / 24)}{" "}
                  {(product.activityDuration / 3600) / 24 == 1 ? (
                    <Text id="product.description.jour">j</Text>
                  ) : (
                    <Text id="product.description.jours">j</Text>
                  )}
                </span>
              ) : (
                ""
              )}

              {product.activityDuration / 3600 > 0 && !Number.isInteger((product.activityDuration / 3600) / 24) ? (
                <span>
                  {Math.floor(product.activityDuration / 3600)}{" "}
                  <Text id="product.description.hours">h</Text>
                </span>
              ) : (
                ""
              )}
              {(product.activityDuration % 3600) / 60 > 0 && !Number.isInteger((product.activityDuration / 3600) / 24) ? (
                <span>
                  {Math.floor((product.activityDuration % 3600) / 60)}{" "}
                  <Text id="product.description.minutes">m</Text>
                </span>
              ) : (
                ""
              )}
            </div>
          </strong>
        </div>
      )}
      <div
        class={styles.textContent}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(convertBreakLineToBr(product.summary))
        }}
      />
    </div>
    <div class={styles.description}>
      <h5 class={styles.title}>
        <Text id="product.description.description">Description</Text>
      </h5>
      <div
        class={styles.textContent}
        dangerouslySetInnerHTML={{
          __html: convertImgToIframe(product.description)
        }}
      />
    </div>
    <div>{product}</div>
    {product._embedded.files &&
      product._embedded.files.length > 0 && (
        <div>
          <h5 class={styles.title}>
            <Text id="product.description.files">Pièce(s) jointe(s)</Text>
          </h5>
          <div>
            {product._embedded.files.map(file => (
              <a href={file.path} target="_blank" class={styles.file}>
                <div class={styles.fileIcon}>
                  <PdfIcon color={styles.darkGrey} />
                </div>
                <div class={styles.fileName}>{file.title}</div>
              </a>
            ))}
          </div>
        </div>
      )}
  </div>
);

export default DescriptionTab;
