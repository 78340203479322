// @flow
// @jsx h

import { h, Component } from "preact";
import { route } from "preact-router";

import Product from "elc-product";
import Loading from "elc-loading";

import { getProductById, getCompanyById } from "elc-open-api";

import { sendProductClickGTM } from "elc-commons";

/**
 * Product Stateful Component
 * fetch a product by his id
 * and render the Product Component
 * displaying product details and booker
 */
/* FIXME when Preact implement getDerivedStateFromProps */
/* eslint-disable-next-line react/no-deprecated */
export default class FetchProduct extends Component {
  state = {
    loading: true
  };

  /* eslint-disable-next-line react/no-deprecated */
  componentWillMount() {
    if (!this.props.config) {
      route(`/${this.props.hash}`);
    }
  }

  fetchWidgetProduct() {
    let companyType =
      this.props.config && this.props.config.company
        ? this.props.config.company.type
        : undefined;
    return getProductById(
      this.props.productId,
      this.props.locale,
      undefined,
      companyType
    )
      .then(product => {
        sendProductClickGTM(
          product,
          this.props.config.company,
          this.props.config.type,
          this.props.hash,
          this.props.currency.isoCode
        );
        return Promise.all([
          product,
          getCompanyById(product._embedded.merchant.id)
        ]);
      })
      .then(([product, merchant]) => {
        product._embedded.merchant = merchant;
        return product;
      })
      .then(product => {
        this.props.config.reference = product.reference;
        this.props.setCurrentProduct(product);
        this.setState({ product, loading: false });
        return product;
      })
      .catch(error => {
        console.error("Error getting product from API", error);
      });
  }

  componentDidMount() {
    this.fetchWidgetProduct();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.fetchWidgetProduct();
    }
    this.props.pageDidMountOrUpdate();
  }

  render(
    {
      hash,
      config,
      preview,
      currency,
      locale,
      currentOrder,
      setOrder,
      goToCatalog,
      pageDidMountOrUpdate
    },
    { loading, product }
  ) {
    return (
      <div>
        <Loading loading={loading}>Loading...</Loading>
        {!loading && (
          <Product
            hash={hash}
            config={config}
            preview={preview}
            currency={currency}
            locale={locale}
            order={currentOrder}
            product={product}
            setOrder={setOrder}
            goToCatalog={goToCatalog}
            pageDidMountOrUpdate={pageDidMountOrUpdate}
            productPageUpdate={this.props.productPageUpdate}
            onEnterBooker={this.props.onEnterBooker}
          />
        )}
      </div>
    );
  }
}
