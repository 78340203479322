// @flow
// @jsx h

// External librairies
import { h } from "preact";
import { Text } from "preact-i18n";

import { toLocalePrice } from "elc-commons";
import { PrimaryButton } from "elc-button";
import {
  MasterCardIcon,
  VisaIcon,
  VirementBancaireIcon,
  PCIDSSIcon,
  MaestroIcon
} from "elc-icons";

import styles from "./BookerIntro.scss";

type BookerIntroProps = {
  startingPrice: number,
  currencySymbol: string,
  productType: string,
  hasGifts: boolean,
  showBooker: Function,
  showGiftVoucherCode: Function
};

/**
 * BookerIntro presentational functional component
 */
const BookerIntro = (
  {
    locale,
    currencyCode,
    product,
    productType,
    hasGifts,
    showBooker,
    showGiftVoucherCode
  }: BookerIntroProps,
  context
) => {
  const getPriceWithTax = (price: number, tax) => {
    return tax ? price + price * (tax.rate / 100) : price;
  };
  return (
    <div class={styles.introBooker}>
      <div class={styles.startingPrice}>
        {productType !== "GIFT" && (
          <span class={styles.priceFrom}>
            <Text id="product.bookerIntro.startingPrice">A partir de</Text>
          </span>
        )}
        <div>
          <span
            class={styles.price}
            style={`color:${
              context.themeConfig && context.themeConfig.color1
                ? context.themeConfig.color1
                : ""
            };`}
          >
            {toLocalePrice(
              product.type === "GIFT"
                ? getPriceWithTax(
                    product._embedded.prices[0].value,
                    product._embedded.tax
                  )
                : product.displayedStartingPrice,
              locale,
              currencyCode
            )}
          </span>
          <span>
            <Text id="product.bookerIntro.ttc">TTC</Text>
          </span>
        </div>
      </div>
      <div class={styles.actionButtons}>
        {productType !== "GIFT" && (
          <PrimaryButton
            className={styles.bookerButton}
            handleClick={() => showBooker("book")}
          >
            <Text id="product.bookerIntro.book">RESERVER</Text>
          </PrimaryButton>
        )}
        {hasGifts || productType === "GIFT" ? (
          <PrimaryButton
            className={styles.bookerButton}
            handleClick={() => showBooker("gift")}
          >
            <Text id="product.bookerIntro.offer">OFFRIR</Text>
          </PrimaryButton>
        ) : null}

        {productType !== "GIFT" &&
          hasGifts && (
            <PrimaryButton
              className={styles.voucherButton}
              handleClick={showGiftVoucherCode}
              inverse
            >
              <Text id="product.bookerIntro.voucher">
                J&apos;ai un bon cadeau
              </Text>
            </PrimaryButton>
          )}
      </div>
      <div class={styles.reassurance}>
        <MasterCardIcon />
        <MaestroIcon />
        <VisaIcon />
        <VirementBancaireIcon />
        <PCIDSSIcon />
      </div>
    </div>
  );
};

export default BookerIntro;
