// @flow
// @jsx h

import { h } from 'preact';

import styles from './PrimaryButton.scss';
import themeMarketplace from './theme-marketplace.scss';
import themeKiosk from './theme-kiosk.scss';

import Button from './Button';

/**
 * Common Button stateless functional Component
 */
const PrimaryButton = (
  {
    type,
    handleClick,
    disabled,
    children,
    className,
    inverse
  }: {
    type?: string,
    handleClick?: Function,
    disabled?: boolean,
    children?: any,
    className?: string,
    inverse?: boolean
  },
  context
) => {
  const colors = context.themeConfig;
  const theme =
    context.widgetContext === 'KIOSK'
      ? themeKiosk
      : context.widgetContext === 'SHOP' || context.widgetContext === 'PMS'
        ? themeMarketplace
        : undefined;

  let computedClassName = '';
  if (className) {
    computedClassName += className;
  }
  computedClassName += ` ${
    theme && theme.primaryButton ? theme.primaryButton : styles.primaryButton
  } ${
    inverse
      ? theme && theme.primaryButtonInverse
        ? theme.primaryButtonInverse
        : styles.primaryButtonInverse
      : ''
  }`;
  return (
    <Button
      type={type || 'button'}
      handleClick={handleClick}
      disabled={disabled}
      className={computedClassName}
      background={!inverse && colors && colors.color2 ? colors.color2 : ''}
      color={inverse && colors && colors.color2 ? colors.color2 : ''}
      borderColor={inverse && colors && colors.color2 ? colors.color2 : ''}
    >
      <span
        class={
          theme && theme.buttonContentWrapper
            ? theme.buttonContentWrapper
            : styles.buttonContentWrapper
        }
      >
        {children}
      </span>
    </Button>
  );
};
export default PrimaryButton;
