// @flow
// @ jsx h
import { h, Component } from "preact";
import styles from "./style.scss";
import ProductCards from "./productcards";
import ProductList from "./productlist";

import orderBy from "lodash/orderBy";

type ProductsProps = {
  hash: string,
  config: WidgetConfig,
  currency: Currency,
  products: Array<Product>,
  productsListViewMode: boolean,
  onSelectProduct: Function
};

export default class Products extends Component<ProductsProps> {
  state = {
    mobileDisplay: false
  };

  componentDidMount() {
    if (!window.matchMedia) return;
    if (this.props.options && this.props.options.disabledView) return;
    this.mql = window.matchMedia("(max-width: 53.75em)");
    this.mql.addListener(this.onMatch);
    this.onMatch(this.mql);
  }

  componentWillUnmount() {
    this.mql && this.mql.removeListener(this.onMatch);
  }

  onMatch = mql => {
    const mobileDisplay = !!mql.matches;
    this.setState({ mobileDisplay });
  };

  render(
    {
      hash,
      config,
      locale,
      currency,
      products,
      productsListViewMode,
      onSelectProduct
    }: {
      hash: string,
      config: WidgetConfig,
      currency: Currency,
      products: Array<Product>,
      productsListViewMode: boolean,
      onSelectProduct: Function
    },
    { mobileDisplay }: { mobileDisplay: boolean }
  ) {
    const productsByPosition = orderBy(products, ["position"], ["asc"]);

    return (
      <div class={styles.products}>
        {!mobileDisplay && productsListViewMode ? (
          <ProductList
            hash={hash}
            config={config}
            locale={locale}
            currency={currency}
            products={productsByPosition}
            onSelectProduct={onSelectProduct}
            disabledReservation={this.props.disabledReservation}
          />
        ) : (
          <ProductCards
            hash={hash}
            locale={locale}
            currency={currency}
            products={productsByPosition}
            onSelectProduct={onSelectProduct}
            disabledReservation={this.props.disabledReservation}
          />
        )}
      </div>
    );
  }
}
