export function dateFormater(date, locale, type) {
  const event = new Date(date);
  const options = { year: "numeric", month: "numeric", day: "numeric" };

  if (type === "time") {
    const time = event.toLocaleTimeString(locale);
    const hoursAndMinutes = time.slice(0, time.lastIndexOf(":"));
    return hoursAndMinutes;
  }
  return event.toLocaleDateString(locale, options);
}

export function convertBreakLineToBr(content) {
  return content.replace(/\n/gm, "<br/>");
}

export function getPriceWithTax(price: number, tax) {
  return tax ? price + price * (tax.rate / 100) : price;
}

export const isSafari = () => {
  const is_safari =
    window.navigator.userAgent.toLowerCase().indexOf("safari/") > -1;
  return is_safari;
};

export const isEdge = () => {
  const is_edge = window.navigator.userAgent.indexOf("Edge") > -1;
  return is_edge;
};
