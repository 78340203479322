// @flow
// @jsx h

import { h, Component } from "preact";
import { Text } from "preact-i18n";

import Match, { Link } from "preact-router/match";

import styles from "./Header.scss";

import ReactFlagsSelectExtension from "./ReactFlagsSelectExtension";

import { CartIcon, ArrowLeftIcon } from "elc-icons";
import { BackButton } from "elc-button";

/**
 * Widget Header component
 */
class Header extends Component {
  render(
    {
      config,
      order,
      matchesProduct,
      changeTranslation,
      backToProduct,
      goToCatalog
    },
    state,
    { themeConfig }
  ) {
    return (
      <nav class={styles.header}>
        {/* <div> */}
        {config && config.type === "MY_PAGE" && (
          <h2 style={`color: ${config.module.conf.color2};`}>
            {config.company.name}
          </h2>
        )}
        <div class={`${styles.headerLeft}`}>
          {matchesProduct && this.props.showBackToProduct && (
            <BackButton
              handleClick={backToProduct}
              className={styles.previousButton}
            >
              <ArrowLeftIcon
                color={
                  this.context.themeConfig && this.context.themeConfig.color1
                    ? this.context.themeConfig.color1
                    : styles.green
                }
              />
            </BackButton>
          )}
          {matchesProduct &&
            !this.props.showBackToProduct &&
            config.module.conf.mode === "catalog" && (
              <BackButton
                handleClick={goToCatalog}
                className={styles.previousButton}
              >
                <ArrowLeftIcon
                  color={
                    this.context.themeConfig && this.context.themeConfig.color1
                      ? this.context.themeConfig.color1
                      : styles.green
                  }
                />
                {!window.matchMedia("(max-width: 53.75em)").matches && (
                  <Text id="header.backToCatalog">Back to catalog</Text>
                )}
              </BackButton>
            )}
          {order && (
            <Match path={`/order/${order.id}`}>
              {({ matches }) =>
                matches && (
                  <BackButton
                    handleClick={goToCatalog}
                    className={styles.previousButton}
                  >
                    <ArrowLeftIcon
                      color={
                        this.context.themeConfig &&
                        this.context.themeConfig.color1
                          ? this.context.themeConfig.color1
                          : styles.green
                      }
                    />
                    {!window.matchMedia("(max-width: 53.75em)").matches && (
                      <span>
                        {config.module.conf.mode === "catalog" ? (
                          <Text id="header.backToCatalog">Back to catalog</Text>
                        ) : (
                          <Text id="header.backToProduct">Back to product</Text>
                        )}
                      </span>
                    )}
                  </BackButton>
                )
              }
            </Match>
          )}
          {order && (
            <Match path={`/order/${order.id}/client`}>
              {({ matches }) =>
                matches && (
                  <Link
                    href={`/order/${order ? order.id : ""}`}
                    class={styles.previousButton}
                  >
                    <ArrowLeftIcon
                      color={
                        themeConfig && themeConfig.color1
                          ? themeConfig.color1
                          : styles.green
                      }
                    />
                    {!window.matchMedia("(max-width: 53.75em)").matches && (
                      <Text id="header.backToBasket">Back to basket</Text>
                    )}
                  </Link>
                )
              }
            </Match>
          )}
        </div>
        {/* {(matchesCatalog ||
          !window.matchMedia('(max-width: 53.75em)').matches) && ( */}
        <div class={`${styles.headerRight}`}>
          {config && config.type === "MY_PAGE" && (
            <div class={`${styles.contact}`}>
              <a
                href={`tel:${config.company.mobilePhone}`}
                style={`color: ${config.module.conf.color2};`}
              >
                <h3>{config.company.mobilePhone}</h3>
              </a>
              <a href={`mailto:${config.company.emailContact}`}>
                {config.company.emailContact.toLowerCase()}
              </a>
            </div>
          )}
          <Link
            href={`/order/${order ? order.id : ""}`}
            class={`${styles.basket} ${
              !order ||
              !order._embedded.bookings ||
              order._embedded.bookings.length == 0
                ? styles.basketInactive
                : ""
            }`}
          >
            <CartIcon color={styles.darkGrey} />
            <span class={styles.basketNb}>
              {order && order._embedded.bookings
                ? order._embedded.bookings.length
                : 0}
            </span>
          </Link>
          <ReactFlagsSelectExtension
            className={`${styles.countrySelect}`}
            defaultCountry="FR"
            countries={["GB", "FR", "DE", "ES", "IT", "NL", "PL"]}
            showSelectedLabel={false}
            showOptionLabel={false}
            selectedSize={18}
            optionsSize={18}
            onSelect={changeTranslation}
          />
        </div>
      </nav>
    );
  }
}

export default Header;
