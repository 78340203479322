// @flow
// @jsx h

// External librairies
import { h } from "preact";
import { Text } from "preact-i18n";

// Style
import styles from "./HeaderTitle.scss";
import { withStyles } from "elc-theme";

/**
 * Header title stateless functional component
 * displaying header title depending on route
 */
const HeaderTitle = (
  {
    getClassName
  }: {
    getClassName: Function
  },
  context
) => (
  <header class={getClassName("header")}>
    <div
      class={getClassName("headerTitle")}
      style={`color:${
        context.themeConfig && context.themeConfig.color1
          ? context.themeConfig.color1
          : ""
      };`}
    >
      <h2>
        <Text id="basket.title">Panier</Text>
      </h2>
    </div>
  </header>
);

export default withStyles(HeaderTitle, styles);
