import { h, Component } from "preact";
import styles from "./style.scss";
import { Text } from "preact-i18n";
import SwipeableViews from "react-swipeable-views";
import DescriptionTab from "./descriptiontab";
import MapTab from "./maptab";

/**
 * Product description and map tabs
 */
export default class ProductTabs extends Component {
  state = {
    index: 0,
    mobileDisplay: true,
    displayMap: false
  };

  componentDidMount() {
    if (!window.matchMedia) return;
    this.mql = window.matchMedia(
      "(max-width: " +
        (styles.mobileMode ? styles.mobileMode : "53.750em") +
        ")"
    );
    this.mql.addListener(this.onMatch);
    this.onMatch(this.mql);
  }

  componentWillUnmount() {
    this.mql && this.mql.removeListener(this.onMatch);
  }

  onMatch = mql => {
    const mobileDisplay = !!mql.matches;
    this.setState({ mobileDisplay });
  };

  /**
   * Show product description
   */
  goToDescription = () => {
    this.setState({
      index: 0
    });
  };

  /**
   * Show product address and map
   */
  goToMap = () => {
    this.setState({
      index: 1,
      displayMap: true
    });
  };

  /**
   * Handle tab change on swiper
   */
  handleChangeIndex = index => {
    this.setState({
      index
    });
  };

  render({ config, product, address }, { index }) {
    return (
      <div class={styles.tabsContainer}>
        <ul class={styles.tabs}>
          <li class={styles.tabsItem}>
            <button
              onClick={this.goToDescription}
              class={`${styles.tabsItemLink} ${
                index === 0 ? styles.active : ""
              }`}
              style={`border-color:${
                this.context.themeConfig && this.context.themeConfig.color2
                  ? this.context.themeConfig.color2
                  : ""
              };
              color:${index === 0 &&
                this.context.themeConfig &&
                this.context.themeConfig.color2}`}
            >
              <Text id="product.description.tabs.description">Description</Text>
            </button>
          </li>
          { config.module.conf.display_map === 1 &&
            address && (
              <li class={styles.tabsItem}>
                <button
                  onClick={this.goToMap}
                  class={`${styles.tabsItemLink} ${
                    index === 1 ? styles.active : ""
                  }`}
                  style={`border-color:${
                    this.context.themeConfig && this.context.themeConfig.color2
                      ? this.context.themeConfig.color2
                      : ""
                  };
                  color:${index === 1 &&
                    this.context.themeConfig &&
                    this.context.themeConfig.color2}`}
                >
                  <Text id="product.description.tabs.map">Map</Text>
                </button>
              </li>
            )}
        </ul>
        <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
          <DescriptionTab product={product} />
          { config.module.conf.display_map === 1 &&
            address && (
              <MapTab
                product={product}
                displayMap={this.state.displayMap}
                address={address}
              />
            )}
        </SwipeableViews>
      </div>
    );
  }
}
