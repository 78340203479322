// @flow
// @jsx h
import { h } from "preact";

import { withText } from "preact-i18n";

// Tab component
import Tab from "./Tab";
import TabIcon from "./TabIcon";
import TabLabel from "./TabLabel";

// Icons
import { QuantitiesIcon } from "elc-icons";

/**
 * Quantities tab component
 */
const QuantitiesTab = withText({ label: "header.quantity" })(
  ({ theme, active, currentQuantities, handleClick, label }) => (
    <Tab handleClick={handleClick}>
      <TabIcon theme={theme} active={active}>
        <QuantitiesIcon />
      </TabIcon>
      <TabLabel
        theme={theme}
        active={active}
        label={
          currentQuantities
            ? Array.from(currentQuantities.quantities).reduce(
                (acc, quantity) => acc + quantity[1],
                0
              )
            : label
        }
      />
    </Tab>
  )
);

export default QuantitiesTab;
