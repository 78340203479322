// @flow
// @jsx h
import { h } from 'preact';

const Creditcards = ({ color }: { color: string }) => (
  <svg
    fill={color ? color : '#000000'}
    width="26.203"
    height="24"
    viewBox="0 0 26.203 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m24.021 24h-18.564c-1.205 0-2.1815-0.97772-2.1827-2.1827v-4.0765l-2.9852-5.2106c-0.59564-1.041-0.24256-2.3674 0.79176-2.9745l15.921-9.262c0.3074-0.1802 0.65497-0.28079 1.0111-0.29263 0.21287-7e-3 0.42552 0.0178 0.63127 0.0728 0.5591 0.14759 1.0353 0.51391 1.3214 1.0164l3.7502 6.5433h0.30494c1.205 0 2.1815 0.97772 2.1827 2.1827v11.999c-3.21e-4 1.2056-0.97708 2.1831-2.1827 2.1843zm-20.746-8.4579v-0.9683l-0.41728 0.24074zm-0.9576-1.6745 0.9576-0.55637v-1.2572l-1.4979 0.87201zm-0.68477-3.3703c-0.51411 0.30328-0.69028 0.96263-0.39588 1.4819l2.0382-1.1823v-0.97901c0-0.0988 0.013374-0.19724 0.032099-0.29423zm17.387-8.8645c-0.1431-0.24956-0.37999-0.43159-0.65802-0.50555-0.27268-0.075-0.56408-0.0364-0.80781 0.107l-11.004 6.3983h2.1559l10.314-5.997v-5e-3zm0.54032 0.95225-8.688 5.0502h2.1559l7.0723-4.1139zm1.0806 1.8831-5.4407 3.167h7.2596zm4.4724 5.3497c0-0.60163-0.48971-1.0887-1.0913-1.0913h-18.564c-0.60163 0-1.0887 0.48971-1.0913 1.0913v11.999c0 0.60163 0.48971 1.0887 1.0913 1.0913h18.564c0.60163 0 1.0887-0.48971 1.0913-1.0913zm-4.4349 10.908c-0.64528-0.32098-1.4037-0.32098-2.0489 0-3.0028 0.094-3.0028-4.4594 0-4.3654 0.64357 0.32815 1.4054 0.32815 2.0489 0 3.0028-0.094 3.0028 4.4594 0 4.3654zm0-3.274c-0.25379-6e-5 -0.50111 0.0805-0.70616 0.23003-0.19141 0.13904-0.45056 0.13904-0.64197 0-0.20345-0.14872-0.44879-0.22923-0.70082-0.23003-1.5491-0.097-1.5491 2.2797 0 2.1827 0.25203 0 0.49736-0.0813 0.70082-0.23004 0.19254-0.13556 0.44943-0.13556 0.64197 0 0.20506 0.14958 0.45237 0.23009 0.70616 0.23004 1.5362 0.0833 1.5362-2.266 0-2.1827zm-5.9382 3.274h-7.6448c-0.72756 0-0.72756-1.0913 0-1.0913h7.6448c0.72756 0 0.72756 1.0913 0 1.0913zm-2.1827-3.274h-5.4621c-0.72756 0-0.72756-1.0914 0-1.0914h5.4621c0.72756 0 0.72756 1.0914 0 1.0914z"
      fillRule="evenodd"
      strokeWidth=".535"
    />
  </svg>
);
export default Creditcards;
