// @flow
// @jsx h
import { h } from "preact";
import styles from "./Tab.scss";

/**
 * Tab stateless functional component
 * used to display a booker tab in common styled container
 */
const Tab = ({ handleClick, children }) => (
  <button class={styles.tab} onClick={handleClick}>
    {children}
  </button>
);
export default Tab;
