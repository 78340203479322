// @flow

import getMandatoryMainUnitQuantityToAdd from "./getMandatoryMainUnitQuantityToAdd";

const hasInvalidQuantities = (
  product,
  quantities,
  { isPrivatized = false, discounts } = { isPrivatized: false }
) =>
  product.type === "FRANCE_BILLET"
    ? quantities.size === 0 ||
      (discounts &&
        discounts.reduce(
          (acc, discount) =>
            quantities.has(discount.secondaryCustomerClassId)
              ? Math.floor(
                  discount.secondaryCustomerClassTicketQuantity *
                    (quantities.has(discount.primaryCustomerClassId)
                      ? quantities.get(discount.primaryCustomerClassId)
                      : 0) /
                    discount.primaryCustomerClassTicketQuantity -
                    quantities.get(discount.secondaryCustomerClassId)
                ) < 0
              : acc,
          false
        ))
      ? true
      : false
    : Array.isArray(quantities)
      ? quantities.length === 0 ||
        quantities.reduce((acc, quantity) => acc + quantity[1], 0) <
          product.minParticipants ||
        (isPrivatized &&
          quantities.reduce((acc, quantity) => acc + quantity[1], 0) <
            product.minParticipantsToPrivatize)
        ? true
        : false
      : quantities.size === 0 ||
        Array.from(quantities).reduce((acc, quantity) => acc + quantity[1], 0) <
          product.minParticipants ||
        (isPrivatized &&
          Array.from(quantities).reduce(
            (acc, quantity) => acc + quantity[1],
            0
          ) < product.minParticipantsToPrivatize) ||
        getMandatoryMainUnitQuantityToAdd(quantities, product.units) > 0
        ? true
        : false;

export default hasInvalidQuantities;
