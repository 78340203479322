// @flow
// @jsx h

//External librairies
import { h } from "preact";

// Style
import styles from "./style.scss";
import { withStyles } from "elc-theme";

/**
 * Calendar day button Component
 */
function CalendarDay({
  day,
  isToday,
  isDifferentMonth,
  selected,
  bookable,
  availability,
  fullyBooked,
  onDayClick,
  getClassName,
  inProgress
}: {
  theme: object,
  day: Date,
  isToday: boolean,
  isDifferentMonth: boolean,
  selected: boolean,
  bookable: boolean,
  fullyBooked: boolean,
  availability: Object,
  onDayClick: Function,
  getClassName: Function,
  inProgress: boolean
}) {
  const onClick = () => {
    if (availability) onDayClick(new Date(availability.date));
  };
  return (
    <td class={getClassName("dayCell")}>
      <div class={getClassName("cellContainer")}>
        <button
          class={getClassName("dayButton", {
            selectedDay: selected,
            today: isToday,
            otherMonth: isDifferentMonth,
            bookable: bookable,
            fullyBooked: fullyBooked,
            inProgress: inProgress
          })}
          onClick={onClick}
          disabled={!bookable}
        >
          <span>
            <span class={getClassName("date")}>{day.getDate()}</span>
            {/* <span class={styles.reduction}>-40%</span> */}
          </span>
        </button>
      </div>
    </td>
  );
}

export default withStyles(CalendarDay, styles);
