// @flow
// @jsx h

import { h, Component } from "preact";
import { Text } from "preact-i18n";

import Swiper from "react-id-swiper";

import { Button } from "elc-button";
import { RemoveIcon, MenuIcon } from "elc-icons";

import styles from "./style.scss";

const WidgetCategoriesTab = (
  { widgetCategory, setWidgetCategory, currentCategory },
  context
) => {
  const handleTabClick = () => {
    const widgetCategoryId = widgetCategory ? widgetCategory.id : undefined;
    setWidgetCategory(widgetCategoryId);
  };

  return (
    <div
      class={`${styles.tab} ${
        (widgetCategory && currentCategory.indexOf(widgetCategory.id) >= 0) ||
        (!widgetCategory && currentCategory.length === 0)
          ? styles.active
          : ""
      }`}
    >
      <button
        onClick={handleTabClick}
        style={`border-color:${
          context.themeConfig && context.themeConfig.color2
            ? context.themeConfig.color2
            : ""
        };`}
      >
        {widgetCategory ? (
          widgetCategory.name
        ) : (
          <Text id="widgetCategories.allProducts">All products</Text>
        )}
      </button>
    </div>
  );
};

class WidgetCategoriesTabs extends Component {
  state = {
    opened: false,
    mobileDisplay: true
  };

  componentDidMount() {
    if (!window.matchMedia) return;
    this.mql = window.matchMedia(
      "(max-width: " +
        (styles.mobileMode ? styles.mobileMode : "53.750em") +
        ")"
    );
    this.mql.addListener(this.onMatch);
    this.onMatch(this.mql);
  }

  componentWillUnmount() {
    this.mql && this.mql.removeListener(this.onMatch);
  }

  onMatch = mql => {
    const mobileDisplay = !!mql.matches;
    this.setState({ mobileDisplay });
  };

  openCategories = () => {
    if (!this.state.opened) {
      this.setState({ opened: true });
    }
  };

  closeCategories = () => {
    if (this.state.opened) {
      this.setState({ opened: false });
    }
  };

  render({ widgetCategories, setWidgetCategory, currentCategory }) {
    const swiperParams = {
      slidesPerView: "auto",
      spaceBetween: 0,
      slideClass: styles.slideContainer,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
        disabledClass: styles.disablesNavigation
      },
      nextButtonCustomizedClass: styles.swiperButtonNext,
      prevButtonCustomizedClass: styles.swiperButtonPrev
    };

    return (
      <div class={styles.widgetCategoriesContainer}>
        <Button
          className={styles.openCategoriesButton}
          handleClick={this.openCategories}
        >
          <MenuIcon
            color={
              this.context.themeConfig && this.context.themeConfig.color1
                ? this.context.themeConfig.color1
                : styles.filterColor
            }
          />
        </Button>
        <div
          onClick={this.closeCategories}
          class={`${styles.widgetCategoriesContent} ${
            this.state.opened ? styles.widgetCategoriesOpened : ""
          }`}
          role={this.state.opened ? "dialog" : ""}
        >
          {this.state.opened && (
            <Button
              className={styles.closeCategoriesButton}
              handleClick={this.closeCategories}
            >
              <RemoveIcon
                color={
                  this.context.themeConfig && this.context.themeConfig.color1
                    ? this.context.themeConfig.color1
                    : styles.filterColor
                }
              />
            </Button>
          )}
          {this.state.mobileDisplay && (
            <ul class={styles.tabs}>
              {widgetCategories.map(widgetCategory => (
                <li key={widgetCategory.id} onClick={this.closeCategories}>
                  <WidgetCategoriesTab
                    widgetCategory={widgetCategory}
                    setWidgetCategory={setWidgetCategory}
                    currentCategory={currentCategory}
                  />
                </li>
              ))}
              <li onClick={this.closeCategories}>
                <WidgetCategoriesTab
                  setWidgetCategory={setWidgetCategory}
                  currentCategory={currentCategory}
                />
              </li>
            </ul>
          )}

          {!this.state.mobileDisplay && (
            <ul class={styles.tabs}>
              <Swiper {...swiperParams}>
                <li>
                  <WidgetCategoriesTab
                    setWidgetCategory={setWidgetCategory}
                    currentCategory={currentCategory}
                  />
                </li>
                {widgetCategories.map(widgetCategory => (
                  <li key={widgetCategory.id}>
                    <WidgetCategoriesTab
                      widgetCategory={widgetCategory}
                      setWidgetCategory={setWidgetCategory}
                      currentCategory={currentCategory}
                    />
                  </li>
                ))}
              </Swiper>
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default WidgetCategoriesTabs;
