// @flow

declare var PHP_API_ENDPOINT: string;

import { handleResponse } from '../handle-response';

/**
 * Return the Widget OpenAPI token
 * @param hash {string} - the order reference
 * @return {Promise} the widget OpenAPI token
 */
export const generateTokenByOrderReference = (
  orderReference: string
): Promise<string> =>
  fetch(`${PHP_API_ENDPOINT}/orders/${orderReference}/generateToken`).then(
    handleResponse
  );
