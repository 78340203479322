// @flow
// @jsx h

// External librairies
import { h } from "preact";

// Style
import styles from "./style.scss";

/**
 * Footer button container component
 * used to display the footer buttons in a common styled container
 */
const FooterButton = ({ theme, children }) => (
  <div
    class={`${
      theme && theme.footerButtonContainer
        ? theme.footerButtonContainer
        : styles.footerButtonContainer
    }`}
  >
    {children}
  </div>
);

export default FooterButton;
