// import Promise from 'promise-polyfill';
import "isomorphic-fetch";
import "babel-polyfill";
import "react-id-swiper/src/styles/css/swiper.css";
import { h, render } from "preact";
import "./style/index.scss";

let root;
function init() {
  let App = require("./components/app").default;
  root = render(<App />, document.body, root);
}

// To add to window test
if (!window.Promise) {
  window.Promise = Promise;
}

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    "use strict";
    if (typeof start !== "number") {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

// register ServiceWorker via OfflinePlugin, for prod only :
// if (
//   process.env.NODE_ENV === "preprod" ||
//   process.env.NODE_ENV === "demo" ||
//   process.env.NODE_ENV === "production"
// ) {
//   // require('./pwa') ;
//   if ("serviceWorker" in navigator) {
//     navigator.serviceWorker
//       .register("/service-worker.js")
//       // .then(registration => {
//       //   // registration worked
//       //   console.log('Service Worker registration succeeded.');
//       //   return registration.unregister();
//       // })
//       // .then(unregistered => {
//       //   if (unregistered) {
//       //     console.log('Service Worker successfully unregistered.');
//       //   }
//       //   return unregistered;
//       // })
//       .catch(function(error) {
//         // registration failed
//         console.error("Service Worker registration failed with " + error);
//       });
//   }
// }

// in development, set up HMR:
if (module.hot) {
  require("preact/devtools"); // turn this on if you want to enable React DevTools!
  module.hot.accept("./components/app", () => requestAnimationFrame(init));
}

if (!global.Intl) {
  require.ensure(
    ["intl", "intl/locale-data/jsonp/en.js", "intl/locale-data/jsonp/fr.js"],
    function(require) {
      require("intl");
      require("intl/locale-data/jsonp/en.js");
      require("intl/locale-data/jsonp/fr.js");
      init();
    }
  );
} else {
  init();
}
// forc ebuild
