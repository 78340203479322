// @flow
// @jsx h
import { h, Component } from "preact";

import styles from "./style.scss";

const CANCEL_DISTANCE_ON_SCROLL = 20;

export default class SidePanel extends Component<SidePanelProps> {
  constructor(props) {
    super(props);
    this.state = {
      // the detected width of the sidebar in pixels
      sidebarWidth: 0,

      // keep track of touching params
      touchIdentifier: null,
      touchStartX: null,
      touchStartY: null,
      touchCurrentX: null,
      touchCurrentY: null,

      // if touch is supported by the browser
      dragSupported: false
    };
  }

  componentDidMount = () => {
    this.setState({
      dragSupported: typeof window === "object" && "ontouchstart" in window
    });
    this.saveSidebarWidth();
  };

  componentDidUpdate = () => {
    // filter out the updates when we're touching
    if (!this.isTouching()) {
      this.saveSidebarWidth();
    }
  };

  isTouching = () => {
    return this.state.touchIdentifier !== null;
  };

  saveSidebarWidth = () => {
    const width = this.sidebar.offsetWidth;

    if (width !== this.state.sidebarWidth) {
      this.setState({ sidebarWidth: width });
    }
  };

  onTouchStart = ev => {
    // filter out if a user starts swiping with a second finger
    if (!this.isTouching()) {
      const touch = ev.targetTouches[0];
      this.setState({
        touchIdentifier: touch.identifier,
        touchStartX: touch.clientX,
        touchStartY: touch.clientY,
        touchCurrentX: touch.clientX,
        touchCurrentY: touch.clientY
      });
    }
  };

  onTouchMove = ev => {
    if (this.isTouching()) {
      for (let ind = 0; ind < ev.targetTouches.length; ind++) {
        // we only care about the finger that we are tracking
        if (ev.targetTouches[ind].identifier === this.state.touchIdentifier) {
          this.setState({
            touchCurrentX: ev.targetTouches[ind].clientX,
            touchCurrentY: ev.targetTouches[ind].clientY
          });
          break;
        }
      }
    }
  };

  onTouchEnd = () => {
    if (this.isTouching()) {
      // trigger a change to open if sidebar has been dragged beyond dragToggleDistance
      const touchWidth = this.touchSidebarWidth();

      if (
        (this.props.open &&
          touchWidth <
            this.state.sidebarWidth - this.props.dragToggleDistance) ||
        (!this.props.open && touchWidth > this.props.dragToggleDistance)
      ) {
        this.props.onSetOpen(!this.props.open);
      }

      this.setState({
        touchIdentifier: null,
        touchStartX: null,
        touchStartY: null,
        touchCurrentX: null,
        touchCurrentY: null
      });
    }
  };

  // This logic helps us prevents the user from sliding the sidebar horizontally
  // while scrolling the sidebar vertically. When a scroll event comes in, we're
  // cancelling the ongoing gesture if it did not move horizontally much.
  onScroll = () => {
    if (this.isTouching() && this.inCancelDistanceOnScroll()) {
      this.setState({
        touchIdentifier: null,
        touchStartX: null,
        touchStartY: null,
        touchCurrentX: null,
        touchCurrentY: null
      });
    }
  };

  // True if the on going gesture X distance is less than the cancel distance
  inCancelDistanceOnScroll = () => {
    let cancelDistanceOnScroll;

    cancelDistanceOnScroll =
      Math.abs(this.state.touchStartX - this.state.touchCurrentX) <
      CANCEL_DISTANCE_ON_SCROLL;

    return cancelDistanceOnScroll;
  };

  overlayClicked = () => {
    if (this.props.open) {
      this.props.onSetOpen(false);
    }
  };

  // calculate the sidebarWidth based on current touch info
  touchSidebarWidth = () => {
    // if the sidebar is open and start point of drag is inside the sidebar
    // we will only drag the distance they moved their finger
    // otherwise we will move the sidebar to be below the finger.
    if (this.props.open && this.state.touchStartX < this.state.sidebarWidth) {
      if (this.state.touchCurrentX > this.state.touchStartX) {
        return this.state.sidebarWidth;
      }
      return (
        this.state.sidebarWidth -
        this.state.touchStartX +
        this.state.touchCurrentX
      );
    }
    return Math.min(this.state.touchCurrentX, this.state.sidebarWidth);
  };

  render() {
    const sidebarStyle = {};
    const overlayStyle = {};
    const useTouch = this.state.dragSupported;
    const isTouching = this.isTouching();
    const rootProps = {
      role: "navigation"
    };
    let dragHandle;

    // sidebarStyle left
    sidebarStyle.left = 0;
    sidebarStyle.transform = "translateX(-100%)";
    sidebarStyle.WebkitTransform = "translateX(-100%)";

    if (isTouching) {
      const percentage = this.touchSidebarWidth() / this.state.sidebarWidth;

      // slide open to what we dragged
      sidebarStyle.transform = `translateX(-${(1 - percentage) * 100}%)`;
      sidebarStyle.WebkitTransform = `translateX(-${(1 - percentage) * 100}%)`;

      // fade overlay to match distance of drag
      overlayStyle.opacity = percentage;
      overlayStyle.visibility = "visible";
    } else if (this.props.docked) {
      // show sidebar
      if (this.state.sidebarWidth !== 0) {
        sidebarStyle.transform = `translateX(0%)`;
        sidebarStyle.WebkitTransform = `translateX(0%)`;
      }
    } else if (this.props.open) {
      // slide open sidebar
      sidebarStyle.transform = `translateX(0%)`;
      sidebarStyle.WebkitTransform = `translateX(0%)`;

      // show overlay
      overlayStyle.opacity = 1;
      overlayStyle.visibility = "visible";
    }

    if (isTouching || !this.props.transitions) {
      sidebarStyle.transition = "none";
      sidebarStyle.WebkitTransition = "none";
      overlayStyle.transition = "none";
    }

    if (useTouch) {
      if (this.props.open) {
        rootProps.onTouchStart = this.onTouchStart;
        rootProps.onTouchMove = this.onTouchMove;
        rootProps.onTouchEnd = this.onTouchEnd;
        rootProps.onTouchCancel = this.onTouchEnd;
        rootProps.onScroll = this.onScroll;
      } else {
        dragHandle = (
          <div
            class={styles.dragHandle}
            onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            onTouchEnd={this.onTouchEnd}
            onTouchCancel={this.onTouchEnd}
          />
        );
      }
    }

    return (
      <div {...rootProps} class={styles.root}>
        <div
          class={styles.sidebar}
          style={sidebarStyle}
          ref={saveSidebarRef => (this.sidebar = saveSidebarRef)}
        >
          {this.props.children}
        </div>
        <div
          class={styles.overlay}
          style={overlayStyle}
          role="presentation"
          tabIndex="0"
          onClick={this.overlayClicked}
        />
        {dragHandle}
      </div>
    );
  }
}
