// @flow
// @jsx h

import { h, Component } from "preact";
// force build
// Translations
import { Text, IntlProvider } from "preact-i18n";
import ca from "../translations/ca.json";
import de from "../translations/de.json";
import en from "../translations/en.json";
import es from "../translations/es.json";
import fr from "../translations/fr.json";
import it from "../translations/it.json";
import nl from "../translations/nl.json";

// Style and theming
import styles from "./style.scss";
import themeMarketplace from "./theme-marketplace.scss";
import themeKiosk from "./theme-kiosk.scss";
import themeShop from "./theme-shop.scss";
import { ThemeProvider, WidgetContextProvider } from "elc-theme";

import Loading from "elc-loading";
// Components
import Header from "./header";
import Quantities from "./quantities";
import Day from "./calendar";
import TimeSlots from "./time-slots";
import Extras from "./extras";
// import GiftQuantity from './gift-quantity';
import GiftBeneficiary from "./gift-beneficiary";
import ProductGifts from "./product-gifts";
import TicketHolders from "./ticket-holders";

// API
import {
  setToken,
  getBookingById,
  getProductDayAvailabilities,
  getProductTimeSlotAvailabilities,
  getProductById,
  createBooking,
  createGiftBooking,
  updateBooking,
  addTimeRange,
  addVoucherCode,
  getOrderById
} from "elc-open-api";

type BookerProps = {
  token: string,
  productId: number,
  bookingId: number,
  currency: Currency,
  company: Company,
  apiEndpoint?: string,
  phpApiEndpoint?: string,
  mediasEndpoint?: string,
  order?: Order,
  locale: "fr" | "en" | "de" | "es" | "it" | "nl" | "ca",
  voucherCode?: string,
  isBook: String
};

type BookerState = {
  loading: boolean,
  singlePanelMode: boolean,
  localeDefinition: Object,
  currentProduct?: Product,
  currentGift?: Product,
  currentQuantities?: Quantities,
  currentExtrasQuantities?: Quantities,
  currentDay?: Day,
  currentTimeSlots?: TimeSlots,
  currentSlot?: Slot,
  currentOrder?: Order,
  giftBeneficiary?: Beneficiary,
  currency: Currency
};

/* FIXME when Preact implement getDerivedStateFromProps */
/* eslint-disable-next-line react/no-deprecated */
export default class Booker extends Component<BookerProps, BookerState> {
  state = {
    loading: true,
    singlePanelMode: true,
    localeDefinition: fr,
    currentProduct: undefined,
    currentGift: undefined,
    currentQuantities: undefined,
    currentExtrasQuantities: undefined,
    currentDay: undefined,
    currentTimeSlots: undefined,
    currentSlot: undefined,
    currentOrder: undefined,
    giftBeneficiary: undefined,
    currency: undefined
  };

  constructor(props: BookerProps) {
    super(props);
    if (!props.token) {
      throw new Error("The Open API token property is mandatory.");
    }
    setToken(props.token);
    if (!props.productId && !props.bookingId) {
      throw new Error("The productId property is mandatory.");
    }
    if (!props.currency || !props.currency.isoCode) {
      throw new Error("The currency property is mandatory.");
    }
    this.setState({ currency: props.currency });
    if (props.apiEndpoint) {
      window.API_ENDPOINT = props.apiEndpoint;
    }
    if (props.phpApiEndpoint) {
      window.PHP_API_ENDPOINT = props.phpApiEndpoint;
    }
    if (props.mediasEndpoint) {
      window.MEDIAS_ENDPOINT = props.mediasEndpoint;
    }
    if (props.order) {
      this.setState({ currentOrder: props.order });
    }
    if (props.locale) {
      switch (props.locale) {
        case "ca":
          this.setState({ localeDefinition: ca });
          break;

        case "de":
          this.setState({ localeDefinition: de });
          break;

        case "en":
          this.setState({ localeDefinition: en });
          break;

        case "es":
          this.setState({ localeDefinition: es });
          break;

        case "it":
          this.setState({ localeDefinition: it });
          break;

        case "nl":
          this.setState({ localeDefinition: nl });
          break;
      }
    }
  }

  setCurrentQuantities = currentQuantities => {
    let route;
    const { currentProduct } = this.state;
    if (
      currentProduct._embedded.unit &&
      (currentProduct._embedded.unit.type === "MAIN" ||
        currentProduct._embedded.unit.type === "FORFAIT") &&
      currentProduct.scheduleType !== "OPEN_BILLET_WITHOUT_DATE"
    ) {
      route = "day";
      this.setState({ currentQuantities, route });
      // } else if (currentProduct.type === 'FRANCE_BILLET') {
    } else if (
      currentProduct.type === "FRANCE_BILLET" &&
      currentProduct.publicMetadata.franceBillet.isTicketHolderMandatory ===
        true
    ) {
      route = "ticketHolders";
      this.setState({ currentQuantities, route });
    } else if (
      currentProduct.type === "FRANCE_BILLET" ||
      currentProduct.scheduleType === "OPEN_BILLET_WITHOUT_DATE" ||
      currentProduct._embedded.unit.type === "SESSION"
    ) {
      if (
        this.state.currentProduct._embedded.extraProducts &&
        this.state.currentProduct._embedded.extraProducts.length
      ) {
        this.setState({ currentQuantities, route: "extras" });
      } else {
        this.setState({ currentQuantities }, () => {
          this.bookProduct();
        });
      }
    }
  };

  getDayAvailabilities = (fromDate: Date, nextAvailableDate?: boolean) => {
    const { currentProduct, currentQuantities, currentBooking } = this.state;
    return getProductDayAvailabilities(currentProduct.id, fromDate, {
      unitQuantities: currentQuantities
        ? currentQuantities.quantities
        : undefined,
      isPrivatized: currentQuantities
        ? currentQuantities.isPrivatized
        : undefined,
      nextAvailableDate,
      excludedBookingsIds: currentBooking ? [currentBooking.id] : undefined,
      type: this.props.config.type,
      inProgress: this.props.config.inProgress
        ? this.props.config.inProgress
        : false
    });
  };

  setCurrentDay = currentDay => {
    let route;
    // TODO handle OPEN BILLET
    let { currentProduct } = this.state;
    this.setState({ currentDay });
    if (currentProduct.scheduleType === "OPEN_BILLET") {
      if (
        currentProduct._embedded.unit.type === "MAIN" ||
        currentProduct._embedded.unit.type === "FORFAIT"
      ) {
        if (
          currentProduct._embedded.extraProducts &&
          currentProduct._embedded.extraProducts.length &&
          this.props.config.type !== "pos"
        ) {
          route = "extras";
        } else {
          this.bookProduct();
        }
      } else if (currentProduct._embedded.unit.type === "SESSION") {
        route = "quantities";
      }
    } else {
      route = "slot";
    }
    this.setState({ route });
  };

  getProductTimeSlotAvailabilities = () => {
    const {
      currentProduct,
      currentDay,
      currentQuantities,
      currentBooking
    } = this.state;
    return getProductTimeSlotAvailabilities(
      currentProduct.id,
      currentDay.toISOString(),
      {
        unitQuantities: currentQuantities
          ? currentQuantities.quantities
          : undefined,
        isPrivatized: currentQuantities
          ? currentQuantities.isPrivatized
          : undefined,
        excludedBookingsIds: currentBooking ? [currentBooking.id] : undefined,
        type: this.props.config.type,
        inProgress: this.props.config.inProgress
          ? this.props.config.inProgress
          : false
      }
    );
  };

  setCurrentTimeSlots = currentTimeSlots => {
    this.setState({ currentTimeSlots });
  };

  setCurrentSlot = currentSlot => {
    if (
      this.state.currentProduct._embedded.unit &&
      (this.state.currentProduct._embedded.unit.type === "MAIN" ||
        this.state.currentProduct._embedded.unit.type === "FORFAIT")
    ) {
      if (
        this.state.currentProduct._embedded.extraProducts &&
        this.state.currentProduct._embedded.extraProducts.length &&
        (!this.props.editMode || this.props.editMode != "addTimeRange") &&
        this.props.config.type !== "pos"
      ) {
        this.setState({ currentSlot, route: "extras" });
      } else {
        this.setState({ currentSlot }, () => {
          this.bookProduct();
        });
      }
    } else if (
      this.state.currentProduct.type === "FRANCE_BILLET" ||
      (this.state.currentProduct._embedded.unit &&
        this.state.currentProduct._embedded.unit.type === "SESSION")
    ) {
      this.setState({ currentSlot, route: "quantities" });
    }
  };

  bookProduct = () => {
    if (this.props.preview !== true) {
      if (this.props.bookingId) {
        if (this.props.editMode == "addTimeRange") {
          this.setState({ loading: true }, () => {
            addTimeRange(
              this.state.currentBooking,
              this.state.currentQuantities,
              this.state.currentDay,
              this.state.currentSlot
            )
              .then(booking => {
                // this.setState({ loading: false }, () => {
                this.props.setOrder();
                // });
                return booking;
              })
              .catch(error => {
                this.setState({ error, loading: false });
                console.error("error adding time range booking", error);
                return error;
              });
          });
        } else {
          this.setState({ loading: true }, () => {
            updateBooking(
              this.state.currentBooking,
              this.state.currentQuantities,
              this.state.currentDay,
              this.state.currentSlot,
              this.state.currentExtrasQuantities
            )
              .then(booking => {
                //this.setState({ loading: false }, () => {
                this.props.setOrder();
                //});
                return booking;
              })
              .catch(error => {
                this.setState({ error, loading: false });
                console.error("error updating booking", error);
                return error;
              });
          });
        }
      } else {
        this.setState({ loading: true }, () => {
          createBooking(
            this.props.config.module
              ? this.props.config.module.hash
              : this.props.config.hash,
            this.props.config.type,
            this.state.currentProduct,
            this.state.currentQuantities,
            this.state.currentDay,
            this.state.currentSlot,
            this.state.currentExtrasQuantities,
            this.state.currentOrder,
            this.props.company,
            this.props.locale
          )
            .then(order =>
              this.props.voucherCode
                ? addVoucherCode(order.id, this.props.voucherCode).then(() =>
                    getOrderById(order.id)
                  )
                : order
            )
            .then(order => {
              // this.setState({ currentOrder: order, loading: false }, () => {
              this.props.setOrder(order);
              // });
              return order;
            })
            .catch(error => {
              this.setState({ error, loading: false });
              console.error("error creating booking", error);
              return error;
            });
        });
      }
    }
  };

  setCurrentExtrasQuantities = currentExtrasQuantities => {
    this.setState({ currentExtrasQuantities }, () => {
      this.bookProduct();
    });
  };

  // setGiftQuantity = giftQuantity => {
  //   this.setState({ giftQuantity, route: 'giftBeneficiary' });
  // };

  setBeneficiary = currentBeneficiary => {
    createGiftBooking(
      this.props.config.hash,
      this.props.config.type.toUpperCase(),
      this.state.currentGift || this.state.currentProduct,
      1,
      // this.state.giftQuantity,
      currentBeneficiary,
      this.state.currentOrder,
      this.props.company,
      this.state.config
    )
      .then(order => {
        this.setState({ currentOrder: order }, () => {
          this.props.setOrder(this.state.currentOrder);
        });
        return order;
      })
      .catch(error => {
        this.setState({ error });
        console.error("error creating booking", error);
      });
  };

  componentDidMount() {
    if (this.props.bookingId) {
      getBookingById(this.props.bookingId)
        .then(booking => {
          let unitQuantities = [];
          if (booking._embedded.timeRanges) {
            booking._embedded.timeRanges.forEach((timeRange, index) => {
              timeRange._embedded.unitQuantities.forEach(unitQuantity => {
                if (unitQuantity._embedded.unitSaved.type == "SESSION") {
                  unitQuantities.push({
                    quantity: unitQuantity.sessionQuantity,
                    numberOfSessions: index + 1,
                    id: unitQuantity._embedded.unitSaved.id
                  });
                } else {
                  unitQuantities = new Map(unitQuantities);
                  unitQuantities.set(
                    unitQuantity._embedded.unitSaved.id,
                    unitQuantity.sessionQuantity
                  );
                }
              });
            });
          }
          let currentExtrasQuantities = new Map();
          if (booking._embedded.associatedBookings) {
            booking._embedded.associatedBookings.forEach(associatedBooking => {
              currentExtrasQuantities.set(
                parseInt(
                  associatedBooking._links.product.href.split("/").pop()
                ),
                associatedBooking.quantity
              );
            });
          }
          let currentQuantities = {
            total: booking.quantity,
            quantities: unitQuantities
          };
          this.setState({
            currentBooking: booking,
            currentQuantities: currentQuantities,
            currentDay: new Date(booking.startTime),
            currentExtrasQuantities: currentExtrasQuantities
          });
          return getProductById(
            booking._embedded.product.id,
            this.props.locale
          ).then(product => {
            if (
              product.type === "CLASSIC" &&
              product.scheduleType !== "OPEN_BILLET"
            ) {
              this.setState({
                currentSlot: { startDateTime: booking.startTime }
              });
            }
            if (
              product.type === "CLASSIC" &&
              (product._embedded.unit.type === "MAIN" ||
                product._embedded.unit.type === "FORFAIT")
            ) {
              this.setState({
                loading: false,
                route:
                  this.props.editMode == "addTimeRange" ? "day" : "quantities",
                currentProduct: product
              });
            } else if (
              product.type === "FRANCE_BILLET" ||
              (product.type === "CLASSIC" &&
                product._embedded.unit.type === "SESSION")
            ) {
              this.setState({
                loading: false,
                route: "day",
                currentProduct: product
              });
            } else if (product.type === "GIFT") {
              this.setState({
                loading: false,
                // route: 'giftQuantity',
                route: "giftBeneficiary",
                currentProduct: product
              });
            }
            return product;
          });
        })
        .catch(error => {
          this.setState({ error });
          console.error("error getting initial data", error);
        });
    } else {
      let companyType =
        this.props.company && this.props.company.type
          ? this.props.company.type
          : undefined;
      getProductById(
        this.props.productId,
        this.props.locale,
        undefined,
        companyType
      )
        .then(product => {
          // Check isBook value to redirect to activity booking or gift booking
          if (this.props.isBook === "book") {
            if (companyType === "RESELLER") {
              //product._embedded.extraProducts = [];
              product._embedded.companyType = "RESELLER";
            }
            if (
              product.type === "CLASSIC" &&
              (product._embedded.unit.type === "MAIN" ||
                product._embedded.unit.type === "FORFAIT")
            ) {
              this.setState({
                loading: false,
                route: "quantities",
                currentProduct: product
              });
            } else if (
              product.type === "FRANCE_BILLET" ||
              (product.type === "CLASSIC" &&
                product._embedded.unit.type === "SESSION")
            ) {
              this.setState({
                loading: false,
                route: "day",
                currentProduct: product
              });
            } else if (product.type === "GIFT") {
              this.setState({
                loading: false,
                route: "giftBeneficiary",
                currentProduct: product
              });
            }
          } else if (this.props.isBook === "gift") {
            this.setState({
              loading: false,
              currentProduct: product
            });
            this.goToProductGifts();
          }
          return product;
        })
        .catch(error => {
          this.setState({ error });
          console.error("error getting initial data", error);
        });
    }

    if (!window.matchMedia) return;
    this.mql = window.matchMedia(
      "(max-width: " +
        (styles.singlePanelMode ? styles.singlePanelMode : "53.750em") +
        ")"
    );
    this.mql.addListener(this.onMatch);
    this.onMatch(this.mql);
  }

  componentWillUnmount() {
    this.mql && this.mql.removeListener(this.onMatch);
  }

  onMatch = mql => {
    const singlePanelMode = !!mql.matches;
    this.setState({ singlePanelMode });
  };

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    if (nextProps.currency !== this.state.currency) {
      this.setState({ currency: nextProps.currency });
    }
    if (nextProps.locale !== this.props.locale) {
      switch (nextProps.locale) {
        case "ca":
          this.setState({ localeDefinition: ca });
          break;

        case "de":
          this.setState({ localeDefinition: de });
          break;

        case "en":
          this.setState({ localeDefinition: en });
          break;

        case "es":
          this.setState({ localeDefinition: es });
          break;

        case "fr":
          this.setState({ localeDefinition: fr });
          break;

        case "it":
          this.setState({ localeDefinition: it });
          break;

        case "nl":
          this.setState({ localeDefinition: nl });
          break;
      }

      getProductById(this.props.productId, nextProps.locale).then(product => {
        this.setState({
          currentProduct: product
        });
      });
    }
  }

  goToQuantities = () => {
    this.setState({ route: "quantities" });
  };

  goToDay = () => {
    this.setState({ route: "day" });
  };

  goToSlot = () => {
    this.setState({ route: "slot" });
  };

  goToProductGifts = () => {
    if (
      this.state.currentProduct._embedded.giftProducts &&
      this.state.currentProduct._embedded.giftProducts.length === 1
    ) {
      this.goToGift(this.state.currentProduct._embedded.giftProducts[0].id);
    } else if (this.state.currentProduct.type === "GIFT") {
      this.goToGift(this.state.currentProduct.id);
    } else {
      this.setState({
        route: "productGifts",
        currentGift: undefined
      });
    }
  };

  goToProduct = () => {
    if (
      this.state.currentProduct.type === "CLASSIC" &&
      (this.state.currentProduct._embedded.unit.type === "MAIN" ||
        this.state.currentProduct._embedded.unit.type === "FORFAIT")
    ) {
      this.setState({
        currentGift: undefined,
        route: "quantities"
      });
    } else if (
      this.state.currentProduct.type === "CLASSIC" &&
      this.state.currentProduct._embedded.unit.type === "SESSION"
    ) {
      this.setState({
        currentGift: undefined,
        route: "day"
      });
    } else if (this.state.currentProduct.type === "GIFT") {
      this.setState({
        currentGift: undefined,
        route: "giftBeneficiary"
      });
    }
  };

  goToGift = giftId => {
    this.setState({
      loading: true
    });
    getProductById(giftId)
      .then(gift => {
        this.setState({
          loading: false,
          currentGift: gift,
          route: "giftBeneficiary"
        });
        return gift;
      })
      .catch(error => {
        this.setState({ error });
        console.error("error getting gift product", error);
      });
  };

  getTheme = (configType: string) => {
    return configType === "KIOSK"
      ? themeKiosk
      : configType === "PMS"
      ? themeMarketplace
      : configType === "SHOP"
      ? themeShop
      : undefined;
  };

  render(
    { config, locale, setOrder, company },
    {
      loading,
      error,
      route,
      localeDefinition,
      currentOrder,
      currentProduct,
      currentGift,
      currentQuantities,
      currentDay,
      currentTimeSlots,
      currentSlot,
      currentExtrasQuantities,
      currency
    }
  ) {
    const currentTheme = this.getTheme(config.type);
    return (
      <ThemeProvider theme={currentTheme}>
        <WidgetContextProvider widgetContext={config.type}>
          <IntlProvider definition={localeDefinition}>
            <div
              class={
                currentTheme && currentTheme.booker
                  ? currentTheme.booker
                  : styles.booker
              }
            >
              <Loading loading={loading && !error} />
              {error && (
                <div class={styles.errorMessage}>
                  <Text id={"basket.promoCode.error." + error.stringCode}>
                    {error.stringCode}
                  </Text>
                </div>
              )}
              {!loading && !error && (
                <Header
                  theme={currentTheme}
                  route={route}
                  config={config}
                  singlePanelMode={this.state.singlePanelMode}
                  locale={locale}
                  currency={currency}
                  currentProduct={currentGift || currentProduct}
                  currentQuantities={currentQuantities}
                  currentExtrasQuantities={currentExtrasQuantities}
                  currentDay={currentDay}
                  currentSlot={currentSlot}
                  goToQuantities={this.goToQuantities}
                  goToDay={this.goToDay}
                  goToSlot={this.goToSlot}
                  goToGifts={this.goToProductGifts}
                />
              )}
              {!loading && !error && route === "productGifts" && (
                <ProductGifts
                  theme={currentTheme}
                  config={config}
                  locale={locale}
                  currency={currency}
                  currentProduct={currentProduct}
                  goToGift={this.goToGift}
                />
              )}
              {!loading && !error && route === "quantities" && (
                  <Quantities
                    theme={currentTheme}
                    config={config}
                    locale={locale}
                    currency={currency}
                    currentProduct={currentProduct}
                    currentQuantities={currentQuantities}
                    currentTimeSlots={currentTimeSlots}
                    currentSlot={currentSlot}
                    setCurrentQuantities={this.setCurrentQuantities}
                    goToSlot={this.goToSlot}
                  />
              )}
              {!loading && !error && route === "day" && (
                <Day
                  theme={currentTheme}
                  config={config}
                  locale={locale}
                  currency={currency}
                  currentProduct={currentProduct}
                  currentQuantities={currentQuantities}
                  currentDay={currentDay}
                  onDayClick={this.setCurrentDay}
                  getProductMonthAvailabilities={this.getDayAvailabilities}
                  goToQuantities={this.goToQuantities}
                />
              )}
              {!loading && !error && route === "slot" && (
                <TimeSlots
                  theme={currentTheme}
                  config={config}
                  locale={locale}
                  currency={currency}
                  currentProduct={currentProduct}
                  currentQuantities={currentQuantities}
                  currentSlot={currentSlot}
                  setCurrentTimeSlots={this.setCurrentTimeSlots}
                  onSlotClick={this.setCurrentSlot}
                  getProductTimeSlotAvailabilities={
                    this.getProductTimeSlotAvailabilities
                  }
                  goToDay={this.goToDay}
                />
              )}
              {!loading && !error && route === "extras" && (
                <Extras
                  theme={currentTheme}
                  config={config}
                  locale={locale}
                  currentProduct={currentProduct}
                  currency={currency}
                  extras={currentProduct._embedded.extraProducts}
                  setCurrentQuantities={this.setCurrentExtrasQuantities}
                  currentExtrasQuantities={currentExtrasQuantities}
                />
              )}
              {/*!loading &&
            !error &&
            route === 'giftQuantity' && (
              <GiftQuantity
                theme={config.type === 'KIOSK' ? themeKiosk : themeDefault}
                config={config}
                currentGift={currentGift}
                maxParticipants={currentProduct.maxParticipants}
                setGiftQuantity={this.setGiftQuantity}
              />
            )*/}
              {!loading && route === "giftBeneficiary" && (
                <GiftBeneficiary
                  theme={currentTheme}
                  config={config}
                  locale={locale}
                  currentOrder={currentOrder}
                  currentCompany={company}
                  currentProduct={currentProduct}
                  setOrder={setOrder}
                  error={error}
                  currentGift={currentGift}
                />
              )}
              {!loading && route === "ticketHolders" && (
                <TicketHolders
                  theme={currentTheme}
                  config={config}
                  locale={locale}
                  currentOrder={currentOrder}
                  currentDay={currentDay}
                  currentTimeSlots={currentTimeSlots}
                  currentSlot={currentSlot}
                  currentQuantities={currentQuantities}
                  currentCompany={company}
                  currentProduct={currentProduct}
                  setOrder={setOrder}
                  error={error}
                />
              )}
            </div>
          </IntlProvider>
        </WidgetContextProvider>
      </ThemeProvider>
    );
  }
}
