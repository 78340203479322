// @flow
// @jsx h

declare var STRIPE_API_KEY: string;
declare var Stripe: Object;

// External librairies
import { h, Component } from "preact";

import CreditCardConfirmation from "../payment-confirmation/CreditCardConfirmation";

// API methods
import { StripeService } from "elc-internal-api";

type Stripe3DSecureConfirmationProps = {
  config: WidgetConfig,
  order: Order,
  source: string,
  client_secret: string
};

type Stripe3DSecureConfirmationState = {
  loading: boolean,
  error: string
};

/**
 * Product Stateful Component
 * fetch a product by his id
 * and render the Product Component
 * displaying product details and booker
 */
class Stripe3DSecureConfirmation extends Component<
  Stripe3DSecureConfirmationProps,
  Stripe3DSecureConfirmationState
> {
  state = {
    loading: true,
    error: undefined,
    order: undefined
  };

  componentDidMount() {
    const stripe = Stripe(STRIPE_API_KEY);
    const { source, client_secret, config, order } = this.props;
    Promise.all([
      stripe.retrieveSource({
        id: source,
        client_secret: client_secret
      }),
      StripeService.getPaymentMethods(
        config.company.id,
        order.id,
        config.type.toLowerCase()
      )
    ])
      .then(([{ source }, paymentMethods]) => {
        // throw an error if no credit card payment module configured for this company
        if (
          !paymentMethods.find(
            paymentMethod => paymentMethod.baseModule.code === "creditcard"
          )
        ) {
          throw new Error(
            "Credit card payment method is not available for this company."
          );
          // return Promise.reject(
          //   'Credit card payment method is not available for this company.'
          // );
        } else if (source.error) {
          throw new Error("3D Secure error.");
        } else if (
          source.status === "canceled" ||
          source.status === "consumed" ||
          source.status === "failed"
        ) {
          throw new Error("3D Secure error: canceled/consumed/fail.");
        } else if (
          /* source.three_d_secure.authenticated && */ source.status ===
          "chargeable"
        ) {
          const creditCardPaymentMethod = paymentMethods.find(
            paymentMethod => paymentMethod.baseModule.code === "creditcard"
          );
          return Promise.all([
            creditCardPaymentMethod,
            StripeService.createCharge({
              orderId: order.id,
              confId: creditCardPaymentMethod.configuration.id,
              // amount: order.amount,
              // currency: currency.isoCode,
              source: source.id,
              downPayment:
                order.status === "USER_FILLED" &&
                creditCardPaymentMethod.configuration.deposit !== 100
                  ? true
                  : false
              // customer: customer
            })
          ]);
        } else {
          throw new Error("Unexpected 3D Secure error.");
        }
      })
      .then(([creditCardPaymentMethod, payment]) => {
        this.setState({
          loading: false,
          paymentMethod: creditCardPaymentMethod,
          payment
        });
        return payment;
      })
      .catch(error => {
        console.error(error);
        if (error.chargeErrorDetails) {
          error = error.chargeErrorDetails.message;
        }
        this.setState({ loading: false, error });
      });
  }

  render(
    { config }: Stripe3DSecureConfirmationProps,
    { loading, error, paymentMethod, payment }: Stripe3DSecureConfirmationState
  ) {
    return (
      <div>
        {loading && <div>Processing 3D Secure validation...</div>}
        {error && <div>Error: {error}</div>}
        {!loading &&
          !error && (
            <CreditCardConfirmation
              config={config}
              currency={payment.charge.currency}
              paymentMethod={paymentMethod}
              payment={payment}
            />
          )}
      </div>
    );
  }
}

export default Stripe3DSecureConfirmation;
