// @flow

/**
 * Client side form inputs validation
 */
export default function inputsFormValidation(
  formFields: Array<ClientFormField>,
  formData,
  isClientInformationMandatory
) {
  // mandatory inputs validation
  let formErrors = formFields
    .filter(formField => formField.mandatory === true)
    .reduce((acc, curr) => {
      let formItem = formData.find(
        inputData =>
          inputData.clientFormInputType.id ===
          curr._embedded.clientFormInputType.id
      );
      if (!formItem || formItem.value === "") {
        return acc.concat([
          { id: curr._embedded.clientFormInputType.id, type: "mandatory" }
        ]);
      } else {
        return acc;
      }
    }, []);
  // email validation

  let emailData = formData.find(
    inputData => inputData.clientFormInputType.inputType === "EMAIL"
  );

  //On test la validation du mail ATTENTION : existe aussi pour le particpant-client-forms
  let emailDataConfirm = formData.find(
    inputData => inputData.clientFormInputType.inputType === "EMAILCONFIRM"
  );

  if (emailData && emailData.value != "" && !isValidEmail(emailData.value)) {
    formErrors = [
      ...formErrors,
      { id: emailData.clientFormInputType.id, type: "invalid" }
    ];
  }

  if (emailDataConfirm && emailDataConfirm.value != "") {
    if (!isValidEmail(emailDataConfirm.value)) {
      formErrors = [
        ...formErrors,
        { id: emailData.clientFormInputType.id, type: "invalid" }
      ];
    }

    if (emailData.value != emailDataConfirm.value) {
      formErrors = [
        ...formErrors,
        { id: emailData.clientFormInputType.id, type: "invalidconfirm" }
      ];
    }
  } else {
    if (emailData && emailData.value != "") {
      formErrors = [
        ...formErrors,
        { id: emailData.clientFormInputType.id, type: "confirmationrequired" }
      ];
    }
  }

  // phone number validation
  formData
    .filter(formField => formField.clientFormInputType.inputType === "TEL")
    .map(phoneField => {
      if (!phoneField.isValid) {
        formErrors = [
          ...formErrors,
          { id: phoneField.clientFormInputType.id, type: "invalid" }
        ];
      }
    });

  //address validation
  if (
    formFields.find(
      formField =>
        formField.mandatory === true &&
        formField._embedded.clientFormInputType.inputType === "ADDRESS"
    ) ||
    (formFields.find(
      formField =>
        formField._embedded.clientFormInputType.inputType === "ADDRESS"
    ) &&
      isClientInformationMandatory)
  ) {
    let addressData = formData.find(
      inputData => inputData.clientFormInputType.inputType === "ADDRESS"
    );
    if (addressData) {
      const addressParsed = JSON.parse(addressData.value);
      let addressErrors = [];
      if (!addressParsed.route) {
        addressErrors.push("route");
      }
      if (!addressParsed.locality) {
        addressErrors.push("locality");
      }
      if (!addressParsed.country) {
        addressErrors.push("country");
      }
      if (!addressParsed.postal_code) {
        addressErrors.push("postal_code");
      }
      if (addressErrors.length > 0) {
        formErrors = [
          ...formErrors,
          {
            id: addressData.clientFormInputType.id,
            type: "mandatory",
            fields: addressErrors
          }
        ];
      }
    } else {
      formErrors = [
        ...formErrors,
        {
          id: formFields.find(
            formField =>
              formField._embedded.clientFormInputType.inputType === "ADDRESS"
          )._embedded.clientFormInputType.id,
          type: "mandatory",
          fields: ["route", "locality", "country", "postal_code"]
        }
      ];
    }
  }

  return formErrors;
}

const isValidEmail = email =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
// function isValidEmail(email) {
//   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(email);
// }
