// @flow
// @jsx h
import { h, Component } from "preact";
import { Text } from "preact-i18n";

import { PrimaryButton, BackButton } from "elc-button";

import Container from "../container";
import BookerContainer from "../bookercontainer";
import UnitsQuantities from "./units-quantities";
import SessionsQuantities from "./sessions-quantities";
import FranceBilletQuantities from "./france-billet-quantities";
import StartingPrice from "../starting-price";
import FooterButton from "../footerbutton";

import hasInvalidQuantities from "./hasInvalidQuantities";
import getMandatoryMainUnitQuantityToAdd from "./getMandatoryMainUnitQuantityToAdd";

import { ArrowLeftIcon } from "elc-icons";

import { sendCheckoutEventProductGTM } from "elc-commons";

import styles from "./style.scss";

type Quantities = {
  quantities?: {
    quantities: Map<number | string, number>,
    isPrivatized: boolean
  },
  isPrivatized: boolean
};

type ProductQuantitiesProps = {
  config: WidgetConfig,
  locale: string,
  currency: Currency,
  currentProduct: Product,
  currentQuantities: Quantities,
  currentTimeSlots?: Array<TimeSlot>,
  currentSlot?: TimeSlot,
  setCurrentQuantities: Function,
  goToSlot: Function
};

type ProductQuantitiesState = {
  quantities?: Map<number, number>,
  isPrivatized: boolean,
  errors: {
    mandatoryMainUnitToAdd: number
  }
};

/**
 * Product Quantities component
 * displaying the quantities selectors for the different product units
 */
export default class ProductQuantities extends Component<
  ProductQuantitiesProps,
  ProductQuantitiesState
> {
  state = {
    // quantities: new Map(),
    isPrivatized: false,
    errors: {
      mandatoryMainUnitToAdd: 0
    }
  };

  constructor(props: ProductQuantitiesProps) {
    super(props);
    const { currentProduct } = this.props;
    if (props.currentQuantities) {
      this.state.quantities = props.currentQuantities.quantities;
      this.state.isPrivatized = props.currentQuantities.isPrivatized;
    } else if (
      (currentProduct && currentProduct.type === "FRANCE_BILLET") ||
      (currentProduct &&
        currentProduct._embedded.unit &&
        (currentProduct._embedded.unit.type === "MAIN" ||
          currentProduct._embedded.unit.type === "FORFAIT"))
    ) {
      this.state.quantities = new Map();
    } else {
      this.state.quantities = [];
    }
  }

  onChangePrivatized = (isPrivatized: boolean) => {
    this.setState({ isPrivatized });
  };

  updateCurrentQuantities = quantities => {
    const { currentProduct } = this.props;
    if (
      currentProduct &&
      currentProduct._embedded.unit &&
      currentProduct._embedded.unit.type === "MAIN"
    ) {
      const mandatoryMainUnitToAdd = getMandatoryMainUnitQuantityToAdd(
        quantities,
        this.props.currentProduct.units
      );
      const errors = { ...this.state.errors };
      if (mandatoryMainUnitToAdd > 0) {
        errors.mandatoryMainUnitToAdd = mandatoryMainUnitToAdd;
        this.setState({ quantities, errors });
      } else {
        errors.mandatoryMainUnitToAdd = 0;
        this.setState({ quantities, errors });
      }
    } else {
      this.setState({ quantities });
    }
  };

  onClickNext = () => {
    this.props.setCurrentQuantities({
      quantities: this.state.quantities,
      isPrivatized: this.state.isPrivatized
    });
    sendCheckoutEventProductGTM(this.props.currentProduct, 1, false);
  };

  render(
    {
      theme,
      config,
      currentProduct,
      locale,
      currency,
      currentTimeSlots,
      currentSlot,
      goToSlot
    },
    { quantities, isPrivatized, errors }
  ) {
    return (
      <Container>
        <BookerContainer product={currentProduct}>
          {currentProduct &&
            currentProduct._embedded.unit &&
            (currentProduct._embedded.unit.type === "MAIN" ||
              currentProduct._embedded.unit.type === "FORFAIT") && (
              <UnitsQuantities
                theme={theme}
                tax={currentProduct._embedded.tax}
                locale={locale}
                currency={currency}
                product={currentProduct}
                quantities={quantities}
                isPrivatized={isPrivatized}
                units={currentProduct.units}
                minParticipants={currentProduct.minParticipants}
                maxParticipants={currentProduct.maxParticipants}
                canBePrivatized={currentProduct.canBePrivatized}
                minParticipantsToPrivatize={
                  currentProduct.minParticipantsToPrivatize
                }
                updateCurrentQuantities={this.updateCurrentQuantities}
                onChangePrivatized={this.onChangePrivatized}
              />
            )}
          {currentProduct &&
            currentProduct._embedded.unit &&
            currentProduct._embedded.unit.type === "SESSION" && (
              <SessionsQuantities
                theme={theme}
                tax={currentProduct._embedded.tax}
                locale={locale}
                currency={currency}
                quantities={quantities}
                units={currentProduct.units}
                currentTimeSlots={currentTimeSlots}
                currentSlot={currentSlot}
                minParticipants={currentProduct.minParticipants}
                maxParticipants={currentProduct.maxParticipants}
                canBePrivatized={currentProduct.canBePrivatized}
                minParticipantsToPrivatize={
                  currentProduct.minParticipantsToPrivatize
                }
                updateCurrentQuantities={this.updateCurrentQuantities}
                onChangePrivatized={this.onChangePrivatized}
              />
            )}
          {currentProduct &&
            currentProduct.type === "FRANCE_BILLET" && (
              <FranceBilletQuantities
                theme={theme}
                tax={currentProduct._embedded.tax}
                locale={locale}
                currency={currency}
                quantities={quantities}
                currentTimeSlots={currentTimeSlots}
                currentSlot={currentSlot}
                minParticipants={currentProduct.minParticipants}
                maxParticipants={currentProduct.maxParticipants}
                canBePrivatized={currentProduct.canBePrivatized}
                minParticipantsToPrivatize={
                  currentProduct.minParticipantsToPrivatize
                }
                updateCurrentQuantities={this.updateCurrentQuantities}
              />
            )}
          {errors.mandatoryMainUnitToAdd > 0 && (
            <span
              class={
                theme && theme.quantityMessage
                  ? theme.quantityMessage
                  : styles.quantityMessage
              }
            >
              <Text
                id="quantities.mandatoryMainUnitQuantity.notReached"
                fields={{
                  mandatoryMainUnitQuantityToAdd: errors.mandatoryMainUnitToAdd,
                  mainUnitName: currentProduct.units.find(
                    unit => unit.type === "MAIN"
                  ).name
                }}
              >
                You need to add {errors.mandatoryMainUnitToAdd}{" "}
                {currentProduct.units.find(unit => unit.type === "MAIN").name}{" "}
                to continue.
              </Text>
            </span>
          )}
        </BookerContainer>
        {(config.type === "KIOSK" || config.type === "PMS") && (
          <StartingPrice
            theme={theme}
            productType={currentProduct.type}
            price={
              currentProduct.type !== "GIFT" && currentProduct.type !== "EXTRA"
                ? currentProduct.displayedStartingPrice
                : currentProduct._embedded.prices[0].value
            }
            locale={locale}
            currency={currency}
          />
        )}
        <FooterButton theme={theme} config={config}>
          <PrimaryButton
            disabled={hasInvalidQuantities(currentProduct, quantities, {
              isPrivatized,
              discounts:
                currentSlot &&
                currentSlot.publicMetadata &&
                currentSlot.publicMetadata.franceBillet &&
                currentSlot.publicMetadata.franceBillet.discounts
            })}
            handleClick={this.onClickNext}
          >
            <Text id="footer.button.next">Next</Text>
          </PrimaryButton>
          {currentProduct &&
            currentProduct._embedded.unit &&
            currentProduct._embedded.unit.type === "SESSION" && (
              <BackButton handleClick={goToSlot}>
                {config.type !== "KIOSK" &&
                  config.type !== "PMS" && (
                    <ArrowLeftIcon
                      color={
                        this.context.themeConfig &&
                        this.context.themeConfig.color1
                          ? this.context.themeConfig.color1
                          : styles.green
                      }
                    />
                  )}
                <Text id="footer.button.previous">Previous</Text>
              </BackButton>
            )}
        </FooterButton>
      </Container>
    );
  }
}
