// @flow
// @jsx h
declare var API_ENDPOINT: string;
// External librairies
import { h, Component } from "preact";
import { Text } from "preact-i18n";

// Style
import styles from "./style.scss";
import { withStyles } from "elc-theme";

// Components
import Container from "../container";
import BasketContainer from "../basketcontainer";
import Booking from "./booking";
import AddPromoCode from "./addpromocode";
import PromoCode from "./booking/promocode";

import ClientInfos from "./ClientInfos";
import TermSales from "./term-sales";
import { PrimaryButton, BackButton } from "elc-button";
import FooterButton from "../footerbutton";
import {
  MasterCardIcon,
  VisaIcon,
  MaestroIcon,
  VirementBancaireIcon,
  PCIDSSIcon,
  ArrowLeftIcon
} from "elc-icons";
import Loading from "elc-loading";

import {
  toLocalePrice,
  sendCheckoutEventOrderGTM,
  sendDataLayerVariableGTM
} from "elc-commons";

import { sendOrderConfirmation } from "elc-internal-api";

type BasketProps = {
  config: WidgetConfig,
  paymentContext?: string,
  locale: string,
  currency: Currency,
  currentOrder: Order,
  onDeleteBooking: Function,
  onAddPromoCode: Function,
  goToClientForm: Function,
  goToConfirmation: Function,
  goToConfirmationPayOnArrival: Function,
  goToConfirmationCash: Function,
  goToPayment?: Function,
  addVoucherError?: Error,
  noPayment: boolean,
  paymentMethod: PaymentMethod,
  checkSession: Function
};

type BasketState = {
  isTermSalesAccepted: boolean,
  loading: boolean,
  orderLocale: string,
  loadingPaiement: string
};

/**
 * Basket Component showing the order and his bookings
 * with the possibility of :
 * - delete a bookings
 * - add a promo code
 * - finalize the order by going a the client details form
 */
class Basket extends Component<BasketProps, BasketState> {
  state = {
    isTermSalesAccepted: false,
    loading: false,
    orderLocale: null,
    loadingPaiement: null
  };


  goToPayment = () => {
    this.props.checkSession(this.props.currentOrder, (sessionValid) => {
      if(sessionValid){
        this.setState({ loading: true });
        this.props.goToPayment();
      }
    });
  };

  toggleTermSalesAccepted = isTermSalesAccepted => {
    this.setState({ isTermSalesAccepted });
  };

  setUserClientData = userClientData => {
    this.setState({ userClientData });
  };
  getOrderRecap() {}
  /**
   * handle the click on finalize order button
   */
  onClickSendOrderConfirmation = () => {
    this.props.checkSession(this.props.currentOrder, (sessionValid) => {
      if(sessionValid){
        const { currentOrder, goToConfirmation } = this.props;

        this.setState({ loadingPaiement: "OrderConfirmation" });

        // const { userClientData } = this.state;
        let orderConfirmationPayload = {};

        if (this.props.currentOrder._embedded.userSaved.email) {
          orderConfirmationPayload.email = this.props.currentOrder._embedded.userSaved.email;
        }
        if (this.props.currentOrder._embedded.userSaved.phone) {
          orderConfirmationPayload.mobilePhone = this.props.currentOrder._embedded.userSaved.phone;
        }
        // if (
        //   userClientData &&
        //   userClientData.find(
        //     data => data._embedded.clientFormInputTypeSaved.code === "mobilephone"
        //   )
        // )
        //   orderConfirmationPayload.mobilePhone = userClientData.find(
        //     data => data._embedded.clientFormInputTypeSaved.code === "mobilephone"
        //   ).value;

        sendDataLayerVariableGTM({
          "user-id": this.props.currentOrder._embedded.userSaved.id
        });

        sendCheckoutEventOrderGTM(this.props.currentOrder, 7, false);

        if (Object.keys(orderConfirmationPayload)) {
          sendOrderConfirmation(
            currentOrder.id,
            orderConfirmationPayload,
            currentOrder ? currentOrder.locale : "fr",
            this.state.orderLocale
          )
            .then(() => goToConfirmation())
            .catch(error => console.error(error));
        } else {
          this.setState({ loadingPaiement: null });
          throw new Error("The order has no email or mobilePhone.");
        }
      }
    })
  };

  /**
   * handle the click pay on arrival
   */
  onClickSendOrderConfirmationPayOnArrival = () => {
    this.props.checkSession(this.props.currentOrder, (sessionValid) => {
      if(sessionValid){
        this.setState({ loadingPaiement: "OrderConfirmationPayOnArrival" });

        let payOnArrival = true;
        // putain
        const { currentOrder, goToConfirmationPayOnArrival } = this.props;
        // const { userClientData } = this.state;

        let orderConfirmationPayload = {};

        if (this.props.currentOrder._embedded.userSaved.email) {
          orderConfirmationPayload.email = this.props.currentOrder._embedded.userSaved.email;
        }
        if (this.props.currentOrder._embedded.userSaved.phone) {
          orderConfirmationPayload.mobilePhone = this.props.currentOrder._embedded.userSaved.phone;
        }
        if (payOnArrival) {
          orderConfirmationPayload.payOnArrival = payOnArrival;
        }
        // if (
        //   userClientData &&
        //   userClientData.find(
        //     data => data._embedded.clientFormInputTypeSaved.code === "mobilephone"
        //   )
        // )
        //   orderConfirmationPayload.mobilePhone = userClientData.find(
        //     data => data._embedded.clientFormInputTypeSaved.code === "mobilephone"
        //   ).value;

        sendDataLayerVariableGTM({
          "user-id": this.props.currentOrder._embedded.userSaved.id
        });

        sendCheckoutEventOrderGTM(this.props.currentOrder, 7, false);

        if (Object.keys(orderConfirmationPayload)) {
          sendOrderConfirmation(
            currentOrder.id,
            orderConfirmationPayload,
            currentOrder ? currentOrder.locale : "fr",
            this.state.orderLocale
          )
            .then(() => goToConfirmationPayOnArrival())
            .catch(error => console.error(error));
        } else {
          this.setState({ loadingPaiement: null });
          throw new Error("The order has no email or mobilePhone.");
        }
      }
    })

  };

  /**
   * handle the click cash
   */
  onClickSendOrderConfirmationCash = () => {
    this.props.checkSession(this.props.currentOrder, (sessionValid) => {
      if(sessionValid){
          this.setState({ loadingPaiement: "OrderConfirmationCash" });
          // console.log("CA PASSE avec CASH ");
          let cash = true;
          // putain
          const { currentOrder, goToConfirmationCash } = this.props;
          // const { userClientData } = this.state;

          let orderConfirmationPayload = {};

          if (this.props.currentOrder._embedded.userSaved.email) {
            orderConfirmationPayload.email = this.props.currentOrder._embedded.userSaved.email;
          }
          if (this.props.currentOrder._embedded.userSaved.phone) {
            orderConfirmationPayload.mobilePhone = this.props.currentOrder._embedded.userSaved.phone;
          }
          if (cash) {
            orderConfirmationPayload.cash = cash;
          }
          sendDataLayerVariableGTM({
            "user-id": this.props.currentOrder._embedded.userSaved.id
          });
          sendCheckoutEventOrderGTM(this.props.currentOrder, 7, false);

          if (Object.keys(orderConfirmationPayload)) {
            sendOrderConfirmation(
              currentOrder.id,
              orderConfirmationPayload,
              currentOrder ? currentOrder.locale : "fr",
              this.state.orderLocale
            )
              .then(() => goToConfirmationCash())
              .catch(error => console.error(error));
          } else {
            this.setState({ loadingPaiement: null });
            throw new Error("The order has no email or mobilePhone.");
          }
        }
      });
  };

  /**
   * Change select langue
   */
  onChangeFacture = e => {
    this.setState({
      orderLocale: e.target.value == "" ? null : e.target.value
    });

    //console.log("onChangeFacture", e.target.value);
    //console.log("currentOrder", this.props.currentOrder.id);
  };

  /**
   * render
   * @param {BasketProps} the Basket component props
   * @return {ReactElement} markup
   */
  render(
    {
      config,
      paymentContext,
      locale,
      currency,
      currentOrder,
      onDeleteBooking,
      onAddPromoCode,
      goToClientForm,
      goToCatalog,
      // goToPayment,
      addVoucherError,
      getClassName,
      noPayment,
      paymentMethod,
      checkSession
    }: {
      config: WidgetConfig,
      locale: string,
      currency: Currency,
      currentOrder: Order,
      onDeleteBooking: Function,
      onAddPromoCode: Function,
      goToClientForm: Function,
      goToCatalog: Function,
      getClassName: Function,
      // goToPayment?: Function,
      addVoucherError?: Error,
      noPayment: boolean,
      checkSession: Function
    },
    {
      isTermSalesAccepted,
      loading,
      loadingPaiement
    }: {
      isTermSalesAccepted: boolean,
      loading: boolean,
      loadingPaiement: string
    }
  ) {
    const lang = [
      {
        value: "fr",
        label: "Français"
      },
      {
        value: "en",
        label: "Anglais"
      },
      {
        value: "de",
        label: "Allemand"
      },
      {
        value: "it",
        label: "Italien"
      },
      {
        value: "nl",
        label: "Néerlandais"
      },
      {
        value: "es",
        label: "Espagnol"
      },
      {
        value: "ca",
        label: "Catalan"
      }
    ];

    let bookings = currentOrder ? currentOrder._embedded.bookings : [];
    let promoCode;
    let orderContainesGiftProduct = false; // pas de payOnArrival si GIFT dans le panier
    let orderContainsPromocode = false;
    let multiMerchants = false;

    let payOnArrivalAuthorized = false;
    let cashAuthorized = false;
    this.listBookingsMerchants = [];
    this.listBookingsMerchantsWithLinks = [];
    if (currentOrder) {
      payOnArrivalAuthorized = currentOrder._embedded.company.payOnArrivalAuthorized; // config admin
      cashAuthorized = currentOrder._embedded.company.cashAuthorized; // config admin

      checkSession(currentOrder);

    }





    bookings.forEach(item => {
      if (item._embedded && item._embedded.merchant) {
        this.listBookingsMerchants.push(item._embedded.merchant.id);
      }
      if (item._links && item._links.merchant) {
        // console.log("item._links.merchant.href  :", item._links.merchant.href);
        let link = item._links.merchant.href;
        let parts = link.split("/v1/companies/");
        let url = Number(parts[1]);

        this.listBookingsMerchantsWithLinks.push(url);
      }
    });
    /*    console.log(
      "this.listBookingsMerchantsWithLinks ; ",
      this.listBookingsMerchantsWithLinks
    );*/
    /*    console.log("this.listBookingsMerchants ; ", this.listBookingsMerchants);*/
    this.listBookingsMerchants = this.listBookingsMerchants.concat(
      this.listBookingsMerchantsWithLinks
    );

    const uniqueMerchants = Array.from(new Set(this.listBookingsMerchants));
    /*    const uniqueMerchantsWithLinks = Array.from(
      new Set(this.listBookingsMerchantsWithLinks)
    );*/
    // console.log(uniqueMerchants);
    if (uniqueMerchants.length > 1) {
      multiMerchants = true;
    }
    //  console.log(multiMerchants);
    bookings.forEach(item => {
      if (item._embedded.promoCodes && item._embedded.promoCodes.length > 0) {
        // console.log(item._embedded.promoCodes.length);
        promoCode =
          item._embedded.promoCodes[item._embedded.promoCodes.length - 1];
        orderContainsPromocode = true;
        // console.log("orderContainsPromocode?", orderContainsPromocode);
      }
    });

    bookings.forEach(item => {
      if (item._embedded.product.type === "GIFT") {
        orderContainesGiftProduct = true;
      }
    });

    return (
      <Container>
        <BasketContainer>
          {!currentOrder ? (
            <div class={getClassName("emptyBasket")}>
              <Text id="basket.empty">Your basket is empty.</Text>
            </div>
          ) : (
            currentOrder && (
              <div class={getClassName("basket")}>
                <div class={getClassName("basketScrollableContent")}>
                  {currentOrder && currentOrder.status === "USER_FILLED" && (
                    <div class={getClassName("basketInfosReminder")}>
                      <strong>
                        <Text id="basket.infosReminder">
                          Veuillez confirmer vos informations.
                        </Text>
                      </strong>
                      <em>
                        <Text id="basket.infosReminderSuite">
                          Attention : Après la confirmation, vous avez 15
                          minutes pour finaliser le paiement.
                        </Text>
                      </em>
                    </div>
                  )}
                  {currentOrder &&
                    currentOrder.status !== "DRAFT" &&
                    currentOrder._embedded.userSaved && (
                      <ClientInfos
                        orderStatus={currentOrder.status}
                        user={currentOrder._embedded.userSaved}
                        userClientData={
                          currentOrder._embedded.userSaved._embedded &&
                          currentOrder._embedded.userSaved._embedded
                            .clientDataList
                            ? currentOrder._embedded.userSaved._embedded
                                .clientDataList
                            : undefined
                        }
                        goToClientForm={goToClientForm}
                      />
                    )}
                  <div class={getClassName("bookingsContainer")}>
                    {currentOrder._embedded.bookings &&
                      currentOrder._embedded.bookings.map(booking => (
                        <Booking
                          key={booking.id}
                          order={currentOrder}
                          booking={booking}
                          locale={locale}
                          currency={currency}
                          onDeleteBooking={onDeleteBooking}
                        />
                      ))}
                  </div>
                  {currentOrder.publicMetadata &&
                    currentOrder.publicMetadata.additionalPlatformFees &&
                    currentOrder.publicMetadata.additionalPlatformFees
                      .franceBillet && (
                      <footer class={getClassName("additionalPlatformFees")}>
                        <span
                          class={getClassName("additionalPlatformFeesLabel")}
                        >
                          <Text id="basket.additionalPlatformFees">
                            Additional platform fees :
                          </Text>
                        </span>
                        <span
                          class={getClassName("additionalPlatformFeesPrice")}
                          style={`color:${
                            this.context.themeConfig &&
                            this.context.themeConfig.color1
                              ? this.context.themeConfig.color1
                              : ""
                          };`}
                        >
                          {toLocalePrice(
                            currentOrder.publicMetadata.additionalPlatformFees
                              .franceBillet.deliveryMode.priceTaxIncluded,
                            locale,
                            currency.isoCode
                          )}
                        </span>
                      </footer>
                    )}
                </div>
                {/* on masque les code promo si on arrive du SHOP */}
                {config.type !== "KIOSK" &&
                  (this.props.currentOrder.origin !== "SHOP" && this.props.currentOrder.origin !== "OPEN_API" ) &&
                  (this.props.paymentMethod && this.props.paymentMethod.configuration.deposit === 100 ) && (
                    <div>
                      <AddPromoCode
                        config={config}
                        order={currentOrder}
                        onAddPromoCode={onAddPromoCode}
                        addVoucherError={addVoucherError}
                      />
                    </div>
                  )}
                {currentOrder.status === "USER_FILLED" &&
                  (!paymentContext || paymentContext !== "mobile") && (
                    <TermSales
                      order={currentOrder}
                      isTermSalesAccepted={isTermSalesAccepted}
                      toggleTermSalesAccepted={this.toggleTermSalesAccepted}
                    />
                  )}
                {promoCode && (
                  <div>
                    {this.props.addVoucherError &&
                      this.props.addVoucherError.stringCode !== "" && (
                        <div class={styles.errorMessage}>
                          <Text
                            id={
                              "basket.promoCode.error." +
                              this.props.addVoucherError.stringCode
                            }
                          >
                            Promocode already used for this booking
                          </Text>
                        </div>
                      )}
                    <div class={styles.promotionChips}>
                      <div class={styles.promotionCodeLabel}>
                        <Text id="basket.promotion">Promotion :</Text>
                      </div>
                      <div>
                        <PromoCode
                          key={promoCode.id}
                          promoCode={promoCode}
                          locale={locale}
                          currency={currency}
                          class={styles.row}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div class={getClassName("footerPaymentContainer")}>
                  <footer class={getClassName("footerPaiement")}>
                    <h3 class={getClassName("totalTitle")}>
                      <Text id="basket.total">Total :</Text>
                    </h3>
                    <div
                      class={getClassName("price")}
                      style={`color:${
                        this.context.themeConfig &&
                        this.context.themeConfig.color1
                          ? this.context.themeConfig.color1
                          : ""
                      };`}
                    >
                      <h3>
                        {toLocalePrice(
                          currentOrder.totalPrice,
                          locale,
                          currency.isoCode
                        )}
                      </h3>
                    </div>
                  </footer>
                </div>
              </div>
            )
          )}
        </BasketContainer>
        <FooterButton config={config}>
          {currentOrder &&
          currentOrder.status === "USER_FILLED" &&
          paymentContext &&
          paymentContext === "mobile" ? (
            <div>
              <div class={getClassName("selectWrapper")}>
                <select
                  name="factureLang"
                  onChange={this.onChangeFacture}
                  value={
                    this.state.orderLocale === null
                      ? ""
                      : this.state.orderLocale
                  }
                  class={getClassName("select")}
                >
                  <option value="">
                    <Text id="basket.choixLangueEdition">
                      Choisissez la langue de l'édition
                    </Text>
                  </option>
                  {lang.map((option, key) => (
                    <option key={key} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : (
            ""
          )}

          {currentOrder &&
          currentOrder.status === "USER_FILLED" &&
          paymentContext &&
          paymentContext === "mobile" &&
          payOnArrivalAuthorized === true &&
          orderContainesGiftProduct === false &&
          multiMerchants === false ? (
            <PrimaryButton
              handleClick={this.onClickSendOrderConfirmationPayOnArrival}
              disabled={
                this.state.orderLocale === null || loadingPaiement !== null
              }
            >
              {loadingPaiement === "OrderConfirmationPayOnArrival" ? (
                <Loading color="#ff4156" />
              ) : (
                <Text id="basket.payOnArrival">Payer sur place</Text>
              )}
            </PrimaryButton>
          ) : (
            ""
          )}
          {currentOrder &&
          currentOrder.status === "USER_FILLED" &&
          paymentContext &&
          paymentContext === "mobile" &&
          cashAuthorized === true &&
          orderContainesGiftProduct === false &&
          orderContainsPromocode === false &&
          multiMerchants === false ? (
            <PrimaryButton
              handleClick={this.onClickSendOrderConfirmationCash}
              disabled={
                this.state.orderLocale === null || loadingPaiement !== null
              }
            >
              {loadingPaiement === "OrderConfirmationCash" ? (
                <Loading color="#ff4156" />
              ) : (
                <Text id="basket.cash">J'ai encaissé</Text>
              )}
            </PrimaryButton>
          ) : (
            ""
          )}

          {noPayment ? (
            <div class={styles.errorMessage}>
              <Text id="basket.noPayment">
                Paiement temporairement indisponible
              </Text>
            </div>
          ) : (
            ""
          )}

          {currentOrder && currentOrder.status === "DRAFT" ? (
            <PrimaryButton handleClick={goToClientForm}>
              <Text id="basket.finalizeOrder">Finalize my order</Text>
            </PrimaryButton>
          ) : currentOrder &&
            currentOrder.status === "USER_FILLED" &&
            paymentContext &&
            paymentContext === "mobile" ? (
            <PrimaryButton
              handleClick={this.onClickSendOrderConfirmation}
              disabled={
                this.state.orderLocale === null || loadingPaiement !== null
              }
            >
              {loadingPaiement === "OrderConfirmation" ? (
                <Loading color="#ff4156" />
              ) : (
                <Text id="basket.payWithMobile">Pay with my mobile</Text>
              )}
            </PrimaryButton>
          ) : currentOrder &&
            currentOrder.status === "USER_FILLED" &&
            !noPayment ? (
            <PrimaryButton
              handleClick={this.goToPayment}
              disabled={!isTermSalesAccepted || loading}
            >
              {loading ? (
                <Loading color="#fff" />
              ) : currentOrder.totalPrice !== 0 &&
                currentOrder.totalPrice !== null ? (
                <Text id="basket.payOrder">Pay my order</Text>
              ) : (
                <Text id="basket.confirmOrder">Confirm my order</Text>
              )}
            </PrimaryButton>
          ) : (
            ""
          )}
          {goToCatalog && (
            <BackButton
              handleClick={goToCatalog}
              e={getClassName("previousButton")}
            >
              {config.type !== "kiosk" && (
                <ArrowLeftIcon
                  color={
                    this.context.themeConfig && this.context.themeConfig.color1
                      ? this.context.themeConfig.color1
                      : styles.green
                  }
                />
              )}
              <Text id="basket.continueShopping">Continue shopping</Text>
            </BackButton>
          )}
          <div class={getClassName("ccImgContainer")}>
            <MasterCardIcon />
            <MaestroIcon />
            <VisaIcon />
            <VirementBancaireIcon />
            <PCIDSSIcon />
          </div>
        </FooterButton>
      </Container>
    );
  }
}

export default withStyles(Basket, styles);
