// @flow
// @ jsx h

import { h, Component } from "preact";
import { Text } from "preact-i18n";
import Link from "../link";
import { ArrowLeftIcon } from "elc-icons";
import styles from "./ParticipantFormCreated.scss";

type ParticipantCreatedProps = {
  changeLoadingState:any
};


export default class ParticipantFormCreated extends Component<ParticipantCreatedProps> {
  componentDidMount () {
    this.props.changeLoadingState();
  }
  render({ themeConfig }) {
    const redirectUrl = this.props.hash ? `/${this.props.hash}` : '/';
    return (
      <div>
        <div>
          <h2>
            <Text id="participant.created.title">
              Vos participants ont été bien créés.
            </Text>
          </h2>
          <p>
            <Text id="participant.created.body">
            Votre commande a bien été prise en compte et vos participants sont correctement renseignés.
            </Text>
            <br/>
            <br/>
            <Text id="participant.created.thanx">
              Merci pour votre confiance et à très vite
            </Text>
          </p>
          <div>
            <Link href={redirectUrl} className={styles.newOrderButton}>
              <ArrowLeftIcon
                color={
                  themeConfig && themeConfig.color1
                    ? themeConfig.color1
                    : styles.arrowColor
                }
              />
              <Text id="payment.confirmation.newOrder">Pass a new order</Text>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
