// @flow

type Resource = {
  calendars: any,
  capacity: number,
  closedTimeRanges: any,
  divisible: boolean,
  human: 0 | 1,
  id: number,
  name: string,
  quantity: number,
  scheduleType: string,
  staffType: number,
  user: any
};

type UnitResource = {
  _embedded: {
    resource: Resource,
    units: Array<Unit>
  }
};

/**
 * If the product is linked to resources (staff or material)
 * return the remaining capacity per unit
 * depending on the quantities currently selected
 */
const getUnitsRemainingCapacity = (
  units: Array<Unit>,
  productUnitResources: Array<UnitResource>,
  quantities: Map<number, number>,
  materialCombined: boolean
) =>
  productUnitResources && productUnitResources.length > 0
    ? units.map(unit => {
        const unitResources = productUnitResources.filter(
          productUnitResource =>
            productUnitResource._embedded.units.findIndex(
              resourceUnit => unit.id === resourceUnit.id
            ) !== -1
        );

        const getQuantitiesForResources = (
          quantities: Map<number, number>,
          unitResources: Array<UnitResource>
        ) =>
          quantities.size > 0
            ? Array.from(quantities)
                .filter(
                  quantity =>
                    unitResources
                      .map(unitResource => unitResource._embedded.units)
                      .reduce((acc, units) => acc.concat(units))
                      .findIndex(
                        resourceUnit => quantity[0] === resourceUnit.id
                      ) !== -1
                )
                .reduce((total, quantity) => total + quantity[1], 0)
            : 0;

        const hasStaff =
          unitResources.findIndex(
            unitRessource => unitRessource._embedded.resource.human === 1
          ) !== -1
            ? true
            : false;

        const staff = hasStaff
          ? unitResources.filter(
              unitRessource => unitRessource._embedded.resource.human === 1
            )
          : undefined;

        const staffRemainingCapacity = hasStaff
          ? staff
              .map(unitRessource => unitRessource._embedded.resource)
              .reduce(
                (staffRemainingCapacity, staff) =>
                  staffRemainingCapacity + staff.capacity * staff.quantity,
                0
              ) - getQuantitiesForResources(quantities, staff)
          : undefined;

        const hasMaterial =
          unitResources.findIndex(
            unitResource => unitResource._embedded.resource.human === 0
          ) !== -1
            ? true
            : false;

        const material = hasMaterial
          ? unitResources.filter(
              unitResource => unitResource._embedded.resource.human === 0
            )
          : undefined;

        const materialRemainingCapacity = hasMaterial
          ? material
              .map(unitResource => unitResource._embedded.resource)
              .reduce(
                (materialRemainingCapacity, material) =>
                  materialCombined
                    ? materialRemainingCapacity +
                      material.capacity * material.quantity
                    : material.capacity * material.quantity >
                      materialRemainingCapacity
                      ? material.capacity * material.quantity
                      : materialRemainingCapacity,
                0
              ) - getQuantitiesForResources(quantities, material)
          : undefined;

        return {
          id: unit.id,
          remainingCapacity:
            hasStaff && hasMaterial
              ? staffRemainingCapacity < materialRemainingCapacity
                ? staffRemainingCapacity
                : materialRemainingCapacity
              : hasStaff
                ? staffRemainingCapacity
                : hasMaterial
                  ? materialRemainingCapacity
                  : undefined
        };
      })
    : undefined;

export default getUnitsRemainingCapacity;
