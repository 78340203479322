// @flow
// @jsx h

import { h } from "preact";
import { Text } from "preact-i18n";

import { toLocalePrice } from "elc-commons";

import { ConfirmationIcon } from "elc-icons";

import styles from "./BankCheckConfirmation.scss";

/**
 * Bank check payment confirmation Component
 */
const BankCheck = (
  {
    locale,
    currency,
    order,
    payment,
    paymentMethod
  }: {
    locale: string,
    currency: Currency,
    order: Order,
    payment: Payment,
    paymentMethod: PaymentMethod
  },
  { themeConfig }
) => (
  <div class={styles.column}>
    <header
      class={styles.headerTitle}
      style={`color:${
        themeConfig && themeConfig.color1 ? themeConfig.color1 : ""
      };`}
    >
      <h2>
        <Text id="payment.confirmation.title">Confirmation</Text>
      </h2>
    </header>
    <div class={styles.content}>
      <div class={styles.iconContainer}>
        <ConfirmationIcon color={styles.iconColor} />
      </div>
      <div class={styles.headerContainer}>
        <Text
          id="payment.confirmation.paymentMethod"
          fields={{ paymentMethodName: paymentMethod.baseModule.name }}
        >
          Vous avez choisi de payer par {paymentMethod.baseModule.name}.
        </Text>
      </div>
      <div class={styles.subHeaderContainer}>
        <div>
          <Text id="payment.confirmation.check.depositAmount">
            Pour finaliser votre commande, merci de regler la somme de :
          </Text>
        </div>
        <div>
          <span class={styles.priceAmount}>
            {toLocalePrice(payment.totalAmount / 100, locale, currency.isoCode)}
          </span>
        </div>
      </div>
      <div class={styles.subHeaderContainer}>
        <div>
          <Text id="payment.confirmation.check.order">A l ordre de :</Text>
        </div>
        <div class={styles.paymentMethodDetail}>
          {paymentMethod.configuration.checkOrder}
        </div>
      </div>
      <div class={styles.subHeaderContainer}>
        <div>
          <Text id="payment.confirmation.check.details">Adresse :</Text>
        </div>
        <div class={styles.paymentMethodDetail}>
          {paymentMethod.configuration.checkDetails}
        </div>
      </div>

      {paymentMethod.configuration.deposit !== 100 && (
        <div class={styles.subHeaderContainer}>
          <span>
            <Text id="payment.confirmation.leftToPay">
              Solde à payer sur place :
            </Text>
          </span>
          <span class={styles.paymentMethodDetail}>
            {toLocalePrice(
              order.balance - payment.totalAmount / 100,
              locale,
              currency.isoCode
            )}
          </span>
        </div>
      )}
    </div>
  </div>
);

export default BankCheck;
