// @flow

declare var API_ENDPOINT: string;

import { handleResponse } from "../errors";
import { withToken } from "../auth";

/**
 * Return a company for a given ID
 * @param {number} companyId - the company ID
 * @return {Promise} the company
 */
export const getCompanyById = withToken(
  (accessToken: string, companyId: number): Promise<Company> =>
    fetch(
      `${API_ENDPOINT}/companies/${companyId}?access_token=${accessToken}&embed=languages,reseller_type,default_currency`
    ).then(handleResponse)
);

/**
 * Return a promo code for a given voucher code
 * @param {number} companyId - the company ID the search the promo code on
 * @param {string} voucherCode - the voucher code
 * @return {Promise} the promo code
 */
export const getPromoCodeByVoucherCode = withToken(
  (accessToken: string, companyId: number, voucherCode: string) =>
    fetch(
      `${API_ENDPOINT}/companies/${companyId}/promo_codes?access_token=${accessToken}&voucher_code=${voucherCode}&embed=products`
    ).then(handleResponse)
);
