// @flow
// @jsx h

import { h } from "preact";
import { Text } from "preact-i18n";
import { toLocalePrice } from "elc-commons";

import { ConfirmationIcon } from "elc-icons";

import styles from "./CreditCardConfirmation.scss";

/**
 * Credit card payment confirmation Component
 */
const CreditCardConfirmation = (
  {
    currency,
    payment,
    locale
  }: {
    currency: Currency
  },
  { themeConfig }
) => {
  return (
    <div class={styles.column}>
      <header
        class={styles.headerTitle}
        style={`color:${
          themeConfig && themeConfig.color1 ? themeConfig.color1 : ""
        };`}
      >
        <h2>
          <Text id="payment.confirmation.title">Confirmation</Text>
        </h2>
      </header>
      <div class={styles.content}>
        <div class={styles.iconContainer}>
          <ConfirmationIcon color={styles.iconColor} />
        </div>
        <div class={styles.headerContainer}>
          <Text id="payment.confirmation.creditCard">
            Vous avez choisi de payer par carte de crédit.
          </Text>
        </div>

        {payment.charge && (
          <div class={styles.subHeaderContainer}>
            <Text
              id="payment.confirmation.creditCardAmount"
              fields={{
                depositAmount: toLocalePrice(
                  payment.charge.amount / 100,
                  locale,
                  currency.isoCode
                )
              }}
            >
              Montant réglé : {payment.charge.amount / 100}
            </Text>
          </div>
        )}

        <div class={styles.confrimationInfosReminder}>
          <Text id="payment.confirmation.infomail">
            Si vous ne recevez pas d’email de confirmation suite à votre
            réservation, nous vous invitons à vérifier le dossier «
            spam/indésirable » de votre boîte mail. Une fois le message
            retrouvé, classez-le comme « non spam »
          </Text>
        </div>
      </div>
    </div>
  );
};

export default CreditCardConfirmation;
