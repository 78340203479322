// @flow
// @jsx h

import { h, Component } from "preact";

import Loading from "elc-loading";
import TicketHoldersForm from "./TicketHoldersForm";

// Styles
// import styles from './index.scss';

import Container from "../container";

import { getDefaultTicketHolderForm } from "elc-open-api";

type FetchTicketHolderFormProps = {
  config?: WidgetConfig,
  theme?: Object,
  currentCompany: Company,
  currentProduct: Product,
  currentOrder: Order,
  currentDay: Day,
  currentTimeSlots: TimeSlots,
  currentSlot: Slot,
  currentQuantities: Quantities,
  setOrderUser: Function,
  locale?: string
};

type FetchTicketHolderFormState = {
  loading: boolean,
  clientFormFields?: Array<ClientFormField>
};

/**
 * Fetch ticket holder form component
 */
export default class FetchTicketHolderForm extends Component<
  FetchTicketHolderFormProps,
  FetchTicketHolderFormState
> {
  /**
   * @type {object}
   * @property {boolean} loading fetch the beneficiary form fields
   * @property {Object} clientFormFields the beneficiary form fields
   */
  state = {
    loading: true,
    clientFormFields: undefined
  };

  componentDidMount() {
    getDefaultTicketHolderForm()
      .then(defaultTicketHolderForm => {
        this.setState({
          loading: false,
          clientFormFields: defaultTicketHolderForm._embedded.clientFormFields
        });
        return defaultTicketHolderForm;
      })
      .catch(error => {
        console.error(error);
      });
  }

  componentDidUpdate() {
    if (this.props.pageDidMountOrUpdate) {
      this.props.pageDidMountOrUpdate();
    }
  }

  /**
   * render
   * @param {FetchTicketHolderFormProps} the FetchTicketHolderForm component props
   * @param {FetchTicketHolderFormState} the FetchTicketHolderForm component state
   * @return {ReactElement} markup
   */
  render(
    {
      config,
      theme,
      locale,
      currentCompany,
      currentProduct,
      currentOrder,
      currentDay,
      currentTimeSlots,
      currentSlot,
      currentQuantities,
      setOrder
    }: {
      config?: WidgetConfig,
      booking: Booking,
      currency: Currency,
      backEndError: Error,
      theme?: Object,
      locale?: string,
      setOrder: Function
    },
    {
      clientFormFields,
      loading
    }: {
      clientFormFields: ClientFormFields,
      loading: boolean
    }
  ) {
    return (
      <Container>
        {/* <div class={styles.ticketHoldersScroll}> */}
        {loading ? (
          <Loading />
        ) : (
          <TicketHoldersForm
            theme={theme}
            config={config}
            locale={locale}
            currentCompany={currentCompany}
            currentProduct={currentProduct}
            currentOrder={currentOrder}
            currentDay={currentDay}
            currentTimeSlots={currentTimeSlots}
            currentSlot={currentSlot}
            currentQuantities={currentQuantities}
            clientFormFields={clientFormFields}
            setOrder={setOrder}
          />
        )}
        {/* </div> */}
      </Container>
    );
  }
}
