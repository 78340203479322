// @flow
import { h } from 'preact';

const Visa = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="svg36"
    viewBox="0 0 130.57025 39.999999"
    width="130.57"
    height="40"
  >
    <g transform="translate(-8.947 -26.618) scale(.19795)" id="g4610">
      <path
        id="Shape"
        d="m 278.1975,334.2275 33.361,-195.762 h 53.359 l -33.384,195.762 z"
        fill="#0e4595"
      />
      <path
        id="path13"
        d="m 524.3075,142.6875 c -10.572,-3.966 -27.136,-8.222 -47.823,-8.222 -52.724,0 -89.864,26.551 -90.18,64.604 -0.297,28.129 26.514,43.821 46.754,53.185 20.769,9.595 27.752,15.715 27.653,24.283 -0.132,13.122 -16.586,19.117 -31.923,19.117 -21.357,0 -32.703,-2.967 -50.226,-10.276 l -6.876,-3.112 -7.489,43.824 c 12.463,5.464 35.509,10.199 59.438,10.444 56.089,0 92.501,-26.247 92.915,-66.882 0.201,-22.269 -14.015,-39.217 -44.799,-53.188 -18.651,-9.056 -30.073,-15.099 -29.952,-24.269 0,-8.137 9.668,-16.838 30.557,-16.838 17.449,-0.271 30.088,3.534 39.936,7.5 l 4.782,2.259 7.233,-42.429"
        fill="#0e4595"
      />
      <path
        id="Path"
        d="m 661.6145,138.4655 h -41.231 c -12.773,0 -22.331,3.486 -27.941,16.234 l -79.245,179.403 h 56.031 c 0,0 9.162,-24.122 11.234,-29.418 6.123,0 60.554,0.084 68.336,0.084 1.596,6.853 6.492,29.334 6.492,29.334 h 49.512 z m -65.417,126.407 c 4.413,-11.279 21.259,-54.723 21.259,-54.723 -0.315,0.521 4.38,-11.334 7.075,-18.684 l 3.607,16.878 c 0,0 10.217,46.729 12.352,56.528 h -44.293 z"
        fill="#0e4595"
      />
      <path
        id="path16"
        d="m 232.9025,138.4655 -52.24,133.495 -5.566,-27.129 c -9.725,-31.274 -40.025,-65.156 -73.899,-82.119 l 47.767,171.203 56.455,-0.065 84.004,-195.385 h -56.521"
        fill="#0e4595"
      />
      <path
        id="path18"
        d="m 131.9195,138.4655 h -86.041 l -0.681,4.073 c 66.939,16.204 111.232,55.363 129.618,102.414 l -18.709,-89.96 c -3.23,-12.396 -12.598,-16.095 -24.187,-16.527"
        fill="#f2ae14"
      />
    </g>
  </svg>
);
export default Visa;
