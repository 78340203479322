// @flow
/* eslint-disable */

declare var API_ENDPOINT: string;
declare var PHP_API_ENDPOINT: string;
declare var MEDIAS_ENDPOINT: string;

import { handleResponse } from "../errors";
import { withToken } from "../auth";

let typeOfCompany;

/**
 * Return the products associated to a company
 * @param {number} companyId - the company ID
 * @return {Promise} the products list
 */
export const getProductsByCompanyId = withToken(
  (accessToken: string, companyId: number) =>
    fetch(
      `${API_ENDPOINT}/companies/${companyId}/products?access_token=${accessToken}&page=1&page_size=64&status=PUBLISHED&embed=prices`
    ).then(handleResponse)
);

/**
 * Return a product for a given ID
 * @param {number} productId - the product ID
 * @param {Array} [embed] - an optional embed Array
 * @return {Promise} the product
 */
export const getProductById = withToken(
  (
    accessToken: string,
    productId: number,
    locale: ?string = "fr",
    embed: ?Array<string> = [
      "primaryImage",
      "files",
      "images",
      "prices",
      "tax",
      "unit",
      "gift_products",
      "extra_products",
      "tags",
      "merchant",
      "address",
      "category",
      "sub_category",
      "product_unit_resources"
    ],
    companyType: ?string
  ): Promise<Product> =>
    fetch(
      `${API_ENDPOINT}/products/${productId}?access_token=${accessToken}&embed=${embed.join(
        ","
      )}`,
      {
        headers: {
          "Accept-language": locale
        },
        method: "GET"
      }
    )
      .then(handleResponse)
      .then(product => _setProductUnits(product, companyType))
      .then(product => _setProductImageUrl(product))
      .then(product => _setProductFileUrl(product))
      .then(product => _getExtraProducts(product, locale))
      .then(product => _getGiftProducts(product, locale))
);

/**
 * Fetch all extra products for a given product
 * @param {Object} product - the product on which
 * @return {Object} the product with the fetched extra products
 */
const _getExtraProducts = (product, locale) =>
  product._embedded.extraProducts &&
  product._embedded.extraProducts.length > 0
    ? Promise.all(
        product._embedded.extraProducts.map(extraProduct =>
          getProductById(extraProduct.id, locale)
        )
      ).then(extraProducts => {
        product._embedded.extraProducts = extraProducts;
        return product;
      })
    : product;

/**
 * Fetch all gift products for a given product
 * @param {Object} product - the product on which
 * @return {Object} the product with the fetched gift products
 */
const _getGiftProducts = (product, locale) =>
  product._embedded.giftProducts && product._embedded.giftProducts.length > 0
    ? Promise.all(
        product._embedded.giftProducts.map(giftProduct =>
          getProductById(giftProduct.id, locale)
        )
      ).then(giftProducts => {
        product._embedded.giftProducts = giftProducts;
        return product;
      })
    : product;

/**
 * Get the product units and their associated prices
 * from the product unit and prices
 * @param {Object} product - the product
 * @ return {Object} the product with a units attribute
 */
function _setProductUnits(product, companyType) {
  if (!typeOfCompany) typeOfCompany = companyType;
  if (product.type === "CLASSIC") {
    if (
      product._embedded.unit.type === "MAIN" ||
      product._embedded.unit.type === "FORFAIT"
    ) {
      // get main/forfait unit selling
      product._embedded.unit.prices = product._embedded.prices.filter(
        price => price.type === "SELLING"
      );
      // get degressive prices for main unit
      product._embedded.unit.prices = product._embedded.unit.prices.concat(
        product._embedded.prices.filter(
          price =>
            price.type === "DEGRESSIVE" &&
            price._embedded.targetUnit.id === product._embedded.unit.id
        )
      );
      product.units = [product._embedded.unit];
    }
    if (product._embedded.unit.type === "MAIN") {
      // get all the specific prices
      let specificPrices = product._embedded.prices.filter(
        price => price.type === "SPECIFIC" && price._embedded.targetUnit
      );

      if (!specificPrices) {
        return;
      }
      // get all the target units
      let targetUnits = specificPrices
        .filter(
          (price, index, prices) =>
            prices
              .map(price => price._embedded.targetUnit.id)
              .indexOf(price._embedded.targetUnit.id) === index
        )
        .map(price => price._embedded.targetUnit);
      // get all the target units specific prices
      targetUnits = targetUnits.map(targetUnit => {
        targetUnit.prices = specificPrices.filter(
          price => price._embedded.targetUnit.id === targetUnit.id
        );
        return targetUnit;
      });
      // get all the target units degressive prices
      targetUnits = targetUnits.map(targetUnit => {
        targetUnit.prices = targetUnit.prices.concat(
          product._embedded.prices.filter(
            price =>
              price.type === "DEGRESSIVE" &&
              price._embedded.targetUnit.id === targetUnit.id
          )
        );
        return targetUnit;
      });
      product.units = product.units.concat(targetUnits);
    } else if (product._embedded.unit.type === "SESSION") {
      // get session unit selling prices
      product.units = product._embedded.prices
        .reduce(
          (prev, curr) =>
            prev.indexOf(curr.numberOfSessions) === -1
              ? prev.concat([curr.numberOfSessions])
              : prev,
          []
        )
        .sort((ns1, ns2) => ns1 - ns2)
        .map(numberOfSessions =>
          Object.assign(
            {
              id: product._embedded.unit.id,
              type: product._embedded.unit.type,
              name: product._embedded.unit.name
            },
            {
              prices: product._embedded.prices.filter(
                price => price.numberOfSessions === numberOfSessions
              )
            },
            { numberOfSessions }
          )
        );
    }
  } else if (product.type === "EXTRA") {
    product.units = [{ price: product._embedded.prices[0] }];
  }
  return product;
}

/**
 * Add the media endpoint root url to the product images files
 * @param {Object} product - the product
 * @return {Object} the product with updated images files
 */
export function _setProductImageUrl(product) {
  if (product._embedded.images && product._embedded.images.length) {
    product._embedded.images = product._embedded.images.map(image => {
      if (image._embedded.files) {
        image._embedded.files = image._embedded.files.map(file => {
          file.path = `${MEDIAS_ENDPOINT}/${file.path}`;
          return file;
        });
      }
      return image;
    });
  }
  return product;
}

/**
 * Add the media endpoint root url to the product files
 * @param {Object} product - the product
 * @return {Object} the product with updated files
 */
export function _setProductFileUrl(product) {
  if (product._embedded.files && product._embedded.files.length) {
    product._embedded.files = product._embedded.files.map(file => {
      file.path = `${MEDIAS_ENDPOINT}/${file.path}`;
      return file;
    });
  }
  return product;
}

/**
 * Get product availabilities for a given product id and month
 * @param {number} productId - the product ID
 * @param {Date} fromDate - the start date to look availabilities from
 * @param {Object} [options] - various options for the request
 * @param {Map} [options.unitQuantities] - the currently selected units quantities
 * @param {boolean} [options.isPrivatized=false] - the product is privatized
 * @param {boolean} [options.nextAvailableDate=false] - get the availabilities for the next avalable date (month)
 * @param {Array.<string>} [options.excludedBookingsIds] - the bookings IDs to exclude from the availabilities check
 * @return {Promise} the promise of month availabilities
 */
export const getProductDayAvailabilities = withToken(
  (
    accessToken: string,
    productId: number,
    fromDate: Date = new Date(),
    {
      unitQuantities,
      isPrivatized = false,
      nextAvailableDate = false,
      excludedBookingsIds,
      type,
      inProgress
    }: {
      unitQuantities: ?Map<number | string, number>,
      isPrivatized: ?boolean,
      nextAvailableDate: ?boolean,
      excludedBookingsIds: ?Array<string>,
      type: ?string,
      inProgress: ?boolean
    }
  ): Promise<Array<ProductDayAvailability>> => {
    // fromDate.setDate(1);
    let url = `${API_ENDPOINT}/products/${productId}/product_day_availabilities?access_token=${accessToken}&from_date=${fromDate.toISOString()}`;
    // set unit quantities as query parameters
    if (unitQuantities && unitQuantities.size > 0) {
      let i = 0;
      url += "&unit_quantities=";
      unitQuantities.forEach((value, key, quantities) => {
        i++;
        url += key + ":" + value;
        if (quantities.size > i) {
          url += ";";
        }
      });
    }
    if (isPrivatized) {
      url += `&privatized=${isPrivatized}`;
    }
    if (nextAvailableDate) {
      url += `&next_available_date=${nextAvailableDate.toString()}`;
    }
    if (excludedBookingsIds && excludedBookingsIds.length > 0) {
      url += `&excluded_bookings_ids=${excludedBookingsIds.join(",")}`;
    }
    if (inProgress) {
      url += `&delay_before_activity=inProgress`;
    }
    return fetch(url)
      .then(handleResponse)
      .then(response => {
        return response._embedded.productDayAvailabilities;
      })
      .catch(error => {
        console.log(error);
      });
  }
);

/**
 * Get product time slots availabilities for a given product id and day
 * @param {number} productId - the product ID
 * @param {string} onDate - the day to look availabilities for
 * @param {Object} [options] - various options for the request
 * @param {Map} [options.unitQuantities] - the currently selected units quantities
 * @param {boolean} [options.isPrivatized=false] - the product is privatized
 * @param {Array.<string>} [options.excludedBookingsIds] - the bookings IDs to exclude from the availabilities check
 * @return {Promise} the promise of time slots availabilities
 */
export const getProductTimeSlotAvailabilities = withToken(
  (
    accessToken: string,
    productId: number,
    onDate: string,
    {
      unitQuantities,
      isPrivatized = false,
      excludedBookingsIds,
      type,
      inProgress
    }: {
      unitQuantities: ?Map<number | string, number>,
      isPrivatized: ?boolean,
      excludedBookingsIds: ?Array<string>,
      type: ?string,
      inProgress: ?boolean
    }
  ) => {
    let url = `${API_ENDPOINT}/products/${productId}/product_time_slot_availabilities?access_token=${accessToken}&on_date=${onDate}&page_size=120`;

    // set unit quantities as query parameters
    if (unitQuantities && unitQuantities.size > 0) {
      let i = 0;
      url += "&unit_quantities=";
      unitQuantities.forEach((value, key, quantities) => {
        i++;
        url += key + ":" + value;
        if (quantities.size > i) {
          url += ";";
        }
      });
    }

    if (isPrivatized) {
      url += `&privatized=${isPrivatized}`;
    }
    if (excludedBookingsIds && excludedBookingsIds.length > 0) {
      url += `&excluded_bookings_ids=${excludedBookingsIds.join(",")}`;
    }

    if (inProgress) {
      url += `&delay_before_activity=inProgress`;
    }
    return fetch(url)
      .then(handleResponse)
      .then(response => {
        return response._embedded.productTimeSlotAvailabilities;
      });
  }
);
