import parseProductsForGTM from './parseProductsForGTM';
import { push } from './EasyLoisirsGTMClient';

const sendPurchaseEventGTM = order => {
  let productsAndPromoCode = parseProductsForGTM(order);
  let products = productsAndPromoCode.products;
  let promocode = productsAndPromoCode.promocode;

  //On prépare l'action field dynamique en fonction du promo code ou non
  let actionField = {};
  actionField.id = order.id.toString();
  //actionField.affiliation:"Online Store", //TODO : quelle est l'affiliation dans notre contexte
  actionField.revenue = parseFloat(order.totalPrice.toFixed(2)); //total including tax and shipping
  actionField.tax = (order.totalPrice - order.totalPriceHt).toFixed(2);
  if (promocode != false) actionField.coupon = promocode; //Exemple

  push({
    event: 'EEtransaction',
    ecommerce: {
      purchase: {
        actionField: actionField,
        products: products
      }
    }
  });
};

export default sendPurchaseEventGTM;
