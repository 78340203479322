// @flow
// @jsx h
import { h, Component } from "preact";
import { Text, withText } from "preact-i18n";

// Style
import styles from "./style.scss";
import { withStyles } from "elc-theme";

import PromoCodeError from "./promocodeerror";

type AddPromoCodeProps = {
  config: WidgetConfig,
  order: Order,
  addVoucherError?: Error,
  onAddPromoCode: Function,
  promoCodePlaceholder?: string
};

type AddPromoCodeState = {
  value: string
};

/**
 * Add a promo code Component
 * offering possibility to add a promo code on an order
 */
@withText({
  promoCodePlaceholder: "basket.promoCode.placeholder"
})
class AddPromoCode extends Component<AddPromoCodeProps, AddPromoCodeState> {
  /**
   * handle promo code input change
   *   @param {SyntheticInputEvent} e
   */
  handleChange = ({ target }: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ value: target.value });
  };

  /**
   * handle the promo code submit
   *   @param {SyntheticEvent} event
   */
  handleSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (this.state.value != null) {
      this.props.onAddPromoCode(this.props.order.id, this.state.value);
    }
  };

  /**
   * render
   *   @param {AddPromoCodeProps} the AddPromoCode component props
   *   @return {ReactElement} markup
   */
  render({
    config,
    addVoucherError,
    promoCodePlaceholder,
    getClassName
  }: AddPromoCodeProps) {
    let buttonProps = {};
    if (
      config &&
      config.module &&
      config.module.conf &&
      config.module.conf.color5
    )
      buttonProps.color = config.module.conf.color5;
    if (
      config &&
      config.module &&
      config.module.conf &&
      config.module.conf.color1
    )
      buttonProps.background = config.module.conf.color1;
    if (
      !this.props.order._embedded.company.alentour ||
      this.props.order._embedded.company.alentour == false
    ) {
      return (
        <div>
          <form
            class={getClassName("addPromoCodeForm")}
            onSubmit={this.handleSubmit}
          >
            <input
              class={getClassName("input")}
              placeholder={promoCodePlaceholder}
              type="text"
              value={this.state.value}
              onChange={this.handleChange}
            />
            <button type="submit" class={getClassName("addPromoCodeButton")}>
              <Text id="basket.promoCode.addButton">Add</Text>
            </button>
          </form>
          {addVoucherError && <PromoCodeError error={addVoucherError} />}
        </div>
      );
    }
  }
}

export default withStyles(AddPromoCode, styles);
