// @flow
// @jsx h
import { h } from "preact";

const Maestro = () => (
  <svg
    width="40"
    height="40"
    viewBox=".205 .428 66.852 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m520.2 696.93c7.598-6.875 12.347-16.811 12.347-27.854 0-11.042-4.749-20.97-12.347-27.846-6.648-6.044-15.531-9.722-25.196-9.722-20.783 0-37.599 16.819-37.599 37.568 0 20.757 16.816 37.576 37.599 37.576 9.664 1e-3 18.547-3.685 25.196-9.722"
      fill="#0066cb"
      transform="translate(-243.28 -335.72) scale(.5323)"
    />
    <path
      d="m520.2 696.93c7.598-6.875 12.347-16.811 12.347-27.854 0-11.042-4.749-20.97-12.347-27.846"
      fill="#0066cb"
      transform="translate(-243.28 -335.72) scale(.5323)"
    />
    <path
      d="m520.2 696.93c7.598-6.875 12.347-16.811 12.347-27.854 0-11.042-4.749-20.97-12.347-27.846"
      fill="none"
      stroke="#0066cb"
      strokeWidth=".31"
      transform="translate(-243.28 -335.72) scale(.5323)"
    />
    <path
      d="m545.46 631.5c-9.721 0-18.604 3.678-25.252 9.722-1.341 1.236-2.626 2.564-3.799 3.983h7.598c1.062 1.259 2.012 2.587 2.85 3.976h-13.297c-0.782 1.274-1.508 2.602-2.179 3.976h17.654c0.614 1.29 1.117 2.617 1.564 3.983h-20.783c-0.447 1.29-0.838 2.618-1.117 3.976h23.018c0.559 2.564 0.838 5.227 0.838 7.952 0 4.173-0.671 8.188-1.956 11.935h-20.782c0.447 1.365 0.95 2.693 1.564 3.982h17.654c-0.671 1.375-1.341 2.702-2.179 3.977h-13.297c0.838 1.389 1.844 2.717 2.85 3.977h7.598c-1.173 1.418-2.458 2.746-3.799 3.982 6.648 6.037 15.531 9.723 25.252 9.723 20.727 0 37.543-16.819 37.543-37.576 0-20.749-16.817-37.568-37.543-37.568"
      fill="#cc0001"
      transform="translate(-243.28 -335.72) scale(.5323)"
    />
    <g fill="#231f20">
      <path
        d="m579.03 689.94h0.168c0.057 0 0.111 8e-3 0.111-0.016 0.057-0.03 0.112-0.076 0.112-0.121 0-0.047-0.056-0.092-0.112-0.115 0-0.023-0.111-0.016-0.111-0.016h-0.168zm0 0.61h-0.223v-1.068h0.447c0.111 0 0.167 0 0.279 0.054 0.056 0.061 0.111 0.16 0.111 0.268 0 0.121-0.056 0.229-0.168 0.273l0.168 0.474h-0.279l-0.111-0.427h-0.225v0.426zm0.168 0.39c0.503 0 0.95-0.412 0.95-0.924s-0.447-0.924-0.95-0.924c-0.502 0-0.894 0.412-0.894 0.924s0.392 0.924 0.894 0.924zm-1.229-0.924c0-0.672 0.559-1.213 1.229-1.213 0.671 0 1.229 0.541 1.229 1.213s-0.559 1.213-1.229 1.213-1.229-0.541-1.229-1.213"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
      <path
        d="m489.14 679.53h-4.749l2.793-14.645-6.425 14.645h-3.911l-0.782-14.561-3.128 14.561h-4.302l3.631-19.047h7.431l0.111 11.79 5.14-11.79h7.877l-3.686 19.047"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
      <path
        d="m541.38 679.34c-1.285 0.352-2.291 0.504-3.352 0.504-2.402 0-3.688-1.198-3.688-3.403 0-0.435 0-0.9 0.111-1.39l0.279-1.518 0.224-1.221 2.179-11.829h4.637l-0.67 3.556h2.402l-0.67 3.762h-2.402l-1.229 6.464c-0.056 0.289-0.056 0.496-0.056 0.641 0 0.801 0.447 1.152 1.564 1.152 0.559 0 0.949-0.045 1.285-0.145l-0.614 3.427"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
      <path
        d="m522.83 668.93c0 1.961 1.062 3.305 3.575 4.319 1.9 0.779 2.234 1.008 2.234 1.71 0 0.97-0.838 1.403-2.736 1.403-1.397 0-2.738-0.189-4.246-0.617l-0.615 3.578 0.168 0.031 0.894 0.16c0.279 0.046 0.671 0.092 1.229 0.137 1.117 0.084 2.011 0.131 2.625 0.131 4.973 0 7.264-1.641 7.264-5.174 0-2.122-0.95-3.373-3.297-4.313-2.011-0.786-2.234-0.954-2.234-1.678 0-0.84 0.782-1.267 2.291-1.267 0.949 0 2.234 0.092 3.407 0.237l0.671-3.587c-1.229-0.168-3.073-0.305-4.135-0.305-5.307 0-7.151 2.381-7.095 5.235"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
      <path
        d="m501.1 672.63c-0.502-0.054-0.726-0.068-1.062-0.068-2.625 0-3.966 0.793-3.966 2.357 0 0.961 0.67 1.58 1.676 1.58 1.899 1e-3 3.296-1.579 3.352-3.869zm3.184 6.899h-3.911l0.112-1.61c-1.229 1.274-2.793 1.877-4.917 1.877-2.57 0-4.302-1.725-4.302-4.228 0-3.777 3.017-5.975 8.212-5.975 0.559 0 1.229 0.037 1.9 0.121 0.167-0.511 0.223-0.732 0.223-1.007 0-1.03-0.838-1.412-3.017-1.412-1.341 0-2.849 0.168-3.911 0.435l-0.615 0.16-0.447 0.099 0.67-3.479c2.346-0.596 3.854-0.824 5.586-0.824 4.022 0 6.146 1.572 6.146 4.548 0 0.763-0.056 1.343-0.391 3.068l-0.95 5.494-0.167 0.984-0.112 0.786-0.112 0.542v0.421"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
      <path
        d="m517.36 669.97c0-0.32 0.056-0.587 0.056-0.786 0-1.259-0.838-2-2.235-2-1.508 0-2.57 0.992-2.961 2.777zm2.29 9.219c-1.62 0.428-3.185 0.633-4.805 0.625-5.251 0-7.933-2.381-7.933-6.936 0-5.319 3.464-9.234 8.157-9.234 3.854 0 6.313 2.19 6.313 5.631 0 1.146-0.168 2.251-0.615 3.823h-9.274c-0.056 0.236-0.056 0.344-0.056 0.488 0 1.793 1.397 2.709 4.134 2.709 1.676 0 3.185-0.297 4.861-0.977l-0.782 3.871"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
      <path
        d="m564.79 670.65c0-1.618-0.67-3.152-2.569-3.152-2.402 0-3.854 2.824-3.854 5.311 0 2.099 1.005 3.511 2.682 3.479 1.005 0 3.184-1.381 3.575-3.793 0.11-0.556 0.166-1.173 0.166-1.845zm4.749 1.853c-0.671 5.151-4.302 7.434-9.106 7.434-5.252 0-7.375-3.205-7.375-7.135 0-5.495 3.576-9.211 9.163-9.211 4.86 0 7.431 3.06 7.431 6.991-1e-3 0.953-1e-3 1.021-0.113 1.921"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
    </g>
    <g fill="#fff">
      <path
        d="m571.1 677.15h0.167s0.057 0 0.112-0.023 0.056-0.076 0.056-0.122c0-0.038 0-0.084-0.056-0.106 0-0.023-0.112-0.023-0.167-0.023h-0.112zm0 0.603h-0.224v-1.068h0.447c0.056 0 0.168 8e-3 0.279 0.062 0.056 0.053 0.111 0.16 0.111 0.267 0 0.114-0.056 0.229-0.167 0.274l0.167 0.466h-0.279l-0.168-0.42h-0.166zm0.167 0.389c0.504 0 0.895-0.412 0.895-0.924 0-0.504-0.391-0.916-0.895-0.916-0.502 0-0.949 0.412-0.949 0.916 0 0.512 0.447 0.924 0.949 0.924zm-1.228-0.924c0-0.664 0.559-1.205 1.229-1.205 0.671 0 1.229 0.541 1.229 1.205 0 0.672-0.559 1.213-1.229 1.213-0.671 0-1.229-0.541-1.229-1.213"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
      <path
        d="m490.32 678.43h-4.693l2.793-14.644-6.425 14.644h-4.246l-0.782-14.552-2.793 14.552h-4.246l3.631-19.047h7.318l0.335 11.798 5.196-11.798h7.598l-3.686 19.047"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
      <path
        d="m542.55 678.25c-1.285 0.351-2.291 0.503-3.353 0.503-2.402 0-3.688-1.205-3.688-3.402 0-0.436 0.056-0.9 0.112-1.389l0.279-1.52 0.223-1.229 2.18-11.828h4.637l-0.67 3.556h2.402l-0.671 3.77h-2.402l-1.229 6.463c-0.056 0.283-0.056 0.496-0.056 0.642 0 0.802 0.503 1.145 1.62 1.145 0.503 0 0.895-0.046 1.229-0.146l-0.613 3.435"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
    </g>
    <path
      d="m554.56 667.39-0.111-0.488 0.95-2.427c-0.225-0.023-0.336-0.03-0.336-0.045-0.279-0.054-0.391-0.084-0.447-0.084-0.111-0.031-0.279-0.038-0.446-0.038-1.564 0-2.626 0.679-4.079 2.511l0.504-2.755h-4.861l-2.85 15.468h4.638c0.614-3.549 0.894-5.57 1.285-7.25 0.614-2.785 2.682-4.052 4.19-3.77 0.167 0.031 0.279 0.031 0.502 0.115l0.279 0.106 0.782-1.343"
      fill="#231f20"
      transform="translate(-243.28 -335.72) scale(.5323)"
    />
    <g fill="#fff">
      <path
        d="m556.18 663.07c-0.391-0.153-0.447-0.153-0.502-0.168-0.225-0.054-0.392-0.084-0.392-0.092-0.167-0.023-0.335-0.038-0.503-0.038-1.508 0-2.625 0.687-4.078 2.519l0.447-2.351h-4.246l-2.85 15.491h4.693c1.676-9.47 2.402-11.133 4.637-11.133 0.168 0 0.335 0.015 0.559 0.038l0.559 0.114 1.676-4.38"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
      <path
        d="m524 667.84c0 1.953 1.117 3.304 3.632 4.319 1.899 0.771 2.179 1 2.179 1.71 0 0.961-0.838 1.396-2.682 1.396-1.453 0-2.738-0.191-4.246-0.611l-0.67 3.572 0.223 0.037 0.838 0.152c0.279 0.055 0.727 0.1 1.285 0.139 1.117 0.084 1.955 0.129 2.57 0.129 5.027 0 7.318-1.633 7.318-5.166 0-2.129-0.949-3.373-3.352-4.312-1.955-0.786-2.18-0.961-2.18-1.687 0-0.832 0.783-1.259 2.291-1.259 0.949 0 2.179 0.084 3.408 0.229l0.67-3.586c-1.229-0.168-3.072-0.306-4.189-0.306-5.251 1e-3 -7.095 2.382-7.095 5.244"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
      <path
        d="m502.1 671.53c-0.503-0.053-0.727-0.061-1.062-0.061-2.626 0-3.967 0.793-3.967 2.357 0 0.962 0.615 1.58 1.676 1.58 1.9 1e-3 3.241-1.587 3.353-3.876zm3.407 6.898h-3.911l0.112-1.602c-1.173 1.273-2.793 1.869-4.917 1.869-2.514 0-4.246-1.725-4.246-4.228 0-3.771 3.017-5.975 8.212-5.975 0.503 0 1.173 0.045 1.899 0.122 0.112-0.512 0.167-0.725 0.167-1.007 0-1.023-0.838-1.404-3.017-1.404-1.341 0-2.85 0.16-3.855 0.427l-0.67 0.168-0.391 0.1 0.615-3.488c2.346-0.595 3.854-0.816 5.586-0.816 4.022 0 6.146 1.572 6.146 4.541 0 0.763-0.056 1.343-0.335 3.067l-1.006 5.503-0.167 0.984-0.112 0.786-0.056 0.533-0.054 0.42"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
      <path
        d="m518.36 668.88v-0.794c0-1.259-0.782-1.992-2.179-1.992-1.508 0-2.57 0.984-3.017 2.778zm2.459 9.218c-1.565 0.42-3.129 0.626-4.75 0.626-5.251 0-7.989-2.381-7.989-6.944 0-5.311 3.463-9.226 8.156-9.226 3.855 0 6.313 2.19 6.313 5.624 0 1.145-0.168 2.259-0.56 3.83h-9.273c-0.056 0.237-0.056 0.344-0.056 0.488 0 1.794 1.396 2.71 4.078 2.71 1.676 0 3.185-0.306 4.86-0.985l-0.779 3.877"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
      <path
        d="m565.9 669.42c0-1.618-0.67-3.151-2.569-3.151-2.402 0-3.854 2.823-3.854 5.311 0 2.106 1.006 3.519 2.682 3.487 1.006 0 3.184-1.381 3.575-3.793 0.111-0.565 0.166-1.182 0.166-1.854zm4.861 1.985c-0.727 5.15-4.303 7.439-9.106 7.439-5.308 0-7.431-3.213-7.431-7.143 0-5.487 3.631-9.21 9.219-9.21 4.859 0 7.43 3.06 7.43 6.99 0 0.961 0 1.023-0.112 1.924"
        transform="translate(-243.28 -335.72) scale(.5323)"
      />
    </g>
  </svg>
);
export default Maestro;
