/* eslint-disable */
// @flow
// @jsx h

// External librairies
import { h, Component } from "preact";
import { Text } from "preact-i18n";

// Components
import { Button } from "elc-button";
import { DeleteIcon } from "elc-icons";
import TimeRange from "./timerange";
import PromoCode from "./promocode";

// Style
import styles from "./style.scss";
import { withStyles } from "elc-theme";

// Utility functions
import { toLocalePrice } from "elc-commons";

type BookingProps = {
  locale: string,
  currency: Currency,
  order: Order,
  booking: Booking,
  onDeleteBooking: Function
};

/**
 * Booking Component
 * displaying the booking details
 */
class Booking extends Component<BookingProps> {
  /**
   * handle the click on delete booking button
   */
  onClickDeleteBooking = () => {
    this.props.onDeleteBooking(this.props.booking);
  };

  /**
   * render
   * @param {BookingProps} the Booking component props
   * @return {ReactElement} markup
   */
  render({ locale, currency, order, booking, getClassName }: BookingProps) {
    return (
      <div class={getClassName("booking")}>
        <div class={styles.bookingHeader}>
          <div class={getClassName("productName")}>
            {booking._embedded.product.name}
          </div>
          {/*!booking._embedded.timeRanges && <div>{booking.quantity}</div>*/}
          <div>
            <span
              class={getClassName("bookingTotal")}
              style={`color:${
                this.context.themeConfig && this.context.themeConfig.color1
                  ? this.context.themeConfig.color1
                  : ""
              };`}
            >
              {toLocalePrice(booking.productTax, locale, currency.isoCode)}
            </span>
            {/* ajout "SHOP" afin de ne pas supprimer les produits  */}
            {(order.status === "DRAFT" || order.status === "USER_FILLED") &&
              order.origin !== "SHOP" && (
                <Button
                  handleClick={this.onClickDeleteBooking}
                  className={getClassName("deleteProductButton")}
                >
                  <DeleteIcon />
                </Button>
              )}
          </div>
        </div>

        {booking._embedded.timeRanges &&
          booking._embedded.timeRanges.reduce(
            (acc, timeRange) =>
              timeRange._embedded.unitQuantities.filter(
                unitQuantity =>
                  !unitQuantity.sessionQuantity ||
                  unitQuantity.sessionQuantity > 0
              ).length > 0
                ? acc.concat(
                    <TimeRange
                      key={timeRange.id}
                      timeRange={timeRange}
                      currency={currency}
                      class={styles.row}
                    />
                  )
                : acc,
            []
          )}

        {booking._embedded.product.scheduleType !==
          "OPEN_BILLET_WITHOUT_DATE" && (
          <div class={styles.startTime}>
            {booking._embedded.product.scheduleType === "OPEN_BILLET"
              ? new Date(booking.startTime).toLocaleDateString(locale, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  timeZone: "Europe/Paris"
                })
              : new Date(booking.startTime).toLocaleDateString(locale, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  timeZone: "Europe/Paris"
                })}
          </div>
        )}

        {booking._embedded.associatedBookings &&
          booking._embedded.associatedBookings.length > 0 && (
            <div class={styles.extrasList}>
              {booking._embedded.associatedBookings.map(extra => (
                <div key={extra.id} class={getClassName("bookingExtra")}>
                  <div class={getClassName("bookingExtraHeader")}>
                    <div class={getClassName("bookingExtraTitle")}>
                      {extra.quantity} x {extra._embedded.product.name}
                    </div>
                    <div>
                      <span
                        class={getClassName("bookingTotal")}
                        style={`color:${
                          this.context.themeConfig &&
                          this.context.themeConfig.color1
                            ? this.context.themeConfig.color1
                            : ""
                        };`}
                      >
                        {toLocalePrice(
                          extra.totalTax,
                          locale,
                          currency.isoCode
                        )}
                      </span>
                      {(order.status === "DRAFT" ||
                        order.status === "USER_FILLED") && (
                        <Button
                          handleClick={() => {
                            this.props.onDeleteBooking(extra);
                          }}
                          className={getClassName("deleteProductButton")}
                        >
                          <DeleteIcon />
                        </Button>
                      )}
                    </div>
                  </div>
                  <div class={getClassName("extraChip")}>
                    <Text id="basket.extra.label">Extra</Text>
                  </div>
                  {extra._embedded.promoCodes &&
                    extra._embedded.promoCodes.map(promoCode => (
                      <PromoCode
                        key={promoCode.id}
                        promoCode={promoCode}
                        locale={locale}
                        currency={currency}
                      />
                    ))}
                </div>
              ))}
            </div>
          )}
      </div>
    );
  }
}

export default withStyles(Booking, styles);
