// @flow
// @jsx h
import { h, Component } from "preact";
import { Text } from "preact-i18n";

import styles from "./style.scss";

import Booker from "elc-booker";
// import 'elc-booker/dist/style.css';
import ProductDescription from "../productdescription";
import BookerIntro from "./BookerIntro";
import GiftVoucher from "./GiftVoucher";
import BookerHowTo from "./BookerHowTo";
import { MailIcon } from "elc-icons";

import { getToken } from "elc-open-api";

import { sendAddToCartGTM } from "elc-commons";

type ProductProps = {
  hash: string,
  config: WidgetConfig,
  preview: boolean,
  locale: string,
  currency: Currency,
  product: Product,
  currentOrder: Order,
  setOrder: Function,
  goToCatalog: Function,
  pageDidMountOrUpdate: Function,
  onEnterBooker: Function
};

type ProductState = {
  singlePanelMode: boolean,
  hideDescription: boolean,
  showBooker: boolean,
  voucherCode?: string,
  showGiftVoucherCode: boolean
};

/**
 * Product component
 * displaying the product description and the booker
 */
/* FIXME when Preact implement getDerivedStateFromProps */
/* eslint-disable-next-line react/no-deprecated */
export default class Product extends Component<ProductProps, ProductState> {
  state = {
    singlePanelMode: true,
    hideDescription: false,
    showBooker: false,
    voucherCode: undefined,
    showGiftVoucherCode: false,
    isBook: "book"
  };

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    if (nextProps.productPageUpdate) {
      this.setState({
        showBooker: false,
        showGiftVoucherCode: false,
        hideDescription: false
      });
    }
  }

  hideDescription = () => {
    this.setState({ hideDescription: true });
    this.props.onEnterBooker();
  };

  showBooker = isBook => {
    if (this.state.singlePanelMode) {
      //prevent iframe wrong content height on touch screens
      setTimeout(() => {
        this.hideDescription();
      }, 150);
    }
    //Step GTM AddToCart
    sendAddToCartGTM(this.props.product, this.props.currency.isoCode);
    this.setState({ showBooker: true, isBook });
  };

  bookFromGift = (voucherCode: string) => {
    this.setState({
      showBooker: true,
      showGiftVoucherCode: false,
      voucherCode
    });
  };

  /**
   * Check if hide/display voucherCode
   */
  showGiftVoucherCode = () => {
    if (this.state.singlePanelMode) {
      this.hideDescription();
    }
    this.setState({ showGiftVoucherCode: true });
  };

  backFromGiftForm = () => {
    this.setState({ showGiftVoucherCode: false });
  };

  componentDidMount() {
    parent.postMessage(`action=loaded&id_iframe=${this.props.hash}`, "*");

    if (!window.matchMedia) return;
    this.mql = window.matchMedia(
      "(max-width: " +
        (styles.singlePanelMode ? styles.singlePanelMode : "53.750em") +
        ")"
    );
    this.mql.addListener(this.onMatch);
    this.onMatch(this.mql);
  }

  componentWillUnmount() {
    this.mql && this.mql.removeListener(this.onMatch);
  }

  onMatch = mql => {
    const singlePanelMode = !!mql.matches;
    this.setState({ singlePanelMode });
  };

  render(
    {
      hash,
      config,
      preview,
      locale,
      currency,
      product,
      currentOrder,
      setOrder,
      goToCatalog,
      pageDidMountOrUpdate
    }: ProductProps,
    {
      singlePanelMode,
      hideDescription,
      showBooker,
      showGiftVoucherCode,
      voucherCode
    }: ProductState
  ) {
    const hasGifts =
      product.type !== "GIFT" &&
      product._embedded.giftProducts &&
      product._embedded.giftProducts.length !== 0;
    return (
      <div
        id="product"
        class={`${styles.productPageContainer} ${
          hideDescription ? styles.enteredBooker : ""
        }`}
      >
        {config.module.conf.show_product_info !== 0 && (
          <div
            class={`${styles.cellDescription} ${
              hideDescription ? styles.hideDescription : ""
            }`}
          >
            <ProductDescription
              product={product}
              config={config}
              currency={currency}
              goToCatalog={goToCatalog}
              pageDidMountOrUpdate={pageDidMountOrUpdate}
              singlePanelMode={this.state.singlePanelMode}
              showBooker={showBooker}
              showBookerClick={this.showBooker}
              showGiftVoucherCode={showGiftVoucherCode}
              locale={locale}
            />
          </div>
        )}
        <div
          class={`${styles.cellBooker} ${
            config.module.conf.show_product_info === 1 && !hideDescription
              ? styles.booker
              : ""
          }`}
        >
          {(showBooker || config.module.conf.show_product_info === 0) && (
            <Booker
              hash={hash}
              config={config}
              preview={preview}
              locale={locale}
              token={getToken()}
              company={config.company}
              currency={currency}
              order={currentOrder}
              productId={product.id}
              setOrder={setOrder}
              pageDidMountOrUpdate={pageDidMountOrUpdate}
              voucherCode={voucherCode}
              isBook={this.state.isBook}
            />
          )}
          {showGiftVoucherCode && (
            <GiftVoucher
              companyId={config.company.id}
              productId={product.id}
              bookFromGift={this.bookFromGift}
              backFromGiftForm={this.backFromGiftForm}
            />
          )}
          {!showBooker &&
            !showGiftVoucherCode &&
            (!singlePanelMode || hasGifts) &&
            config.module.conf.show_product_info === 1 && (
              <BookerIntro
                locale={locale}
                currencyCode={currency.isoCode}
                product={product}
                productType={product.type}
                hasGifts={hasGifts}
                showBooker={this.showBooker}
                showGiftVoucherCode={this.showGiftVoucherCode}
              />
            )}
          <BookerHowTo showBooker={showBooker} />
          {config.company.emailContact && (
            <div
              class={`${styles.questionBlock} ${
                showBooker ? styles.bookerShown : ""
              }`}
            >
              <div>
                <MailIcon color={styles.darkGrey} />
              </div>
              <div>
                <span class={styles.questionBlockHeader}>
                  <Text id="product.description.contactHeader">
                    Une question ?
                  </Text>
                </span>
                <a
                  href={`mailto:${config.company.emailContact}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text id="product.description.contact">Contactez-nous !</Text>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
