// @flow
// @jsx h

declare var GOOGLE_MAP_URL: string;

import { h, Component } from "preact";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import { Text } from "preact-i18n";

/*
 * This is the modify version of:
 * https://developers.google.com/maps/documentation/javascript/examples/event-arguments
 *
 * Add <script src="https://maps.googleapis.com/maps/api/js"></script> to your HTML to provide google.maps reference
 */
const GoogleMapContainer = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      ref={props.onMapLoad}
      options={{
        draggable: false,
        streetViewControl: false,
        zoomControl: false,
        scaleControl: false,
        rotateControl: false,
        mapTypeControl: false
      }}
      defaultZoom={13}
      defaultCenter={{
        lat: props.marker.position.lat,
        lng: props.marker.position.lng
      }}
      onClick={props.onMapClick}
    >
      <Marker
        {...props.marker}
        onRightClick={() => props.onMarkerRightClick(props.marker)}
      >
        <InfoWindow>
          <div>
            <h2>
              <Text id="product.map.itinerary.title">Itinéraire</Text>
            </h2>
            <p>
              <Text id="product.map.itinerary.link">
                Pour connaitre l itinéraire :
              </Text>
              <a
                href={`https://www.google.fr/maps/dir/${
                  props.marker.position.lat
                }, ${props.marker.position.lng}/${props.marker.position.lat},${
                  props.marker.position.lng
                }/`}
                target="_blank"
              >
                <Text id="product.map.itinerary.clickHere">cliquez-ici</Text>
              </a>
              <p>
                <Text
                  id="product.map.itinerary.coordinates"
                  fields={{
                    lat: props.marker.position.lat,
                    lng: props.marker.position.lng
                  }}
                >
                  Coordonnées GPS : {props.marker.position.lat.toString()},{" "}
                  {props.marker.position.lng}.
                </Text>
              </p>
            </p>
          </div>
        </InfoWindow>
      </Marker>
    </GoogleMap>
  ))
);

export default class GettingStartedExample extends Component {
  render({ marker }) {
    return (
      <div style={{ height: `100%` }}>
        <GoogleMapContainer
          googleMapURL={GOOGLE_MAP_URL}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `300px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          marker={marker}
        />
      </div>
    );
  }
}
