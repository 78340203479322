import parseProductForGTM from './parseProductForGTM';
import { push } from './EasyLoisirsGTMClient';

const sendAddToCartGTM = (product, currencyCode) => {
  push({
    event: 'EEaddToCart',
    ecommerce: {
      currencyCode: currencyCode,
      add: {
        products: parseProductForGTM(product)
      }
    }
  });
};

export default sendAddToCartGTM;
