// @flow
// @jsx h
import { h, Component } from "preact";
import ReactDOM from "react-dom";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";

class ReactFlagsSelectExtension extends ReactFlagsSelect {
  render() {
    let c = super.render();
    let isSelected = this.state.selected || this.state.defaultCountry;
    let base64Catalogne =
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTEycHQiIGhlaWdodD0iMzM2cHQiIHZpZXdCb3g9IjAgMCA1MTIgMzM2IiB2ZXJzaW9uPSIxLjEiPgo8ZyBpZD0ic3VyZmFjZTEiPgo8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjMzNiIgc3R5bGU9ImZpbGw6cmdiKDk4LjgyMzUyOSUsODYuNjY2NjY3JSwzLjUyOTQxMiUpO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lOyIvPgo8cGF0aCBzdHlsZT0iZmlsbC1ydWxlOm5vbnplcm87ZmlsbDpyZ2IoMCUsMCUsMCUpO2ZpbGwtb3BhY2l0eToxO3N0cm9rZS13aWR0aDo2MDtzdHJva2UtbGluZWNhcDpidXR0O3N0cm9rZS1saW5lam9pbjptaXRlcjtzdHJva2U6cmdiKDg1LjQ5MDE5NiUsNy4wNTg4MjQlLDEwLjE5NjA3OCUpO3N0cm9rZS1vcGFjaXR5OjE7c3Ryb2tlLW1pdGVybGltaXQ6NDsiIGQ9Ik0gMCA5MCBMIDgxMCA5MCBNIDgxMCAyMTAuMDAyMDkzIEwgMCAyMTAuMDAyMDkzIE0gMCAzMjkuOTk3OTA3IEwgODEwIDMyOS45OTc5MDcgTSA4MTAgNDUwIEwgMCA0NTAgIiB0cmFuc2Zvcm09Im1hdHJpeCgwLjYzMjA5OSwwLDAsMC42MjIyMjIsMCwwKSIvPgo8L2c+Cjwvc3ZnPgo=";

    if (isSelected.toLowerCase() === "pl") {
      let domButton = (c.children || [])[0] || {};
      let domSpan = (domButton.children || [])[0] || {};
      let domImg = (domSpan.children || [])[0];
      if (domImg && domImg.nodeName.toLowerCase() === "img")
        domImg.attributes.src = base64Catalogne;
    }

    if (this.state.openOptions) {
      let domUl = (c.children || [])[1] || {};
      for (var i = 0; (domUl.children || []).length; i++) {
        let domLi = domUl.children[i];
        if ((domLi.key || "").toLowerCase() === "pl") {
          let domSpan = (domLi.children || [])[0];
          let domImg = (domSpan.children || [])[0];
          if (domImg && domImg.nodeName.toLowerCase() === "img")
            domImg.attributes.src = base64Catalogne;
          break;
        }
      }
    }

    return c;
  }
}

export default ReactFlagsSelectExtension;
