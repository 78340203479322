// @flow
// @jsx h

// External librairies
import { h } from "preact";
import { Text } from "preact-i18n";

// Style
import styles from "./OtherPaymentMethods.scss";

// Component
import Link from "../link";
import { ArrowLeftIcon } from "elc-icons";
import PaymentMethodButton from "./PaymentMethodButton.js";

// Utility functions
import { toLocalePrice } from "elc-commons";

/**
 * Component displaying the payment other payments method available
 * appart from credit card
 */
const OtherPaymentMethods = (
  {
    currency,
    locale,
    currentOrder,
    paymentMethods,
    setPaymentMethod
  }: {
    currency: Currency,
    locale: string,
    currentOrder: Order,
    paymentMethods: Array<PaymentMethod>,
    setPaymentMethod: Function
  },
  { themeConfig }
) => (
  <div class={styles.column}>
    <header
      style={`color: ${
        themeConfig && themeConfig.color1 ? themeConfig.color1 : ""
      }`}
      class={styles.headerTitle}
    >
      <h2>
        <Text id="payment.other.title">Autres moyens de paiement</Text>
      </h2>
    </header>
    <div class={styles.paymentMethodsContent}>
      <div class={styles.priceInfos}>
        <h3 class={styles.mainPrice}>
          <strong>
            <Text id="payment.balance">Montant à payer :</Text>
          </strong>
        </h3>
        {paymentMethods
          .filter(
            paymentMethod => paymentMethod.baseModule.code !== "creditcard"
          )
          .map(paymentMethod => (
            <div key={paymentMethod.id} class={styles.mainPrice}>
              <span>{paymentMethod.baseModule.name}</span>
              <div>
                {paymentMethod.configuration.deposit < 100 && (
                  <span class={styles.price}>
                    {toLocalePrice(
                      (currentOrder.balance *
                        paymentMethod.configuration.deposit) /
                        100,
                      locale,
                      currency.isoCode
                    )}
                    &nbsp;
                    <Text id="payment.of">sur</Text>
                    &nbsp;
                  </span>
                )}
                <span class={styles.price}>
                  {toLocalePrice(
                    currentOrder.balance,
                    locale,
                    currency.isoCode
                  )}
                </span>
              </div>
            </div>
          ))}
      </div>
      <div class={styles.row}>
        <p class={styles.description}>
          <Text id="payment.other.choose">
            Please choose an other payment method between the following :
          </Text>
        </p>
      </div>
      <div>
        {paymentMethods &&
          paymentMethods
            .filter(
              paymentMethod => paymentMethod.baseModule.code !== "creditcard"
            )
            .map(paymentMethod => (
              <PaymentMethodButton
                key={paymentMethod.id}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                className={styles.paymentButton}
              />
            ))}
      </div>
      <div class={styles.row}>
        <Link
          href={`/order/${currentOrder.id}`}
          className={styles.previousButton}
        >
          <ArrowLeftIcon
            color={
              themeConfig && themeConfig.color1
                ? themeConfig.color1
                : styles.backIconColor
            }
          />
          <Text id="payment.backToBasket">Retour au panier</Text>
        </Link>
      </div>
    </div>
  </div>
);

export default OtherPaymentMethods;
