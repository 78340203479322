// @flow
// @jsx h

import { h, Component } from "preact";

import ReactHintFactory from "react-hint";
import Container from "../container";
import GiftCard from "./gift-card";

import styles from "./style.scss";

type ProductGiftsProps = {
  hash: string,
  config: WidgetConfig,
  locale: string,
  currency: Currency,
  currentProduct: Product,
  goToGift: Function
};

const ReactHint = ReactHintFactory({ createElement: h, Component });

function convertImgToIframe(description) {
  if (description == null) {
    return;
  }

  description = description.replace(
    /<img\s[^>]*?ta-insert-video\s*=\s*['\"]([^'\"]*?)['\"][^>]*?>/,
    `<div style="position: relative; padding-bottom: 56.25%; padding-top: 25px; height: 0;">
    <iframe src=$1 contenteditable='false' style='position: absolute; height: 100%; width: 100%; top: 0; left: 0;' allowfullscreen='true' frameborder='0'></iframe>
  </div>`
  );

  return description;
}

/**
 * Product gift component
 * used to display the gifts associated to a product
 */
export default class ProductGifts extends Component<ProductGiftsProps> {
  onRenderContent = target => {
    const { description } = target.dataset;

    return <div class={styles.tooltipContent} dangerouslySetInnerHTML={{ __html: convertImgToIframe(description) }}></div>;
  };

  render({
    hash,
    config,
    locale,
    currency,
    currentProduct,
    goToGift
  }: ProductGiftsProps) {
    return (
      <Container>
        <div class={styles.giftCardsContainer}>
          <div class={styles.tooltipPositionHint}>
            <ReactHint
              attribute="data-custom"
              className={styles.tooltip}
              events={{ click: true }}
              onRenderContent={this.onRenderContent}
              position="bottom"
              ref={ref => (this.instance = ref)}
            />
          </div>
          {currentProduct._embedded.giftProducts.map(product => (
            <GiftCard
              key={product.id}
              hash={hash}
              config={config}
              locale={locale}
              product={product}
              currency={currency}
              goToGift={goToGift}
            />
          ))}
        </div>
      </Container>
    );
  }
}
