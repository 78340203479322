// @flow
// @jsx h

import { h } from "preact";
import { Text } from "preact-i18n";
import Price from "../price";

import styles from "./style.scss";

type UnitQuantityProps = {
  unit: Unit,
  tax: Tax,
  locale: string,
  currency: Currency,
  max?: number,
  quantity: number,
  maxReached: boolean,
  handleAddUnit: Function,
  handleSubUnit: Function
};

export default function Quantity({
  theme,
  unit,
  tax,
  locale,
  currency,
  max,
  quantity,
  maxReached,
  handleAddUnit,
  handleSubUnit
}: UnitQuantityProps) {
  const addUnit = () => {
    handleAddUnit(unit);
  };

  const subUnit = () => {
    handleSubUnit(unit);
  };
  return (
    <div class={styles.quantityContainer}>
      <div class={theme && theme.caption ? theme.caption : styles.caption}>
        <div>
          <div class={styles.title}>
            {unit.numberOfSessions && <span>{unit.numberOfSessions}</span>}
            {<span>{unit.name}</span>}
          </div>
          {unit.prices && unit.prices[0].targetUnitQuantityMode === 1 && (
            <div class={styles.subtext}>
              <Text
                id="quantity.mode.byPerson"
                fields={{
                  minTargetUnitQuantity: unit.prices[0].minTargetUnitQuantity,
                  maxTargetUnitQuantity: unit.prices[0].maxTargetUnitQuantity
                }}
              >
                De {unit.prices[0].minTargetUnitQuantity} à{" "}
                {unit.prices[0].maxTargetUnitQuantity} personnes.
              </Text>
            </div>
          )}
          {unit.prices && unit.prices[0].targetUnitQuantityMode === 2 && (
            <div class={styles.subtext}>
              <Text
                id="quantity.mode.byAge"
                fields={{
                  minTargetUnitQuantity: unit.prices[0].minTargetUnitQuantity,
                  maxTargetUnitQuantity: unit.prices[0].maxTargetUnitQuantity
                }}
              >
                De {unit.prices[0].minTargetUnitQuantity} à{" "}
                {unit.prices[0].maxTargetUnitQuantity} ans.
              </Text>
            </div>
          )}
          {unit.prices && unit.prices[0].description && (
            <div class={styles.subtext}>{unit.prices[0].description}</div>
          )}
        </div>
        <div>
          {unit.prices && (
            <Price
              theme={theme}
              tax={tax}
              locale={locale}
              currency={currency}
              prices={unit.prices}
            />
          )}
        </div>
      </div>
      {/* <div class={styles.quantities}> */}
      <div
        class={theme && theme.quantities ? theme.quantities : styles.quantities}
      >
        <div
          class={`${
            theme && theme.quantity ? theme.quantity : styles.quantity
          } ${
            quantity > 0
              ? theme && theme.notZero
                ? theme.notZero
                : styles.notZero
              : ""
          }`}
          style={`${
            this.context.themeConfig && this.context.themeConfig.color1
              ? quantity > 0
                ? "background-color:" + this.context.themeConfig.color1
                : "color:" + this.context.themeConfig.color1
              : ""
          }`}
        >
          {quantity}
        </div>
        <div class={styles.quantityControls}>
          <button
            disabled={maxReached || max < 0}
            onClick={addUnit}
            class={`${
              theme && theme.quantityButton
                ? theme.quantityButton
                : styles.quantityButton
            } ${theme && theme.plus ? theme.plus : styles.plus}`}
          >
            <span
              style={`${
                this.context.themeConfig && this.context.themeConfig.color2
                  ? maxReached || max < 0
                    ? ""
                    : "color:" + this.context.themeConfig.color2
                  : ""
              }`}
            >
              +
            </span>
          </button>
          <button
            disabled={quantity === 0}
            onClick={subUnit}
            class={`${
              theme && theme.quantityButton
                ? theme.quantityButton
                : styles.quantityButton
            } ${theme && theme.minus ? theme.minus : styles.minus}`}
          >
            <span
              style={`${
                this.context.themeConfig && this.context.themeConfig.color2
                  ? quantity === 0
                    ? ""
                    : "color:" + this.context.themeConfig.color2
                  : ""
              }`}
            >
              -
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
