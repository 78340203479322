export default function(fbMetadata, customerClassId, currentQuantities) {
  if (fbMetadata.discounts && fbMetadata.discounts.length > 0) {
    const discount = fbMetadata.discounts.find(
      discount => discount.secondaryCustomerClassId === customerClassId
    );
    if (discount) {
      return {
        ...discount,
        currentPrimaryCustomerClassTicketQuantity: currentQuantities.has(
          discount.primaryCustomerClassId
        )
          ? currentQuantities.get(discount.primaryCustomerClassId)
          : 0
      };
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}
