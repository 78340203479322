// @flow
// @jsx h

// External librairies
import { h, Component } from "preact";
import { Text } from "preact-i18n";
import { toLocalePrice } from "elc-commons";

// Style
import styles from "./style.scss";

// Component
import { PrimaryButton } from "elc-button";

type GiftCardProps = {
  hash: string,
  config: WidgetConfig,
  currency: Currency,
  product: Product,
  goToGift: Function
};

/**
 * Gift card component
 * used to display a gift card
 */
export default class GiftCard extends Component<GiftCardProps> {
  getPriceWithTax(price: number, tax) {
    return tax ? price + price * (tax.rate / 100) : price;
  }

  /**
   * Handle click on the gift card
   */
  handleClick = () => {
    this.props.goToGift(this.props.product.id);
  };

  getGiftImage(gift) {
    const image = gift._embedded.images[0];
    return image._embedded.files.find(file => file.name === "200x200")
      ? image._embedded.files.find(file => file.name === "200x200").path
      : image._embedded.files.find(file => file.name === "400x400")
        ? image._embedded.files.find(file => file.name === "400x400").path
        : image._embedded.files.find(file => file.name === "ORIG")
          ? image._embedded.files.find(file => file.name === "ORIG").path
          : undefined;
  }

  /**
   * Render
   * @param {GiftCardProps} props - the component props
   * @return {ReactElement} markup
   */
  render({ locale, currency, product }: GiftCardProps) {
    return (
      <article class={styles.card}>
        <header class={styles.cardHeader}>
          {/* TODO return images from API */}
          <picture class={styles.thumbnail}>
            <img
              src={this.getGiftImage(product)}
              alt={product.name}
              class={styles.cardImg}
            />
          </picture>
          <span class={styles.cardTitle}>{product.name}</span>
        </header>

        <div class={styles.cardContent}>
          <div class={styles.cardSummary}>{product.summary}</div>
          <button
            data-custom
            data-description={product.description}
            class={styles.tooltipButton}
            style={`${
              this.context.themeConfig && this.context.themeConfig.color1
                ? "color:" + this.context.themeConfig.color1
                : ""
            }`}
          >
            <Text id="gift.viewDescription">View description</Text>
          </button>
          <div class={styles.cardPrice}>
            <span>
              <Text id="gift.startingPrice">Starting price</Text>
            </span>
            <span class={styles.price}>
              {/* {product.type === 'GIFT'
                ? this.getPriceWithTax(
                    product.sellingPrices[0].value,
                    product.tax
                  ).toFixed(2)
                : product.displayedStartingPrice} */}
              {toLocalePrice(
                this.getPriceWithTax(
                  product._embedded.prices[0].value,
                  product._embedded.tax
                ),
                locale,
                currency.isoCode
              )}
            </span>
          </div>
        </div>

        <PrimaryButton
          handleClick={this.handleClick}
          className={styles.offerButton}
        >
          <Text id="gift.offer">OFFRIR</Text>
        </PrimaryButton>
      </article>
    );
  }
}
