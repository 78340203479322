// @flow
// @jsx h
import { h, Component } from "preact";
import { Text } from "preact-i18n";
import { route } from "preact-router";

import WidgetCategoriesTabs from "./widget-categories-tabs";
import ProductsFilter from "./productsfilter";
import ProductsViewToggle from "./productsViewToggle";
import ProductsComponent from "elc-products";
import Loading from "elc-loading";

import styles from "./style.scss";

import { getWidgetCategories, getWidgetProducts } from "elc-internal-api";
import { isSafari, isEdge } from "elc-commons";

import {
  sendSessionScopedCustomDimensionsGTM,
  sendProductImpressionGTM,
  sendUXEventGTM
} from "elc-commons";

type Category = {
  id: number,
  name: string,
  code: string
};

export default class FetchProducts extends Component {
  state = {
    loading: true,
    error: false,
    products: [],
    widgetCategories: [],
    filters: {
      // name: 'Dessin',
      categories: [],
      dsc: [],
      // categories: [2, 3],
      // subCategories: [24, 25],
      distance: {
        //   latitude: 48.84161,
        //   longitude: 2.3774648,
        //   radius: 15
      },
      // available_on_date: moment(),
      number_participants: undefined
    }
  };

  constructor(props) {
    super(props);
    if (!this.props.config) {
      route(`/${this.props.hash}`);
    }
    if (this.props.disabledReservation) {
      this.disabledReservation = this.props.disabledReservation;
    }
    this.options = this.props.options;
  }

  /**
   * Fetch the Widget products and update component state
   */
  fetchWidgetProducts(options) {
    return getWidgetProducts(
      this.props.hash,
      this.props.config.module.conf.radius,
      options
    )
      .then(response => {
        this.setState({ products: response, loading: false });
        if (this.props.config) {
          sendSessionScopedCustomDimensionsGTM(
            this.props.config.type,
            this.props.hash,
            this.props.config.company,
            this.props.company._embedded.resellerType,
            this.props.config.module
          );
          sendProductImpressionGTM(
            response,
            this.props.config.company,
            this.props.config.type,
            this.props.hash,
            this.props.currency.isoCode
          );
        }
        return response;
      })
      .catch(error => console.error("Error getWidgetProducts", error));
  }

  /**
   * Fetch the Widget products when the component is mounted
   */
  componentDidMount() {
    if (!this.props.config) return;

    let options = {
      locale: this.props.locale,
      preview: this.props.preview
    };
    if (this.options) {
      if (this.options.geolocalisation) {
        options.filters = {
          distance: this.options.geolocalisation
        };
      }
    }

    const { display_filter } = this.props.config.module.conf;

    this.fetchWidgetCategories(options);

    if (!display_filter) {
      this.fetchWidgetProducts(options);
      return;
    }

    if (!isSafari && !isEdge) {
      // Look for user's geolocation permissions
      window.navigator.permissions
        .query({ name: "geolocation" })
        .then(({ state }) => {
          if (state !== "granted") {
            this.fetchWidgetProducts(options);
          }
          return;
        })
        .catch(err => console.error(err));
    } else {
      this.fetchWidgetProducts(options);
    }
  }

  fetchWidgetCategories = options => {
    const { hash } = this.props;
    getWidgetCategories(hash, options)
      .then(widgetCategories => {
        if (widgetCategories.length > 0) {
          this.setState(({ filters }) => {
            return { widgetCategories, filters };
          });
        }
      })
      .catch(error => console.error("Error getWidgetCategories", error));
  };

  /**
   * Update the Widget products when the locale is changed
   */
  // componentWillReceiveProps(nextProps) {}

  /**
   * Send a signal on DOM update
   * (used for iframe resize)
   */
  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.fetchWidgetProducts({
        locale: this.props.locale,
        preview: this.props.preview
      });
      this.fetchWidgetCategories({
        locale: this.props.locale,
        preview: this.props.preview
      });
    }
    this.props.pageDidMountOrUpdate();
  }

  /**
   * Fetch Widget products specific page
   */
  goToPage = page => {
    this.setState({ loading: true });
    sendUXEventGTM({
      event: "changePage",
      "chosen-page": page
    });
    this.fetchWidgetProducts({
      locale: this.props.locale,
      preview: this.props.preview,
      filters: this.state.filters,
      page
    });
  };

  /**
   * Fetch Widget products with specific filters
   */
  filterProducts = filters => {
    this.setState({ loading: true, filters });
    this.fetchWidgetProducts({
      locale: this.props.locale,
      preview: this.props.preview,
      filters
    });
  };

  setProductsViewModeList = () => {
    if (!this.props.productsListViewMode) {
      this.props.toggleProductsViewMode();
    }
  };

  setProductsViewModeGrid = () => {
    if (this.props.productsListViewMode) {
      this.props.toggleProductsViewMode();
    }
  };

  setWidgetCategory = widgetCategoryId => {
    const dscFilter = widgetCategoryId ? [widgetCategoryId] : [];
    let prevState = { ...this.state };
    prevState.filters.dsc = dscFilter;
    this.filterProducts(prevState.filters);
  };

  render(
    {
      hash,
      config,
      preview,
      currency,
      locale,
      onSelectProduct,
      pageDidMountOrUpdate,
      productsListViewMode
    },
    { loading, categories, products, filters, widgetCategories }
  ) {
    return (
      <div>
        <header class={styles.productListHeader}>
          {!!(
            config &&
            config.module.conf.display_filter &&
            config.module.conf.display_filter === 1
          ) && (
            <ProductsFilter
              hash={hash}
              config={config}
              preview={preview}
              locale={locale}
              categories={categories}
              filters={filters}
              filterProducts={this.filterProducts}
            />
          )}
          <div class={styles.categoriesBar}>
            {widgetCategories && (
              <WidgetCategoriesTabs
                widgetCategories={widgetCategories}
                setWidgetCategory={this.setWidgetCategory}
                currentCategory={this.state.filters.dsc}
              />
            )}
            {((this.props.options && !this.props.options.disabledView) ||
              !this.options) && (
              <ProductsViewToggle
                setProductsViewModeList={this.setProductsViewModeList}
                setProductsViewModeGrid={this.setProductsViewModeGrid}
                productsListViewMode={productsListViewMode}
              />
            )}
          </div>
        </header>
        <Loading loading={loading}>
          <Text id="products.loading">
            Nous recherchons les meilleures activités pour vous...
          </Text>
        </Loading>
        {!loading && products && (
          <ProductsComponent
            hash={hash}
            config={config}
            products={products}
            currency={currency}
            locale={locale}
            onSelectProduct={onSelectProduct}
            goToPage={this.goToPage}
            pageDidMountOrUpdate={pageDidMountOrUpdate}
            productsListViewMode={productsListViewMode}
            setProductsViewModeList={this.setProductsViewModeList}
            setProductsViewModeGrid={this.setProductsViewModeGrid}
            disabledReservation={this.disabledReservation}
            options={this.props.options}
          />
        )}
      </div>
    );
  }
}
