// @flow
// @jsx h
import { h } from 'preact';

const List = ({ color }: { color: string }) => (
  <svg
    fill={color ? color : '#000000'}
    id="loader-1"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 50 50"
    style="enable-background:new 0 0 50 50;"
  >
    <path d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z" />
  </svg>
);
export default List;
