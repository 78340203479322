// @flow
// @jsx h

import { h, Component } from "preact";

import styles from "./style.scss";

import { Text } from "preact-i18n";
import Privatize from "../privatize";
import Quantity from "../quantity";

import { getMaxQuantityBySessions } from "./getMaxQuantityBySessions";

type SessionsQuantitiesProps = {
  tax: Tax,
  locale: string,
  currency: Currency,
  quantities: ProductQuantities,
  units: Array<Unit>,
  currentTimeSlots: Array<TimeSlot>,
  currentSlot: TimeSlot,
  minParticipants: number,
  maxParticipants: number,
  canBePrivatized: boolean,
  minParticipantsToPrivatize: number,
  updateCurrentQuantities: Function
};

type SessionsQuantitiesState = {
  total: number,
  maxReached: boolean,
  isPrivatized: boolean
};

/**
 * SessionsQuantities component
 */
export default class SessionsQuantities extends Component<
  SessionsQuantitiesProps,
  SessionsQuantitiesState
> {
  /**
   * SessionsQuantities component constructor
   * @param {SessionsQuantitiesProps} props - SessionsQuantities component props
   */
  constructor(props: SessionsQuantitiesProps) {
    super(props);

    let timeslots = null;
    if (props.currentTimeSlots) {
      timeslots = props.currentTimeSlots.filter(
        timeSlot =>
          new Date(timeSlot.startDateTime) >=
          new Date(props.currentSlot.startDateTime)
      );
    }

    this.state = {
      total: 0,
      maxReached: false,
      isPrivatized: false,
      timeSlots: timeslots,
      maxQuantityBySessions: getMaxQuantityBySessions(
        props.units,
        timeslots,
        props.quantities
      )
    };
  }

  /**
   * Increment the quantity of a given unit
   * @param {Object} unit - the unit on which you want to increment to the quantity
   */
  incrementUnitQuantity = (unit: Unit) => {
    let quantities = [...this.props.quantities];
    // let timeSlots = [...this.state.timeSlots];
    let unitIndex = quantities.findIndex(
      quantity => quantity.numberOfSessions === unit.numberOfSessions
    );
    let timeSlots = null;

    if (this.state.timeSlots) {
      timeSlots = this.state.timeSlots.map((timeSlot, index) => {
        if (index < unit.numberOfSessions) {
          timeSlot._embedded.productUnitTimeSlotAvailabilities[0]
            .remainingBookingCapacity--;
        }
        return timeSlot;
      });
    }

    if (unitIndex !== -1) {
      quantities[unitIndex].quantity = quantities[unitIndex].quantity + 1;

      this.setState(
        prevState => {
          return {
            total: prevState.total + 1,
            maxReached: this.isMaxReached(prevState.total + 1),
            timeSlots,
            maxQuantityBySessions: getMaxQuantityBySessions(
              this.props.units,
              timeSlots,
              quantities
            )
          };
        },
        () => {
          this.props.updateCurrentQuantities(quantities);
        }
      );
    } else {
      quantities = [
        ...quantities,
        {
          id: unit.id,
          numberOfSessions: unit.numberOfSessions,
          quantity: 1
        }
      ];

      this.setState(
        prevState => {
          return {
            total: prevState.total + 1,
            maxReached: this.isMaxReached(prevState.total + 1),
            timeSlots,
            maxQuantityBySessions: getMaxQuantityBySessions(
              this.props.units,
              timeSlots,
              quantities
            )
          };
        },
        () => {
          this.props.updateCurrentQuantities(quantities);
        }
      );
    }
  };

  /**
   * Decrement quantity of given unit
   * @param {Object} unit - the unit on which you want to decrement to the quantity
   */
  decrementUnitQuantity = (unit: Unit) => {
    let unitIndex = this.props.quantities.findIndex(
      quantity => quantity.numberOfSessions === unit.numberOfSessions
    );
    let timeSlots = null;

    if (this.state.timeSlots) {
      timeSlots = this.state.timeSlots.map((timeSlot, index) => {
        if (index < unit.numberOfSessions) {
          timeSlot._embedded.productUnitTimeSlotAvailabilities[0]
            .remainingBookingCapacity--;
        }
        return timeSlot;
      });
    }

    if (unitIndex !== -1) {
      const quantities = [...this.props.quantities];
      quantities[unitIndex].quantity = quantities[unitIndex].quantity - 1;
      this.setState(
        prevState => {
          return {
            total: prevState.total - 1,
            maxReached: this.isMaxReached(prevState.total - 1),
            timeSlots,
            maxQuantityBySessions: getMaxQuantityBySessions(
              this.props.units,
              timeSlots,
              quantities
            )
          };
        },
        () => {
          this.props.updateCurrentQuantities(quantities);
        }
      );
    }
  };

  isMaxReached(total) {
    return this.props.maxParticipants > 0
      ? total === this.props.maxParticipants
      : false;
  }

  render(
    {
      theme,
      tax,
      units,
      locale,
      minParticipants,
      canBePrivatized,
      minParticipantsToPrivatize,
      currency,
      quantities
    },
    { total, maxReached, maxQuantityBySessions }
  ) {
    return (
      <div class={theme && theme.content ? theme.content : styles.content}>
        {canBePrivatized && (
          <Privatize
            theme={theme}
            onChangePrivatized={this.onChangePrivatized}
            minParticipantsToPrivatize={minParticipantsToPrivatize}
            total={total}
          />
        )}
        {units.map((unit, index) => {
          return (
            <div
              key={index}
              class={
                theme && theme.unitQuantity
                  ? theme.unitQuantity
                  : styles.unitQuantity
              }
            >
              <Quantity
                theme={theme}
                unit={unit}
                tax={tax}
                locale={locale}
                currency={currency}
                max={maxQuantityBySessions[index]}
                quantity={
                  quantities.findIndex(
                    quantity =>
                      quantity.numberOfSessions === unit.numberOfSessions
                  ) !== -1
                    ? quantities.find(
                        quantity =>
                          quantity.numberOfSessions === unit.numberOfSessions
                      ).quantity
                    : 0
                }
                maxReached={maxReached}
                handleAddUnit={this.incrementUnitQuantity}
                handleSubUnit={this.decrementUnitQuantity}
              />
            </div>
          );
        })}
        {minParticipants > 1 &&
          minParticipants > total && (
            <span
              class={
                theme && theme.quantityMessage
                  ? theme.quantityMessage
                  : styles.quantityMessage
              }
            >
              <Text
                id="quantities.minParticipants"
                fields={{ minParticipants }}
              >
                A minimun of {minParticipants} is required.
              </Text>
            </span>
          )}
        {maxReached && (
          <span
            class={
              theme && theme.quantityMessage
                ? theme.quantityMessage
                : styles.quantityMessage
            }
          >
            <Text id="quantities.maxReached">
              Maximum participants reached.
            </Text>
          </span>
        )}
      </div>
    );
  }
}
