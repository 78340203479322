// @flow
// @ jsx h

import { h, Component } from "preact";
import { Text } from "preact-i18n";
import Link from "../link";
import { ArrowLeftIcon } from "elc-icons";

import styles from "./OrderExpired.scss";

type OrderExpiredProps = {
  changeLoadingState:any,
  config:WidgetConfig
};

export default class OrderExpired extends Component<OrderExpiredProps> {
  componentDidMount () {
    this.props.changeLoadingState();
  }
  render({config}, { themeConfig }) {
    return (
      <div>
        <div>
          <h2>
            <Text id="booking.expired.title">
              Le délai pour terminer votre commande est expiré
            </Text>
          </h2>
          <p>
            <Text id="booking.expired.description">
              Malheureusement, vous n&apos;avez pas été assez rapide pour
              terminer votre commande et elle doit donc être considérée comme
              abandonnée pour des raisons de sécurité. Nous vous invitons à
              réaliser une nouvelle commande
            </Text>
          </p>
          <div>
          {config && (config.type === "WIDGET" || config.type === "MYPAGE") && (
            <Link href={`/${config.module.hash}`} className={styles.newOrderButton}>
              <ArrowLeftIcon
                color={
                  themeConfig && themeConfig.color1
                    ? themeConfig.color1
                    : styles.arrowColor
                }
              />
              <Text id="payment.confirmation.newOrder">Pass a new order</Text>
            </Link>
          )}
          </div>
        </div>
      </div>
    );
  }
}
