// @flow
// @jsx h
import { h, Component } from "preact";

import { IntlProvider } from "preact-i18n";
import ca from "../translations/ca.json";
import de from "../translations/de.json";
import en from "../translations/en.json";
import es from "../translations/es.json";
import fr from "../translations/fr.json";
import it from "../translations/it.json";
import nl from "../translations/nl.json";

import ProductsFilter from "./productsfilter";

type ProductsFilterComponentProps = {
  locale: string,
  config: WidgetConfig,
  products: Products,
  filters: object,
  filterProducts: Function
};

type ProductsFilterComponentState = {
  localeDefinition: Object
};

/* FIXME when Preact implement getDerivedStateFromProps */
/* eslint-disable-next-line react/no-deprecated */
export default class App extends Component<
  ProductsFilterComponentProps,
  ProductsFilterComponentState
> {
  state = {
    locale: "fr",
    localeDefinition: fr
  };

  constructor(props: ProductsFilterComponentProps) {
    super(props);
    if (props.locale) {
      switch (props.locale) {
        case "ca":
          this.setState({ locale: "ca", localeDefinition: ca });
          break;

        case "de":
          this.setState({ locale: "de", localeDefinition: de });
          break;

        case "en":
          this.setState({ locale: "en", localeDefinition: en });
          break;

        case "es":
          this.setState({ locale: "es", localeDefinition: es });
          break;

        case "it":
          this.setState({ locale: "it", localeDefinition: it });
          break;

        case "nl":
          this.setState({ locale: "nl", localeDefinition: nl });
          break;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.locale !== this.props.locale) {
      switch (nextProps.locale) {
        case "de":
          this.setState({ locale: "de", localeDefinition: de });
          break;

        case "ca":
          this.setState({ locale: "ca", localeDefinition: ca });
          break;

        case "en":
          this.setState({ locale: "en", localeDefinition: en });
          break;

        case "es":
          this.setState({ locale: "es", localeDefinition: es });
          break;

        case "fr":
          this.setState({ locale: "fr", localeDefinition: fr });
          break;

        case "it":
          this.setState({ locale: "it", localeDefinition: it });
          break;

        case "nl":
          this.setState({ locale: "nl", localeDefinition: nl });
          break;
      }
    }
  }

  render(
    { config, categories, filters, filterProducts },
    { locale, localeDefinition }
  ) {
    return (
      <IntlProvider definition={localeDefinition}>
        <ProductsFilter
          config={config}
          categories={categories}
          filters={filters}
          filterProducts={filterProducts}
          locale={locale}
        />
      </IntlProvider>
    );
  }
}
