const parseProductForGTM = product => {
  //Variables identiquement utilisées dans la fonction parseProductsForGTM
  let zipCode = product._embedded.address
    ? product._embedded.address.zipCode
    : product._embedded.merchant._embedded.address
      ? product._embedded.merchant._embedded.address.zipCode
      : '00000';
  let category = product._embedded.category
    ? product._embedded.category.name
    : 'NoCategory';
  let subCategory = product._embedded.subCategory
    ? product._embedded.subCategory.name
    : 'NoSubCategory';

  return [
    {
      id: String(product.id),
      name: product.name,
      //"price": product._embedded.prices[0].value.toFixed(2),
      category: product.type,
      dimension2: zipCode.substring(0, 2), //Departement -- TODO : département du produit ? du marchand ?
      dimension3: zipCode, //Code Postal
      dimension5: category, //Catégorie du produit
      dimension6: subCategory, //Sous catégorie du produit
      dimension11: String(product.id) //id du produit
    }
  ];
};

export default parseProductForGTM;
