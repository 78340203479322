// @flow
// @jsx h
import { h, Component } from "preact";
import styles from "./style.scss";
import { PrimaryButton } from "elc-button";
import { getProductTitleStyle } from "elc-theme";
import { toLocalePrice } from "elc-commons";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

import ca from "../../../../translations/ca.json";
import de from "../../../../translations/de.json";
import en from "../../../../translations/en.json";
import es from "../../../../translations/es.json";
import fr from "../../../../translations/fr.json";
import it from "../../../../translations/it.json";
import nl from "../../../../translations/nl.json";

type ProductItemProps = {
  config: WidgetConfig,
  locale: String,
  currency: Currency,
  product: Product,
  onSelectProduct: Function
};

export default class ProductItem extends Component<ProductItemProps> {
  handleProductClick = () => {
    this.props.onSelectProduct(this.props.product.id);
  };

  getPriceWithTax(price: number, tax) {
    return tax ? price + price * (tax.rate / 100) : price;
  }

  render({
    locale,
    currency,
    product
  }: {
    locale: string,
    currency: Currency,
    product: Product
  }) {
    // Special case
    // we need to do this because this component is called asynchronously
    // button and text to be translated are not yet ready until the request finish,
    // so the translation doesn't work.
    // To prevent from that issue, we have to do it without using i18n.
    // To reiterate the issue: Change language > go to product detail > go back to catalog
    let language =
      locale === "fr"
        ? fr
        : locale === "en"
        ? en
        : locale === "de"
        ? de
        : locale === "es"
        ? es
        : locale === "it"
        ? it
        : locale === "nl"
        ? nl
        : ca;
    return (
      <article class={styles.card}>
        {product.primaryImage && (
          <picture class={styles.cardPicture}>
            <img
              src={product.primaryImage.path}
              alt={product.primaryImage.legend}
              class={styles.cardImg}
            />
          </picture>
        )}
        <div class={styles.cardContent}>
          <div class={styles.left}>
            <h3
              class={styles.cardTitle}
              style={getProductTitleStyle(this.context.themeConfig)}
            >
              {product.name}
            </h3>
            {product.type === "FRANCE_BILLET" && (
              <div>
                {new Date(product.availableFromDate).toLocaleDateString() ===
                new Date(product.availableToDate).toLocaleDateString()
                  ? new Date(product.availableFromDate).toLocaleDateString()
                  : `${new Date(
                      product.availableFromDate
                    ).toLocaleDateString()}-${new Date(
                      product.availableToDate
                    ).toLocaleDateString()}`}
              </div>
            )}
            <p class={styles.cardDescription}>
              <HTMLEllipsis
                unsafeHTML={
                  product.type === "FRANCE_BILLET"
                    ? product.summary
                    : product.summary
                }
                maxLine={product.type === "FRANCE_BILLET" ? 2 : 4}
                ellipsis="..."
                basedOn="letters"
              />
            </p>
          </div>
          <div class={styles.right}>
            <div class={styles.startingPrice}>
              {language.product.startingPrice}
            </div>
            <h3
              class={styles.price}
              style={`color:${
                this.context.themeConfig && this.context.themeConfig.color1
                  ? this.context.themeConfig.color1
                  : ""
              };`}
            >
              {toLocalePrice(
                product.type === "GIFT"
                  ? this.getPriceWithTax(
                      product.sellingPrices[0].value,
                      product.tax
                    )
                  : product.displayedStartingPrice,
                locale,
                currency.isoCode
              )}
            </h3>
            {!this.props.disabledReservation && (
              <PrimaryButton
                handleClick={this.handleProductClick}
                className={styles.goToProductButton}
              >
                {product.type === "GIFT"
                  ? language.product.offer
                  : language.product.book}
              </PrimaryButton>
            )}
          </div>
        </div>
      </article>
    );
  }
}
