import parseProductForGTM from './parseProductForGTM';
import { push } from './EasyLoisirsGTMClient';

const sendProductClickGTM = (product, company, type, hash, currencyCode) => {
  let informationsProductForGTM = {
    actionField: { list: type + '-' + company.id + '-' + hash },
    products: parseProductForGTM(product)
  };
  push({
    event: 'EEproductClick',
    ecommerce: {
      currencyCode: currencyCode,
      click: informationsProductForGTM
    }
  });
  push({
    event: 'EEproductDetail',
    ecommerce: {
      detail: informationsProductForGTM
    }
  });
};

export default sendProductClickGTM;
