// @flow
// @jsx h

// External librairies
import { h } from "preact";

// Style
import styles from "./style.scss";

/**
 * Booker container Component
 * used to display the booker views in common styled container
 */
export default function Container({ children }) {
  return <div class={styles.container}>{children}</div>;
}
