// @flow
// @jsx h
import { h, Component } from "preact";
import { Text, withText } from "preact-i18n";
import styles from "./style.scss";
import { Button } from "elc-button";
import moment from "moment";
import DatePicker from "react-datepicker";

import { sendUXEventGTM } from "elc-commons";

type WhenFilterProps = {
  dateChange: Function,
  currentFilterDate: any,
  locale: string
};

@withText({
  datepickerPlaceholder: "products.filters.chooseDate"
})
export default class WhenFilter extends Component<WhenFilterProps> {
  constructor(props) {
    super(props);
    this.state = {
      currentDatepickerDate: undefined,
      mobileDisplay: false
    };
  }

  componentDidMount() {
    if (!window.matchMedia) return;
    this.mql = window.matchMedia(
      "(max-width: " +
        (styles.mobileMode ? styles.mobileMode : "53.750em") +
        ")"
    );
    this.mql.addListener(this.onMatch);
    this.onMatch(this.mql);
  }

  componentWillUnmount() {
    this.mql && this.mql.removeListener(this.onMatch);
  }

  onMatch = mql => {
    const mobileDisplay = !!mql.matches;
    this.setState({ mobileDisplay });
  };

  setDateToday = () => {
    if (
      this.props.currentFilterDate &&
      this.props.currentFilterDate.isSame(moment(), "day")
    ) {
      this.clearSelectedDate();
    } else {
      sendUXEventGTM({
        event: "changeDate",
        "filter-chosen-date": moment(),
        "filter-relative-date": "Today"
      });
      this.props.dateChange(moment());
    }
    this.setState({ currentDatepickerDate: undefined });
  };

  setDateTomorrow = () => {
    if (
      this.props.currentFilterDate &&
      this.props.currentFilterDate.isSame(moment().add(1, "days"), "day")
    ) {
      this.clearSelectedDate();
    } else {
      sendUXEventGTM({
        event: "changeDate",
        "filter-chosen-date": moment().add(1, "days"),
        "filter-relative-date": "Tomorrow"
      });
      this.props.dateChange(moment().add(1, "days"));
    }
    this.setState({ currentDatepickerDate: undefined });
  };

  setDatepickerDate = date => {
    if (date) {
      this.setState({
        currentDatepickerDate: date
      });
      sendUXEventGTM({
        event: "changeDate",
        "filter-chosen-date": date,
        "filter-relative-date": "DatePicker"
      });
      this.props.dateChange(date);
    }
  };

  handleInputClick = () => {
    this.clearSelectedDate();
    this.setState({ currentDatepickerDate: undefined });
  };

  clearSelectedDate = () => {
    this.props.dateChange();
  };

  render({ datepickerPlaceholder }) {
    return (
      <div class={styles.whenFilterContainer}>
        <label class={styles.filterLabel}>
          <Text id="products.filters.when.label">Date</Text>
        </label>
        <div class={styles.whenFilter}>
          <Button
            handleClick={this.setDateToday}
            className={`${styles.dateButton} ${
              this.props.currentFilterDate &&
              this.props.currentFilterDate.isSame(moment(), "day")
                ? styles.activeFilter
                : ""
            }`}
            background={`${
              this.context.themeConfig &&
              this.context.themeConfig.color1 &&
              this.props.currentFilterDate &&
              this.props.currentFilterDate.isSame(moment(), "day")
                ? this.context.themeConfig.color1
                : ""
            };`}
          >
            <Text id="products.filters.today">Today</Text>
          </Button>
          <Button
            handleClick={this.setDateTomorrow}
            className={`${styles.dateButton} ${
              this.props.currentFilterDate &&
              this.props.currentFilterDate.isSame(
                moment().add(1, "days"),
                "day"
              )
                ? styles.activeFilter
                : ""
            }`}
            background={`${
              this.context.themeConfig &&
              this.context.themeConfig.color1 &&
              this.props.currentFilterDate &&
              this.props.currentFilterDate.isSame(
                moment().add(1, "days"),
                "day"
              )
                ? this.context.themeConfig.color1
                : ""
            };`}
          >
            <Text id="products.filters.tomorrow">Tomorrow</Text>
          </Button>
          <div
            class={`${styles.datePickerContainer} ${
              this.state.currentDatepickerDate &&
              this.props.currentFilterDate &&
              this.props.currentFilterDate.isSame(
                this.state.currentDatepickerDate,
                "day"
              )
                ? styles.datePickerActive
                : ""
            }`}
            style={`background-color:${
              this.context.themeConfig &&
              this.context.themeConfig.color1 &&
              this.state.currentDatepickerDate &&
              this.props.currentFilterDate &&
              this.props.currentFilterDate.isSame(
                this.state.currentDatepickerDate,
                "day"
              )
                ? this.context.themeConfig.color1
                : ""
            };`}
          >
            {this.state.currentDatepickerDate && (
              <span onClick={this.handleInputClick} class={styles.selectedDate}>
                {this.state.currentDatepickerDate.format("L")}
              </span>
            )}
            {!this.state.currentDatepickerDate && (
              <DatePicker
                selected={this.state.currentDatepickerDate}
                onChange={this.setDatepickerDate}
                placeholderText={datepickerPlaceholder}
                locale={this.props.locale}
                className={`${styles.datePicker} ${
                  this.state.currentDatepickerDate &&
                  this.props.currentFilterDate &&
                  this.props.currentFilterDate.isSame(
                    this.state.currentDatepickerDate,
                    "day"
                  )
                    ? styles.activeFilter
                    : ""
                } ${this.state.currentDatepickerDate && styles.dateSelected}`}
                withPortal={this.state.mobileDisplay}
                minDate={moment()}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
