//@flow

declare var ENV: string;

import { push } from "./EasyLoisirsGTMClient";

const sendSessionScopedCustomDimensionsGTM = (
  support,
  hash,
  company,
  resellerType,
  configModule
) => {
  let supportBis = "";
  let resellertype1 = "not_a_reseller";
  let resellertype2 = "not_a_reseller";

  if (support === "kiosk") {
    supportBis = support;
  } else {
    //Widget ou MyPage
    supportBis = support + "-" + company.type;
  }
  if (
    company.type.toString().includes("RESELLER") || //Widget + MyPage
    (company.type === 2 || company.type === 3) //Kiosk
  ) {
    if (resellerType) {
      //Pour les hebergement et agences de voyage, on a possibilité d'avoir un sous-type de resaler (Hebergement --> Hote...)
      if (resellerType._embedded) {
        resellertype1 = resellerType._embedded.parent.name;
        resellertype2 = resellerType.name;
      } else {
        resellertype1 = resellerType.name;
        resellertype2 = "no_sub_reseller_type";
      }
    } else {
      resellertype1 = "undefined_reseller_type";
      resellertype2 = "undefined_subreseller_type";
    }
  }

  push({
    event: "SessionScopedCustomsDimensions",
    support: supportBis, //support dimension1 (widget/kiosk + type du marchand)
    "id-support": hash, //id-support dimension 4 (l'identifiant unique du widget, le hash)
    "nom-marchand": company.name, //nom-marchand dimension7, le nom du marchand associé à l'id-marchand
    "id-marchand": company.id.toString(), //id-marchand dimension8 (l'identifiant unique du marchand associé),
    //"env-name": ENV ? ENV : "unknown origin",
    "reseller-type1": resellertype1,
    "reseller-type2": resellertype2,
    "direct-sale-id": configModule.id
  });
};

export default sendSessionScopedCustomDimensionsGTM;
