// @flow
// @jsx h

import { h, Component } from "preact";

import ProductImage from "./productimage";
import ProductImagesSwipe from "./productimagesswipe";

export default class ProductImages extends Component {
  render({ images, pageDidMountOrUpdate }) {
    return images && images.length === 1 ? (
      <ProductImage
        image={images[0]}
        pageDidMountOrUpdate={pageDidMountOrUpdate}
      />
    ) : images && images.length > 1 ? (
      <ProductImagesSwipe
        images={images}
        pageDidMountOrUpdate={pageDidMountOrUpdate}
      />
    ) : null;
  }
}
