// @flow
// @jsx h

// External librairies
import { h } from "preact";
import { Text } from "preact-i18n";

// Style
import { withStyles } from "elc-theme";
import styles from "./style.scss";

/**
 * Header title stateless functional component
 * displaying header title depending on route
 */
const HeaderTitle = (
  {
    route,
    getClassName
  }: {
    route: string,
    getClassName: Function
  },
  context
) => {
  return (
    <div
      class={getClassName("headerTitle")}
      style={`color:${
        context.themeConfig && context.themeConfig.color1
          ? context.themeConfig.color1
          : ""
      };`}
    >
      <h2>
        {route === "day" ? (
          <Text id="header.title.day">Quel jour ?</Text>
        ) : route === "slot" ? (
          <Text id="header.title.slot">Quel heure ?</Text>
        ) : route === "quantities" ? (
          <Text id="header.title.quantities">Combien ?</Text>
        ) : route === "extras" ? (
          <Text id="header.title.extras">Extras</Text>
        ) : route === "giftQuantity" ? (
          <Text id="header.title.giftQuantity">Combien ?</Text>
        ) : route === "productGifts" ? (
          <Text id="header.title.productGifts">Offrir une activité</Text>
        ) : route === "giftBeneficiary" ? (
          <Text id="header.title.giftBeneficiary">Pour qui ?</Text>
        ) : (
          ""
        )}
      </h2>
    </div>
  );
};

export default withStyles(HeaderTitle, styles);
