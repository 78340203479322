// @flow
// @jsx h

// External librairies
import { h } from "preact";

// import Button from '../button';
import { Button } from "elc-button";

const PaymentButton = ({ paymentMethod, setPaymentMethod, className }) => {
  const handleClick = () => setPaymentMethod(paymentMethod);
  return (
    <Button handleClick={handleClick} className={className}>
      {paymentMethod.baseModule.name}
    </Button>
  );
};

export default PaymentButton;
