// @flow
// @jsx h

import { h } from "preact";
import { Text } from "preact-i18n";

import { ConfirmationIcon } from "elc-icons";

import styles from "./style.scss";
import { withStyles } from "elc-theme";

/**
 * Confirmation that a email or smshas been sent to the customer Component
 */
const PreBookingConfirmation = ({ getClassName }) => (
  <div>
    <header class={getClassName("headerTitle")}>
      <h2>
        <Text id="basket.confirmation">Confirmation</Text>
      </h2>
    </header>
    <div class={styles.column}>
      <div class={styles.contentContainer}>
        <div class={styles.headerconfirmation}>
          <ConfirmationIcon color={styles.vaildColor} />
          <div>
            <Text id="basket.prebooking.title">
              Votre pré-réservation a bien été prise en compte
            </Text>
          </div>
        </div>

        <div class={styles.subHeaderContainer}>
          <Text id="basket.prebooking.text">
            Vous allez recevoir un sms et un email pour procéder au paiement.
            Une fois finalisé, votre réservation sera confirmée et vous
            receverez votre billet par email.
          </Text>
        </div>
      </div>
    </div>
  </div>
);

export default withStyles(PreBookingConfirmation, styles);
