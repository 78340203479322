// @flow
// @jsx h
import { h, Component } from "preact";
import ProductCard from "./productcard";
import styles from "./style.scss";

type ProductCardsProps = {
  hash: string,
  locale: string,
  config: WidgetConfig,
  currency: Currency,
  products: Array<Product>,
  onSelectProduct: Function,
  disabledReservation: boolean
};

export default class ProductCards extends Component<ProductCardsProps> {
  render({
    config,
    locale,
    currency,
    products,
    onSelectProduct,
    disabledReservation
  }: {
    config: WidgetConfig,
    currency: Currency,
    products: Array<Product>,
    onSelectProduct: Function,
    disabledReservation: boolean
  }) {
    return (
      <div class={styles.container}>
        {products && (
          <div id="cards" class={styles.centered}>
            <section class={styles.cards}>
              {products.map(product => (
                <ProductCard
                  key={product.id}
                  config={config}
                  locale={locale}
                  currency={currency}
                  product={product}
                  onSelectProduct={onSelectProduct}
                  disabledReservation={disabledReservation}
                />
              ))}
            </section>
          </div>
        )}
      </div>
    );
  }
}
