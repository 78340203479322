// @flow
// @jsx h
import { h, Component } from "preact";
import styles from "./style.scss";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  FirstPageIcon,
  LastPageIcon
} from "elc-icons";

type ProductsPaginationProps = {
  config: WidgetConfig,
  currentPage: number,
  pageCount: number,
  goToPage: Function
};

export default class ProductsPagination extends Component<
  ProductsPaginationProps
> {
  goToPreviousPage = () => {
    this.props.goToPage(this.props.currentPage - 1);
  };
  goToNextPage = () => {
    this.props.goToPage(this.props.currentPage + 1);
  };
  goToFirstPage = () => {
    this.props.goToPage(1);
  };
  goToLastPage = () => {
    this.props.goToPage(this.props.pageCount);
  };

  render({ currentPage, pageCount }) {
    const customColor =
      this.context.themeConfig && this.context.themeConfig.color1
        ? this.context.themeConfig.color1
        : undefined;
    return (
      <div>
        {pageCount > 1 && (
          <div class={styles.row}>
            <button
              class={styles.paginationButton}
              disabled={currentPage === 1}
              onClick={this.goToFirstPage}
            >
              <FirstPageIcon
                color={
                  currentPage === 1
                    ? styles.arrowColorDisabled
                    : customColor
                      ? customColor
                      : styles.arrowColor
                }
              />
            </button>
            <button
              class={styles.paginationButton}
              disabled={currentPage === 1}
              onClick={this.goToPreviousPage}
            >
              <ArrowLeftIcon
                color={
                  currentPage === 1
                    ? styles.arrowColorDisabled
                    : customColor
                      ? customColor
                      : styles.arrowColor
                }
              />
            </button>
            <div>
              Page {currentPage} / {pageCount}
            </div>
            <button
              class={styles.paginationButton}
              disabled={currentPage === pageCount}
              onClick={this.goToNextPage}
            >
              <ArrowRightIcon
                color={
                  currentPage === pageCount
                    ? styles.arrowColorDisabled
                    : customColor
                      ? customColor
                      : styles.arrowColor
                }
              />
            </button>
            <button
              class={styles.paginationButton}
              disabled={currentPage === pageCount}
              onClick={this.goToLastPage}
            >
              <LastPageIcon
                color={
                  currentPage === pageCount
                    ? styles.arrowColorDisabled
                    : customColor
                      ? customColor
                      : styles.arrowColor
                }
              />
            </button>
          </div>
        )}
      </div>
    );
  }
}
