import parseProductForGTM from './parseProductForGTM';
import { push } from './EasyLoisirsGTMClient';

const sendRemoveFromCartGTM = (booking, currencyCode) => {
  //let booking = this.state.currentOrder._embedded.bookings.find((booking) => booking.id == bookingId); //Utilisé quand on avait que l'id du booking de dispo dans cette méthdoe
  //Si on supprime uniquement un extra, on remove pas le produit du panier au sens du GTM, on va juste préciser qu'à l'étape d'avant, option = sans extras
  if (booking._embedded.product.type == 'EXTRA') {
    push({
      event: 'EEcheckoutOption',
      ecommerce: {
        checkout_option: {
          actionField: {
            step: 4,
            option: 'Removed Extras' //Sans extras ou Removed Extras ?
          }
        }
      }
    });
  } else {
    //Sinon, on supprime tout le produit sans se préoccuper des extras associés
    let product = parseProductForGTM(booking._embedded.product);
    push({
      event: 'EEremoveFromCart',
      ecommerce: {
        currencyCode: currencyCode,
        remove: {
          products: product
        }
      }
    });
  }
};

export default sendRemoveFromCartGTM;
