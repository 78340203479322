// @flow
// @jsx h
import { h, Component } from "preact";

import { Text } from "preact-i18n";

// Style
import styles from "./style.scss";

// Components
import Container from "../container";
import BookerContainer from "../bookercontainer";
import CalendarDay from "./calendar-day";
import { ArrowLeftIcon, ArrowRightIcon } from "elc-icons";
import FooterButton from "../footerbutton";
import StartingPrice from "../starting-price";
import { BackButton } from "elc-button";
import Loading from "elc-loading";

import { sendCheckoutEventProductGTM } from "elc-commons";

import moment from "moment";
import "moment-timezone";

type CalendarProps = {
  theme: object,
  config: WidgetConfig,
  locale: locale,
  currency: Currency,
  currentDay: Date,
  currentProduct: Product,
  getProductMonthAvailabilities: Function,
  onDayClick: Function
};

type CalendarState = {
  minDate: Date,
  firstDayOfCurrentMonth: Date,
  loading: boolean,
  availabilities?: Array<ProductDayAvailability>,
  noAvailabilities: boolean
};

/**
 * Calendar component
 * displaying product availabilities
 */
export default class Calendar extends Component<CalendarProps, CalendarState> {
  state = {
    minDate: undefined,
    firstDayOfCurrentMonth: undefined,
    loading: true,
    availabilities: [],
    noAvailabilities: false,
    merchantTZ: undefined
  };

  componentDidMount() {
    let minDate;
    const {
      currentProduct,
      currentDay,
      getProductMonthAvailabilities
    } = this.props;

    let merchantTZ =
      currentProduct._embedded.merchant._embedded.address.timezone ||
      "Europe/Paris";
    let today = todayToLocalDay(merchantTZ);

    if (
      currentProduct.availableFromDate &&
      apiToLocalDay(currentProduct.availableFromDate) >= today
    ) {
      minDate = apiToLocalDay(currentProduct.availableFromDate);
    } else {
      minDate = today;
    }

    let firstDayOfCurrentMonth = new Date(minDate);
    if (currentDay) {
      firstDayOfCurrentMonth = new Date(apiToLocalDay(currentDay));
      firstDayOfCurrentMonth.setDate(1);
    }

    getProductMonthAvailabilities(localToApiDay(firstDayOfCurrentMonth), true)
      .then(availabilities => {
        if (availabilities.length > 0) {
          let firstDate = apiToLocalDay(availabilities[0].date);
          if (moment(firstDate).isAfter(firstDayOfCurrentMonth)) {
            firstDayOfCurrentMonth = new Date(firstDate);
          }

          if (moment(firstDate).isBefore(today)) {
            minDate = new Date(firstDate);
          }

          firstDayOfCurrentMonth.setDate(1);
        }

        this.setState({
          minDate,
          firstDayOfCurrentMonth,
          noAvailabilities: availabilities.length === 0 ? true : false,
          availabilities,
          loading: false
        });

        return availabilities;
      })
      .catch(error =>
        console.error("Error getting product month availabilities", error)
      );

    this.setState({
      minDate: minDate,
      firstDayOfCurrentMonth: firstDayOfCurrentMonth,
      merchantTZ: merchantTZ
    });
  }

  previousMonth = () => {
    this.setState(prevState => {
      prevState.firstDayOfCurrentMonth.setMonth(
        prevState.firstDayOfCurrentMonth.getMonth() - 1
      );
      return {
        firstDayOfCurrentMonth: prevState.firstDayOfCurrentMonth,
        loading: true
      };
    });
    this.props
      .getProductMonthAvailabilities(
        localToApiDay(this.state.firstDayOfCurrentMonth)
      )
      .then(availabilities => {
        if (availabilities.length > 0) {
          let firstDate = apiToLocalDay(availabilities[0].date);
          if (moment(firstDate).isBefore(this.state.firstDayOfCurrentMonth)) {
            this.setState({ minDate: new Date(firstDate) });
          }
        }
        this.setState({ availabilities, loading: false });
        return availabilities;
      })
      .catch(error =>
        console.error("Error getting product month availabilities", error)
      );
  };

  nextMonth = () => {
    this.setState(prevState => {
      prevState.firstDayOfCurrentMonth.setMonth(
        prevState.firstDayOfCurrentMonth.getMonth() + 1
      );
      return {
        firstDayOfCurrentMonth: prevState.firstDayOfCurrentMonth,
        loading: true
      };
    });
    this.props
      .getProductMonthAvailabilities(
        localToApiDay(this.state.firstDayOfCurrentMonth)
      )
      .then(availabilities => {
        this.setState({ availabilities, loading: false });
        return availabilities;
      })
      .catch(error =>
        console.error("Error getting product month availabilities", error)
      );
  };

  onDayClick = day => {
    sendCheckoutEventProductGTM(this.props.currentProduct, 2, false);
    this.props.onDayClick(day);
  };

  /**
   * Render the Component
   * @param {CalendarProps} - the Calendar component props
   * @param {CalendarState} - the Calendar component props
   * @return {ReactElement} markup
   */
  render(
    {
      theme,
      config,
      locale,
      currentProduct,
      currency,
      currentDay,
      goToQuantities
    },
    {
      loading,
      minDate,
      firstDayOfCurrentMonth,
      availabilities,
      noAvailabilities,
      merchantTZ
    }
  ) {
    function availability(day, availabilities) {
      return (
        availabilities &&
        availabilities.find(availability => {
          return (
            apiToLocalDay(availability.date).toISOString() === day.toISOString()
          );
        })
      );
    }

    function isFullyBooked(day, availabilities) {
      return (
        availabilities &&
        availabilities.find(availability => {
          return (
            apiToLocalDay(availability.date).toISOString() ===
              day.toISOString() && !availability.bookable
          );
        })
      );
    }

    function isBookable(day, availabilities) {
      return (
        availabilities &&
        availabilities.find(availability => {
          return (
            apiToLocalDay(availability.date).toISOString() ===
              day.toISOString() && availability.bookable
          );
        })
      );
    }

    function isInProgress(day, availabilities) {
      return (
        availabilities &&
        availabilities.find(availability => {
          return (
            apiToLocalDay(availability.date).toISOString() ===
              day.toISOString() && availability.inProgress
          );
        })
      );
    }

    function isSameMonth(dt1, dt2) {
      return (
        dt1.getMonth() === dt2.getMonth() &&
        dt1.getFullYear() === dt2.getFullYear()
      );
    }

    return (
      <Container>
        <BookerContainer>
          {loading ? (
            <Loading>
              <Text id="calendar.loading">Loading...</Text>
            </Loading>
          ) : !noAvailabilities ? (
            <div
              class={
                theme && theme.calendarContainer
                  ? theme.calendarContainer
                  : styles.calendarContainer
              }
            >
              <table class={styles.calendar}>
                <thead>
                  <tr class={styles["month-heading"]}>
                    <th colSpan="1">
                      <button
                        class={
                          theme && theme["switch-month"]
                            ? theme["switch-month"]
                            : styles["switch-month"]
                        }
                        onClick={this.previousMonth}
                        disabled={isSameMonth(firstDayOfCurrentMonth, minDate)}
                      >
                        <ArrowLeftIcon
                          color={
                            isSameMonth(firstDayOfCurrentMonth, minDate)
                              ? styles.arrowColorDisabled
                              : this.context.themeConfig &&
                                this.context.themeConfig.color1
                              ? this.context.themeConfig.color1
                              : theme && theme.arrowColor
                              ? theme.arrowColor
                              : styles.arrowColor
                          }
                        />
                      </button>
                    </th>
                    <th colSpan="5">
                      <span class={styles.month}>
                        {`${firstDayOfCurrentMonth.toLocaleString(
                          locale ? locale : "fr",
                          {
                            month: "long"
                          }
                        )} ${firstDayOfCurrentMonth.getFullYear()}`}
                      </span>
                    </th>
                    <th colSpan="1">
                      <button
                        onClick={this.nextMonth}
                        class={
                          theme && theme["switch-month"]
                            ? theme["switch-month"]
                            : styles["switch-month"]
                        }
                        disabled={
                          currentProduct.availableToDate &&
                          isSameMonth(
                            firstDayOfCurrentMonth,
                            apiToLocalDay(currentProduct.availableToDate)
                          )
                        }
                      >
                        <ArrowRightIcon
                          color={
                            currentProduct.availableToDate &&
                            isSameMonth(
                              firstDayOfCurrentMonth,
                              apiToLocalDay(currentProduct.availableToDate)
                            )
                              ? styles.arrowColorDisabled
                              : this.context.themeConfig &&
                                this.context.themeConfig.color1
                              ? this.context.themeConfig.color1
                              : theme && theme.arrowColor
                              ? theme.arrowColor
                              : styles.arrowColor
                          }
                        />
                      </button>
                    </th>
                  </tr>
                  <tr
                    class={
                      theme && theme["week-days"]
                        ? theme["week-days"]
                        : styles["week-days"]
                    }
                  >
                    {/* {getWeekDays(locale).map(weekDay => (
                      <th key={weekDay}>{weekDay}</th>
                    ))} */}
                    <th>
                      <Text id="calendar.days.monday">Lundi</Text>
                    </th>
                    <th>
                      <Text id="calendar.days.tuesday">Mardi</Text>
                    </th>
                    <th>
                      <Text id="calendar.days.wednesday">Mercredi</Text>
                    </th>
                    <th>
                      <Text id="calendar.days.thursday">Jeudi</Text>
                    </th>
                    <th>
                      <Text id="calendar.days.friday">Vendredi</Text>
                    </th>
                    <th>
                      <Text id="calendar.days.saturday">Samedi</Text>
                    </th>
                    <th>
                      <Text id="calendar.days.sunday">Dimanche</Text>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getWeekNumber(firstDayOfCurrentMonth).map((week, index) => (
                    <tr key={index}>
                      {week.map(day => {
                        if (currentDay) {
                          day.setHours(0, 0, 0, 0);
                          currentDay.setHours(0, 0, 0, 0);
                        }
                        return (
                          <CalendarDay
                            key={day}
                            theme={theme}
                            day={day}
                            isToday={
                              day.toISOString() ===
                              todayToLocalDay(merchantTZ).toISOString()
                            }
                            isDifferentMonth={
                              firstDayOfCurrentMonth.getMonth() !==
                              day.getMonth()
                            }
                            selected={
                              currentDay &&
                              currentDay.getTime() === day.getTime()
                                ? true
                                : false
                            }
                            availability={availability(day, availabilities)}
                            fullyBooked={isFullyBooked(day, availabilities)}
                            bookable={
                              isBookable(day, availabilities) &&
                              firstDayOfCurrentMonth.getMonth() ===
                                day.getMonth()
                            }
                            onDayClick={this.onDayClick}
                            inProgress={isInProgress(day, availabilities)}
                          />
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan="7">
                      <div
                        class={
                          theme && theme["calendarLegend"]
                            ? theme["calendarLegend"]
                            : styles["calendarLegend"]
                        }
                      >
                        <div
                          class={
                            theme && theme["booked"]
                              ? theme["booked"]
                              : styles["booked"]
                          }
                        >
                          <span
                            class={
                              theme && theme["color"]
                                ? theme["color"]
                                : styles["color"]
                            }
                          />
                          <span
                            class={
                              theme && theme["calendarLegendCaption"]
                                ? theme["calendarLegendCaption"]
                                : styles["calendarLegendCaption"]
                            }
                          >
                            <Text id="calendar.booked">Booked</Text>
                          </span>
                        </div>
                        <div
                          class={
                            theme && theme["available"]
                              ? theme["available"]
                              : styles["available"]
                          }
                        >
                          <span
                            class={
                              theme && theme["color"]
                                ? theme["color"]
                                : styles["color"]
                            }
                          />
                          <span
                            class={
                              theme && theme["calendarLegendCaption"]
                                ? theme["calendarLegendCaption"]
                                : styles["calendarLegendCaption"]
                            }
                          >
                            <Text id="calendar.available">Available</Text>
                          </span>
                        </div>
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          ) : (
            <div>
              <Text id="calendar.noAvailabilities">No availabilities.</Text>
            </div>
          )}
        </BookerContainer>
        {(config.type === "KIOSK" || config.type === "PMS") && (
          <StartingPrice
            theme={theme}
            productType={currentProduct.type}
            price={currentProduct.displayedStartingPrice}
            locale={locale}
            currency={currency}
          />
        )}
        {(!config || config.type !== "KIOSK") &&
          (!config || config.type !== "PMS") &&
          currentProduct &&
          currentProduct._embedded.unit &&
          (currentProduct._embedded.unit.type === "MAIN" ||
            currentProduct._embedded.unit.type === "FORFAIT") && (
            <FooterButton theme={theme} config={config}>
              <BackButton handleClick={goToQuantities}>
                <ArrowLeftIcon
                  color={
                    this.context.themeConfig && this.context.themeConfig.color1
                      ? this.context.themeConfig.color1
                      : styles.green
                  }
                />
                <Text id="footer.button.previous">Previous</Text>
              </BackButton>
            </FooterButton>
          )}
      </Container>
    );
  }
}

function getWeekDays(locale) {
  let weekdaysShortFr = ["Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"];
  let weekdaysShortCa = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  let weekdaysShortDe = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  let weekdaysShortEn = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  let weekdaysShortEs = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  let weekdaysShortIt = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  let weekdaysShortNl = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

  switch (locale) {
    case "ca":
      return weekdaysShortCa;
    case "de":
      return weekdaysShortDe;
    case "en":
      return weekdaysShortEn;
    case "es":
      return weekdaysShortEs;
    case "fr":
      return weekdaysShortFr;
    case "It":
      return weekdaysShortIt;
    case "Nl":
      return weekdaysShortNl;
  }
}

function getWeekNumber(firstDayOfMonth) {
  let incrementalDate = new Date(firstDayOfMonth);
  // incrementalDate.setUTCHours(0, 0, 0, 0);
  if (incrementalDate.getDay() !== 1) {
    if (incrementalDate.getDay() === 0) {
      incrementalDate.setDate(incrementalDate.getDate() - 6);
    } else {
      incrementalDate.setDate(
        incrementalDate.getDate() - (incrementalDate.getDay() - 1)
      );
    }
  }
  let daysWeeksArray = [];
  while (
    (incrementalDate.getMonth() <= firstDayOfMonth.getMonth() &&
      incrementalDate.getFullYear() === firstDayOfMonth.getFullYear()) ||
    incrementalDate.getFullYear() + 1 === firstDayOfMonth.getFullYear()
  ) {
    let weekArray = [];
    for (let i = 0; i < 7; i++) {
      weekArray.push(new Date(incrementalDate));
      incrementalDate.setDate(incrementalDate.getDate() + 1);
    }
    daysWeeksArray.push(weekArray);
  }
  return daysWeeksArray;
}

function apiToLocalDay(dtApi) {
  return new Date(
    moment(
      moment(dtApi)
        .tz("Europe/Paris")
        .format("YYYY-MM-DD")
    )
  );
}

function todayToLocalDay(merchantTZ) {
  return new Date(
    moment(
      moment()
        .tz(merchantTZ || "Europe/Paris")
        .format("YYYY-MM-DD")
    )
  );
}

function localToApiDay(dtLocal) {
  return new Date(moment(dtLocal).tz("Europe/Paris", true));
}
