// @flow
// @jsx h
import { h, Component } from 'preact';

import ActionAventureIcon from './actionaventure';
import AnimationIcon from './animation';
import AteliersArtistIcon from './ateliersartist';
import AutourDeLaNatureIcon from './autourdelanature';
import BeauteIcon from './beaute';
import CityToursIcon from './citytours';
// import EventsMusicaux from './eventsmusicaux';
// import EventsSportifs from './eventssportifs';
import GastronomieIcon from './gastronomie';
import MusiqueIcon from './musique';
import SoireeIcon from './soiree';
import SportsAeriensIcon from './sportsaeriens';
import SportsCoIcon from './sportsco';
import SportsHiverIcon from './sportshiver';
import SportsMotorisesIcon from './sportsmotorises';
import SportsNautiquesIcon from './sportsnautiques';
import TransportIcon from './transport';

export default class CategoryIcon extends Component {
  getCategoryIcon(categoryId: number, color: string) {
    switch (categoryId) {
      case 1:
        return <ActionAventureIcon color={color ? color : '#fff'} />;
      case 2:
        return <SportsNautiquesIcon color={color ? color : '#fff'} />;
      case 3:
        return <SportsMotorisesIcon color={color ? color : '#fff'} />;
      case 4:
        return <SportsCoIcon color={color ? color : '#fff'} />;
      case 5:
        return <SportsAeriensIcon color={color ? color : '#fff'} />;
      case 6:
        return <CityToursIcon color={color ? color : '#fff'} />;
      case 7:
        return <GastronomieIcon color={color ? color : '#fff'} />;
      case 8:
        return <AteliersArtistIcon color={color ? color : '#fff'} />;
      case 9:
        return <MusiqueIcon color={color ? color : '#fff'} />;
      case 10:
        return <BeauteIcon color={color ? color : '#fff'} />;
      case 11:
        return <AutourDeLaNatureIcon color={color ? color : '#fff'} />;
      case 12:
        return <SoireeIcon color={color ? color : '#fff'} />;
      case 13:
        return <AnimationIcon color={color ? color : '#fff'} />;
      case 14:
        return <TransportIcon color={color ? color : '#fff'} />;
      case 15:
        return <SportsHiverIcon color={color ? color : '#fff'} />;
    }
  }
  render({ categoryId, color }: { categoryId: number, color: string }) {
    return this.getCategoryIcon(categoryId, color);
  }
}
