// @flow
// @jsx h

// External librairies
import { h, Component } from "preact";
// import roundTo from 'round-to';

// Style
import styles from "./style.scss";

type UnitQuantityProps = {
  currency: Currency,
  unitQuantity: UnitQuantity,
  sessionIndex: number
};

/**
 * Unit quantity Component
 * displaying the unit quantity of a booking time range
 */
export default class UnitQuantityRow extends Component<UnitQuantityProps> {
  /**
   * render
   * @param {UnitQuantityProps} the UnitQuantity component props
   * @return {ReactElement} markup
   */
  render({ unitQuantity, sessionIndex }: UnitQuantityProps) {
    return (
      <div class={styles.unitQuantity}>
        <div>
          {unitQuantity.sessionQuantity
            ? unitQuantity.sessionQuantity
            : unitQuantity.quantity}
        </div>
        {unitQuantity._embedded &&
          unitQuantity._embedded.unitSaved.type === "SESSION" && (
            <div>x {sessionIndex}</div>
          )}
        <div>
          {unitQuantity._embedded && unitQuantity._embedded.unitSaved
            ? unitQuantity._embedded.unitSaved.name
            : unitQuantity.publicMetadata.franceBillet.customerClassLabel}
        </div>
      </div>
    );
  }
}
