// @flow
// @jsx h

// External librairies
import { h } from "preact";

// Style
import styles from "./style.scss";
import { withStyles } from "elc-theme";

/**
 * Footer button container component
 * used to display the footer buttons in a common styled container
 */
const FooterButton = ({ getClassName, children }) => (
  <div class={getClassName("footerButtonContainer")}>{children}</div>
);

export default withStyles(FooterButton, styles);
