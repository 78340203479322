// @flow
// @jsx h
import { h, Component } from "preact";
import { Text } from "preact-i18n";

import styles from "./FormInput.scss";
import { withStyles } from "elc-theme";
import { GlassIcon } from "elc-icons";
import SwitchInput from "./SwitchInput";
import InputError from "./InputError";
import { withScriptjs } from "react-google-maps";
// import scriptLoader from 'react-async-script-loader';

class AutocompleteAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.value
        ? props.value
        : {
            street_number: "",
            route: "",
            locality: "",
            // administrative_area_level_1: '',
            country: "",
            postal_code: ""
          },
      visible:
        props.clientFormField.mandatory || props.isClientInformationMandatory
          ? true
          : false
    };
  }

  onChange = ({ target }) => {
    this.setState(prevState => {
      prevState.address[target.id] = target.value;
      this.props.onAddressChange(prevState.address);
      return { address: prevState.address };
    });
  };

  isMandatory = () =>
    this.props.clientFormField.mandatory ||
    this.props.isClientInformationMandatory
      ? true
      : false;

  componentDidMount() {
    let franceBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(41.41747, -5.1157),
      new google.maps.LatLng(51.4604, 11.23195)
    );

    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      {
        types: ["geocode"],
        bounds: franceBounds
      }
    );
    this.setState({ autocomplete });
    autocomplete.addListener("place_changed", this.fillInAddress);
  }

  fillInAddress = () => {
    // Get the place details from the autocomplete object.
    let place = this.state.autocomplete.getPlace();
    let componentForm = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      // administrative_area_level_1: 'short_name',
      country: "long_name",
      postal_code: "short_name"
    };
    let address = {};
    // Get each component of the address from the place details
    // and fill the corresponding field on the form.
    for (let i = 0; i < place.address_components.length; i++) {
      let addressType = place.address_components[i].types[0];
      if (componentForm[addressType]) {
        address[addressType] =
          place.address_components[i][componentForm[addressType]];
      }
    }
    this.props.onAddressChange(address);
    this.setState({ address });
  };

  onToggleAddressVisibility = () => {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  };
  /* eslint-disable */
  render(
    { clientFormField, getClassName, error },
    { address, visible },
    context
  ) {
    return (
      <div>
        <div
          key={clientFormField.id}
          class={getClassName("inputField", "inputFieldCheckbox")}
        >
          <div class={getClassName("switchInput")}>
            {!this.isMandatory() && (
              <SwitchInput
                name={clientFormField._embedded.clientFormInputType.label}
                id={clientFormField.id}
                value={visible ? "1" : "0"}
                onChange={this.onToggleAddressVisibility}
              />
            )}
            <label class={getClassName("label")} htmlFor={clientFormField.id}>
              {clientFormField._embedded.clientFormInputType.label}
              {this.isMandatory() && (
                <span class={getClassName("requiredSign")}>*</span>
              )}{" "}
            </label>
          </div>
        </div>
        <div
          class={getClassName("autoCompleteAddressFields", {
            invisible: !visible
          })}
        >
          <div key={clientFormField.id} class={getClassName("inputField")}>
            <label
              htmlFor={clientFormField.id}
              class={
                context.theme && context.theme.label
                  ? context.theme.label
                  : styles.label
              }
            >
              <Text id="address.search">Search</Text>
            </label>
            <div class={styles.row}>
              <input
                id="autocomplete"
                placeholder="Search your address"
                type="search"
                class={styles.input}
              />
              <GlassIcon
                color={
                  context.themeConfig && context.themeConfig.color1
                    ? context.themeConfig.color1
                    : styles.color
                }
              />
            </div>
          </div>
          <div>
            <div class={getClassName("inputField", "column")}>
              <div
                class={
                  context.theme && context.theme.label
                    ? context.theme.label
                    : styles.label
                }
              >
                <Text id="address.streetAddress">Street address</Text>
                {this.isMandatory() && (
                  <span class={getClassName("requiredSign")}>*</span>
                )}
              </div>
              <div class={styles.row}>
                <div class={styles.streetNumber}>
                  <input
                    class={styles.input}
                    id="street_number"
                    value={address.street_number}
                    placeholder="N°"
                    onChange={this.onChange}
                  />
                </div>
                <div>
                  <input
                    class={styles.input}
                    id="route"
                    value={address.route}
                    placeholder="rue, lieu-dit"
                    autoComplete="street-address"
                    onChange={this.onChange}
                  />
                </div>
              </div>
              {error &&
                error.fields.includes("route") && (
                  <div class={styles.row}>
                    <InputError error={error} />
                  </div>
                )}
            </div>
            <div class={styles.row}>
              <div class={getClassName("inputField", "column", "zipCode")}>
                <div
                  class={
                    context.theme && context.theme.label
                      ? context.theme.label
                      : styles.label
                  }
                >
                  <Text id="address.zipCode">Zip code</Text>
                  {this.isMandatory() && (
                    <span class={getClassName("requiredSign")}>*</span>
                  )}
                </div>
                <div>
                  <input
                    class={styles.input}
                    id="postal_code"
                    value={address.postal_code}
                    autoComplete="postal-code"
                    onChange={this.onChange}
                  />
                  {error &&
                    error.fields.includes("postal_code") && (
                      <InputError error={error} />
                    )}
                </div>
              </div>
              <div class={getClassName("inputField", "column", "city")}>
                <div
                  class={
                    context.theme && context.theme.label
                      ? context.theme.label
                      : styles.label
                  }
                >
                  <Text id="address.city">City</Text>
                  {this.isMandatory() && (
                    <span class={getClassName("requiredSign")}>*</span>
                  )}
                </div>
                <div>
                  <input
                    class={styles.input}
                    id="locality"
                    value={address.locality}
                    onChange={this.onChange}
                  />
                  {error &&
                    error.fields.includes("locality") && (
                      <InputError error={error} />
                    )}
                </div>
              </div>
            </div>
            {/* <div class={styles.row}>
              <div
                class={
                  context.theme && context.theme.label
                    ? context.theme.label
                    : styles.label
                }
              >
                State
              </div>
              <div>
                <input
                  class={styles.input}
                  id="administrative_area_level_1"
                  value={address.administrative_area_level_1}
                />
              </div>
            </div> */}
            <div class={styles.column}>
              <div
                class={
                  context.theme && context.theme.label
                    ? context.theme.label
                    : styles.label
                }
              >
                <Text id="address.country">Country</Text>
                {this.isMandatory() && (
                  <span class={getClassName("requiredSign")}>*</span>
                )}
              </div>
              <div>
                <input
                  class={styles.input}
                  id="country"
                  value={address.country}
                  autoComplete="country"
                  onChange={this.onChange}
                />
                {error &&
                  error.fields.includes("country") && (
                    <InputError error={error} />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/* eslint-enable */
export default withScriptjs(withStyles(AutocompleteAddress, styles));
