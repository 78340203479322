// @flow
// @ jsx h

import { h, Component } from "preact";
import { Text } from "preact-i18n";

import Container from "../container";
import BasketContainer from "../basketcontainer";

import styles from "./OrderExpired.scss";


export default class OrderExpired extends Component {

  render({getClassName}:{getClassName: Function}) {
    return (
      <Container>
        <div class={styles.expiredContainer}>
          <h2 class={styles.title}>
            <Text id="basket.booking.expired.title">
              Le délai pour terminer votre commande est expiré
            </Text>
          </h2>
          <p>
            <Text id="basket.booking.expired.description">
              Malheureusement, vous n&apos;avez pas été assez rapide pour
              terminer votre commande et elle doit donc être considérée comme
              abandonnée pour des raisons de sécurité. Nous vous invitons à
              réaliser une nouvelle commande
            </Text>
          </p>
          </div>
      </Container>
    );
  }
}
