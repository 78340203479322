// @flow
// @jsx h

// External librairies
import { h, Component } from "preact";

// Translations
import { IntlProvider } from "preact-i18n";
import ca from "../translations/ca.json";
import de from "../translations/de.json";
import en from "../translations/en.json";
import es from "../translations/es.json";
import fr from "../translations/fr.json";
import it from "../translations/it.json";
import nl from "../translations/nl.json";

// Components
import Basket from "./basket";
import Header from "./header/HeaderTitle";
import Confirmation from "./confirmation";
import ConfirmationPayOnArrival from "./confirmation-payonarrival";
import OrderExpired from "./order-expired/OrderExpired";
// import ConfirmationCash from "./confirmation-cash";

// Style and theming
import styles from "./style.scss";
import themeKiosk from "./theme-kiosk.scss";
import themeMarketplace from "./theme-marketplace.scss";
import { ThemeProvider, WidgetContextProvider } from "elc-theme";

import { sendCheckoutEventOrderGTM, sendRemoveFromCartGTM } from "elc-commons";
// grrrrrr
// API
import {
  setToken,
  getOrderById,
  deleteBooking,
  addVoucherCode,
  decrementeBookingVoucherUsed
} from "elc-open-api";

type BasketComponentProps = {
  // the eventual Widget/MyPage config
  config?: WidgetConfig,
  // the Open API token
  token: string,
  locale?: string,
  // the payment context
  //in the case of kiosk and marketplace it will be mobile
  // so we will send a confirmation email/sms to the client
  // with a link to online payment
  paymentContext?: string,
  order?: Order,
  currency: Currency,
  setOrder: Function,
  goToClientForm: Function,
  goToPayment?: Function,
  goToCatalog?: Function,
  noPayment: boolean,
  paymentMethod:PaymentMethod,
  checkSession: Function
};

type BasketComponentState = {
  locale: Object,
  route: string,
  currentCompany: {
    id: number,
    type: string
  },
  currentOrder?: Order,
  currentBeneficiary?: Beneficiary,
  currency: Currency,
  sessionValid : boolean
};

/**
 * Basket Component
 * that display
 *  - the order
 *  - his currents bookings
 *  - a promo code (voucher) input
 *  - a customer/client input form
 */
/* FIXME when Preact implement getDerivedStateFromProps */
/* eslint-disable-next-line react/no-deprecated */
export default class App extends Component<
  BasketComponentProps,
  BasketComponentState
> {
  state = {
    locale: "fr",
    localeDefinition: fr,
    route: "basket",
    currentCompany: undefined,
    currentOrder: undefined,
    currentBeneficiary: undefined,
    currency: undefined,
    finalizeOrderError: undefined,
    sessionValid:true
  };

  constructor(props) {
    super(props);
    // set OpenAPI endpoint with props
    if (props.apiEndpoint) {
      window.API_ENDPOINT = props.apiEndpoint;
    }
    if (props.phpApiEndpoint) {
      window.PHP_API_ENDPOINT = props.phpApiEndpoint;
    }
    if (props.mediasEndpoint) {
      window.MEDIAS_ENDPOINT = props.mediasEndpoint;
    }
    // set OpenAPI token
    setToken(props.token);
    // set current locale from props
    // switch (props.locale) {
    //   case "ca":
    //     this.changeLocale(ca);
    //     break;

    //   case "de":
    //     this.changeLocale(de);
    //     break;

    //   case "en":
    //     this.changeLocale(en);
    //     break;

    //   case "es":
    //     this.changeLocale(es);
    //     break;

    //   case "it":
    //     this.changeLocale(it);
    //     break;

    //   case "nl":
    //     this.changeLocale(nl);
    //     break;
    // }
    if (!props.currency || !props.currency.isoCode) {
      throw new Error("The currency property is mandatory.");
    }
    this.setState({ currency: props.currency });
    // set current order with prop if present
    if (props.order) {
      let state = { currentOrder: props.order };
      if (props.order.company) state.company = props.order._embedded.company;
      this.setState(state);
    }

    if (props.locale) {
      switch (props.locale) {
        case "ca":
          this.setState({ locale: "ca", localeDefinition: ca });
          break;

        case "de":
          this.setState({ locale: "de", localeDefinition: de });
          break;

        case "en":
          this.setState({ locale: "en", localeDefinition: en });
          break;

        case "es":
          this.setState({ locale: "es", localeDefinition: es });
          break;

        case "it":
          this.setState({ locale: "it", localeDefinition: it });
          break;

        case "nl":
          this.setState({ locale: "nl", localeDefinition: nl });
          break;
      }
    }
  }

  changeLocale = (locale: Object) => {
    this.setState({ locale });
  };

  goToClientForm = () => {
    const { goToClientForm } = this.props;
    const { currentOrder } = this.state;
    this.checkSession(currentOrder);
    if(this.state.sessionValid){
      //Step 5 GTM : Finaliser ma commande sur le panier, avant le formulaire client
      sendCheckoutEventOrderGTM(this.props.order, 5, false);
      goToClientForm(currentOrder);
    }

  };

  goToPayOnArrival = () => {
    const { currentOrder } = this.state;
      this.props.goToPayment(currentOrder);
  };

  goToPayment = () => {
    const { currentOrder } = this.state;
    this.props.goToPayment(currentOrder);
  };

  goToConfirmation = () => {
      this.props.setOrder(undefined);
      this.setState({ route: "confirmation" });
  };
  goToConfirmationPayOnArrival = () => {
        console.log('this.state.sessionValid', this.state.sessionValid);
        let currentOrderId = this.state.currentOrder.id;
        this.urlToRecap =
          this.props.phpApiEndpoint + "/pdf/order_recap/" + currentOrderId;
        this.urlToTicket =
          this.props.phpApiEndpoint + "/pdf/order_ticket/" + currentOrderId;

        let bookings = this.state.currentOrder._embedded.bookings;
        this.totalBookings = bookings.length;
        this.totalBookingsBooked = 0;
        this.listBookingsNotConfirmed = [];
        this.listBookingsConfirmed = [];
        this.listBookingsMerchants = [];
        this.cash = false;
        this.cashAmount = null;
        bookings.forEach(item => {
          // this.listBookingsMerchants.push(item._embedded.merchant.id);
          if (
            item._embedded.product.bookingManagement === "AUTOMATIC" &&
            item._embedded.product.minBookings === 0
          ) {
            // petite feinte pour contourner le fait que le booking nest pas encore passé en booked + pas de critere de threshold not met
            this.totalBookingsBooked = this.totalBookingsBooked + 1;
            this.listBookingsConfirmed.push(item);
          } else {
            this.listBookingsNotConfirmed.push(item);
          }
        });
        // const uniqueMerchants = Array.from(new Set(this.listBookingsMerchants));
        // console.log(this.listBookingsConfirmed, this.listBookingsNotConfirmed);
        this.props.setOrder(undefined);
        this.setState({ route: "confirmation-payonarrival" });

  };

  goToConfirmationCash = () => {
      let currentOrderId = this.state.currentOrder.id;
      // console.log(this.state.currentOrder);
      // console.log("coucou goToConfirmationCash"); // force prod
      this.urlToRecap =
        this.props.phpApiEndpoint + "/pdf/order_recap/" + currentOrderId;
      this.urlToTicket =
        this.props.phpApiEndpoint + "/pdf/order_ticket/" + currentOrderId;

      let bookings = this.state.currentOrder._embedded.bookings;
      this.totalBookings = bookings.length;
      this.totalBookingsBooked = 0;
      this.listBookingsNotConfirmed = [];
      this.listBookingsConfirmed = [];
      this.listBookingsMerchants = [];
      this.cash = true;
      this.cashAmount = this.state.currentOrder.balance;
      // console.log(this.state.currentOrder.balance);
      bookings.forEach(item => {
        // this.listBookingsMerchants.push(item._embedded.merchant.id);
        if (
          item._embedded.product.bookingManagement === "AUTOMATIC" &&
          item._embedded.product.minBookings === 0
        ) {
          // petite feinte pour contourner le fait que le booking nest pas encore passé en booked + pas de critere de threshold not met
          this.totalBookingsBooked = this.totalBookingsBooked + 1;
          this.listBookingsConfirmed.push(item);
        } else {
          this.listBookingsNotConfirmed.push(item);
        }
        // console.log(this.cashAmount);
      });
      // const uniqueMerchants = Array.from(new Set(this.listBookingsMerchants));
      // console.log(this.listBookingsConfirmed, this.listBookingsNotConfirmed);
      this.props.setOrder(undefined);
      this.setState({ route: "confirmation-payonarrival" });
  };

  onAddPromoCode = (orderId, promoCode) => {
    addVoucherCode(orderId, promoCode)
      .then(order => this.props.setOrder(order))
      .catch(addVoucherError => {
        this.setState({ addVoucherError });
      });
  };

  setOrder = order => {
     console.log("order", order);
    this.setState({ currentOrder: order }, () =>
      this.props.setOrder(this.state.currentOrder)
    );
  };

  onDeleteBooking = (booking: Booking) => {
    decrementeBookingVoucherUsed(booking)
      .then(() => {
        deleteBooking(booking)
          .then(() => {
            sendRemoveFromCartGTM(booking, this.state.currencyCode);
            if (
              booking._embedded.product.type !== "EXTRA" &&
              this.state.currentOrder._embedded.bookings.length === 1
            ) {
              return undefined;
            } else {
              return getOrderById(this.state.currentOrder.id);
            }
          })
          .then(order => {
            // console.log(order);
            if (!order || order._embedded.bookings.length === 0) {
              this.setState({ currentOrder: undefined });
              this.props.setOrder(undefined);
            } else {
              this.setState({ currentOrder: order });
              this.props.setOrder(order);
            }
            return order;
          })
          .catch(error => console.error(error));
        return undefined;
      })
      .catch(error => console.error(error));
  };

  getTheme = (configType: string, orderOrigin: string) => {
    return configType === "KIOSK"
      ? themeKiosk
      : configType === "SHOP" ||
        orderOrigin === "SHOP" ||
        configType === "PMS" ||
        orderOrigin === "PMS"
      ? themeMarketplace
      : undefined;
  };

  getWidgetContext = (configType: string, orderOrigin: string) => {
    return configType
      ? configType
      : orderOrigin === "KIOSK"
      ? "WIDGET"
      : orderOrigin;
  };

  getColorConfig = (conf: Object) =>
    conf && conf.color1
      ? {
          color1: conf.color1,
          color2: conf.color2,
          color3: conf.color3,
          color4: conf.color4,
          color5: conf.color5
        }
      : undefined;


  checkSession =  (order, callback) => {
    // console.log("this", this.state.currentOrder);

      // Utilisation de reduce pour trouver l'objet avec la plus petite creationDate
      var smallestBookingCreationDate = order._embedded.bookings.reduce((minBooking, currentBooking) => {
        var currentBookingCreationDate = new Date(currentBooking.creationDate);
        var minBookingCreationDate = new Date(minBooking.creationDate);
          // Comparaison basée sur creationDate
          return currentBookingCreationDate.getTime() < minBookingCreationDate.getTime() ? currentBooking : minBooking;
        }, order._embedded.bookings[0]); // Utilisation de bookings[0] comme valeur initiale

        let creationDate = new Date(smallestBookingCreationDate.creationDate);

        let now = new Date();
        let timeDiff = Math.abs(
          creationDate.getTime() - now.getTime()
        );
        //console.log('timeDiff', timeDiff )
        // console.log("smallestBookingCreationDate", smallestBookingCreationDate ); // Affiche 1

     if (timeDiff > 900000) {
      this.setState({ sessionValid: false, route: "expired" }, () => {
        this.props.setOrder(undefined);
        // Appeler le callback après avoir terminé le traitement principal
        if (callback && typeof callback === 'function') {
          callback(false);
        }
      });
     } else
     {
            // Appeler le callback après avoir terminé le traitement principal
      if (callback && typeof callback === 'function') {
        callback(true);
      }
     }

    }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    if (nextProps.order !== this.state.currentOrder) {
      let state = { currentOrder: nextProps.order };
      // if (nextProps.order._embedded.userClientData) {
      //   state.route = 'basket';
      // }
      this.setState(state);
    }


    if (nextProps.currency !== this.state.currency) {
      this.setState({ currency: nextProps.currency });
    }
    if (nextProps.locale !== this.props.locale) {
      switch (nextProps.locale) {
        case "ca":
          this.setState({ locale: "ca", localeDefinition: ca });
          break;

        case "de":
          this.setState({ locale: "de", localeDefinition: de });
          break;

        case "en":
          this.setState({ locale: "en", localeDefinition: en });
          break;

        case "es":
          this.setState({ locale: "es", localeDefinition: es });
          break;

        case "fr":
          this.setState({ locale: "fr", localeDefinition: fr });
          break;

        case "it":
          this.setState({ locale: "it", localeDefinition: it });
          break;

        case "nl":
          this.setState({ locale: "nl", localeDefinition: nl });
          break;
      }
    }
  }

  componentDidMount() {
    const { order } = this.props;
    if (order) {
      let state = { currentOrder: order };
      if (order.company) state.company = order._embedded.company;
      this.setState(state);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageDidMountOrUpdate) {
      this.props.pageDidMountOrUpdate();
    }

    if (prevProps.locale !== this.props.locale) {
      getOrderById(this.props.order.id, this.props.locale).then(order => {
        // console.log("order state XXXXXXX", order);
        this.setState({ currentOrder: order }, () =>
          this.props.setOrder(this.state.currentOrder)
        );
      });
    }
  }

  render(
    {
      config,
      locale,
      paymentContext,
      goToPayment,
      goToCatalog,
      noPayment,
      paymentMethod
    }: BasketComponentProps,
    {
      localeDefinition,
      route,
      currentOrder,
      currency,
      // finalizeOrderError,
      addVoucherError
    }: BasketComponentState
  ) {
    const currentTheme = this.getTheme(
      config.type,
      currentOrder && currentOrder.origin
    );
    //console.log(route);
    //console.trace();
    return (
      <ThemeProvider theme={currentTheme}>
        <IntlProvider definition={localeDefinition}>
          <WidgetContextProvider
            widgetContext={this.getWidgetContext(
              config.type,
              currentOrder && currentOrder.origin
            )}
          >
            <div
              class={
                currentTheme && currentTheme.basketCard
                  ? currentTheme.basketCard
                  : styles.basketCard
              }
            >


              {(route === "basket" || route === "expired") && config.type !== "SHOP" && <Header />}
              {route === "expired"  && (
               <OrderExpired/>
              )}
              {route === "basket" && (
                <Basket
                  config={config}
                  paymentContext={paymentContext}
                  locale={locale}
                  currency={currency}
                  currentOrder={currentOrder}
                  goToClientForm={this.goToClientForm}
                  onDeleteBooking={this.onDeleteBooking}
                  onAddPromoCode={this.onAddPromoCode}
                  addVoucherError={addVoucherError}
                  goToConfirmation={this.goToConfirmation}
                  goToConfirmationPayOnArrival={
                    this.goToConfirmationPayOnArrival
                  }
                  goToConfirmationCash={this.goToConfirmationCash}
                  goToPayment={goToPayment}
                  goToCatalog={goToCatalog}
                  noPayment={noPayment}
                  paymentMethod={paymentMethod}
                  checkSession={this.checkSession}
                />
              )}
              {route === "confirmation" && <Confirmation />}
              {route === "confirmation-payonarrival" && (
                <ConfirmationPayOnArrival
                  urlToRecap={this.urlToRecap}
                  urlToTicket={this.urlToTicket}
                  totalBookings={this.totalBookings}
                  totalBookingsBooked={this.totalBookingsBooked}
                  listBookingsConfirmed={this.listBookingsConfirmed}
                  listBookingsNotConfirmed={this.listBookingsNotConfirmed}
                  cash={this.cash}
                  cashAmount={this.cashAmount}
                />
              )}
            </div>
          </WidgetContextProvider>
        </IntlProvider>
      </ThemeProvider>
    );
  }
}
