// @flow
// @jsx h

import { h } from "preact";

import { withText } from "preact-i18n";

// Tab component
import Tab from "./Tab";
import TabIcon from "./TabIcon";
import TabLabel from "./TabLabel";

// Icon
import { BeneficiaryIcon } from "elc-icons";

/**
 * Beneficiary tab component
 */
const BeneficiaryTab = withText({ label: "header.giftBeneficiary" })(
  ({
    theme,
    active,
    label
  }: {
    theme?: Object,
    active: boolean,
    label: string
  }) => (
    <Tab>
      <TabIcon theme={theme} active={active}>
        <BeneficiaryIcon />
      </TabIcon>
      {label && <TabLabel theme={theme} active={active} label={label} />}
    </Tab>
  )
);

export default BeneficiaryTab;
