// @flow
// @jsx h
import { h, Component } from "preact";
import styles from "./style.scss";
import { toLocalePrice } from "elc-commons";

type PromoCodeProps = {
  promoCode: PromoCode,
  locale: string,
  currency: Currency
};

export default class PromoCode extends Component<PromoCodeProps> {
  render({ promoCode, locale, currency }: PromoCodeProps) {
    return (
      <div class={styles.row}>
        <div class={styles.chip}>
          -{promoCode.reductionType === 1
            ? toLocalePrice(promoCode.reductionAmount, locale, currency.isoCode)
            : `${promoCode.reductionAmount} ${
                promoCode.reductionType === "PERCENTAGE" ? "%" : "€"
              }`}
        </div>
        <div class={styles.promoCodeLabel}>{promoCode.name}</div>
      </div>
    );
  }
}
