// @flow
// @jsx h
import { h, Component } from "preact";
import { Text } from "preact-i18n";

import Downshift from "downshift/preact";
import { ArrowRightIcon } from "elc-icons";

import styles from "./style.scss";

type TagFilterProps = {
  categories: Array<Object>,
  selectedCategories: Array<Number>,
  addCategory: Function,
  removeCategory: Function
};

export default class CategoryFilter extends Component<TagFilterProps> {
  constructor(props: TagFilterProps) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleChange = item => {
    if (this.props.selectedCategories.indexOf(item.id) !== -1) {
      this.props.removeCategory(item.id, item.code);
    } else {
      this.props.addCategory(item.id, item.code);
    }
  };

  handleToggleMenu = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  itemToString(item) {
    return item ? item.name : "";
  }

  render() {
    return (
      <div class={styles.container}>
        <label class={styles.filterLabel}>
          <Text id="products.filters.categories.label">Categories</Text>
        </label>
        <Downshift
          itemToString={this.itemToString}
          onSelect={this.handleChange}
          onToggleMenu={this.handleToggleMenu}
        >
          {({
            getRootProps,
            getButtonProps,
            getItemProps,
            isOpen,
            highlightedIndex
          }) => (
            <div class={styles.categoryFilterContainer} {...getRootProps()}>
              <div class={styles.categoryFilter}>
                <button
                  {...getButtonProps({ onClick: this.handleToggleMenu })}
                  class={`${styles.categoryFilterButton}`}
                >
                  <span
                    class={`${styles.categoryFilterButtonText} ${
                      this.props.selectedCategories.length > 0
                        ? styles.activeFilter
                        : ""
                    }`}
                    style={`background-color:${
                      this.props.selectedCategories.length > 0 &&
                      this.context.themeConfig &&
                      this.context.themeConfig.color1
                        ? this.context.themeConfig.color1
                        : ""
                    };`}
                  >
                    <Text id="products.filters.search.categories">
                      Search categories
                    </Text>
                  </span>
                  <ArrowRightIcon
                    color={
                      this.context.themeConfig &&
                      this.context.themeConfig.color1
                        ? this.context.themeConfig.color1
                        : styles.color
                    }
                  />
                </button>
              </div>
              {!isOpen ? null : (
                <div class={styles.dropdown}>
                  {this.props.categories.map((item, index) => (
                    <div
                      key={item.id}
                      {...getItemProps({
                        item,
                        index,
                        isActive: highlightedIndex === index,
                        isSelected:
                          this.props.selectedCategories.indexOf(item.id) !== -1
                      })}
                      class={`${styles.dropdownItem} ${
                        highlightedIndex === index ? styles.isActive : ""
                      }  ${
                        this.props.selectedCategories.indexOf(item.id) !== -1
                          ? styles.isSelected
                          : ""
                      }`}
                      style={`color:${
                        this.props.selectedCategories.indexOf(item.id) !== -1 &&
                        this.context.themeConfig &&
                        this.context.themeConfig.color1
                          ? this.context.themeConfig.color1
                          : ""
                      };`}
                    >
                      {this.itemToString(item)}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}
