// @flow
// @jsx h
import { h, Component } from "preact";
import { Text } from "preact-i18n";
import styles from "./style.scss";
// import { Text } from 'preact-i18n';
import WhenFilter from "./whenfilter";
import QuantityFilter from "./quantityfilter";
import CategoryFilter from "./categoryfilter";
import SelectedCategories from "./categoryfilter/selectedCategories/SelectedCategories";
import SearchFilter from "./searchfilter";
import RadiusFilter from "./radiusfilter";
import throttle from "lodash.throttle";
import SidePanel from "./sidepanel";

import { Button } from "elc-button";
import { RemoveIcon, FilterIcon } from "elc-icons";
import { sendUXEventGTM } from "elc-commons";

import "react-datepicker/dist/react-datepicker.css";
import "rc-slider/assets/index.css";

export default class ProductsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilterDate: undefined,
      filtersPanelOpened: false
    };
  }

  handleStartDateChange = date => {
    this.setState({
      currentFilterDate: date
    });
    let filters = {
      ...this.props.filters,
      available_on_date: date ? date.format("YYYY-MM-DD") : date
    };
    this.filterProducts(filters);
  };

  filterProducts = throttle(this.props.filterProducts, 500, { trailing: true });

  setQuantity = quantity => {
    let filters = { ...this.props.filters, number_participants: quantity };
    this.filterProducts(filters);
  };

  addCategory = (categoryId: Number, categoryCode: Number) => {
    let categories = [...this.props.filters.categories];
    categories.push(categoryId);
    let filters = { ...this.props.filters, categories };
    this.filterProducts(filters);
    sendUXEventGTM({
      event: "categoryFilter",
      "filter-id-category": categoryId,
      "filter-code-category": categoryCode
    });
  };

  removeCategory = (categoryId: Number, categoryCode: Number) => {
    let categories = [...this.props.filters.categories];
    let categoryIndex = categories.indexOf(categoryId);
    if (categoryIndex > -1) {
      categories.splice(categoryIndex, 1);
    }
    let filters = { ...this.props.filters, categories };
    this.filterProducts(filters);
    sendUXEventGTM({
      event: "removeFilteredCategory",
      "filter-id-category": categoryId,
      "filter-code-category": categoryCode
    });
  };

  setCategory = categoryId => {
    if (categoryId) {
      this.addCategory(categoryId);
    } else {
      let categories = [];
      let filters = { ...this.props.filters, categories };
      this.filterProducts(filters);
    }
  };

  setSearch = geolocation => {
    if (geolocation) {
      let filters = { ...this.props.filters, ...geolocation };
      this.filterProducts(filters);
      return;
    }
    this.filterProducts();
  };

  onRadiusChange = radius => {
    let distance = {
      ...this.props.filters.distance,
      radius: parseInt(radius)
    };

    let filters = {
      ...this.props.filters,
      distance
    };
    this.filterProducts(filters);
  };

  closeFiltersPanel = () => {
    this.setState({ filtersPanelOpened: false });
  };

  openFiltersPanel = () => {
    this.setState({ filtersPanelOpened: true });
  };

  onSetSidebarOpen = open => {
    this.setState({ filtersPanelOpened: open });
  };

  render({ categories, filters, config }) {
    return (
      <div class={styles.filterContainer}>
        {filters && (
          <div class={styles.filters}>
            <SidePanel
              open={this.state.filtersPanelOpened}
              onSetOpen={this.onSetSidebarOpen}
              dragToggleDistance={40}
            >
              <div
                class={styles.filterPanelHeader}
                style={`background-color:${
                  this.context.themeConfig && this.context.themeConfig.color1
                    ? this.context.themeConfig.color1
                    : ""
                };`}
              >
                <span class={styles.filterPanelTitle}>
                  <Text id="products.filters.selectFilters">
                    Select your filters
                  </Text>
                </span>
                <Button
                  className={styles.closeFiltersButton}
                  handleClick={this.closeFiltersPanel}
                >
                  <RemoveIcon />
                </Button>
              </div>
              <div class={styles.filterPanelContent}>
                <SearchFilter
                  currentSearch={filters.name}
                  setSearch={this.setSearch}
                />
                <RadiusFilter config={config} setRadius={this.onRadiusChange} />
                {/* <WhenFilter
                  dateChange={this.handleStartDateChange}
                  currentFilterDate={this.state.currentFilterDate}
                  locale={this.props.locale}
                /> */}

                <QuantityFilter
                  quantity={filters.number_participants}
                  setQuantity={this.setQuantity}
                />
                <CategoryFilter
                  categories={categories}
                  selectedCategories={this.props.filters.categories}
                  addCategory={this.addCategory}
                  removeCategory={this.removeCategory}
                />
              </div>
              <div class={styles.filterPanelSelectedFilters}>
                <SelectedCategories
                  categories={categories}
                  selectedCategories={this.props.filters.categories}
                  removeCategory={this.removeCategory}
                />
              </div>
            </SidePanel>
            <Button
              className={styles.openFiltersButton}
              handleClick={this.openFiltersPanel}
            >
              <FilterIcon
                color={
                  this.context.themeConfig && this.context.themeConfig.color1
                    ? this.context.themeConfig.color1
                    : styles.filterColor
                }
              />
            </Button>
          </div>
        )}
      </div>
    );
  }
}
