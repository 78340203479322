// @flow
// @jsx h

import { h } from "preact";
import { Text } from "preact-i18n";

import styles from "./StripeError.scss";
import { CancelCircleIcon } from "elc-icons";

/**
 * Stripe charge error stateless functional Component
 */
const StripeError = ({
  code,
  message
}: {
  code?: string,
  message?: string
}) => {
  return (
    <div class={styles.stripeError}>
      <span>
        <CancelCircleIcon color="#f04d52" />
      </span>
      <h3>
        <Text id="stripe.charge.failed">Echec du paiement</Text>
      </h3>
      <p>
        {code ? (
          code === "incomplete_number" ? (
            <Text id="stripe.charge.error.incompleteNumber">{message}</Text>
          ) : code === "incomplete_expiry" ? (
            <Text id="stripe.charge.error.incompleteExpiry">{message}</Text>
          ) : code === "incomplete_cvc" ? (
            <Text id="stripe.charge.error.incompleteCVC">{message}</Text>
          ) : code === "invalid_expiry_year_past" ? (
            <Text id="stripe.charge.error.invalidExpiryYearPast">
              {message}
            </Text>
          ) : code === "card_declined" ? (
            <Text id="stripe.charge.error.cardDeclined">{message}</Text>
          ) : code === "incorrect_cvc" ? (
            <Text id="stripe.charge.error.incorrectCVC">{message}</Text>
          ) : code === "expired_card" ? (
            <Text id="stripe.charge.error.expiredCard">{message}</Text>
          ) : code === "processing_error" ? (
            <Text id="stripe.charge.error.processingError">{message}</Text>
          ) : code === "incorrect_number" ? (
            <Text id="stripe.charge.error.incorrectNumber">{message}</Text>
          ) : (
            <div>
              <Text id="stripe.charge.error.unexpected">
                Unexpected charge error.
              </Text>
            </div>
          )
        ) : (
          <Text id="stripe.charge.error.unexpected">
            Unexpected charge error.
          </Text>
        )}
      </p>
      <p>
        {code && (
          <Text id="stripe.charge.checkCard">
            Vérifiez vos coordonnées bancaires ou contactez votre banque.
          </Text>
        )}
      </p>
    </div>
  );
};
export default StripeError;
