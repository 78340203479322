// @flow
// @jsx h

declare var STRIPE_API_KEY: string;
declare var Stripe: Object;

// External librairies
import { h, Component } from "preact";

// Components
import OrderSummary from "../order-summary/OrderSummary";
import LoadStripeScript from "./LoadStripeScript";
import Stripe3DSecureConfirmation from "./Stripe3DSecureConfirmation";

// API methods
import { getOrderByReference } from "elc-internal-api";
import { setToken, getOrderById } from "elc-open-api";

type FetchOrderToConfirmProps = {
  orderReference: string,
  source: string,
  client_secret: string
};

type FetchOrderToConfirmState = {
  loading: boolean,
  error: string,
  order: Order,
  config: WidgetConfig
};

/**
 * Product Stateful Component
 * fetch a product by his id
 * and render the Product Component
 * displaying product details and booker
 */
class FetchOrderToConfirm extends Component<
  FetchOrderToConfirmProps,
  FetchOrderToConfirmState
> {
  state = {
    loading: true,
    error: undefined,
    order: undefined
  };

  componentDidMount() {
    getOrderByReference(this.props.orderReference)
      .then(orderInfos => {
        // set OpenAPI token in session storage
        if (orderInfos.accessToken) {
          setToken(orderInfos.accessToken);
        }
        // init config from order infos
        let config = {
          access_token: orderInfos.accessToken,
          company: {
            id: orderInfos.companyId
          },
          type: orderInfos.order.origin
        };
        return Promise.all([config, getOrderById(orderInfos.orderId)]);
      })
      .then(([config, order]) => {
        this.setState({ loading: false, order, config });
        return order;
      })
      .catch(error => {
        console.error(error);
        this.setState({ loading: false, error });
      });
  }

  render(
    {
      source,
      client_secret
    }: {
      source: string,
      client_secret: string
    },
    { loading, error, order, config }: FetchOrderToConfirmState
  ) {
    return (
      <div>
        {loading && <div>Retrieving order...</div>}
        {error && <div>Error: {error}</div>}
        {!loading &&
          !error && (
            <OrderSummary config={config} order={order}>
              <LoadStripeScript>
                <Stripe3DSecureConfirmation
                  source={source}
                  client_secret={client_secret}
                  config={config}
                  order={order}
                />
              </LoadStripeScript>
            </OrderSummary>
          )}
      </div>
    );
  }
}

export default FetchOrderToConfirm;
