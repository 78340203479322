// @flow

declare var API_ENDPOINT: string;
declare var PHP_API_ENDPOINT: string;

import { handleResponse } from "../errors";
import { withToken } from "../auth";
import { getBookingById } from "../bookings";

/**
 * Get order for a given order id
 * @param {number} orderId - the order ID
 * @return {Promise} the promise of order
 */
export const getOrderById = withToken(
  (accessToken: string, orderId: number, locale: ?string): Promise<Order> =>
    fetch(
      `${API_ENDPOINT}/orders/${orderId}?access_token=${accessToken}&embed=company,bookings,user`,
      {
        headers: {
          "Accept-Language": locale
        }
      }
    )
      .then(handleResponse)
      .then(order => {
        return Promise.all([
          order,
          ...order._embedded.bookings.map(booking =>
            getBookingById(booking.id, locale)
          )
        ]);
      })
      .then(([order, ...bookings]) => {
        order._embedded.bookings = bookings
          // map associated extras bookings
          .map(booking => {
            if (
              booking._embedded.associatedBookings &&
              booking._embedded.associatedBookings.length > 0
            ) {
              booking._embedded.associatedBookings = booking._embedded.associatedBookings.map(
                associatedBooking =>
                  bookings.find(booking => booking.id === associatedBooking.id)
              );
            }
            return booking;
          })
          // filter out extras
          .filter(booking => booking._embedded.product.type !== "EXTRA");
        return order;
      })
);
/**
 * [description]
 * @param  {[type]} voucherCode [description]
 * @param  {[type]} orderId     [description]
 * @return {[type]}             [description]
 */
export const addUsedGift = (voucherCode, orderId, isPromoCode) => {
  return fetch(`${PHP_API_ENDPOINT}/order/${orderId}/byPromocode`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "PATCH",
    body: JSON.stringify({
      voucherCode: voucherCode,
      isPromoCode: isPromoCode
    })
  })
    .then(handleResponse)
    .then(() => {
      return addVoucherCode(orderId, voucherCode);
    });
};

/**
 * Create an order
 * @param {number} companyId - the company ID
 * @param {string} companyType - the company type
 * @param {string} hash - the hash of the module (used to track what specific module is being used)
 * @param {string} [origin] - the order origin if none is provided it will be OpenAPI
 * @param {string} [locale] - the locale the order was booked with
 * @return {Promise} the promise of order
 */
export const createOrder = withToken(
  (
    accessToken: string,
    companyId: number,
    companyType: string,
    hash: string,
    origin: ?string,
    locale: ?string
  ): Promise<Order> =>
    fetch(`${API_ENDPOINT}/orders?access_token=${accessToken}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        company: {
          id: companyId,
          type: companyType
        },
        directSale: {
          hash: hash
        },
        origin,
        locale: locale
      })
    }).then(handleResponse)
);

/**
 * Associated a user to an order
 * @param {number} orderId - the order ID
 * @param {Object} user - the user containing email
 * @param {string} user.email - the user email
 * @param {string} [user.firstName] - the user first name
 * @param {string} [user.lastName] - the user last name
 * @return {Promise} the promise of the order
 */
export const patchOrderUser = withToken(
  (
    accessToken: string,
    orderId: number,
    user: {
      email: string,
      firstName?: string,
      lastName?: string
    },
    note?: string
  ): Promise<Order> =>
    fetch(`${API_ENDPOINT}/orders/${orderId}?access_token=${accessToken}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PATCH",
      body: JSON.stringify({
        user,
        note
      })
    }).then(handleResponse)
);

/**
 * Delete an order by ID
 * @param {number} orderId - the order ID
 * @return {Promise} the promise of deleted order
 */
export const deleteOrder = withToken(
  (accessToken: string, orderId: number): Promise<any> =>
    fetch(
      `${API_ENDPOINT}/marketPlace/order/${orderId}?access_token=${accessToken}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "DELETE"
      }
    ).then(handleResponse)
);

/**
 * Add voucher code to an order
 * @param {number} orderId - the order ID
 * @param {string} voucherCode - the voucher code
 * @return {Promise} the promise of the added voucher code
 */
export const addVoucherCode = withToken(
  (accessToken: string, orderId: number, voucherCode: string): Promise<Order> =>
    fetch(
      `${API_ENDPOINT}/orders/${orderId}/promo_codes?access_token=${accessToken}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify([
          {
            voucherCode
          }
        ])
      }
    )
      .then(handleResponse)
      .then(() => getOrderById(orderId))
      .then(res => {
        // console.log(res);
        return res;
      })
);
