// @flow
// @jsx h

import { h } from "preact";
import { Text } from "preact-i18n";
import { DiscountIcon } from "elc-icons";
import { toLocalePrice } from "elc-commons";

import styles from "./style.scss";
import { withStyles } from "elc-theme";

type UnitQuantityProps = {
  unit: Unit,
  color: string,
  tax: Tax,
  locale: string,
  currency: Currency,
  max?: number,
  quantity: number,
  maxReached: boolean,
  handleAddUnit: Function,
  handleSubUnit: Function
};

function FBQuantity({
  unit,
  color,
  locale,
  currency,
  max,
  quantity,
  maxReached,
  discount,
  handleAddUnit,
  handleSubUnit,
  getClassName
}: UnitQuantityProps) {
  const addUnit = () => {
    handleAddUnit(unit);
  };

  const subUnit = () => {
    handleSubUnit(unit);
  };

  const [
    ,
    title,
    priceType
  ] = unit.publicMetadata.franceBillet.customerClass.customerClassLabel.split(
    ","
  );

  const productPrice = (
    unit.publicMetadata.franceBillet.customerClass.price / 100
  ).toFixed(2);

  return (
    <div class={styles.quantityContainer}>
      <div class={styles.quantitySelector}>
        <div
          class={getClassName("captionFB")}
          style={`border-color: #${color}`}
        >
          <div>
            <div class={styles.title}>
              <span>{title}</span>
            </div>
            <div class={styles.subtext}>
              <Text id="quantities.priceType">Tarif :</Text>{" "}
              <span>{priceType}</span>
            </div>
          </div>
          <div class={getClassName("price")}>
            <div class={getClassName("priceCaptionFB")}>
              {toLocalePrice(productPrice, locale, currency.isoCode)}
            </div>
            {discount && (
              <div class={getClassName("discountIcon")}>
                <DiscountIcon />
              </div>
            )}
          </div>
        </div>
        <div class={getClassName("quantitiesFB")}>
          <div
            class={getClassName("quantity", {
              notZero: quantity > 0,
              quantityNotAllowed:
                discount &&
                Math.floor(
                  discount.secondaryCustomerClassTicketQuantity *
                    discount.currentPrimaryCustomerClassTicketQuantity /
                    discount.primaryCustomerClassTicketQuantity -
                    quantity
                ) < 0
            })}
            style={`${
              this.context.themeConfig &&
              this.context.themeConfig.color1 &&
              !(
                discount &&
                Math.floor(
                  discount.secondaryCustomerClassTicketQuantity *
                    discount.currentPrimaryCustomerClassTicketQuantity /
                    discount.primaryCustomerClassTicketQuantity -
                    quantity
                ) < 0
              )
                ? quantity > 0
                  ? "background-color:" + this.context.themeConfig.color1
                  : "color:" + this.context.themeConfig.color1
                : ""
            }`}
          >
            {quantity}
          </div>
          <div class={styles.quantityControls}>
            <button
              disabled={
                maxReached ||
                (max && quantity === max) ||
                (discount &&
                  Math.floor(
                    discount.secondaryCustomerClassTicketQuantity *
                      discount.currentPrimaryCustomerClassTicketQuantity /
                      discount.primaryCustomerClassTicketQuantity -
                      quantity
                  ) <= 0)
              }
              onClick={addUnit}
              class={`${getClassName("quantityButton")} ${getClassName(
                "plus"
              )}`}
            >
              <span
                style={`${
                  this.context.themeConfig && this.context.themeConfig.color1
                    ? maxReached ||
                      (max && quantity === max) ||
                      (discount &&
                        Math.floor(
                          discount.secondaryCustomerClassTicketQuantity *
                            discount.currentPrimaryCustomerClassTicketQuantity /
                            discount.primaryCustomerClassTicketQuantity -
                            quantity
                        ) <= 0)
                      ? ""
                      : "color:" + this.context.themeConfig.color1
                    : ""
                }`}
              >
                +
              </span>
            </button>
            <button
              disabled={quantity === 0}
              onClick={subUnit}
              class={`${getClassName("quantityButton")} ${getClassName(
                "minus"
              )}`}
            >
              <span
                style={`${
                  this.context.themeConfig && this.context.themeConfig.color1
                    ? quantity === 0
                      ? ""
                      : "color:" + this.context.themeConfig.color1
                    : ""
                }`}
              >
                -
              </span>
            </button>
          </div>
        </div>
      </div>
      {discount &&
        Math.floor(
          discount.secondaryCustomerClassTicketQuantity *
            discount.currentPrimaryCustomerClassTicketQuantity /
            discount.primaryCustomerClassTicketQuantity -
            quantity
        ) < 0 && (
          <div class={getClassName("quantityError")}>
            {discount.pairedCustomerClassId ? (
              <Text
                id="quantities.discount.errorPaired"
                fields={{
                  pairedCustomerClassLabel: discount.pairedCustomerClassLabel.split(
                    ","
                  )[2]
                }}
              >
                The quantity selected does not respect the promotion. You can
                choose {discount.pairedCustomerClassLabel.split(",")[2]}{" "}
                instead.
              </Text>
            ) : (
              <Text id="quantities.discount.error">
                The quantity selected does not respect the promotion.
              </Text>
            )}
          </div>
        )}
    </div>
  );
}

export default withStyles(FBQuantity, styles);
