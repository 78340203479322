// @flow

declare var API_ENDPOINT: string;

import { handleResponse } from "../errors";
import { withToken } from "../auth";

/**
 * Return the default client form for an order
 * @return {Promise} the promise of the default client form
 */
export const getDefaultClientForm = withToken(
  (accessToken: string, locale): Promise =>
    fetch(
      `${API_ENDPOINT}/client_forms/1?access_token=${accessToken}&embed=client_form_fields`,
      {
        headers: {
          "Accept-language": locale
        }
      }
    ).then(handleResponse)
);

/**
 * Return the default beneficiary form for an gift product
 * @return {Promise} the promise of the default beneficiary form
 */
export const getDefaultBeneficiaryForm = withToken(
  (accessToken: string, locale): Promise<ClientForm> =>
    fetch(
      `${API_ENDPOINT}/client_forms/2?access_token=${accessToken}&embed=client_form_fields`,
      {
        headers: {
          "Accept-language": locale
        }
      }
    ).then(handleResponse)
);

/**
 * Return the default ticket holder form for an France Billet product or an Open Billet product
 * @return {Promise} the promise of the default ticket holder form
 */
export const getDefaultTicketHolderForm = withToken(
  (accessToken: string, locale): Promise<ClientForm> =>
    fetch(
      `${API_ENDPOINT}/client_forms/3?access_token=${accessToken}&embed=client_form_fields`,
      {
        headers: {
          "Accept-language": locale
        }
      }
    ).then(handleResponse)
);
