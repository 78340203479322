// @flow
// @jsx h
import { h, Component } from "preact";
import $ from "jquery";
import { Text } from "preact-i18n";
import styles from "./ParticipantClientForm.scss";
import { PrimaryButton } from "elc-button";
import { addParticipantClientData, createParticipants } from "elc-open-api";
// import FormInput from "./forminput";
import { FormInput } from "elc-client-forms";
import { route } from "preact-router";

import inputsValidation from "./inputsValidation";

type ParticipantClientFormProps = {
  infoPerParticipant: boolean,
  name: string,
  status: string,
  id: number,
  _embedded: {
    clientFormFields: Array<{
      mandatory: boolean,
      id: number,
      _embedded: {
        clientFormInputType: {
          inputType: string,
          label: string,
          id: number,
          _embedded: ?{
            options: {
              Madame: string,
              Monsieur: string
            }
          }
        }
      }
    }>
  },
  onParticipantSaved: Function
};

type ClientDetailsState = {
  participantClientData: ParticipantClientData,
  inputErrors: Array<number>
};

let tmpCurrentIndex = [];

export default class ParticipantClientForm extends Component<
  ParticipantClientFormProps | null,
  ClientDetailsState
> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      participantClientData: this.props.clientData || [],
      inputErrors: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientData !== this.props.clientData) {
      this.setState({ participantClientData: this.props.clientData || [] });
    }
  }

  handleInputChange = (inputValue: InputValue) => {
    inputValue.participantIndex = this.props.participantIndex + 1;
    this.setState({
      participantClientData: this.state.participantClientData
        .filter(
          value =>
            value.clientFormInputType.id !== inputValue.clientFormInputType.id
        )
        .concat(inputValue)
    });
  };

  handleSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.setState({ loading: true });

    this.setState({
      inputErrors: inputsValidation(
        this.props.participantClientForm._embedded.clientFormFields,
        this.state.participantClientData
      )
    });
    // TEST
    if (this.state.inputErrors.length === 0) {
      const participant = this.state.participantClientData
        .filter(
          participant =>
            participant.clientFormInputType.inputType != "EMAILCONFIRM"
        )
        .map(data => ({
          value: data.value,
          clientFormInputType: {
            id: data.clientFormInputType.id,
            type: data.clientFormInputType.type
          }
        }));
      // TODO : adapter filter dans dans TicketHoldersForm.js (France Billet) ???

      if (this.state.loading === true) {
        addParticipantClientData(
          this.props.bookingId,
          this.props.participantIndex + 1,
          participant
        )
          .then(savedData => {
            this.props.onParticipantSaved(
              this.props.participantIndex,
              savedData
            );
            this.handleRedirect(this.props.participantIndex);
            return savedData;
          })
          .catch(error => {
            console.error("Error addParticipantClientData", error);
          });

        // createParticipants(this.props.bookingId, participant).then(() => {}); // Suppression appel a API PHP qui fait doublon
      }
    } else {
      this.setState({ loading: false });
    }
  };

  handleRedirect(index) {
    let hasErrorField = false;
    let currentIndex = -1;
    let $participants = $("[class*=participantContainer]");
    // loop throught $participants
    // use for instead each so we can break look whenever we found error
    for (var i = 0; i < $participants.length; i++) {
      $($participants)
        .eq(i)
        .find("input, select")
        .each(function() {
          if (
            $(this)
              .closest("[class*=inputField]")
              .find(".requiredSign").length
          ) {
            if (this.value === "") {
              hasErrorField = true;
              currentIndex = i;
            }
          }
        });
      if (hasErrorField) {
        break;
      }
    }

    if (tmpCurrentIndex.indexOf(index) === -1) {
      tmpCurrentIndex.push(index);
    }

    // handle redirection
    if (currentIndex == -1 && tmpCurrentIndex.length === $participants.length) {
      route(`/participantclientforms/created`);
    } else {
      const $participant = $participants.eq(currentIndex);
      const $nextButton = $participant.find("button");
      if (hasErrorField) {
        $nextButton.click();
      }
    }
  }

  render({ participantClientForm }, { participantClientData, inputErrors }) {
    return (
      <div class={styles.participantForm}>
        <form onSubmit={this.handleSubmit} autoComplete="off">
          {participantClientForm &&
            participantClientForm._embedded.clientFormFields.map(
              clientFormField => (
                <FormInput
                  key={clientFormField.id}
                  clientFormField={clientFormField}
                  handleInputChange={this.handleInputChange}
                  hasValue={participantClientData.find(
                    data =>
                      data.clientFormInputType.id ===
                      clientFormField._embedded.clientFormInputType.id
                  )}
                  value={
                    participantClientData.find(
                      data =>
                        data.clientFormInputType.id ===
                        clientFormField._embedded.clientFormInputType.id
                    )
                      ? participantClientData.find(
                          data =>
                            data.clientFormInputType.id ===
                            clientFormField._embedded.clientFormInputType.id
                        ).value
                      : undefined
                  }
                  emailconfirmvalue={
                    participantClientData.find(
                      data =>
                        data.clientFormInputType.inputType === "EMAILCONFIRM"
                    )
                      ? participantClientData.find(
                          data =>
                            data.clientFormInputType.inputType ===
                            "EMAILCONFIRM"
                        ).value
                      : undefined
                  }
                  error={inputErrors.find(
                    inputError =>
                      inputError.id ===
                      clientFormField._embedded.clientFormInputType.id
                  )}
                  localeString={this.props.localeString}
                />
              )
            )}
          <PrimaryButton type="submit" className={styles.submitButton}>
            <Text id="participantClientForm.submit">Save</Text>
          </PrimaryButton>
        </form>
      </div>
    );
  }
}
