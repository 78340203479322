// @flow
// @jsx h

// External librairies
import { h } from "preact";

// Style
import styles from "./style.scss";

/**
 * Basket container Component
 * used to display the basket views in common styled container
 */
export default function BasketContainer({ children }) {
  return <div class={styles.basketContainer}>{children}</div>;
}
