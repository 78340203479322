// @flow
// @jsx h

// External librairies
import { h } from "preact";
import { Text } from "preact-i18n";
import Link from "../link";
import { ArrowLeftIcon } from "elc-icons";
import styles from "./PaymentConfirmation.scss";

// Components
import BankCheckConfirmation from "./BankCheckConfirmation";
import BankTransferConfirmation from "./BankTransferConfirmation";
import CreditCardConfirmation from "./CreditCardConfirmation";
import FreeOrderConfirmation from "./FreeOrderConfirmation";
import ParticipantsFormsLink from "./ParticipantsFormsLink";

/**
 * Component displaying the payment confirmation
 * depending on the chosen payment method
 */
const PaymentConfirmation = (
  {
    hash,
    config,
    locale,
    currency,
    order,
    payment,
    paymentMethod
  }: {
    hash: string,
    config: WidgetConfig,
    locale: string,
    currency: Currency,
    order: Order,
    payment: Payment,
    paymentMethod: PaymentMethod
  },
  { themeConfig }
) => {
  let _paymentObject = typeof payment !== "undefined" ? payment : paymentMethod;

  let _paymentMethod = paymentMethod
    ? paymentMethod.baseModule.code
    : undefined;

  return (
    <div class={styles.column}>
      {_paymentObject ? (
        order ? (
          _paymentMethod === "bankcheck" ? (
            <BankCheckConfirmation
              hash={hash}
              config={config}
              locale={locale}
              currency={currency}
              order={order}
              payment={payment}
              paymentMethod={paymentMethod}
            />
          ) : _paymentMethod === "bankwire" ? (
            <BankTransferConfirmation
              hash={hash}
              config={config}
              locale={locale}
              currency={currency}
              order={order}
              payment={payment}
              paymentMethod={paymentMethod}
            />
          ) : _paymentMethod === "creditcard" ? (
            <CreditCardConfirmation
              hash={hash}
              config={config}
              currency={currency}
              payment={payment}
              paymentMethod={paymentMethod}
              locale={locale}
            />
          ) : _paymentMethod === "freeorder" ? (
            <FreeOrderConfirmation />
          ) : (
            <div>Unknown payment method</div>
          )
        ) : _paymentMethod === "freeorder" ? (
          <FreeOrderConfirmation />
        ) : (
          <div>Unknown payment method</div>
        )
      ) : (
        <div>No payment or no order</div>
      )}
      <div>
        {order && <ParticipantsFormsLink order={order} />}
        {hash && (
          <Link href={`/${hash}/products`} className={styles.newOrderButton}>
            <ArrowLeftIcon
              color={
                themeConfig && themeConfig.color1
                  ? themeConfig.color1
                  : styles.arrowColor
              }
            />
            <Text id="payment.confirmation.newOrder">Pass a new order</Text>
          </Link>
        )}
      </div>
    </div>
  );
};

export default PaymentConfirmation;
