// @flow
// @jsx h
import { h, Component } from "preact";
import styles from "./style.scss";

import { Text } from "preact-i18n";
import ca from "../../../../translations/ca.json";
import de from "../../../../translations/de.json";
import en from "../../../../translations/en.json";
import es from "../../../../translations/es.json";
import fr from "../../../../translations/fr.json";
import it from "../../../../translations/it.json";
import nl from "../../../../translations/nl.json";

import { PrimaryButton } from "elc-button";
import { getProductTitleStyle } from "elc-theme";
import { toLocalePrice } from "elc-commons";

type ProductCardProps = {
  config: WidgetConfig,
  locale: String,
  currency: Currency,
  product: Product,
  onSelectProduct: Function
};

export default class ProductCard extends Component<ProductCardProps> {
  handleProductClick = () => {
    this.props.onSelectProduct(this.props.product.id);
  };

  getPriceWithTax(price: number, tax) {
    return tax ? price + price * (tax.rate / 100) : price;
  }

  render({
    locale,
    currency,
    product
  }: {
    locale: string,
    currency: Currency,
    product: Product
  }) {
    let language =
      locale === "fr"
        ? fr
        : locale === "en"
        ? en
        : locale === "de"
        ? de
        : locale === "es"
        ? es
        : locale === "it"
        ? it
        : locale === "nl"
        ? nl
        : ca;
    return (
      <article class={styles.card}>
        {product.primaryImage && (
          <picture class="thumbnail">
            <img
              src={product.primaryImage.path}
              alt={product.primaryImage.legend}
              class={styles.cardImg}
            />
          </picture>
        )}
        <div class={styles.cardContent}>
          <div class={styles.row}>
            <div class={styles.left}>
              <h3
                class={styles.cardTitle}
                style={getProductTitleStyle(this.context.themeConfig)}
              >
                {product.name}
              </h3>
              {product.type === "FRANCE_BILLET" && (
                <div>
                  {new Date(product.availableFromDate).toLocaleDateString() ===
                  new Date(product.availableToDate).toLocaleDateString()
                    ? new Date(product.availableFromDate).toLocaleDateString()
                    : `${new Date(
                        product.availableFromDate
                      ).toLocaleDateString()}-${new Date(
                        product.availableToDate
                      ).toLocaleDateString()}`}
                </div>
              )}
            </div>
            <div class={styles.right}>
              <h6 class={styles.startingPrice}>
                {language.product.startingPrice}
              </h6>
              <h3
                class={styles.price}
                style={`color:${
                  this.context.themeConfig && this.context.themeConfig.color1
                    ? this.context.themeConfig.color1
                    : ""
                };`}
              >
                {toLocalePrice(
                  product.type === "GIFT"
                    ? this.getPriceWithTax(
                        product.sellingPrices[0].value,
                        product.tax
                      ).toFixed(2)
                    : product.displayedStartingPrice,
                  locale,
                  currency.isoCode
                )}
              </h3>
            </div>
          </div>
          {!this.props.disabledReservation && (
            <PrimaryButton
              handleClick={this.handleProductClick}
              className={styles.goToProductButton}
            >
              {product.type === "GIFT"
                ? language.product.offer
                : language.product.book}
            </PrimaryButton>
          )}
        </div>
      </article>
    );
  }
}
