// @flow
// @jsx h

// External librairies
import { h, Component } from "preact";

// Components
import UnitQuantity from "./unitquantity";

type TimeRangeProps = {
  currency: Currency,
  timeRange: TimeRange
};

/**
 * Time range Component
 * displaying the details of a booking time range
 */
export default class TimeRangeRow extends Component<TimeRangeProps> {
  /**
   * render
   * @param {TimeRangeProps} the Time range component props
   * @return {ReactElement} markup
   */
  render({ currency, timeRange }: TimeRangeProps) {
    return (
      <div>
        {timeRange._embedded.unitQuantities.reduce(
          (acc, unitQuantity) =>
            unitQuantity.sessionQuantity === undefined ||
            unitQuantity.sessionQuantity === null ||
            unitQuantity.sessionQuantity > 0
              ? acc.concat(
                  <UnitQuantity
                    key={unitQuantity.id}
                    unitQuantity={unitQuantity}
                    currency={currency}
                    sessionIndex={timeRange.sessionIndex}
                  />
                )
              : acc,
          []
        )}
      </div>
    );
  }
}
