// @flow
// @jsx h
import { h, Component } from "preact";
import { Text } from "preact-i18n";

import styles from "./style.scss";
import { withStyles } from "elc-theme";

import Container from "../container";
import BookerContainer from "../bookercontainer";
import TimeSlot from "./time-slot";
import FooterButton from "../footerbutton";
import StartingPrice from "../starting-price";
import { BackButton } from "elc-button";
import { ArrowLeftIcon } from "elc-icons";
import Loading from "elc-loading";

import { sendCheckoutEventProductGTM } from "elc-commons";

import moment from "moment";
import "moment-timezone";

class TimeSlots extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.props
      .getProductTimeSlotAvailabilities()
      .then(timeSlots => {
        this.setState({ timeSlots, loading: false });
        this.props.setCurrentTimeSlots(timeSlots);
        return timeSlots;
      })
      .catch(error => {
        console.error("Error getting productTimeSlotAvailabilities", error);
      });
  }

  onSlotClick = timeSlot => {
    this.props.onSlotClick(timeSlot);
    sendCheckoutEventProductGTM(this.props.currentProduct, 3, false);
  };

  render(
    {
      config,
      currentProduct,
      currentQuantities,
      locale,
      currency,
      goToDay,
      currentSlot,
      theme,
      getClassName
    },
    { loading, timeSlots }
  ) {
    return (
      <Container>
        {loading ? (
          <BookerContainer>
            <Loading>
              <Text id="timeSlots.loading">Loading...</Text>
            </Loading>
          </BookerContainer>
        ) : (
          <BookerContainer>
            <div class={getClassName("timeSlotsContainer")}>
              {timeSlots.filter(
                timeSlot =>
                  moment(timeSlot.startDateTime)
                    .tz("Europe/Paris")
                    .hour() < 12
              ).length > 0 && (
                <div class={getClassName("label")}>
                  <Text id="timeSlots.morning">Morning</Text>
                </div>
              )}
              <div class={getClassName("row")}>
                {timeSlots
                  .filter(
                    timeSlot =>
                      moment(timeSlot.startDateTime)
                        .tz("Europe/Paris")
                        .hour() < 12
                  )
                  // .filter(
                  //   timeSlot => {
                  //     timeSlot._embedded.productUnitTimeSlotAvailabilities.find
                  //   }
                  // )
                  .map(timeSlot => {
                    return (
                      <TimeSlot
                        key={timeSlot.startDateTime}
                        slot={timeSlot}
                        theme={theme}
                        selected={
                          currentSlot &&
                          currentSlot.startDateTime === timeSlot.startDateTime
                            ? true
                            : false
                        }
                        currentProduct={currentProduct}
                        currentQuantities={currentQuantities}
                        onSlotClick={this.onSlotClick}
                      />
                    );
                  })}
              </div>
              {timeSlots.filter(
                timeSlot =>
                  moment(timeSlot.startDateTime)
                    .tz("Europe/Paris")
                    .hour() >= 12
              ).length > 0 && (
                <div class={getClassName("label")}>
                  <Text id="timeSlots.afternoon">Afternoon</Text>
                </div>
              )}
              <div class={getClassName("row")}>
                {timeSlots
                  .filter(
                    timeSlot =>
                      //new Date(timeSlot.startDateTime).getHours() >= 12
                      moment(timeSlot.startDateTime)
                        .tz("Europe/Paris")
                        .hour() >= 12
                  )
                  .map(timeSlot => {
                    return (
                      <TimeSlot
                        key={timeSlot.startDateTime}
                        slot={timeSlot}
                        theme={theme}
                        selected={
                          currentSlot &&
                          currentSlot.startDateTime === timeSlot.startDateTime
                            ? true
                            : false
                        }
                        currentProduct={currentProduct}
                        currentQuantities={currentQuantities}
                        onSlotClick={this.onSlotClick}
                      />
                    );
                  })}
              </div>
              <div class={getClassName("timeSlotsLegend")}>
                <div class={getClassName("timeSlotsBooked")}>
                  <span class={getClassName("timeSlotsColor")} />
                  <span class={getClassName("timeSlotsLegendCaption")}>
                    <Text id="timeSlots.booked">Booked</Text>
                  </span>
                </div>
                <div class={getClassName("timeSlotsAvailable")}>
                  <span class={getClassName("timeSlotsColor")} />
                  <span class={getClassName("timeSlotsLegendCaption")}>
                    <Text id="timeSlots.available">Available</Text>
                  </span>
                </div>
              </div>
            </div>
          </BookerContainer>
        )}
        {(config.type === "KIOSK" || config.type === "PMS") && (
          <StartingPrice
            theme={theme}
            productType={currentProduct.type}
            price={currentProduct.displayedStartingPrice}
            locale={locale}
            currency={currency}
          />
        )}
        {(!config || config.type !== "KIOSK" || config.type !== "PMS") && (
          <FooterButton theme={theme} config={config}>
            <BackButton handleClick={goToDay}>
              {config.type !== "KIOSK" && config.type !== "PMS" && (
                <ArrowLeftIcon
                  color={
                    this.context.themeConfig && this.context.themeConfig.color1
                      ? this.context.themeConfig.color1
                      : styles.green
                  }
                />
              )}
              <Text id="footer.button.previous">Previous</Text>
            </BackButton>
          </FooterButton>
        )}
      </Container>
    );
  }
}

export default withStyles(TimeSlots, styles);
